import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Delivery/deliverySlice";
import { approveCertificate, getDeliveryCertificate } from "../api";
import { toast } from "react-toastify";

const useDeliveryCertificate = (setCertificateList, certificateList) => {
  const deliveryState = useSelector((state) => state.delivery);
  const [status, setSubmissionStatus] = useState();

  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];
  const dispatch = useDispatch();

  const disableMultiSelect = useMemo(
    () => certificateList?.data?.data.every((item) => item.disable === true),
    //eslint-disable-next-line
    []
  );

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };
  const handleConfirmModal = (e) => {
    let certificateCheck = [];
    dispatch(
      updateConfig((state) => {
        state.showAddDeliverySecModal = !state.showAddDeliverySecModal;
        certificateCheck.push(...[e]);
        state.selectedDeliverySection = certificateCheck;
      })
    );
  };
  const handleConfirm = () => {
    let params = {
      recheck_item_details_id: deliveryState.selectedDeliverySection[0],
      status: status,
    };
    approveCertificate(params).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Success");
        handleConfirmModal();
        getDeliveryCertificate(deliveryState.selectedRecheckCardId).then(
          (response) => {
            const certificateList = response?.data;
            setCertificateList(certificateList);
          }
        );
      } else {
        toast.error("Something went wrong");
        handleConfirmModal();
      }
    });
  };

  return {
    paginationOptions,
    disableMultiSelect,
    deliveryState,
    getRow,
    handlePageSize,
    handleConfirm,
    handlePagination,
    handleConfirmModal,
    setSubmissionStatus,
  };
};

export default useDeliveryCertificate;
