import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  verificationData: {
    Pending: [],
    Approved: [],
  },
  pendingData: {},
  pendingStatus: "idle",
  approvedStatus: "idle",
  per_page: 10,
  selectedProfiles: [],
  PendingTotal: "",
  ApprovedTotal: "",
  currentPage: 1,
  search: "",
  from_date: "",
  to_date: "",
  item_type: "",
  shop: "",
  status: "",
  approveModal: false,
  rejectModal: false,
};
export const getPendingList = createAsyncThunk(
  "verification/pending-list",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/verification/pending-list`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getApprovedList = createAsyncThunk(
  "verification/approved-list",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/verification/approved-list`, params);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getPendingListById = createAsyncThunk(
  "verification/getPendingListById",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/verification/stock-recept-details?id=${params}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getApprovedListById = createAsyncThunk(
  "verification/getApprovedListById",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/verification/certificate-list?receipt_id=${params}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
const verificationSlice = createSlice({
  name: "verification",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setStoreFormValues: (state, action) => {
      state.from_date = action.payload.from_date;
      state.to_date = action.payload.to_date;
      state.item_type = action.payload.item_type;
      state.shop = action.payload.shop;
      state.status = action.payload.status;
    },
    resetExtraFilters: (state) => {
      state.from_date = "";
      state.to_date = "";
      state.item_type = "";
      state.shop = "";
      state.status = "";
    },
  },
  extraReducers: {
    [getPendingList.pending]: (state, action) => {
      state.pendingStatus = "pending";
    },
    [getPendingList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedPending = [];
      // Add existing items with unique _id values to uniqueItems
      state.verificationData.Pending.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedPending.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedPending.push(item);
        }
      });
      state.verificationData.Pending = combinedPending;
      state.PendingTotal = action.payload.data.data.total_count;
      state.pendingStatus = "fulfilled";
    },

    [getPendingList.rejected]: (state) => {
      state.pendingStatus = "failed";
    },

    [getApprovedList.pending]: (state) => {
      state.approvedStatus = "pending";
    },
    [getApprovedList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;

      const uniqueItems = {};
      const combinedApproved = [];

      // Add existing items with unique _id values to uniqueItems
      state.verificationData.Approved.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedApproved.push(item);
      });

      // Add new items with unique _id values to uniqueItems and combinedApproved
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedApproved.push(item);
        }
      });

      state.verificationData.Approved = combinedApproved;
      state.ApprovedTotal = action.payload.data.data.total_count;
      state.approvedStatus = "fulfilled";
    },

    [getApprovedList.rejected]: (state) => {
      state.approvedStatus = "failed";
    },
    [getPendingListById.fulfilled]: (state, action) => {
      state.pendingData = action?.payload?.data?.data;
    },
  },
});

export const { updateConfig, setStoreFormValues, resetExtraFilters } =
  verificationSlice.actions;

export default verificationSlice.reducer;
