import React from "react";
import useDeliveryCertificate from "./useDeliveryCertificate";
import {
  Button,
  ModalLayout,
  Pagination,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import AddStatus from "../../../Global/AddStatus";
import Style from "../delivery.module.scss";

const DeliveryCertificate = ({
  gliNumber,
  certificateList,
  setCertificateList,
  slipRef,
}) => {
  const {
    paginationOptions,
    deliveryState,
    disableMultiSelect,
    handlePrintSlip,
    getRow,
    handleConfirm,
    handlePageSize,
    handlePagination,
    handleConfirmModal,
    setSubmissionStatus,
  } = useDeliveryCertificate(setCertificateList, certificateList, slipRef);

  return (
    <>
      <div className={Style.print_head}>
        <h5 className="pro-ttl h5 pro-mb-2">{gliNumber}</h5>
        <Button className={Style.print_btn} onClick={handlePrintSlip}>
          <span className="material-symbols-outlined">print</span>
          Print Slip
        </Button>
      </div>
      <Table
        multiSelect={disableMultiSelect ? true : false}
        data={certificateList?.data?.data || []}
        uniqueID={"id"}
        fields={certificateList?.data?.fields || []}
        showCheckBox={true}
        SortIcon={<FaSort />}
        getRow={getRow}
        perpage={10}
        assignable={true}
        editable={false}
        deletable={false}
        assignText={"Submit"}
        assignIcon={
          <span className="material-symbols-outlined">check_circle</span>
        }
        handleAssign={handleConfirmModal}
      />
      <ModalLayout
        show={deliveryState?.showAddDeliverySecModal}
        handleClose={handleConfirmModal}
        title={"Choose status of submission?"}
      >
        <div className="pro-m-5">
          <AddStatus
            handleConfirm={handleConfirm}
            handleConfirmModal={handleConfirmModal}
            setSubmissionStatus={setSubmissionStatus}
          />
        </div>
      </ModalLayout>

      <Pagination
        currentPage={certificateList?.data?.current_page}
        totalPageCount={Math.ceil(
          certificateList?.data?.total_count / certificateList?.data?.per_page
        )}
        onPageChange={handlePagination}
        options={paginationOptions}
        onActionChange={handlePageSize}
        center
      />
    </>
  );
};

export default DeliveryCertificate;
