import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getCVDAssignedRecheckList,
  getCVDCompletedRecheckList,
  getCVDPendingRecheckList,
} from "../../../store/slices/CVD/CVDSlice";
import { updateConfig } from "../../../store/slices/CVD/CVDSlice";
import { getFields, startTimmer, submitForm } from "./api";
import { toast } from "react-toastify";
import { debounce } from "lodash";

const useCVDRecheck = () => {
  const [timer, setTimer] = useState(true);
  const [formValues, setFormValues] = useState();
  const [status, setSubmissionStatus] = useState();
  const [isLoading, setIsLoading] = useState();
  const [assignedPage, setAssignedPage] = useState(1);
  const [pendingPage, setPendingPage] = useState(1);
  const [completedPage, setCompletedPage] = useState(1);
  const [activeCard, setActiveCard] = useState({ id: "", key: "" });
  const {
    total,
    itemID,
    showForm,
    formType,
    valueType,
    allValues,
    showValues,
    assignedID,
    certificateID,
    cvdRecheckData,
    cvdPendingStatus,
    cvdAssignedStatus,
    cvdCompletedStatus,
    showCvdConfirmModal,
  } = useSelector((state) => state.cvd);
  const dispatch = useDispatch();
  const slug = localStorage?.getItem("slug");
  useEffect(() => {
    dispatch(
      getCVDAssignedRecheckList({
        page: assignedPage,
        per_page: 10,
        process_slug: slug,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedPage]);
  useEffect(() => {
    if (assignedID) {
      dispatch(
        getCVDPendingRecheckList({
          page: pendingPage,
          per_page: 10,
          receipt_id: assignedID,
          process_slug: slug,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingPage]);
  useEffect(() => {
    if (assignedID) {
      dispatch(
        getCVDCompletedRecheckList({
          page: completedPage,
          per_page: 10,
          receipt_id: assignedID,
          process_slug: slug,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedPage]);

  const handleCardClick = (item, key) => {
    setActiveCard({ id: item?._id, key: key });
    if (key === "Assigned") {
      dispatch(
        updateConfig((state) => {
          state.assignedID = item?._id;
          state.cvdRecheckData.Pending = [];
          state.cvdRecheckData.Completed = [];
        })
      );
      dispatch(
        getCVDPendingRecheckList({
          page: 1,
          per_page: 10,
          receipt_id: item?._id,
          process_slug: slug,
        })
      );
      dispatch(
        getCVDCompletedRecheckList({
          page: 1,
          per_page: 10,
          receipt_id: item?._id,
          process_slug: slug,
        })
      );
    } else if (key === "Pending") {
      startTimmer({
        recheck_item_details_id: item?._id,
        process_slug: slug,
      }).then((response) => {
        if (timer) {
          setTimer(false);
          if (response?.data?.status_code === 200) {
            toast.success("Timer Started");
          } else {
            toast.error("Can't start timer");
          }
        }
      });
      dispatch(
        updateConfig((state) => {
          state.certificateID = item?._id;
          state.itemID = item?.gli_number;
          if (item?.item_type_slug === "diamond_jewellery") {
            state.formType = "Form1";
          } else if (item?.item_type_slug === "diamond_loose") {
            state.formType = "Form2";
          } else {
            state.formType = "noForm";
          }
          state.showForm = true;
          state.showValues = false;
        })
      );
    } else if (key === "Passed") {
      dispatch(
        updateConfig((state) => {
          state.showForm = false;
          state.showValues = true;
        })
      );
      getFields(item?._id, slug).then((response) => {
        if (response?.data?.status_code === 200) {
          if (response?.data?.data?.item_type_slug === "diamond_jewellery") {
            dispatch(updateConfig((state) => (state.valueType = 1)));
          } else if (response?.data?.data?.item_type_slug === "diamond_loose") {
            dispatch(updateConfig((state) => (state.valueType = 2)));
          }
        }
        dispatch(
          updateConfig(
            (state) => (state.allValues = response?.data?.data?.graded_values)
          )
        );
      });
    }
  };

  const handleSearchInput = debounce((searchValue) => {
    dispatch(
      updateConfig((state) => {
        state.cvdRecheckData.Pending = [];
        state.cvdRecheckData.Completed = [];
        state.cvdRecheckData.Assigned = [];
      })
    );
    dispatch(
      getCVDAssignedRecheckList({
        search: searchValue,
        page: 1,
        process_slug: slug,
      })
    );
    if (assignedID) {
      dispatch(
        getCVDPendingRecheckList({
          search: searchValue,
          page: 1,
          receipt_id: assignedID,
          process_slug: slug,
        })
      );
      dispatch(
        getCVDCompletedRecheckList({
          search: searchValue,
          page: 1,
          receipt_id: assignedID,
          process_slug: slug,
        })
      );
    }
  }, 500);

  const handleSearch = (e) => {
    dispatch(updateConfig((state) => (state.search = e.target.value)));
    const inputValue = e.target.value;
    handleSearchInput(inputValue);
  };
  const handleConfirm = () => {
    let formData = new FormData();
    formData.append("recheck_item_details_id", certificateID);
    formData.append("rft_diamond_number", formValues?.rft_no);
    formData.append("percentage", formValues?.percentage);
    formData.append("image", formValues?.image);
    formData.append("status", status);
    submitForm(formData).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Successfully submitted");
        dispatch(updateConfig((state) => (state.showForm = false)));
        dispatch(
          getCVDPendingRecheckList({
            page: 1,
            per_page: 10,
            receipt_id: assignedID,
            process_slug: slug,
          })
        );
        dispatch(
          getCVDCompletedRecheckList({
            page: 1,
            per_page: 10,
            receipt_id: assignedID,
            process_slug: slug,
          })
        );
        handleConfirmModal();
      } else {
        toast.error("Something went wrong");
        handleConfirmModal();
      }
    });
  };
  const handleConfirmModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCvdConfirmModal = !state.showCvdConfirmModal;
      })
    );
  };
  const handleLoadMore = (mouseHover) => {
    if (
      mouseHover === "Pending" &&
      cvdRecheckData?.Pending?.length !== total?.Pending
    ) {
      setPendingPage(pendingPage + 1);
    }
    if (
      mouseHover === "Assigned" &&
      cvdRecheckData?.Assigned?.length !== total?.Assigned
    ) {
      setAssignedPage(assignedPage + 1);
    }
    if (
      mouseHover === "Passed" &&
      cvdRecheckData?.Completed?.length !== total?.Completed
    ) {
      setCompletedPage(completedPage + 1);
    }
  };

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cvdPendingStatus, cvdAssignedStatus, cvdCompletedStatus]);
  return {
    itemID,
    formType,
    showForm,
    valueType,
    allValues,
    activeCard,
    showValues,
    totalLength: {
      Pending: total?.Pending,
      Assigned: total?.Assigned,
      Completed: total?.Completed,
    },
    cvdRecheckData,
    showCvdConfirmModal,
    handleSearch,
    handleConfirm,
    setFormValues,
    setIsLoading,
    handleLoadMore,
    handleCardClick,
    setSubmissionStatus,
    handleConfirmModal,
  };
};

export default useCVDRecheck;
