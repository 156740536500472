import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Delivery/deliverySlice";
import {
  approveCertificate,
  getDeliveryCertificate,
  getPrintSlip,
} from "../api";
import { toast } from "react-toastify";
import html2pdf from "html2pdf.js";
import moment from "moment";

const useDeliveryCertificate = (
  setCertificateList,
  certificateList,
  slipRef
) => {
  const deliveryState = useSelector((state) => state.delivery);
  const [status, setSubmissionStatus] = useState();

  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];
  const dispatch = useDispatch();

  const disableMultiSelect = useMemo(
    () => certificateList?.data?.data.every((item) => item.disable === true),
    //eslint-disable-next-line
    []
  );

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };
  const handleConfirmModal = (e) => {
    let certificateCheck = [];
    dispatch(
      updateConfig((state) => {
        state.showAddDeliverySecModal = !state.showAddDeliverySecModal;
        certificateCheck.push(...[e]);
        state.selectedDeliverySection = certificateCheck;
      })
    );
  };
  const handleConfirm = () => {
    let params = {
      item_details_id: deliveryState.selectedDeliverySection[0],
      status: status,
    };
    approveCertificate(params).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Success");
        handleConfirmModal();
        getDeliveryCertificate(deliveryState.selectedCardId).then(
          (response) => {
            const certificateList = response?.data;
            setCertificateList(certificateList);
          }
        );
      } else {
        toast.error("Something went wrong");
        handleConfirmModal();
      }
    });
  };

  const handlePrintSlip = () => {
    const stockId = deliveryState?.selectedCardId;
    getPrintSlip(stockId).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => (state.printData = response?.data?.data))
        );
      } else {
        toast.error("Something went wrong");
      }
      const printSlip = () => {
        const element = slipRef?.current;
        html2pdf(element, {
          margin: [2, 2, 2, 2],
          filename: `${
            "Delivery Invoice" +
            "-" +
            moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
          }.pdf`,
          image: { type: "jpeg", quality: 1 },
          html2canvas: {
            dpi: 100,
            letterRendering: true,
            useCORS: true,
            logging: true,
            scale: 4,
            scrollY: 0,
          },
          jsPDF: { unit: "mm", format: "a5", orientation: "portrait" },
          pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
          DisablePdfCompression: true,
        });
      };
      setTimeout(() => {
        printSlip();
      }, 500);
    });
  };

  return {
    paginationOptions,
    disableMultiSelect,
    deliveryState,
    handlePrintSlip,
    getRow,
    handlePageSize,
    handleConfirm,
    handlePagination,
    handleConfirmModal,
    setSubmissionStatus,
  };
};

export default useDeliveryCertificate;
