import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../store/slices/Orders/orderSlice";
import { useSelector } from "react-redux";
import { useState } from "react";
import { updateOrderData } from "../../../../../store/slices/Orders/addOrderSlice";

const useEngraving = (formik, orderData) => {
  const { isEdit, engravingImagePreview } = useSelector((state) => state.order);
  const [engravingImage, setEngravingImage] = useState(
    isEdit ? orderData?.engraving?.engraving_logo : ""
  );
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddOrderModal = false;
      })
    );
  };

  const handleImageChange = (e) => {
    // if (!isEdit) {
    //   formik?.setFieldValue("engraving_image", e?.target?.files?.[0]);
    //   formik?.setFieldValue(
    //     "engraving_image_name",
    //     e?.target?.files?.[0]?.name
    //   );
    // }

    // const file = e.target.files[0];
    // const reader = new FileReader();
    // reader.onloadend = () => {
    //   dispatch(
    //     updateConfig((state) => {
    //       state.engravingImagePreview = reader.result;
    //     })
    //   );
    // };
    // if (file) {
    //   reader.readAsDataURL(file);
    // }
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik?.setFieldValue("engraving_image", e?.target?.files?.[0]);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setEngravingImage(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      // toast.error("The image must be less than 5MB in size.");
      formik.setFieldError(
        "engraving_image",
        "The image must be less than 5MB in size."
      );
    }
  };

  const handleSubmit = () => {
    dispatch(
      updateOrderData((state) => {
        state.activeTab = "Engraving";
      })
    );
  };

  return {
    isEdit,
    engravingImagePreview,
    engravingImage,
    handleSubmit,
    handleCloseModal,
    handleImageChange,
  };
};

export default useEngraving;
