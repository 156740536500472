import {
  DataContainer,
  Image,
  ModalLayout,
  SearchFilters,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useCVD from "./useCVDRecheck";
import Style from "./cvd.module.scss";
import OrderCardListing from "../../Global/OrderCardListing";
import Form1 from "./Form1";
import Form2 from "./Form2";
import AddStatus from "../../Global/AddStatus";

const CVDRecheck = () => {
  const {
    itemID,
    formType,
    showForm,
    showValues,
    allValues,
    isLoading,
    activeCard,
    totalLength,
    valueType,
    cvdRecheckData,
    showCvdConfirmModal,
    handleSearch,
    setFormValues,
    handleConfirm,
    handleCardClick,
    handleLoadMore,
    setIsLoading,
    handleConfirmModal,
    setSubmissionStatus,
  } = useCVD();
  return (
    <>
      <div className={Style.varifaication_main_wrap}>
        <div className="row">
          <div className={`col-xxl-4 col-5 ${Style.card_main_wrapper}`}>
            <div className="pro-mb-4">
              <SearchFilters
                showDateRange={false}
                onSearchInput={handleSearch}
                showFilters={true}
                showActions={false}
                SearchIcon={
                  <span className="material-symbols-outlined"> search </span>
                }
              />
            </div>
            <div className={`pro-d-flex ${Style.card_flow_wrap}`}>
              <OrderCardListing
                activeCard={activeCard}
                data={cvdRecheckData}
                handleChange={handleCardClick}
                hasButton={false}
                isLoading={isLoading}
                totalLength={totalLength}
                setIsLoading={setIsLoading}
                handleLoadMore={handleLoadMore}
              />
            </div>
          </div>
          {showForm ? (
            <div className={`col ${Style.table_main_wrapper}`}>
              <h6 className="pro-ttl h6">{itemID}</h6>
              {formType === "Form1" ? (
                <Form1
                  handleConfirmModal={handleConfirmModal}
                  setFormValues={setFormValues}
                />
              ) : formType === "Form2" ? (
                <Form2 />
              ) : (
                <h6>No forms </h6>
              )}
            </div>
          ) : showValues ? (
            <div className={`col ${Style.table_main_wrapper}`}>
              <h6 className="pro-ttl h6">{itemID}</h6>
              {valueType === 1 ? (
                <>
                  {Object?.keys(allValues || {})?.map((key) => {
                    if (key === "image") {
                      return (
                        <div>
                          <Image alt={"key"} src={allValues?.image?.path} />
                        </div>
                      );
                    } else {
                      const inputString = key;
                      const words = inputString.split("_");
                      const capitalizedWords = words
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ");
                      return (
                        <div Style={{ display: "flex" }}>
                          <p>
                            {capitalizedWords} : {allValues[key]}
                          </p>
                        </div>
                      );
                    }
                  })}
                </>
              ) : valueType === 2 ? (
                <div className="row gy-3 gx-3">
                  {Object.keys(allValues).map((key) => {
                    const formatKey = (key) => {
                      // Remove underscores and convert to uppercase
                      return key.replace(/_/g, " ").toUpperCase();
                    };
                    const formatSubKey = (subKey) => {
                      // Remove underscores and capitalize the first letter
                      return subKey
                        .replace(/_/g, " ")
                        .replace(/\w\S*/g, (txt) => {
                          return (
                            txt.charAt(0).toUpperCase() +
                            txt.substr(1).toLowerCase()
                          );
                        });
                    };
                    return (
                      <div key={key} className="col-6">
                        <DataContainer>
                          <div className={Style.content_box}>
                            <h6 className="h6 pro-ttl">{formatKey(key)}</h6>
                            {Object.keys(allValues[key]).map((subKey) => (
                              <p key={subKey} className="pro-mb-0">
                                {formatSubKey(subKey)} :{" "}
                                <b>{allValues[key][subKey]}</b>
                              </p>
                            ))}
                          </div>
                        </DataContainer>
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className={`col ${Style.table_main_wrapper}`}>{/* <h3>Select One</h3> */}</div>
          )}
        </div>
      </div>
      <ModalLayout
        show={showCvdConfirmModal}
        handleClose={handleConfirmModal}
        title={"Choose status of submission?"}
      >
        <div className="pro-m-5">
          <AddStatus
            handleConfirm={handleConfirm}
            handleConfirmModal={handleConfirmModal}
            setSubmissionStatus={setSubmissionStatus}
          />
        </div>
      </ModalLayout>
    </>
  );
};

export default CVDRecheck;
