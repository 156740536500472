import React from "react";
import { useSelector } from "react-redux";

const InvoicePdf = React.forwardRef(({ invoiceRef }) => {
  const { invoicePrintData } = useSelector((state) => state.billing);
  const data = invoicePrintData ?? [];
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();
  const todayDate = `${dd}.${mm}.${yyyy}`;

  const dataArrayWithoutTotal = data?.invoice_details?.filter(
    (item) => item?.description !== "TOTAL"
  );
  const totalObject = data?.invoice_details?.find(
    (item) => item?.description === "TOTAL"
  );

  return (
    <div ref={invoiceRef}>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>DHC INVOICE</title>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap"
        rel="stylesheet"
      />
      {/* <style
        dangerouslySetInnerHTML={{
          __html:
            "\n      th {\n        border: 1px solid #000000;\n        padding: 0 8px;\n        font-size: 12px;\n        text-align: center;\n        font-weight: 800;\n      }\n\n      td {\n        vertical-align: top;\n      }\n    ",
        }}
      /> */}
      <table
        align="center"
        border={0}
        cellSpacing={0}
        role="presentation"
        cellPadding={0}
        width="100%"
        style={{
          maxWidth: 595,
          minWidth: 595,
          borderCollapse: "collapse",
          backgroundColor: "#ffffff",
          boxSizing: "border-box",
          margin: "0 auto",
          fontFamily: '"Poppins", sans-serif',
          border: "1px solid #dfe3eb",
        }}
      >
        <tbody>
          <tr>
            <td style={{ padding: "48px 48px 14px 48px" }}>
              <table
                align="center"
                border={0}
                cellSpacing={0}
                role="presentation"
                cellPadding={0}
                width="100%"
                style={{
                  borderCollapse: "collapse",
                  backgroundColor: "#ffffff",
                  boxSizing: "border-box",
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ width: 88, paddingRight: 34 }}>
                      <img
                        src={data?.branch?.branch_logo}
                        alt="logo"
                        width={88}
                        height={88}
                        style={{
                          width: 88,
                          height: 88,
                          display: "inline-block",
                        }}
                      />
                    </td>
                    <td>
                      <table
                        align="center"
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        width="100%"
                        style={{
                          borderCollapse: "collapse",
                          backgroundColor: "#ffffff",
                          boxSizing: "border-box",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                fontSize: 22,
                                fontWeight: 800,
                                fontFamily: '"Poppins", sans-serif',
                                textAlign: "center",
                                lineHeight: "28px",
                              }}
                            >
                              {data?.branch?.branch_name ?? "Main heading"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontFamily: '"Poppins", sans-serif',
                                fontSize: 18,
                                fontWeight: 700,
                                textAlign: "center",
                                lineHeight: "24px",
                              }}
                            >
                              Certifies Diamonds &amp; Gemstones.
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontFamily: '"Poppins", sans-serif',
                                fontSize: 10,
                                fontWeight: 600,
                                textAlign: "center",
                                lineHeight: "12px",
                                paddingBottom: 4,
                              }}
                            >
                              {data?.branch?.branch_address?.address_line1 +
                                data?.branch?.branch_address?.address_line2}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontFamily: '"Poppins", sans-serif',
                                fontSize: 10,
                                fontWeight: 600,
                                textAlign: "center",
                                lineHeight: "12px",
                              }}
                            >
                              {data?.branch?.branch_address?.city +
                                "," +
                                data?.branch?.branch_address?.country +
                                "," +
                                data?.branch?.branch_address?.state +
                                "," +
                                "Ph" +
                                data?.branch?.country_code +
                                "-" +
                                data?.branch?.contact_number_1 +
                                "," +
                                data?.branch?.contact_number_2}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "0 48px 16px 48px" }}>
              <table
                align="center"
                border={0}
                cellSpacing={0}
                role="presentation"
                cellPadding={0}
                width="100%"
                style={{
                  borderCollapse: "collapse",
                  backgroundColor: "#ffffff",
                  boxSizing: "border-box",
                  border: "1px solid #000000",
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        textAlign: "left",
                        fontSize: 10,
                        textTransform: "uppercase",
                        fontWeight: 700,
                        paddingLeft: 8,
                      }}
                    >
                      GST IN: {data?.branch?.gst_number}
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                        textTransform: "uppercase",
                        fontSize: 10,
                        fontWeight: 700,
                        paddingRight: 8,
                      }}
                    >
                      PAN No: AAICD5796P.
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: 16,
                fontWeight: 800,
                textDecoration: "underline",
                textAlign: "center",
                padding: "0 48px 16px 48px",
              }}
            >
              TAX INVOICE
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: "0 48px 8px 48px",
                fontSize: 10,
                fontWeight: 800,
              }}
            >
              <table
                align="center"
                border={0}
                cellSpacing={0}
                role="presentation"
                cellPadding={0}
                width="100%"
                style={{
                  borderCollapse: "collapse",
                  backgroundColor: "#ffffff",
                  boxSizing: "border-box",
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ textAlign: "left", width: "50%" }}>
                      <table
                        align="center"
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        width="100%"
                        style={{
                          borderCollapse: "collapse",
                          backgroundColor: "#ffffff",
                          boxSizing: "border-box",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td style={{ maxWidth: 88, width: 88 }}>
                              Invoice No
                            </td>
                            <td style={{ padding: "0 8px", width: 10 }}>:</td>
                            <td>{data?.invoice_number}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <table
                        align="right"
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        width="100%"
                        style={{
                          borderCollapse: "collapse",
                          backgroundColor: "#ffffff",
                          boxSizing: "border-box",
                          width: 100,
                        }}
                      >
                        <tbody>
                          <tr>
                            <td>Date</td>
                            <td style={{ padding: "0 8px" }}>:</td>
                            <td>{todayDate}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", width: "75%" }}>
                      <table
                        align="center"
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        width="100%"
                        style={{
                          borderCollapse: "collapse",
                          backgroundColor: "#ffffff",
                          boxSizing: "border-box",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                maxWidth: 88,
                                width: 88,
                                verticalAlign: "top",
                              }}
                            >
                              Customer Name
                            </td>
                            <td
                              style={{
                                padding: "0 8px",
                                width: 10,
                                verticalAlign: "top",
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                textTransform: "uppercase",
                                verticalAlign: "top",
                              }}
                            >
                              {data?.customer_address?.address_line1} <br />
                              {data?.customer_address?.address_line2}{" "}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td style={{ textAlign: "right", verticalAlign: "top" }}>
                      <table
                        align="right"
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        width="100%"
                        style={{
                          borderCollapse: "collapse",
                          backgroundColor: "#ffffff",
                          boxSizing: "border-box",
                          width: 100,
                        }}
                      >
                        <tbody>
                          <tr>
                            <td>Work ID</td>
                            <td style={{ padding: "0 8px", width: 10 }}>:</td>
                            <td>00001.</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", width: "75%" }}>
                      <table
                        align="center"
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        width="100%"
                        style={{
                          borderCollapse: "collapse",
                          backgroundColor: "#ffffff",
                          boxSizing: "border-box",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td style={{ maxWidth: 88, width: 88 }}>GST NO</td>
                            <td style={{ padding: "0 8px", width: 10 }}>:</td>
                            <td style={{ textTransform: "uppercase" }}>
                              {data?.customer_gst_number}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", width: "75%" }}>
                      <table
                        align="center"
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        width="100%"
                        style={{
                          borderCollapse: "collapse",
                          backgroundColor: "#ffffff",
                          boxSizing: "border-box",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td style={{ maxWidth: 88, width: 88 }}>
                              Supplier Name
                            </td>
                            <td style={{ padding: "0 8px", width: 10 }}>:</td>
                            <td style={{ textTransform: "uppercase" }}>
                              {data?.supplier_name}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td />
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "0 48px" }}>
              <table
                align="center"
                border={0}
                cellSpacing={0}
                role="presentation"
                cellPadding={0}
                width="100%"
                style={{
                  borderCollapse: "collapse",
                  backgroundColor: "#ffffff",
                  boxSizing: "border-box",
                }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        textTransform: "uppercase",
                        width: 35,
                        border: "1px solid #000000",
                        padding: "0px 12px",
                        textAlign: "center",
                        fontSize: 12,
                        fontWeight: 800,
                      }}
                    >
                      SL NO
                    </th>
                    <th
                      style={{
                        width: 226,
                        border: "1px solid #000000",
                        padding: "0px 12px",
                        textAlign: "center",
                        fontSize: 12,
                        fontWeight: 800,
                      }}
                    >
                      Description
                    </th>
                    <th
                      style={{
                        width: 51,
                        textTransform: "uppercase",
                        border: "1px solid #000000",
                        padding: "0px 12px",
                        textAlign: "center",
                        fontSize: 12,
                        fontWeight: 800,
                      }}
                    >
                      SAC CODE
                    </th>
                    <th
                      style={{
                        width: 36,
                        border: "1px solid #000000",
                        padding: "0px 12px",
                        textAlign: "center",
                        fontSize: 12,
                        fontWeight: 800,
                      }}
                    >
                      Pcs
                    </th>
                    <th
                      style={{
                        width: 45,
                        border: "1px solid #000000",
                        padding: "0px 12px",
                        textAlign: "center",
                        fontSize: 12,
                        fontWeight: 800,
                      }}
                    >
                      Carat
                    </th>
                    <th
                      style={{
                        width: 45,
                        border: "1px solid #000000",
                        padding: "0px 12px",
                        textAlign: "center",
                        fontSize: 12,
                        fontWeight: 800,
                      }}
                    >
                      Rate
                    </th>
                    <th
                      style={{
                        width: 61,
                        border: "1px solid #000000",
                        padding: "0px 12px",
                        textAlign: "center",
                        fontSize: 12,
                        fontWeight: 800,
                      }}
                    >
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dataArrayWithoutTotal?.map((value, index) => {
                    return (
                      <tr key={index}>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            textAlign: "center",
                            fontSize: 11,
                            fontWeight: 700,
                            padding: 8,
                          }}
                        >
                          {value?.serial_number ?? ""}
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            textAlign: "left",
                            fontSize: 11,
                            fontWeight: 700,
                            padding: 8,
                          }}
                        >
                          {value?.description ?? ""}
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            textAlign: "center",
                            fontSize: 11,
                            fontWeight: 700,
                            padding: 8,
                          }}
                        >
                          {value?.sac_code ?? ""}
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            textAlign: "center",
                            fontSize: 11,
                            fontWeight: 700,
                            padding: 8,
                          }}
                        >
                          {value?.pcs ?? ""}
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            textAlign: "center",
                            fontSize: 11,
                            fontWeight: 700,
                            padding: 8,
                          }}
                        >
                          {value?.carat ?? ""}
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            textAlign: "center",
                            fontSize: 11,
                            fontWeight: 700,
                            padding: 8,
                          }}
                        >
                          {value?.rate ?? ""}
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            textAlign: "center",
                            fontSize: 11,
                            fontWeight: 700,
                            padding: 8,
                            borderRight: "1px solid #000",
                          }}
                        >
                          {value?.amount ?? ""}
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td
                      style={{
                        borderLeft: "1px solid #000",
                        textAlign: "center",
                        fontSize: 11,
                        fontWeight: 700,
                        padding: 8,
                        paddingTop: 16,
                        borderBottom: "1px solid #000",
                        borderTop: "1px solid #000",
                      }}
                    >
                      {totalObject?.serial_number ?? ""}
                    </td>
                    <td
                      style={{
                        borderLeft: "1px solid #000",
                        fontSize: 11,
                        fontWeight: 700,
                        padding: 8,
                        paddingTop: 16,
                        borderBottom: "1px solid #000",
                        borderTop: "1px solid #000",
                        textAlign: "center",
                      }}
                    >
                      TOTAL
                    </td>
                    <td
                      style={{
                        borderLeft: "1px solid #000",
                        textAlign: "center",
                        fontSize: 11,
                        fontWeight: 700,
                        padding: 8,
                        paddingTop: 16,
                        borderBottom: "1px solid #000",
                        borderTop: "1px solid #000",
                      }}
                    >
                      {totalObject?.sac_code ?? ""}
                    </td>
                    <td
                      style={{
                        borderLeft: "1px solid #000",
                        textAlign: "center",
                        fontSize: 11,
                        fontWeight: 700,
                        padding: 8,
                        paddingTop: 16,
                        borderBottom: "1px solid #000",
                        borderTop: "1px solid #000",
                      }}
                    >
                      {totalObject?.pcs ?? ""}
                    </td>
                    <td
                      style={{
                        borderLeft: "1px solid #000",
                        textAlign: "center",
                        fontSize: 11,
                        fontWeight: 700,
                        padding: 8,
                        paddingTop: 16,
                        borderBottom: "1px solid #000",
                        borderTop: "1px solid #000",
                      }}
                    >
                      {totalObject?.carat}
                    </td>
                    <td
                      style={{
                        borderLeft: "1px solid #000",
                        textAlign: "center",
                        fontSize: 11,
                        fontWeight: 700,
                        padding: 8,
                        paddingTop: 16,
                        borderBottom: "1px solid #000",
                        borderTop: "1px solid #000",
                      }}
                    >
                      {totalObject?.rate}
                    </td>
                    <td
                      style={{
                        borderLeft: "1px solid #000",
                        textAlign: "center",
                        fontSize: 11,
                        fontWeight: 700,
                        padding: 8,
                        borderRight: "1px solid #000",
                        paddingTop: 16,
                        borderBottom: "1px solid #000",
                        borderTop: "1px solid #000",
                      }}
                    >
                      {totalObject?.amount}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={4}
                      style={{
                        borderLeft: "1px solid #000",
                        borderRight: "1px solid #000",
                        padding: 8,
                        verticalAlign: "top",
                        borderBottom: "1px solid #000",
                      }}
                    >
                      <table
                        align="center"
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        width="100%"
                        style={{
                          borderCollapse: "collapse",
                          backgroundColor: "#ffffff",
                          boxSizing: "border-box",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td>
                              <table
                                align="center"
                                border={0}
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                width="100%"
                                style={{
                                  borderCollapse: "collapse",
                                  backgroundColor: "#ffffff",
                                  boxSizing: "border-box",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      colSpan={2}
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 800,
                                        textDecoration: "underline",
                                        paddingBottom: 12,
                                      }}
                                    >
                                      Bank Details
                                    </td>
                                  </tr>
                                  {data?.branch?.bank_details?.map(
                                    (value, index) => {
                                      return (
                                        <div key={index}>
                                          <tr>
                                            <td
                                              style={{
                                                fontWeight: 700,
                                                fontSize: 10,
                                                paddingRight: 4,
                                                lineHeight: "12px",
                                                paddingBottom: 4,
                                              }}
                                            >
                                              {index + 1}.
                                            </td>
                                            <td
                                              style={{
                                                fontWeight: 700,
                                                fontSize: 10,
                                                lineHeight: "12px",
                                                paddingBottom: 4,
                                              }}
                                            >
                                              {value?.bank_name}
                                            </td>
                                          </tr>

                                          <tr>
                                            <td
                                              style={{
                                                fontWeight: 700,
                                                fontSize: 10,
                                                paddingRight: 4,
                                                lineHeight: "12px",
                                                paddingBottom: 4,
                                              }}
                                            />
                                            <td
                                              style={{
                                                fontWeight: 700,
                                                fontSize: 10,
                                                lineHeight: "12px",
                                                paddingBottom: 4,
                                              }}
                                            >
                                              A/C No : {value?.account_number}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                fontWeight: 700,
                                                fontSize: 10,
                                                paddingRight: 4,
                                                lineHeight: "12px",
                                                paddingBottom: 4,
                                              }}
                                            />
                                            <td
                                              style={{
                                                fontWeight: 700,
                                                fontSize: 10,
                                                lineHeight: "12px",
                                                paddingBottom: 4,
                                              }}
                                            >
                                              IFSC : {value?.ifsc}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                fontWeight: 700,
                                                fontSize: 10,
                                                paddingRight: 4,
                                                lineHeight: "12px",
                                                paddingBottom: 4,
                                              }}
                                            />
                                            <td
                                              style={{
                                                fontWeight: 700,
                                                fontSize: 10,
                                                lineHeight: "12px",
                                                paddingBottom: 4,
                                              }}
                                            >
                                              {value?.bank_address}
                                            </td>
                                          </tr>
                                        </div>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td
                      colSpan={2}
                      style={{
                        fontSize: 11,
                        fontWeight: 800,
                        verticalAlign: "top",
                        borderRight: "1px solid #000",
                        borderBottom: "1px solid #000",
                      }}
                    >
                      <table
                        align="center"
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        width="100%"
                        style={{
                          borderCollapse: "collapse",
                          backgroundColor: "#ffffff",
                          boxSizing: "border-box",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                padding: 5,
                                borderBottom: "1px solid #000",
                                height: 20,
                              }}
                            >
                              Discount
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: 5,
                                borderBottom: "1px solid #000",
                                height: 20,
                              }}
                            >
                              Taxable Value
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: 5,
                                borderBottom: "1px solid #000",
                                height: 20,
                              }}
                            >
                              SGST (9%)
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: 5,
                                borderBottom: "1px solid #000",
                                height: 20,
                              }}
                            >
                              CGST (9%)
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: 5,
                                borderBottom: "1px solid #000",
                                height: 20,
                              }}
                            >
                              IGST (18%)
                            </td>
                          </tr>
                          <tr>
                            <td style={{ padding: 5, height: 20 }}>
                              Grand Total
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td
                      style={{
                        borderRight: "1px solid #000",
                        fontSize: 11,
                        fontWeight: 800,
                        verticalAlign: "top",
                        borderBottom: "1px solid #000",
                      }}
                    >
                      <table
                        align="center"
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        width="100%"
                        style={{
                          borderCollapse: "collapse",
                          backgroundColor: "#ffffff",
                          boxSizing: "border-box",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                padding: 5,
                                borderBottom: "1px solid #000",
                                height: 20,
                                textAlign: "center",
                              }}
                            >
                              {data?.discount !== "" ? data?.discount : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: 5,
                                borderBottom: "1px solid #000",
                                height: 20,
                                textAlign: "center",
                              }}
                            >
                              {data?.taxable_value !== ""
                                ? data?.taxable_value
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: 5,
                                borderBottom: "1px solid #000",
                                height: 20,
                                textAlign: "center",
                              }}
                            >
                              {data?.sgst !== "" ? data?.sgst : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: 5,
                                borderBottom: "1px solid #000",
                                height: 20,
                                textAlign: "center",
                              }}
                            >
                              {data?.cgst ? data?.cgst : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: 5,
                                borderBottom: "1px solid #000",
                                height: 20,
                                textAlign: "center",
                              }}
                            >
                              {data?.igst ? data?.igst : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: 5,
                                height: 20,
                                textAlign: "center",
                              }}
                            >
                              {data?.grand_total !== ""
                                ? data?.grand_total
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={4}
                      style={{
                        fontSize: 9,
                        fontWeight: 700,
                        padding: 8,
                        borderLeft: "1px solid #000",
                        borderRight: "1px solid #000",
                        borderBottom: "1px solid #000",
                      }}
                    >
                      Rupees :- {data?.total_in_words ?? "-"}
                    </td>
                    <td
                      colSpan={2}
                      style={{
                        fontSize: 11,
                        fontWeight: 800,
                        borderBottom: "1px solid #000",
                        borderRight: "1px solid #000",
                        padding: 8,
                      }}
                    >
                      Rounded Off
                    </td>
                    <td
                      style={{
                        fontSize: 11,
                        fontWeight: 800,
                        padding: 8,
                        textAlign: "center",
                        borderBottom: "1px solid #000",
                        borderRight: "1px solid #000",
                      }}
                    >
                      {data?.round_off !== "" ? data?.round_off : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ padding: "16px 0" }} />
                    <td
                      colSpan={5}
                      style={{
                        padding: "16px 0",
                        fontSize: 11,
                        fontWeight: 700,
                        textAlign: "center",
                      }}
                    >
                      For DHC Gem Lab &amp; Institute Pvt Ltd
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ paddingBottom: 48 }} />
                    <td
                      colSpan={5}
                      style={{
                        fontSize: 11,
                        fontWeight: 700,
                        textAlign: "center",
                        paddingBottom: 48,
                      }}
                    >
                      Authorized Signatory
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});

export default InvoicePdf;
