import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getCardAssignedList,
  getCardPendingList,
  updateConfig,
} from "../../../store/slices/Staff/staffSlice";
import { debounce } from "lodash";

const useStaffWindow = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeCard, setActiveCard] = useState({ id: "", key: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [pendingPage, setPendingPage] = useState(1);
  const [assignedPage, setAssignedPage] = useState(1);
  const staffState = useSelector((state) => state.staff);
  const slug = localStorage?.getItem("slug");
  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.cardData.Pending = [];
        state.cardData.Assigned = [];
      })
    );
    dispatch(
      getCardPendingList({
        page: 1,
        per_page: 10,
        date: staffState.date,
        item_type: staffState.item_type,
        shop: staffState.shop,
        status: staffState.status,
        process_slug: slug,
      })
    );
    dispatch(
      getCardAssignedList({
        page: 1,
        per_page: 10,
        date: staffState.date,
        item_type: staffState.item_type,
        shop: staffState.shop,
        status: staffState.status,
        process_slug: slug,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    staffState.date,
    staffState.item_type,
    staffState.shop,
    staffState.status,
    slug,
  ]);

  useEffect(() => {
    if (pendingPage !== 1) {
      dispatch(
        getCardPendingList({
          page: pendingPage,
          per_page: 10,
          process_slug: slug,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingPage]);
  useEffect(() => {
    if (assignedPage !== 1) {
      dispatch(
        getCardAssignedList({
          page: assignedPage,
          per_page: 10,
          process_slug: slug,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedPage]);

  const handleFilter = () => {
    dispatch(
      updateConfig((state) => {
        state.showFilter = !state.showFilter;
      })
    );
  };

  const handleCardClick = (item, key) => {
    setActiveCard({ id: item?._id, key: key });
    dispatch(
      updateConfig((state) => {
        state.gliData.name = item?.gli_number;
        state.gliData.id = item?._id;
        state.gliData.shop = item?.shop;
      })
    );
    if (key === "Pending") {
      // dispatch(getTableData(item?._id));
      dispatch(
        updateConfig((state) => {
          state.showAssignedTable = false;
          state.showTable = true;
        })
      );
    } else if (key === "Assigned") {
      dispatch(
        updateConfig((state) => {
          // state.certificateData = response?.data?.data;
          state.showTable = false;
          state.showAssignedTable = true;
        })
      );
      // assignedCertificate(item?._id).then((response) => {
      //   if (response?.data?.status_code === 200) {
      //     dispatch(
      //       updateConfig((state) => {
      //         state.certificateData = response?.data?.data;
      //         state.showTable = false;
      //         state.showAssignedTable = true;
      //       })
      //     );
      //   } else {
      //     toast.error("Something went wrong");
      //   }
      // });
    }
  };

  const handleLoadMore = (mouseHover) => {
    if (
      mouseHover === "Pending" &&
      staffState?.cardData?.Pending?.length !== staffState?.PendingTotal
    ) {
      setPendingPage(pendingPage + 1);
    }
    if (
      mouseHover === "Assigned" &&
      staffState?.cardData?.Assigned?.length !== staffState?.AssignedTotal
    ) {
      setAssignedPage(assignedPage + 1);
    }
  };
  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffState?.cardAssignedStatus, staffState?.cardPendingStatus]);

  const handleStartClick = () => {
    switch (localStorage?.getItem("slug")) {
      case "cvd_rft":
        navigate("cvd-work");
        break;
      case "qc":
        navigate("qc-work");
        break;
      case "metal_qc":
        navigate("metal-qc-work");
        break;
      case "og_qc":
        navigate("old-gold-qc-work");
        break;
      case "final_qc":
        navigate("final-qc-work");
        break;
      case "photo_section":
        navigate("photo-upload-work");
        break;
      case "engraving":
        navigate("engraving-work");
        break;
      case "billing":
        navigate("billing");
        break;
      case "packing":
        navigate("packing-work");
        break;
      case "printing":
        navigate("printing-work");
        break;
      case "delivery":
        navigate("delivery-work");
        break;
      default:
        navigate("/");
    }
  };
  const handleSearchInput = debounce((searchValue) => {
    dispatch(
      updateConfig((state) => {
        state.cardData.Pending = [];
        state.cardData.Assigned = [];
      })
    );
    dispatch(
      getCardPendingList({
        search: searchValue,
        page: 1,
      })
    );
    dispatch(
      getCardAssignedList({
        search: searchValue,
        page: 1,
      })
    );
  }, 500);

  // Inside your component
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
    const inputValue = e.target.value;
    handleSearchInput(inputValue);
  };

  return {
    staffState,
    activeCard,
    isLoading,
    totalLength: {
      Pending: staffState?.PendingTotal,
      Assigned: staffState?.AssignedTotal,
    },
    handleLoadMore,
    setIsLoading,
    handleFilter,
    handleSearch,
    handleCardClick,
    handleStartClick,
  };
};

export default useStaffWindow;
