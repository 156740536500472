import {
  Button,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import usePendingTable from "./usePendingTable";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../OrderColumn";

const PendingTable = () => {
  const {
    getRow,
    isLoading,
    isFetching,
    staffState,
    tableFields,
    showEditModal,
    pendingTableData,
    paginationOptions,
    refetch,
    handleSearch,
    handlePageSize,
    handlePagination,
    handleAssignClick,
    updatePendingFields,
    handleEditColumnsClick,
  } = usePendingTable();

  return (
    <div>
      <SearchFilters
        onSearchInput={handleSearch}
        SearchIcon={<span className="material-symbols-outlined"> search </span>}
        showClearFilters={false}
        searchInputProps={{ value: staffState?.pendingSearch }}
        showActions={true}
        handleActionClick={handleEditColumnsClick}
        loading={isLoading}
      />
      <Table
        multiSelect={pendingTableData?.data?.data?.length !== 0 ? true : false}
        data={pendingTableData?.data?.data ?? []}
        uniqueID={"id"}
        fields={tableFields}
        SortIcon={<FaSort />}
        assignIcon={<span className="material-symbols-outlined">badge</span>}
        getRow={getRow}
        loading={isFetching}
        perpage={staffState?.currentPageSize}
        assignable={true}
        handleAssign={handleAssignClick}
        deletable={false}
        editable={false}
        showCheckBox={true}
      />
      <Pagination
        currentPage={pendingTableData?.data?.current_page}
        totalPageCount={Math.ceil(
          pendingTableData?.data?.total_count / pendingTableData?.data?.per_page
        )}
        onPageChange={handlePagination}
        options={paginationOptions}
        onActionChange={handlePageSize}
        center
      />
      <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
        <div className="pro-m-5">
          <OrderColumn
            title={"Choose which columns you see"}
            refetchData={refetch}
            tableFields={pendingTableData?.data?.fields}
            moduleId={pendingTableData?.data?.module_id}
            updateData={updatePendingFields}
          />
        </div>
      </ModalLayout>
    </div>
  );
};

export default PendingTable;
