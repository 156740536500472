import React from "react";
import Select from "react-select";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const RecheckFilterForm = ({ formik, handleReset, FilterData }) => {
  return (
    <div>
      {/* Render form fields for the "Basic" tab */}

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          From Date
        </label>
        <input
          className={`pro-input lg`}
          type="date"
          name="from_date"
          placeholder="Date of Birth"
          onChange={formik.handleChange}
          value={formik.values.from_date}
        />
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          To Date
        </label>
        <input
          className={`pro-input lg`}
          type="date"
          name="to_date"
          placeholder="Date of Birth"
          onChange={formik.handleChange}
          value={formik.values.to_date}
        />
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          From Range
        </label>
        <input
          className={`pro-input lg`}
          type="text"
          name="from_range"
          placeholder="Example : 100"
          onChange={(e) => formik.setFieldValue("from_range", e.target.value)}
          value={formik.values.from_range}
        />
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          To Range
        </label>
        <input
          className={`pro-input lg`}
          type="text"
          name="to_range"
          placeholder="Example : 200"
          onChange={(e) => formik.setFieldValue("to_range", e.target.value)}
          value={formik.values.to_range}
        />
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Item Type
        </label>
        <Select
          id="Item_type"
          placeholder={"Select"}
          className={"pro-input lg"}
          classNamePrefix="pro-input"
          isMulti
          name="item_type"
          options={FilterData?.data?.item_types}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={FilterData?.data?.item_types?.filter((m) =>
            formik.values.item_type?.includes?.(m._id)
          )}
          onChange={(value) =>
            formik.setFieldValue(
              "item_type",
              value.map((v) => v._id)
            )
          }
          menuPlacement="auto"
        />
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Shop
        </label>
        <Select
          id="shop"
          placeholder={"Select"}
          className={"pro-input lg"}
          classNamePrefix="pro-input"
          isMulti
          name="shop"
          options={FilterData?.data?.shops}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={FilterData?.data?.shops?.filter((m) =>
            formik.values.shop?.includes?.(m._id)
          )}
          onChange={(value) =>
            formik.setFieldValue(
              "shop",
              value.map((v) => v._id)
            )
          }
          menuPlacement="auto"
        />
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Status
        </label>
        <Select
          id="status"
          placeholder={"Select"}
          className={"pro-input lg"}
          classNamePrefix="pro-input"
          isMulti
          name="status"
          options={FilterData?.data?.status}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={FilterData?.data?.status?.filter((m) =>
            formik.values.status?.includes?.(m.id)
          )}
          onChange={(value) =>
            formik.setFieldValue(
              "status",
              value.map((v) => v.id)
            )
          }
          menuPlacement="auto"
        />
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button className={"pro-btn-link lg pro-px-5"} onClick={handleReset}>
          Reset
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default RecheckFilterForm;
