import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useAddItemType from "./useAddItemType";

const AddItemType = ({ refetch, isChecked, setIsChecked, handleChecked }) => {
  const { formik, basicData, serviceState, getFieldError, handleCloseModal } =
    useAddItemType({ refetch, isChecked, setIsChecked });
  return (
    <div className="row pro-p-4">
      <Input
        label={"Name"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${getFieldError("name") && " error"}`}
        {...formik.getFieldProps("name")}
        error={getFieldError("name")}
        errorMessage={getFieldError("name")}
      />
      <Input
        label={"Code"}
        type="text"
        id="code"
        name="code"
        className={`pro-input lg ${getFieldError("code") && " error"}`}
        {...formik.getFieldProps("code")}
        error={getFieldError("code")}
        errorMessage={getFieldError("code")}
      />
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Services
        </label>
        <Select
          id="services"
          name="services"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("services") && " error"}`}
          classNamePrefix="pro-input"
          options={basicData?.services ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?._id}
          value={formik?.values?.services}
          onBlur={formik.handleBlur("services")}
          isMulti={true}
          onChange={(value) => formik?.setFieldValue("services", value || null)}
          menuPlacement="auto"
        />
        {getFieldError("services") && (
          <span className="error-text">{getFieldError("services")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          QC Fields
        </label>
        <Select
          id="qc_fields"
          name="qc_fields"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("qc_fields") && " error"}`}
          classNamePrefix="pro-input"
          options={basicData?.qc_fields ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?._id}
          value={formik?.values?.qc_fields}
          onBlur={formik.handleBlur("qc_fields")}
          isMulti={true}
          onChange={(value) =>
            formik?.setFieldValue("qc_fields", value || null)
          }
          menuPlacement="auto"
        />
        {getFieldError("qc_fields") && (
          <span className="error-text">{getFieldError("qc_fields")}</span>
        )}
      </div>
      <div className="pro-check-box">
        <input
          type="checkbox"
          className="pro-check"
          id="isCenterStone"
          name="isCenterStone"
          onChange={handleChecked}
          // eslint-disable-next-line eqeqeq
          checked={isChecked}
        />
        <label htmlFor="isCenterStone" className="pro-check-label">
          {" is Center Stone "}
        </label>
      </div>
      {isChecked ? (
        <div>
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Center Stone
            </label>
            <Select
              id="center_stone_fields"
              name="center_stone_fields"
              placeholder={"Select"}
              className={`pro-input lg  ${
                getFieldError("center_stone_fields") && " error"
              }`}
              classNamePrefix="pro-input"
              options={basicData?.qc_fields ?? []}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?._id}
              value={formik?.values?.center_stone_fields}
              onBlur={formik.handleBlur("center_stone_fields")}
              isMulti={true}
              onChange={(value) =>
                formik?.setFieldValue("center_stone_fields", value || null)
              }
              menuPlacement="auto"
            />
            {getFieldError("center_stone_fields") && (
              <span className="error-text">
                {getFieldError("center_stone_fields")}
              </span>
            )}
          </div>
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Side Stone
            </label>
            <Select
              id="side_stone_fields"
              name="side_stone_fields"
              placeholder={"Select"}
              className={`pro-input lg  ${
                getFieldError("side_stone_fields") && " error"
              }`}
              classNamePrefix="pro-input"
              options={basicData?.qc_fields ?? []}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?._id}
              value={formik?.values?.side_stone_fields}
              onBlur={formik.handleBlur("side_stone_fields")}
              isMulti={true}
              onChange={(value) =>
                formik?.setFieldValue("side_stone_fields", value || null)
              }
              menuPlacement="auto"
            />
            {getFieldError("side_stone_fields") && (
              <span className="error-text">
                {getFieldError("side_stone_fields")}
              </span>
            )}
          </div>
        </div>
      ) : null}

      {/* <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          CVD Fields
        </label>
        <Select
          id="cvd_fields"
          name="cvd_fields"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("cvd_fields") && " error"}`}
          classNamePrefix="pro-input"
          options={basicData?.cvd_fields ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?._id}
          value={formik?.values?.cvd_fields}
          onBlur={formik.handleBlur("cvd_fields")}
          isMulti={true}
          onChange={(value) =>
            formik?.setFieldValue("cvd_fields", value || null)
          }
        />
        {getFieldError("cvd_fields") && (
          <span className="error-text">{getFieldError("cvd_fields")}</span>
        )}
      </div> */}
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {serviceState?.isEdit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default AddItemType;
