import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/EngravingSection/engravingSectionSlice";
import { approveCertificate, getEngravingCertificate } from "../api";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import { saveAs } from "file-saver";
import Style from "../engravingSection.module.scss";
import moment from "moment";
import { toast } from "react-toastify";

const useEngravingCertificate = (setCertificateList, certificateList) => {
  const engravingSectionState = useSelector((state) => state.engravingSection);
  const [status, setSubmissionStatus] = useState();
  const [imageData, setImageData] = useState();
  const [showImageModal, setShowImageModal] = useState(false);

  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];
  const dispatch = useDispatch();

  const disableMultiSelect = useMemo(
    () => certificateList?.data?.data.every((item) => item.disable === true),
    //eslint-disable-next-line
    []
  );
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      engraving_logo: (feild, data) =>
        data?.[feild] ? (
          <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Photo"}`}
            onClick={() => handleViewImage(data?.[feild])}
          />
        ) : (
          ""
        ),
      engraving_text: (field, data) =>
        data?.[field] ? (
          <div className="pro-d-flex pro-items-center pro-gap-2 no-wrap">
            <p className="pro-mb-0">{data[field]}</p>
            <span
              className={Style.copy_text}
              onClick={() => handleCopyText(data[field])}
            >
              <span className="material-symbols-outlined">content_copy</span>
            </span>
          </div>
        ) : (
          ""
        ),
      engraving_format: (field, data) =>
        data?.[field] ? (
          <div className="pro-d-flex pro-items-center pro-gap-2 no-wrap">
            <p className="pro-mb-0">{data[field]}</p>
            <span
              className={Style.copy_text}
              onClick={() => handleCopyFormat(data[field])}
            >
              <span className="material-symbols-outlined">content_copy</span>
            </span>
          </div>
        ) : (
          ""
        ),
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleViewImage = (logoImage) => {
    setImageData(logoImage);
    setShowImageModal(true);
  };
  const closeModal = () => {
    setShowImageModal(false);
  };

  const handleCopyText = async (data) => {
    try {
      await navigator.clipboard.writeText(data);
      toast.success("Copied");
    } catch (error) {
      toast.error("Failed to copy");
    }
  };

  const handleCopyFormat = async (data) => {
    try {
      await navigator.clipboard.writeText(data);
      toast.success("Copied");
    } catch (error) {
      toast.error("Failed to copy");
    }
  };

  const handleDownload = (e) => {
    saveAs(
      e,
      `${
        "Engraving-image" +
        "-" +
        moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
      }`
    );
  };

  const handleConfirmModal = (e) => {
    let certificateCheck = [];
    dispatch(
      updateConfig((state) => {
        state.showAddEngravingSecModal = !state.showAddEngravingSecModal;
        certificateCheck.push(...[e]);
        state.selectedEngravingSection = certificateCheck;
      })
    );
  };
  const handleConfirm = () => {
    let params = {
      item_details_id: engravingSectionState.selectedEngravingSection[0],
      status: status,
    };
    approveCertificate(params).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Certificate approved successfully");
        handleConfirmModal();
        getEngravingCertificate({
          receipt_id: engravingSectionState.selectedCardId,
        }).then((response) => {
          const certificateList = response?.data;
          setCertificateList(certificateList);
        });
      } else {
        toast.error("Something went wrong");
        handleConfirmModal();
      }
    });
  };

  return {
    paginationOptions,
    disableMultiSelect,
    engravingSectionState,
    imageData,
    showImageModal,
    handleDownload,
    closeModal,
    getRow,
    handlePageSize,
    handleConfirm,
    handlePagination,
    handleConfirmModal,
    setSubmissionStatus,
  };
};

export default useEngravingCertificate;
