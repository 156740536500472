import { getAxiosInstance } from "../../../../api";

export const getVerificationEditBasicData = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `verification/basic-data?item_detail_id=${id}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};
export const updateCertificateData = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`verification/excel-map`, params);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getPrintBarCode = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/verification/sticker-printing?id=${id}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
