import { useFormik } from "formik";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { updateForm } from "../../api";
import { toast } from "react-toastify";

const useVersionTwo = () => {
  const { excelEditedFormData, itemID, certificateID } = useSelector(
    (state) => state.qc
  );
  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReset = () => {
    excelEditedFormData?.map((value) =>
      formik?.setFieldValue(`${value?.slug}`, value?.value)
    );
  };

  const formik = useFormik({
    initialValues: {},
    validate: (values) => {
      let errors = {};
      Object.keys(values || {}).forEach((key) => {
        if (!values[key]) {
          errors[`${key}`] = `This field is required`;
        }
      });
      return errors;
    },
    onSubmit: (values) => {
      let data = {
        recheck_item_details_id: certificateID,
        ...values,
      };
      updateForm(data).then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Data updated ");
        } else {
          toast.error("Something went wrong");
        }
      });
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  return { formik, excelEditedFormData, itemID, getFieldError, handleReset };
};

export default useVersionTwo;
