import React from "react";
import useVersionOne from "./useVersionOne";
import { Input } from "@wac-ui-dashboard/wac_component_library";

const VersionOne = () => {
  const { excelFormData, showCompare, itemID, excelData } = useVersionOne();
  return (
    <div className="row">
      <div className="col-6">
        <h6>{itemID}</h6>
        {excelFormData?.map((value, index) => {
          return (
            <div key={index}>
              <Input
                label={value?.name}
                type="text"
                id={`${value?.slug}`}
                name={`${value?.slug}`}
                value={value?.value}
                disabled={true}
              />
            </div>
          );
        })}
      </div>
      <div className="col-6">
        {showCompare && (
          <>
            <h6>{"Excel Data"}</h6>
            {excelData?.map((value, index) => {
              return (
                <div key={index}>
                  <Input
                    label={value?.name}
                    type="text"
                    id={`${value?.slug}`}
                    name={`${value?.slug}`}
                    value={value?.value}
                    disabled={true}
                  />
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default VersionOne;
