import { getAxiosInstance } from "../../../api";

export const geFormData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `/qc/fields?item_details_id=${body?.item_details_id}&item_type_id=${body?.item_type_id}&process_slug=${body?.process_slug}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getExcelFormData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `/qc/excel-qc?item_details_id=${body?.item_details_id}&item_type_id=${body?.item_type_id}&process_slug=${body?.process_slug}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getExcelData = async (id, slug) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `/qc/excel-data?item_details_id=${id}&process_slug=${slug}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const submitForm = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/qc/save`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const updateForm = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/qc/save-updated`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getReport = async (id, slug) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `/qc/edit?item_details_id=${id}&process_slug=${slug}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};
