import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  updateConfig,
  getEngravingSectionRecheckListData,
} from "../../../store/slices/EngravingSection/engravingSectionSlice";
import { getEngravingCertificate } from "./api";

const useEngraving = () => {
  const [pendingPage, setPendingPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const engravingSectionState = useSelector((state) => state.engravingSection);
  const engravingData = engravingSectionState.engravingSectionRecheckData;

  const [showDetail, setShowDetail] = useState(false);
  const [gliNumber, setGliNumber] = useState("");
  const [certificateListData, setCertificateList] = useState();
  const [activeCard, setActiveCard] = useState({ id: "", key: "" });
  const dispatch = useDispatch();
  const slug = localStorage?.getItem("slug");

  useEffect(() => {
    let params = {
      page: pendingPage,
      per_page: engravingSectionState.currentPageSize,
      search: engravingSectionState.search,
      process_slug: slug,
    };
    dispatch(getEngravingSectionRecheckListData(params));

    // eslint-disable-next-line
  }, [
    engravingSectionState.search,
    pendingPage,
    engravingSectionState.currentPageSize,
    slug,
  ]);

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleChange = (item, key) => {
    setActiveCard({ id: item?._id, key: key });
    let params = {
      receipt_id: item?._id,
      process_slug: slug,
    };
    dispatch(
      updateConfig((state) => {
        state.selectedCardId = item?._id;
      })
    );
    setGliNumber(item?.gli_number);
    if (key === "Pending") {
      setShowDetail(true);
      getEngravingCertificate(params).then((response) => {
        const certificateList = response?.data;
        setCertificateList(certificateList);
      });
    } else {
      setShowDetail(false);
      dispatch(
        updateConfig((state) => {
          state.receipt_id = item?._id;
        })
      );
    }
  };
  const handleLoadMore = (mouseHover) => {
    if (
      mouseHover === "Pending" &&
      engravingData?.Pending?.length !== engravingSectionState?.total.Pending
    ) {
      setPendingPage(pendingPage + 1);
    }
  };
  return {
    gliNumber,
    showDetail,
    activeCard,
    engravingData,
    isLoading,
    totalLength: {
      Pending: engravingSectionState?.total?.Pending,
    },
    certificateListData,
    handleSearch,
    handleChange,
    setIsLoading,
    handleLoadMore,
    setCertificateList,
  };
};

export default useEngraving;
