import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 20,
  sortBy: "",
  sortOrder: "",
  search: "",
  showAddRecheckModal: false,
  showPassword: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedRecheck: [],
  isEdit: false,
  recheckData: [],
  logoPreview: "",
  item_type: [],
  shop: [],
  status: [],
  date: [],
  from_date: "",
  to_date: "",
  from_range: "",
  to_range: "",
  gliData: {
    name: "",
    id: "",
    shop: "",
  },
  assignedSearch: "",
  pendingSearch: "",
};

const recheckSlice = createSlice({
  name: "recheck",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    resetConfig: () => {
      return initialState;
    },
  },
});

export const { updateConfig, resetConfig } = recheckSlice.actions;

export default recheckSlice.reducer;
