import { useFormik } from "formik";
import { getSubmitList } from "../api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/Billing/staffBillingSlice";
import { useNavigate } from "react-router-dom";

const useSubmitForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const billingState = useSelector((state) => state.billing);

  const formik = useFormik({
    initialValues: {
      status: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.status || values?.status?.length === 0) {
        errors.status = "Status is required";
      }
      return errors;
    },
    onSubmit: (values) => {
      let status = values?.status?.map((value) => value?.id);
      let data = {
        receipt_id: billingState.selectedBilling,
        status,
      };
      getSubmitList(data)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            localStorage?.setItem("billing", JSON.stringify(data));
            dispatch(
              updateConfig((state) => {
                state.estimateResponse = response?.data?.data;
                state.showAddBillingModal = false;
              })
            );
            navigate("/billing/estimate");
          }
        })
        .catch((error) => toast.error(error));
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  return { formik, getFieldError };
};

export default useSubmitForm;
