import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 20,
  startDate: null,
  endDate: null,
  sortBy: "",
  sortOrder: "",
  receipt_id: "",
  search: "",
  showAddOrderModal: false,
  clearSelection: false,
  isEdit: false,
  selectedOrder: "",
  orderData: [],
  showDeleteModal: false,
  engravingImagePreview: "",
  certificateImagePreview: "",
  printData: [],
};

const approvedSlice = createSlice({
  name: "approved",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = approvedSlice.actions;

export default approvedSlice.reducer;
