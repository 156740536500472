import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../store/slices/Billing/staffBillingSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  useGetGliListDataQuery,
  useUpdateBillingTableHeadDataMutation,
} from "../../../store/queries/Billing";
import { getEstimateList } from "../api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useStaffBilling = () => {
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];
  const [filterShow, setFilterShow] = useState(false);
  const billingState = useSelector((state) => state.billing);
  const { showEditModal } = useSelector((state) => state.global);
  const [updateBillingTableHeadData] = useUpdateBillingTableHeadDataMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: billingList = {}, refetch } = useGetGliListDataQuery({
    per_page: billingState?.currentPageSize,
    page: billingState?.currentPage,
    sort_by: billingState?.sortBy,
    sort_order: billingState?.sortOrder,
    start: billingState?.startDate,
    end: billingState?.endDate,
    search: billingState?.search,
    date: billingState.date ? billingState.date : [],
    item_type: billingState.item_type ? billingState.item_type : [],
    shop: billingState.shop ? billingState.shop : [],
    status: billingState.status ? billingState.status : [],
  });
  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handleSort = (label) => {
    if (billingState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = billingState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleGenerateEstimate = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showAddBillingModal = !state.showAddBillingModal;
        state.selectedBilling = e;
      })
    );
  };

  return {
    filterShow,
    billingState,
    billingList,
    showEditModal,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    setFilterShow,
    handlePageSize,
    handlePagination,
    handleGenerateEstimate,
    handleEditColumnsClick,
    updateBillingTableHeadData,
  };
};

export default useStaffBilling;
