import { useEffect, useRef, useState } from "react";
import {
  getOverView,
  updateConfig,
} from "../../../store/slices/OverSlice/overViewSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { subDays } from "date-fns";

const useOverView = () => {
  const state = useSelector((state) => state.overview);
  const [page, setPage] = useState(1);
  const [changedDate, setChangedDate] = useState("");
  const [isLoading, setIsLoading] = useState();
  const [date, setDate] = useState({
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
    key: "selection",
  });

  const scrollRef = useRef(null);
  const states = state?.overViewData;
  let qcData = states?.map((item) => (item.qc !== 0 ? item : null));
  let photoData = states?.map((item) =>
    item.photo_section !== 0 ? item : null
  );
  let finalData = states?.map((item) => (item.final_qc !== 0 ? item : null));
  let metalData = states?.map((item) => (item.metal_qc !== 0 ? item : null));
  let ogData = states?.map((item) => (item.og_qc !== 0 ? item : null));
  let cvdData = states?.map((item) => (item.cvd_rft !== 0 ? item : null));
  let engravingData = states?.map((item) =>
    item.engraving !== 0 ? item : null
  );
  let billingData = states?.map((item) => (item.billing !== 0 ? item : null));
  let packingData = states?.map((item) => (item.packing !== 0 ? item : null));
  let deliveryData = states?.map((item) => (item.delivery !== 0 ? item : null));
  const dispatch = useDispatch();
  useEffect(() => {
    if (date) {
      initialCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  function initialCall() {
    const startDate = new Date(date.startDate).toISOString().split("T")[0];
    const endDate = new Date(date.endDate).toISOString().split("T")[0];
    dispatch(
      getOverView({ date: [startDate, endDate], page: page, per_page: 10 })
    );
    setChangedDate([startDate, endDate]);
  }
  const handleSearch = (e) => {
    dispatch(getOverView({ search: e.target.value }));
  };
  let dataArray = [
    { data: qcData, key: "qc", title: "QC" },
    { data: finalData, key: "final_qc", title: "Final QC" },
    { data: photoData, key: "photo_section", title: "Photo" },
    { data: metalData, key: "metal_qc", title: "Metal QC" },
    { data: ogData, key: "og_qc", title: "Og QC" },
    { data: cvdData, key: "cvd_rft", title: "CVD" },
    { data: engravingData, key: "engraving", title: "Engraving" },
    { data: billingData, key: "billing", title: "Billing" },
    { data: packingData, key: "packing", title: "Packing" },
    { data: deliveryData, key: "delivery", title: "Delivery" },
  ];

  const handleDateChange = (date) => {
    const startDateUTC = new Date(date.startDate);
    const endDateUTC = new Date(date.endDate);

    const timeZoneOffset = 5.5 * 60 * 60 * 1000;
    const startDateIST = new Date(startDateUTC.getTime() + timeZoneOffset);
    const endDateIST = new Date(endDateUTC.getTime() + timeZoneOffset);

    const formatDate = (date) => {
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    const startDateFormatted = formatDate(startDateIST);
    const endDateFormatted = formatDate(endDateIST);

    const dateArray = [startDateFormatted, endDateFormatted];
    dispatch(getOverView({ date: dateArray }));
    setChangedDate(dateArray);
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
        state.date = [];
        state.filter.itemType = [];
        state.filter.shop = [];
        state.filter.priority = [];
      })
    );
    initialCall();
    setDate({
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    });
  };

  const handleFilterModal = () => {
    dispatch(updateConfig((state) => (state.filterModal = !state.filterModal)));
  };

  useEffect(() => {
    if (page !== 1) {
      dispatch(getOverView({ date: changedDate, page: page, per_page: 10 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleLoadMore = () => {
    if (state?.total?.overView !== states?.length) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.overViewStatus]);

  return {
    state,
    date,
    states,
    dataArray,
    isLoading,
    totalLength: {
      overView: state?.total?.overView,
    },
    scrollRef,
    setIsLoading,
    handleLoadMore,
    handleFilterModal,
    handleSearch,
    handleDateChange,
    handleClearClick,
  };
};

export default useOverView;
