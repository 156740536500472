import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getCompleteList,
  getMyQueueList,
  getPendingList,
  getSearchList,
  getUploadedPhoto,
  deleteUploadedPhoto,
  startTimer,
  updateConfig,
  getUploadedRoughPhoto,
} from "../../../store/slices/photoUpload/photoUploadSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { uploadPhoto } from "./api";
import moment from "moment";
import { saveAs } from "file-saver";

const usePhotoUpload = () => {
  const [uploadStatus, setUploadStatus] = useState("idle");
  const [photoUpload, setPhotoUpload] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pendingPage, setPendingPage] = useState(1);
  const [queuePage, setQueuePage] = useState(1);
  const [completedPage, setCompletedPage] = useState(1);
  const [file, setFile] = useState("");
  const [searchKey, setsearchKey] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isSelected, setIsSelected] = useState();
  const [checkedItems, setCheckedItems] = useState([]);
  const [itemId, setItemId] = useState("");
  const [designId, setDesignId] = useState("");
  const [activePhotCard, setActivePhotoCard] = useState();
  const [receiptId, setReceiptId] = useState("");
  const state = useSelector((state) => state.photoUpload);
  const states = state?.myQueueList;
  const photoUploadData = state?.photoUploadData;
  const photoList = state?.searchData;
  const uploadedImageData = state?.uploadedImageData;
  const searchStatus = state?.searchStatus;
  const [showDetail, setShowDetail] = useState(false);
  const [showCompleted, setShowCompleted] = useState();
  const [timer, setTimer] = useState(true);
  const [activeCard, setActiveCard] = useState({ id: "", key: "" });
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageData, setImageData] = useState();
  const [roughPhotoUpload, setRoughPhotoUpload] = useState({
    imageData: "",
    photo: "",
  });
  const dispatch = useDispatch();
  const slug = localStorage?.getItem("slug");

  useEffect(() => {
    dispatch(
      getMyQueueList({
        page: queuePage,
        process_slug: slug,
      })
    );
    //eslint-disable-next-line
  }, [queuePage]);
  const handleChange = (id, gli_number) => {
    setActiveCard({ id: id, key: "Pending" });
    dispatch(
      updateConfig((state) => {
        state.photoUploadData.pending_list = [];
        state.photoUploadData.complete_list = [];
      })
    );
    setReceiptId(id);
    if (id) {
      dispatch(
        getCompleteList({
          page: completedPage,
          per_page: 10,
          receipt_id: id,
          process_slug: slug,
        })
      );
      dispatch(
        getPendingList({
          page: pendingPage,
          per_page: 10,
          receipt_id: id,
          process_slug: slug,
        })
      );
    }
  };
  useEffect(() => {
    if (receiptId) {
      dispatch(
        getCompleteList({
          page: completedPage,
          per_page: 10,
          receipt_id: receiptId,
          process_slug: slug,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedPage]);
  useEffect(() => {
    if (receiptId) {
      dispatch(
        getPendingList({
          page: pendingPage,
          per_page: 10,
          receipt_id: receiptId,
          process_slug: slug,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingPage]);

  useEffect(() => {
    setRoughPhotoUpload({
      photo: state?.uploadedRoughImage?.url,
      imageData: "",
    });
  }, [state?.uploadedRoughImage]);


  const photoHandleChange = (id, key, certificate_number) => {
    setActiveCard({ id: id, key: key });
    setItemId(id);

    //clearing selection while switching cards
    setShowDetail(false);
    setsearchKey("");
    setIsSelected("");
    dispatch(updateConfig((state) => (state.searchData = {})));

    setActivePhotoCard(id);
    // eslint-disable-next-line eqeqeq
    if (key == "pending_list") {
      if (timer) {
        setTimer(false);
        toast.success("Timer started");
      }
      setShowDetail(true);
      dispatch(startTimer({ item_details_id: id, process_slug: slug }));
      dispatch(getUploadedRoughPhoto(id));
      setShowCompleted(false);
    } else {
      setsearchKey("");
      dispatch(getUploadedPhoto(id));
      setShowDetail(false);
      setShowCompleted(true);
    }
  };
  const handleUploadPhoto = (e) => {
    const files = e.target.files;
    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      setFile(e?.target?.files?.[0]);
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhotoUpload(reader.result);
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
      setFile("");
      setPhotoUpload("");
    }
  };
  const handleCheck = (e, design_id, name, photolist) => {
    setIsChecked((prev) => !prev);
    setIsSelected(
      photolist?.image?.find((listItem) => listItem?.name === name) || null
    );
    if (e.target.checked === true) {
      setCheckedItems({
        item_details_id: itemId,
        design_id: design_id,
        name: name,
      });
      setDesignId(design_id);
    } else {
      setCheckedItems([]);
      setDesignId(null);
    }
  };

  const handleSearch = (e) => {
    setsearchKey(e.target.value);
  };
  const SearchFile = () => {
    dispatch(
      getSearchList({
        search: searchKey,
      })
    );
  };

  const handleRefetch = () => {
    setUploadStatus("success");
    toast.success("Image uploaded successfully");
    setRoughPhotoUpload({ imageData: "", photo: "" });
    setShowDetail(false);
    setPhotoUpload("");
    setsearchKey("");
    setFile("");
    setDesignId("");
    setIsChecked(false);
    dispatch(
      updateConfig((state) => {
        state.photoUploadData.pending_list = [];
        state.photoUploadData.complete_list = [];
      })
    );
    dispatch(
      getCompleteList({
        page: 1,
        per_page: 10,
        receipt_id: receiptId,
        process_slug: slug,
      })
    );
    dispatch(
      getPendingList({
        page: 1,
        per_page: 10,
        receipt_id: receiptId,
        process_slug: slug,
      })
    );
  };
  const handleSubmit = () => {
    setUploadStatus("pending");
    let data;
    if (designId) {
      data = checkedItems;
      setsearchKey("");
      setIsSelected("");
      dispatch(updateConfig((state) => (state.searchData = {})));
    } else {
      if (searchKey?.length === 0) {
        data = { image: file, item_details_id: itemId };
      }
      data = { design_id: searchKey, image: file, item_details_id: itemId };
    }
    let newObj = Object.entries(data);
    newObj = newObj
      .filter(
        (item) => item[1] !== undefined && item[1] !== "" && item[1] !== null
      )
      .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
    const formData = new FormData();
    Object.keys(newObj).forEach((key) => {
      formData.append(key, data[key]);
    });
    if (file) {
      uploadPhoto(formData).then((response) => {
        if (response?.data?.status_code === 200) {
          handleRefetch();
        } else {
          toast.error("Failed Uploading Image");
          setUploadStatus("failed");
        }
      });
    } else if (isChecked === true) {
      uploadPhoto(formData).then((response) => {
        if (response?.data?.status_code === 200) {
          handleRefetch();
        } else {
          toast.error("Failed Uploading Image");
          setUploadStatus("failed");
        }
      });
    } else if (roughPhotoUpload?.imageData && roughPhotoUpload?.photo) {
      const roughFormData = new FormData();
      roughFormData.append("rough_image", roughPhotoUpload?.imageData);
      roughFormData.append("item_details_id", itemId);
      uploadPhoto(roughFormData).then((response) => {
        if (response?.data?.status_code === 200) {
          handleRefetch();
        } else {
          toast.error("Something went wrong");
        }
      });
    } else {
      toast.error("Please Select or upload an image");
      setUploadStatus("failed");
    }
  };

  const handleLoadMore = (mouseHover) => {
    if (
      mouseHover === "Pending" &&
      states?.myQueueList?.Pending?.length !== state?.total.Queue
    ) {
      setQueuePage(queuePage + 1);
    }
    if (
      mouseHover === "pending_list" &&
      states?.photoUploadData?.pending_list?.length !== state?.total.Pending
    ) {
      setPendingPage(pendingPage + 1);
    }
    if (
      mouseHover === "complete_list" &&
      states?.photoUploadData?.complete_list?.length !== state?.total.Completed
    ) {
      setCompletedPage(completedPage + 1);
    }
  };

  //function to delete the uploaded image
  const handlePhotoDelete = (design_id, name) => {
    setUploadStatus("Deleting");
    dispatch(deleteUploadedPhoto({ design_id, name }))
      .then((res) => {
        if (res?.payload?.data?.status_code === 200) {
          setUploadStatus("Deleted");
          toast.success("Image deleted successfully");
          setsearchKey("");
          dispatch(updateConfig((state) => (state.searchData = {})));
        } else {
          toast.error("failed to delete image");
          setUploadStatus("Failed");
        }
      })
      .catch((error) => {
        toast.error("An error occurred while deleting the image");
      });
  };

  //modal opening closing and image viewing functions
  const handlePhotoClick = (logoImage) => {
    setImageData(logoImage.url);
    setShowImageModal(true);
  };
  const closeModal = () => {
    setShowImageModal(false);
  };

  const handleRoughImage = (e) => {
    const files = e.target.files;
    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      const file = e.target.files[0];
      const reader = new FileReader();
      // Check the file type
      if (file.type === "image/jpeg" || file.type === "image/png") {
        reader.onloadend = () => {
          setRoughPhotoUpload({
            photo: reader.result,
            imageData: files?.[0],
          });
          setImageData(reader.result);
        };
        if (file) {
          reader.readAsDataURL(file);
        }
      } else {
        toast.error("Only JPEG and PNG files are allowed.");
        setRoughPhotoUpload({
          photo: "",
          imageData: "",
        });
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
      setRoughPhotoUpload({
        photo: "",
        imageData: "",
      });
    }
  };

  //function to download image while displaying in modal
  const handleDownload = (e) => {
    saveAs(
      e,
      `${
        "uploaded-image" +
        "-" +
        moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
      }`
    );
  };
  const handleRoughView = () => {
    if (true) {
      dispatch(
        updateConfig(
          (state) => (state.roughImageModal = !state.roughImageModal)
        )
      );
    }
  };

  return {
    file,
    state,
    states,
    isChecked,
    showImageModal,
    imageData,
    checkedItems,
    isSelected,
    photoList,
    showDetail,
    searchKey,
    searchStatus,
    activeCard,
    photoUpload,
    showCompleted,
    activePhotCard,
    photoUploadData,
    uploadedImageData,
    uploadStatus,
    isLoading,
    totalLength: {
      Pending: state.total.Pending,
      Completed: state.total.Completed,
      Queue: state.total.Queue,
    },
    roughPhotoUpload,
    handleRoughView,
    handleRoughImage,
    handleDownload,
    closeModal,
    SearchFile,
    handleCheck,
    handleChange,
    handleSubmit,
    handleSearch,
    setIsLoading,
    handleLoadMore,
    photoHandleChange,
    handleUploadPhoto,
    handlePhotoDelete,
    handlePhotoClick,
  };
};

export default usePhotoUpload;
