import { useFormik } from "formik";
import * as Yup from "yup";
import { addDepartments, logout } from "../api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getMenuPermissions,
  updateConfig as globalUpdateConfig,
} from "../../../../../store/slices/Global";
import { useEffect } from "react";

const useDepartmentForm = (isOutside, basicData) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validation = Yup.object({
    departments: Yup.object().required("Select One"),
    branches: Yup.object().required("Select One"),
    process: Yup.object().required("Select One"),
  });

  const formik = useFormik({
    initialValues: {
      departments: "",
      branches: "",
      process: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      let data = {
        branch_id: values?.branches?._id,
        department_id: values?.departments?._id,
        process_slug: values?.process?._id,
      };
      addDepartments(data).then((response) => {
        if (response?.data?.status_code === 200) {
          localStorage?.setItem(
            "USER_ACCESS_TOKEN",
            response?.data?.data?.token
          );
          dispatch(
            globalUpdateConfig((state) => {
              state.showLoginModal = false;
              state.showSettingsModal = false;
            })
          );
          if (isOutside) {
            localStorage?.setItem(
              "user_type",
              response?.data?.data?.user?.user_type
            );
            navigate("/");
            dispatch(getMenuPermissions());
          }
          toast.success("Successfully Updated");
        } else if (response?.status_code === 422) {
          // displays backend errors
          let errors = response?.errors;
          let errorFields = Object.keys(errors);
          errorFields.forEach((field) => {
            formik.setFieldError(field, errors[field]);
          });
        } else {
          toast.error("Something went wrong");
        }
      });
    },
  });

  useEffect(() => {
    if (basicData?.branch_id) {
      const branch = basicData?.branches?.filter(
        (value) => basicData?.branch_id === value?._id
      );
      formik?.setFieldValue("branches", branch?.[0]);
    }
    if (basicData?.department_id) {
      const department = basicData?.departments?.filter(
        (value) => basicData?.department_id === value?._id
      );
      formik?.setFieldValue("departments", department?.[0]);
    }
    if (basicData?.process_slug) {
      const process = basicData?.process?.filter(
        (value) => basicData?.process_slug === value?._id
      );
      formik?.setFieldValue("process", process?.[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicData]);
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = (isOutside) => {
    if (isOutside) {
      dispatch(
        globalUpdateConfig((state) => (state.showSettingsModal = false))
      );
    } else {
      dispatch(globalUpdateConfig((state) => (state.showLoginModal = false)));
      logout().then((response) => {
        if (response?.data?.status_code === 200) {
          localStorage.clear("USER_ACCESS_TOKEN");
          localStorage.clear("dashboard_permissions");
          localStorage.clear("user_type");
          localStorage.clear("process");
          navigate("/");
        } else {
          toast.error("Something went wrong");
        }
      });
    }
  };
  return {
    formik,
    getFieldError,
    handleCloseModal,
  };
};

export default useDepartmentForm;
