import { useSelector } from "react-redux";
import {
  useGetCertificatePrefixListDataQuery,
  useDeleteCertificatePrefixMutation,
  useUpdateCertificatePrefixTableHeadDataMutation,
} from "../../../store/queries/Master";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Master/CertificatePrefix/certificatePrefixSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getCertificatePrefixData } from "./api";

const useCertificatePrefix = () => {
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];

  const dispatch = useDispatch();

  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const certificatePrefixState = useSelector(
    (state) => state.certificatePrefix
  );
  const {
    data: certificatePrefixList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetCertificatePrefixListDataQuery({
    page_size: certificatePrefixState?.currentPageSize,
    page: certificatePrefixState?.currentPage,
    sort_by: certificatePrefixState?.sortBy,
    sort_order: certificatePrefixState?.sortOrder,
    start: certificatePrefixState?.startDate,
    end: certificatePrefixState?.endDate,
  });
  // edited by sreejitha
  const [deleteCertificatePrefix] = useDeleteCertificatePrefixMutation();
  const [updateCertificatePrefixFields] =
    useUpdateCertificatePrefixTableHeadDataMutation();

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = certificatePrefixList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [certificatePrefixList]);

  useEffect(() => {
    if (
      certificatePrefixState?.selectedCertificatePrefix &&
      certificatePrefixState?.isEdit
    ) {
      fillCertificatePrefixData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    certificatePrefixState?.isEdit,
    certificatePrefixState?.showAddCertificatePrefixModal,
  ]);
  useEffect(() => {
    if (certificatePrefixState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [certificatePrefixState.clearSelection]);

  const handleModalClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddCertificatePrefixModal =
          !state.showAddCertificatePrefixModal;
        state.isEdit = false;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      prefix_order: (field, data) => (
        <ul>
          {data?.[field]?.map((value, index) => {
            return <li key={index}>{value}</li>;
          })}
        </ul>
      ),
      status: (field, data) =>
        data?.status === "Deleted" ? (
          <span className={"pro-badge badge-danger-outline"}>{"Deleted"}</span>
        ) : data?.status === "Active" ? (
          <span className={"pro-badge badge-success-outline"}>{"Active"}</span>
        ) : (
          data?.status
        ),
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleDelete = () => {
    deleteCertificatePrefix(
      certificatePrefixState?.selectedCertificatePrefix
    ).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Certificate Prefix deleted successfully");
        handleDeleteModal();
        refetch();
      } else {
        toast.error("Something went wrong");
        handleDeleteModal();
      }
    });
  };

  const fillCertificatePrefixData = () => {
    getCertificatePrefixData(
      certificatePrefixState?.selectedCertificatePrefix
    ).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig(
            (state) => (state.certifecatePrefixData = response?.data?.data)
          )
        );
      } else {
        toast.error("Something went wrong to fetch brach data");
      }
    });
  };

  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedCertificatePrefix = e?.[0];
      })
    );
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showAddCertificatePrefixModal = true;
        state.selectedCertificatePrefix = e?.[0];
        state.isEdit = true;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (certificatePrefixState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            certificatePrefixState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  return {
    certificatePrefixState,
    showEditModal,
    tableFields,
    certificatePrefixList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    handleDelete,
    handleDeleteModal,
    handleEditAction,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    updateCertificatePrefixFields,
  };
};

export default useCertificatePrefix;
