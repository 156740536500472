import React from "react";
import Select from "react-select";
import useItem from "./useItem";
import { Button } from "@wac-ui-dashboard/wac_component_library";
import AddItem from "./Additem";

const Item = ({ isStickyFooter, formik, optionsData, isEdit }) => {
  let temp = [];
  const {
    getFieldError,
    handleCloseModal,
    isEditProfile,
    handleNext,
    handleAddItemClick,
  } = useItem(formik);

  let itemTypeObj = optionsData?.item_types?.filter(
    (item) => item?._id === formik?.values?.item_type
  );

  return (
    <div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Item Type
        </label>
        <Select
          id="item_type"
          name="item_type"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("item_type") && " error"}`}
          classNamePrefix="pro-input"
          options={optionsData?.item_types ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?._id}
          onChange={(value) => formik?.setFieldValue("item_type", value?._id)}
          value={itemTypeObj}
          onBlur={formik?.handleBlur("item_type")}
          menuPlacement="auto"
        />
        {getFieldError("item_type") && (
          <span className="error-text">{getFieldError("item_type")}</span>
        )}
      </div>
      {formik?.values?.group?.map((value, index) => {
        temp = [...temp, formik?.values?.group[index]?.item_id];
        return (
          <AddItem
            key={index}
            itmIndex={index}
            optionsData={optionsData}
            values={value}
            formik={formik}
            isEdit={isEdit}
            tempIds={temp}
            getFieldError={getFieldError}
          />
        );
      })}
      {formik?.values?.group?.length !== optionsData?.items?.length && (
        <Button
          className={`pro-btn-primary lg pro-w-100 pro-mt-4`}
          onClick={handleAddItemClick}
          type="button"
        >
          {` Add Another`}
        </Button>
      )}

      <div
        className={`col-12 pro-d-flex pro-justify-end pro-pt-3  pro-mt-5 ${
          isStickyFooter && "offcanvas-footer-sticky-btn"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={handleNext}
          type="button"
        >
          {isEditProfile ? "Update" : "Next"}
        </Button>
      </div>
    </div>
  );
};

export default Item;
