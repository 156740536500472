import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  useGetFormDataQuery,
  useUpdateCertificatePrefixDataMutation,
  useGetBasicCertificatePrefixDataQuery,
} from "../../../../store/queries/Master";
import { updateConfig } from "../../../../store/slices/Master/CertificatePrefix/certificatePrefixSlice.js";
import countryData from "../../../../utils/components/countryCode";

const useAddCertificatePrefix = ({ refetch }) => {
  const dispatch = useDispatch();
  const [dhcChecked, setDhcChecked] = useState(false);
  const [dhcBranchChecked, setDhcBranchChecked] = useState(false);
  const [shopChecked, setShopChecked] = useState(false);
  const [countryChecked, setCountryChecked] = useState(false);
  const [itemTypeChecked, setItemTypeChecked] = useState(false);
  const [dayChecked, setDayChecked] = useState(false);
  const [monthChecked, setMonthChecked] = useState(false);
  const [yearChecked, setYearChecked] = useState(false);
  const [goldBasedChecked, setGoldBasedChecked] = useState(false);
  const [platinumBasedChecked, setPlatinumBasedChecked] = useState(false);
  const [exampleText, setExampleText] = useState("");

  const { certifecatePrefixData, isEdit } = useSelector(
    (state) => state.certificatePrefix
  );
  const { data: formData = {}, isLoading } = useGetFormDataQuery();
  const { data: basicData = {} } = useGetBasicCertificatePrefixDataQuery();
  const [updateCertifecatePrefixData] =
    useUpdateCertificatePrefixDataMutation();

  const optionsArray = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: 8, value: 8 },
    { label: 9, value: 9 },
  ];
  const [selectedOrders, setSelectedOrders] = useState({});

  const [filteredOptions, setFilteredOptions] = useState(optionsArray);

  const checkboxFields = [
    {
      id: "dhc",
      label: "DHC",
      state: dhcChecked,
      setState: setDhcChecked,
      name: "dhc_order",
    },
    {
      id: "dhc_branch",
      label: "DHC Branch",
      state: dhcBranchChecked,
      setState: setDhcBranchChecked,
      name: "dhc_branch_order",
    },
    {
      id: "shop",
      label: "Shop",
      state: shopChecked,
      setState: setShopChecked,
      name: "shop_order",
    },
    {
      id: "country",
      label: "Country",
      state: countryChecked,
      setState: setCountryChecked,
      name: "country_order",
    },
    {
      id: "item_type",
      label: "Item",
      state: itemTypeChecked,
      setState: setItemTypeChecked,
      name: "item_type_order",
    },
    {
      id: "month",
      label: "Month",
      state: monthChecked,
      setState: setMonthChecked,
      name: "month_order",
    },
  ];

  const keyFunctionMap = {
    dhc: (value) => {
      setDhcChecked(true);
      setOrderValue("dhc", value);
    },
    dhc_branch: (value) => {
      setDhcBranchChecked(true);
      setOrderValue("dhc_branch", value);
    },
    shop: (value) => {
      setShopChecked(true);
      setOrderValue("shop", value);
    },
    country: (value) => {
      setCountryChecked(true);
      setOrderValue("country", value);
    },
    item_type: (value) => {
      setItemTypeChecked(true);
      setOrderValue("item_type", value);
    },
    day: (value) => {
      setOrderValue("day_check", value);
      setDayChecked(true);
    },
    month: (value) => {
      setMonthChecked(true);
      setOrderValue("month", value);
    },
    year: (value) => {
      setOrderValue("year_check", value);
      setYearChecked(true);
    },
    serial_number: (value) => {
      setOrderValue("serial_number", value);
    },
  };

  useEffect(() => {
    if (certifecatePrefixData && isEdit) {
      Object.keys(certifecatePrefixData || {}).forEach((key) => {
        if (key === "shop_id") {
          const result = basicData?.data?.shop.find(
            (value) => value?._id === certifecatePrefixData?.[key]
          );
          if (result) {
            formik?.setFieldValue("shop_id", result);
          }
        } else if (key === "serial_number_reset") {
          const result = basicData?.data?.serial_number_reset.find(
            (value) => value?.id === certifecatePrefixData?.[key]
          );
          formik?.setFieldValue("serial_number_reset", result);
        } else if (key === "serial_number_basis") {
          const result = basicData?.data?.serial_number_basis.find(
            (value) => value?.id === certifecatePrefixData?.[key]
          );
          formik?.setFieldValue("serial_number_basis", result);
        } else if (key === "gold_based") {
          setGoldBasedChecked(!!certifecatePrefixData?.[key]);
        } else if (key === "platinum_based") {
          setPlatinumBasedChecked(!!certifecatePrefixData?.[key]);
        } else if (key === "serial_number") {
          formik?.setFieldValue("serial_number", certifecatePrefixData?.[key]);
        } else if (key === "day") {
          const result = basicData?.data?.day.find(
            (value) => value?.id === certifecatePrefixData?.[key]
          );
          setDayChecked(true);
          formik?.setFieldValue("day", result);
        } else if (key === "year") {
          const result = basicData?.data?.year.find(
            (value) => value?.id === certifecatePrefixData?.[key]
          );
          setYearChecked(true);
          formik?.setFieldValue("year", result);
        } else if (key === "prefix_order") {
          const newOrders = {};
          certifecatePrefixData?.[key].forEach((item) => {
            const [key, value] = Object.entries(item)[0];
            newOrders[`${key}_order`] = value;
            if (keyFunctionMap.hasOwnProperty(key)) {
              keyFunctionMap[key](value);
            }
          });
          setSelectedOrders(newOrders);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certifecatePrefixData, isLoading, basicData]);

  const validation = Yup.object({
    shop_id: Yup.object().required("Select Shop"),
    serial_number_reset: Yup.object().required("Select Serial number reset"),
    serial_number_basis: Yup.object().required("Select Serial number basis"),
    day: dayChecked && Yup.object().required("Select Day"),
    year: yearChecked && Yup.object().required("Select Year"),
    // gold_based:
    //   !goldBasedChecked && !platinumBasedChecked
    //     ? Yup.boolean().required("Please choose either Gold or platinum based")
    //     : undefined,
    serial_number: !isEdit
      ? Yup.string()
          .required("Enter Serial Number")
          .max(6, "Maximum 6 digits allowed")
          .matches(/^[0-9]*$/, "Enter valid serial number")
      : undefined,
  });

  const formik = useFormik({
    initialValues: {
      certificate_prefix_id: "",
      shop_id: "",
      dhc: "",
      dhc_branch: "",
      shop: "",
      country: "",
      item_type: "",
      day: "",
      month: "",
      year: "",
      gold_based: "",
      platinum_based: "",
      serial_number: "",
      serial_number_reset: "",
      serial_number_basis: "",
      prefix_order: [],
      dhc_order: "",
      dhc_branch_order: "",
      shop_order: "",
      country_order: "",
      item_type_order: "",
      day_check_order: "",
      month_order: "",
      year_check_order: "",
      serial_number_order: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      const orderArray = [
        { check_box_name: "dhc", order: values.dhc_order?.value },
        { check_box_name: "dhc_branch", order: values.dhc_branch_order?.value },
        { check_box_name: "shop", order: values.shop_order?.value },
        { check_box_name: "country", order: values.country_order?.value },
        { check_box_name: "item_type", order: values.item_type_order?.value },
        { check_box_name: "day", order: values.day_check_order?.value },
        { check_box_name: "month", order: values.month_order?.value },
        { check_box_name: "year", order: values.year_check_order?.value },
        {
          check_box_name: "serial_number",
          order: values.serial_number_order?.value,
        },
      ];
      const prefixes = [];
      orderArray
        .filter((option) => option.order !== undefined)
        .sort((a, b) => (a.order > b.order ? 1 : -1))
        .forEach((item) => {
          if (item.order) {
            prefixes.push(item.check_box_name);
          }
        });
      let data = {
        certificate_prefix_id: isEdit ? certifecatePrefixData?._id : "",
        shop_id: values?.shop_id?._id,
        serial_number: values?.serial_number,
        dhc: dhcChecked,
        dhc_branch: dhcBranchChecked,
        shop: shopChecked,
        country: countryChecked,
        item_type: itemTypeChecked,
        day: dayChecked ? values?.day?.id : null,
        month: monthChecked,
        year: yearChecked ? values?.year?.id : null,
        gold_based: goldBasedChecked,
        platinum_based: platinumBasedChecked,
        serial_number_basis: values?.serial_number_basis?.id,
        serial_number_reset: values?.serial_number_reset?.id,
        prefix_order: prefixes,
      };

      let newObj = Object.entries(data);
      newObj = newObj
        .filter(
          (item) => item[1] !== undefined && item[1] !== "" && item[1] !== null
        )
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});

      updateCertifecatePrefixData(newObj).then((response) => {
        if (response?.data?.status_code === 200) {
          handleCloseModal();
          if (isEdit) {
            toast.success("Certificate Prefix Details Updated");
          } else {
            toast.success("New Certificate Prefix Created");
          }
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
          refetch();
        } else if (response?.error?.data?.status_code === 422) {
          // displays backend errors
          const errors = response?.error?.data?.errors;
          Object.keys(errors).forEach((field) => {
            if (field === "gold or platinum based") {
              const temp = "gold_based";
              formik.setFieldError(
                temp,
                "Please choose either Gold or platinum based"
              );
            } else if (field === "prefix_order") {
              toast.error("The prefix order field is required");
            } else {
              formik.setFieldError(field, errors[field]);
            }
          });
        } else {
          toast.error("Something went wrong");
        }
      });
    },
  });

  useEffect(() => {
    const orderArray = [
      { check_box_name: "dhc", order: formik?.values.dhc_order?.value },
      {
        check_box_name: "dhc_branch",
        order: formik?.values.dhc_branch_order?.value,
      },
      { check_box_name: "shop", order: formik?.values.shop_order?.value },
      { check_box_name: "country", order: formik?.values.country_order?.value },
      {
        check_box_name: "item_type",
        order: formik?.values.item_type_order?.value,
      },
      { check_box_name: "day", order: formik?.values.day_check_order?.value },
      { check_box_name: "month", order: formik?.values.month_order?.value },
      { check_box_name: "year", order: formik?.values.year_check_order?.value },
      {
        check_box_name: "serial_number",
        order: formik?.values.serial_number_order?.value,
      },
    ];

    const exampleTextArray = [];
    orderArray
      .filter((option) => option.order !== undefined)
      .sort((a, b) => (a.order > b.order ? 1 : -1))
      .forEach((item) => {
        if (item.order) {
          exampleTextArray.push(item.check_box_name);
        }
      });

    const updatedValues = exampleTextArray.map((item) =>
      item === "serial_number"
        ? goldBasedChecked
          ? "GB" + formik?.values?.serial_number
          : platinumBasedChecked
          ? "PB" + formik?.values?.serial_number
          : formik?.values?.serial_number
        : item
    );

    const concatenatedValue = updatedValues.join("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setExampleText(concatenatedValue.toUpperCase());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values]);

  const setOrderValue = (id, ordervalue) => {
    const result = optionsArray.find((value) => value.value === ordervalue);
    formik.setFieldValue(`${id}_order`, result);
  };

  const handleCheckboxChange = (e, setter, id, checkedValue) => {
    const { checked } = e.target;
    setter(checked);
    formik.setFieldValue(id, checked);
    if (checkedValue) {
      const fieldNameArr = `${id}_order`;
      delete selectedOrders[fieldNameArr];
      const newSlectedOrders = { ...selectedOrders };
      setSelectedOrders(newSlectedOrders);
      formik.setFieldValue(fieldNameArr, "");
    }
  };

  const handleInputChange = (fieldName) => (selectedOption) => {
    formik.setFieldValue(fieldName, selectedOption);
    const localSelectedOrders = { ...selectedOrders };
    localSelectedOrders[fieldName] = selectedOption?.value;
    setSelectedOrders(localSelectedOrders);
  };
  useEffect(() => {
    if (selectedOrders.length !== 0) {
      handleChangeFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrders]);

  const handleChangeFunction = () => {
    const selectedOrdersArray = Object.values(selectedOrders);
    const filteredOptionsArray = optionsArray.filter(
      (option) => !selectedOrdersArray.includes(option.value)
    );
    setFilteredOptions(filteredOptionsArray);
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddCertificatePrefixModal = false;
      })
    );
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const inputFields = [
    {
      label: "Shop",
      name: "shop_id",
      data: basicData?.data?.shop,
      valueFn: (option) => option?.name,
      keyFn: (option) => option?._id,
      value: formik?.values?.shop?._id,
      blurHandler: formik.handleBlur("shop_id"),
      changeHandler: (value) => formik?.setFieldValue("shop_id", value || null),
      error: getFieldError("shop_id"),
    },
    {
      label: "Serial number reset",
      name: "serial_number_reset",
      data: basicData?.data?.serial_number_reset,
      valueFn: (option) => option?.name,
      keyFn: (option) => option?.id,
      value: formik?.values?.serial_number_reset,
      blurHandler: formik.handleBlur("serial_number_reset"),
      changeHandler: (value) =>
        formik?.setFieldValue("serial_number_reset", value || null),
      error: getFieldError("serial_number_reset"),
    },
    {
      label: "Serial number basis",
      name: "serial_number_basis",
      data: basicData?.data?.serial_number_basis,
      valueFn: (option) => option?.name,
      keyFn: (option) => option?.id,
      value: formik?.values?.serial_number_basis,
      blurHandler: formik.handleBlur("serial_number_basis"),
      changeHandler: (value) =>
        formik?.setFieldValue("serial_number_basis", value || null),
      error: getFieldError("serial_number_basis"),
    },
  ];

  return {
    formik,
    isEdit,
    formData: formData?.data,
    basicData: basicData?.data,
    countryData,
    dhcChecked,
    dhcBranchChecked,
    shopChecked,
    countryChecked,
    itemTypeChecked,
    dayChecked,
    monthChecked,
    yearChecked,
    goldBasedChecked,
    platinumBasedChecked,
    filteredOptions,
    exampleText,
    checkboxFields,
    inputFields,
    selectedOrders,
    handleCloseModal,
    getFieldError,
    handleInputChange,
    handleCheckboxChange,
    setDhcChecked,
    setDhcBranchChecked,
    setShopChecked,
    setCountryChecked,
    setItemTypeChecked,
    setDayChecked,
    setMonthChecked,
    setYearChecked,
    setGoldBasedChecked,
    setPlatinumBasedChecked,
    handleChangeFunction,
  };
};

export default useAddCertificatePrefix;
