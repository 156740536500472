import { getAxiosInstance } from "../../../api";

export const getFormData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `/qc/recheck/fields?recheck_item_details_id=${body?.item_details_id}&item_type_id=${body?.item_type_id}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getExcelFormData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `/qc/recheck/excel-qc?recheck_item_details_id=${body?.item_details_id}&item_type_id=${body?.item_type_id}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getExcelData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/qc/excel-data?item_details_id=${body}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const submitForm = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/qc/recheck/save`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const updateForm = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/qc/recheck/save-updated`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
