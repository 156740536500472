import {
  Header,
  MenuDrawer,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Assets from "../../../assets/Assets";
import HeaderActions from "./HeaderActions";
import useCommonLayout from "./useCommonLayout";
import ProtectRoute from "../../../utils/components/ProtectRoute/ProtectRoute";
import Style from "./commonLayout.module.scss";
import DepartmentForm from "./HeaderActions/DepartmentForm";

const CommonLayout = ({ children, drawerMenu, dashboard = false }) => {
  const {
    menuDrawer,
    showLoginModal,
    formData,
    handleGoBack,
    handleModalClose,
  } = useCommonLayout();

  const location = window.location?.pathname.includes("/employee/qc");
  const locationRecheck = window.location?.pathname.includes(
    "/employee-recheck/qc"
  );
  const locationOver = window.location?.pathname.includes("/billing/invoice");
  return (
    <ProtectRoute>
      <Header
        // navigations={navigations}
        Link={Link}
        logo={Assets.HEADERLOGO}
        children={<HeaderActions />}
        propStyle={{ header: Style.header }}
      />
      <div className={`pro-w-100 container-fluid pt-custom`}>
        <div className={`row`}>
          {!dashboard ? (
            <div
              className={`col-auto wrapper pro-ps-0 ${Style.MenuDrawer_container}`}
            >
              <MenuDrawer
                menus={drawerMenu}
                Link={Link}
                type={`type-2`}
                Nav={Nav}
                icon={
                  <span className="material-symbols-outlined ">
                    chevron_right
                  </span>
                }
                isGoBack={menuDrawer?.show}
                goBackTitle={menuDrawer?.text}
                goBackIcon={
                  <span className="material-symbols-outlined">
                    chevron_left
                  </span>
                }
                handleGoBack={handleGoBack}
                propStyle={{ type_2: Style.type_2 }}
              />
            </div>
          ) : (
            <></>
          )}
          <div
            className={`wrapper-table pro-pt-4 pro-pe-5 pro-ps-2 ${
              location || locationRecheck ? "pt-0" : ""
            } ${locationOver ? "pro-overflow-initial" : ""}`}
          >
            {children}
            <ModalLayout
              show={showLoginModal}
              handleClose={handleModalClose}
              title={"Select Departments"}
              closeIcon={
                <span className="material-symbols-outlined"> close </span>
              }
            >
              <DepartmentForm basicData={formData} isOutside={false} />
            </ModalLayout>
          </div>
        </div>
      </div>
    </ProtectRoute>
  );
};

export default CommonLayout;
