import axios from "axios";
const BASE_URL =
  process.env.REACT_APP_PRODUCTION === "FALSE"
    ? process.env.REACT_APP_QAURL
    : process.env.REACT_APP_QAURL;

let token = "";

export const getAxiosInstance = async () => {
  try {
    token = localStorage.getItem("USER_ACCESS_TOKEN");
  } catch (e) {
  } finally {
    const instance = axios.create({
      baseURL: BASE_URL,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    instance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response && error.response.status === 401) {
          // Set the unauth flag to true
        }
        return Promise.reject(error);
      }
    );

    return instance;
  }
};
