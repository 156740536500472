import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  useGetRecheckListDataQuery,
  useUpdateRecheckTableHeadDataMutation,
} from "../../../store/queries/Orders";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Recheck/recheckSlice";
import { useEffect, useState } from "react";
import { addToRecheckList } from "./api";
import { toast } from "react-toastify";

const useRecheck = () => {
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];
  const [filterShow, setFilterShow] = useState(false);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const recheckState = useSelector((state) => state.recheck);
  const dispatch = useDispatch();

  const {
    data: recheckList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetRecheckListDataQuery({
    page_size: recheckState?.currentPageSize,
    page: recheckState?.currentPage,
    sort_by: recheckState?.sortBy,
    sort_order: recheckState?.sortOrder,
    start: recheckState?.startDate,
    end: recheckState?.endDate,
    search: recheckState?.search,
    date: recheckState.date ? recheckState.date : [],
    range: recheckState.range ? recheckState.range : [],
    item_type: recheckState.item_type ? recheckState.item_type : [],
    shop: recheckState.shop ? recheckState.shop : [],
    status: recheckState.status ? recheckState.status : [],
  });

  const [updateRecheckTableData] = useUpdateRecheckTableHeadDataMutation();

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = recheckList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [recheckList]);
  useEffect(() => {
    if (recheckState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [recheckState.clearSelection]);

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      status: (field, data) =>
        data?.status === "Rejected" ? (
          <span className={"pro-badge badge-danger-outline pro-d-inline-block"}>{"Rejected"}</span>
        ) : data?.status === "Marked" ? (
          <span className={"outline-primary pro-badge pro-d-inline-block"}>{"Marked"}</span>
        ) : (
          data?.status
        ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleSort = (label) => {
    if (recheckState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = recheckState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handleRecheckModal = (e) => {
    let recheckItems = [];
    dispatch(
      updateConfig((state) => {
        state.showAddRecheckModal = !state.showAddRecheckModal;
        recheckItems.push(e);
        state.selectedRecheck = recheckItems;
      })
    );
  };
  const clearSelectedList = () => {
    dispatch(
      updateConfig((state) => {
        state.clearSelection = true;
        state.showAddRecheckModal = !state.showAddRecheckModal;
      })
    );
  };
  const handleRecheck = () => {
    const params = {
      item_details_id: recheckState.selectedRecheck[0],
    };
    addToRecheckList(params).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Certificate added to recheck list successfully");
        clearSelectedList();
        refetch();
      } else {
        toast.error("Something went wrong");
        clearSelectedList();
      }
    });
  };

  return {
    tableFields,
    showEditModal,
    recheckList,
    filterShow,
    isLoading,
    isFetching,
    recheckState,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    setFilterShow,
    handleRecheck,
    handlePageSize,
    handleClearClick,
    handlePagination,
    handleRecheckModal,
    handleEditColumnsClick,
    updateRecheckTableData,
  };
};

export default useRecheck;
