import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useForm1 from "./useForm1";

const Form1 = (handleConfirmModal, setFormValues) => {
  const {
    formik,
    imagePreview,
    getFieldError,
    handleImageChange,
    handleReset,
  } = useForm1(handleConfirmModal, setFormValues);
  return (
    <div className="row">
      <Input
        label={"RFT D/No"}
        type="text"
        id="rft_no"
        name="rft_no"
        className={`pro-input lg ${getFieldError("rft_no") && " error"}`}
        {...formik.getFieldProps("rft_no")}
        error={getFieldError("rft_no")}
        errorMessage={getFieldError("rft_no")}
      />
      <Input
        label={"Percentage"}
        type="text"
        id="percentage"
        name="percentage"
        className={`pro-input lg ${getFieldError("percentage") && " error"}`}
        {...formik.getFieldProps("percentage")}
        error={getFieldError("percentage")}
        errorMessage={getFieldError("percentage")}
      />
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Image
        </label>
        <div className="input-drag">
          <input
            type="file"
            accept=".jpeg,.jpg , .png "
            className={`pro-input ${
              formik.errors.image && formik.touched.image && " error"
            }`}
            id="image"
            onBlur={formik.handleBlur("logo")}
            onChange={(e) => handleImageChange(e)}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.image?.name ?? `Drop files to attach or browse`
              }
            />
          </span>
          {formik.touched.image && formik.errors.image && (
            <span className="error-text">{formik.errors.image}</span>
          )}
        </div>
        <div className={`col-2 pro-my-2`}>
          <div className={``}>
            <Image
              width={100}
              height={100}
              src={imagePreview}
              alt={`image - 01`}
            />
          </div>
        </div>
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleReset}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={formik?.handleSubmit}
          type="submit"
        >
          {"Submit"}
        </Button>
      </div>
    </div>
  );
};

export default Form1;
