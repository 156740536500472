import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useDepartmentForm from "./useDepartmentForm";
import Select from "react-select";

const DepartmentForm = ({ basicData, isOutside }) => {
  const { formik, getFieldError, handleCloseModal } = useDepartmentForm(
    isOutside,
    basicData
  );
  return (
    <div>
      <div className={`pro-p-4`}>
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Department
          </label>
          <Select
            id="departments"
            name="departments"
            placeholder={"Select"}
            className={`pro-input lg  ${
              getFieldError("departments") && " error"
            }`}
            classNamePrefix="pro-input"
            options={basicData?.departments ?? []}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?._id}
            value={formik?.values?.departments}
            onBlur={formik.handleBlur("departments")}
            onChange={(value) =>
              formik?.setFieldValue("departments", value || null)
            }
            menuPlacement="auto"
          />
          {getFieldError("departments") && (
            <span className="error-text">{getFieldError("departments")}</span>
          )}
        </div>
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Branch
          </label>
          <Select
            id="branches"
            name="branches"
            placeholder={"Select"}
            className={`pro-input lg  ${getFieldError("branches") && " error"}`}
            classNamePrefix="pro-input"
            options={basicData?.branches ?? []}
            getOptionLabel={(option) => option?.branch_name}
            getOptionValue={(option) => option?._id}
            value={formik?.values?.branches}
            onBlur={formik.handleBlur("branches")}
            onChange={(value) =>
              formik?.setFieldValue("branches", value || null)
            }
            menuPlacement="auto"
          />
          {getFieldError("branches") && (
            <span className="error-text">{getFieldError("branches")}</span>
          )}
        </div>
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Process
          </label>
          <Select
            id="process"
            name="process"
            placeholder={"Select"}
            className={`pro-input lg  ${getFieldError("process") && " error"}`}
            classNamePrefix="pro-input"
            options={basicData?.process ?? []}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?._id}
            value={formik?.values?.process}
            onBlur={formik.handleBlur("process")}
            onChange={(value) =>
              formik?.setFieldValue("process", value || null)
            }
            menuPlacement="auto"
          />
          {getFieldError("process") && (
            <span className="error-text">{getFieldError("process")}</span>
          )}
        </div>
        <div
          className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
        >
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={() => handleCloseModal(isOutside)}
          >
            Cancel
          </Button>

          <Button
            className={"pro-btn-primary lg pro-ms-3"}
            type="submit"
            onClick={formik.handleSubmit}
          >
            Done
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DepartmentForm;
