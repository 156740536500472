import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../store/slices/Orders/orderSlice";
import { updateOrderData } from "../../../../../store/slices/Orders/addOrderSlice";

const useItem = (formik) => {
  const [count, setCount] = useState(3);
  const dispatch = useDispatch();

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddOrderModal = false;
      })
    );
  };

  const handleNext = () => {
    dispatch(
      updateOrderData((state) => {
        state.activeTab = "Service";
      })
    );
  };

  const handleAddItemClick = () => {
    setCount((state) => state + 1);
    let { group } = formik?.values;
    group = [
      ...group,
      {
        item_id: "",
        item_quantity: "",
        total_weight: "",
      },
    ];
    formik.setFieldValue("group", group);
  };

  return {
    formik,
    getFieldError,
    handleCloseModal,
    handleNext,
    handleAddItemClick,
  };
};

export default useItem;
