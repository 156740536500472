import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Master/Department/departmentSlice";
import * as Yup from "yup";
import { addDepartment, editDepartment } from "../api";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const useAddDepartment = ({ refetch }) => {
  const dispatch = useDispatch();
  const departmentState = useSelector((state) => state.department);

  useEffect(() => {
    if (departmentState?.isEdit && departmentState?.itemData) {
      formik?.setFieldValue("name", departmentState?.itemData?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentState?.isEdit, departmentState?.itemData]);

  const validation = Yup.object({
    name: Yup.string().required("Enter Name").max(50, "Enter valid Name"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      let data = {
        name: values?.name,
      };

      let editData = {
        name: values?.name,
        department_id: departmentState?.selectedDepartment,
      };

      if (departmentState?.isEdit) {
        editDepartment(editData).then((response) => {
          if (response?.data?.status_code === 200) {
            handleCloseModal();
            toast.success("Successfully Updated");
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
            refetch();
          } else if (response?.status_code === 422) {
            // displays backend errors
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        addDepartment(data).then((response) => {
          if (response?.data?.status_code === 200) {
            handleCloseModal();
            toast.success("Successfully Added");
            refetch();
          } else if (response?.status_code === 422) {
            // displays backend errors
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.showAddDepartmentModal = false)));
  };
  return {
    formik,
    departmentState,
    getFieldError,
    handleCloseModal,
  };
};

export default useAddDepartment;
