import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Master/Service/serviceSlice";
import * as Yup from "yup";
import { addProcessData, editProcessData } from "../api";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useGetServiceBasicDataQuery } from "../../../../store/queries/Master";

const useAddService = ({ refetch }) => {
  const dispatch = useDispatch();
  const serviceState = useSelector((state) => state.service);
  const { data: basicData = {} } = useGetServiceBasicDataQuery();

  useEffect(() => {
    const process = basicData?.data?.filter(
      (item) => serviceState?.itemData?.process?.process_id === item?._id
    );
    const subProcess = basicData?.data?.filter(
      (item) =>
        serviceState?.itemData?.sub_process?.sub_process_id === item?._id
    );

    if (serviceState?.itemData && serviceState?.isEdit) {
      formik?.setFieldValue("name", serviceState?.itemData?.name);
      formik?.setFieldValue("process", process?.[0]);
      formik?.setFieldValue("sub_process", subProcess?.[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceState?.isEdit, serviceState?.itemData]);

  const validation = Yup.object({
    name: Yup.string()
      .trim()
      .required("Enter Name")
      .max(50, "Enter valid Name"),
    process: Yup.object().required("Select A Process"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      process: "",
      sub_process: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      let data = {
        name: values?.name,
        process_id: values?.process?._id,
        sub_process_id: values?.sub_process?._id,
      };
      let editData = {
        service_id: serviceState?.selectedItem,
        name: values?.name,
        process_id: values?.process?._id,
        sub_process_id: values?.sub_process?._id,
      };
      if (serviceState?.isEdit) {
        editProcessData(editData).then((response) => {
          if (response?.data?.status_code === 200) {
            handleCloseModal();
            toast.success("Successfully Updated");
            refetch();
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        addProcessData(data).then((response) => {
          if (response?.data?.status_code === 200) {
            handleCloseModal();
            toast.success("Successfully Added");
            refetch();
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.showAddServiceModal = false)));
  };
  return {
    formik,
    serviceState,
    basicData: basicData?.data,
    getFieldError,
    handleCloseModal,
  };
};

export default useAddService;
