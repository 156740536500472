import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getPrintingList,
  updateConfig,
} from "../../../store/slices/Printing/printingSlice";
import { debounce } from "lodash";

const usePrinting = () => {
  const visitingOneFrontPrintRef = useRef();
  const visitingOneBackPrintRef = useRef();
  const papperFrontPrintRef = useRef();
  const papperBackPrintRef = useRef();
  const bigFrontPrintRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [pendingPage, setPendingPage] = useState(1);
  const [activeCard, setActiveCard] = useState({ id: "", key: "" });
  const dispatch = useDispatch();
  const printingState = useSelector((state) => state.printing);
  const slug = localStorage?.getItem("slug");

  useEffect(() => {
    let params = {
      page: pendingPage,
      per_page: 10,
      process_slug: slug,
    };
    dispatch(getPrintingList(params));
    // eslint-disable-next-line
  }, [printingState.currentPage, printingState.currentPageSize, pendingPage]);

  const handleSearch = (e) => {
    // dispatch(updateConfig((state) => (state.search = e?.target?.value)));
    const inputValue = e.target.value;
    handleSearchInput(inputValue);
  };

  const handleSearchInput = debounce((searchValue) => {
    dispatch(
      updateConfig((state) => {
        state.cardData.Pending = [];
      })
    );
    dispatch(
      getPrintingList({
        search: searchValue,
        page: 1,
        process_slug: slug,
      })
    );
  }, 500);

  const handleCardClick = (e) => {
    setActiveCard({ id: e._id, key: "Pending" });
    dispatch(
      updateConfig((state) => {
        state.showTable = true;
        state.selectedId = e?._id;
        state.gli_id = e?.gli_number;
      })
    );
  };

  const handleModalClick = () => {
    dispatch(updateConfig((state) => (state.printModal = !state.printModal)));
  };
  const handleLoadMore = (mouseHover) => {
    if (
      mouseHover === "Pending" &&
      printingState?.cardData?.Pending?.length !== printingState?.total?.Pending
    ) {
      setPendingPage(pendingPage + 1);
    }
  };
  return {
    cardData: printingState?.cardData,
    visitingOneFrontPrintRef,
    visitingOneBackPrintRef,
    papperFrontPrintRef,
    papperBackPrintRef,
    bigFrontPrintRef,
    printingState,
    totalLength: { Pending: printingState.total.Pending },
    isLoading,
    activeCard,
    handleSearch,
    setIsLoading,
    handleLoadMore,
    handleCardClick,
    handleModalClick,
  };
};

export default usePrinting;
