import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start")}${getParams("end")}filter=${
            params?.filter || "all"
          }&${getParams("sort_by")}sort_order=${
            params?.sort_order || "dec"
          }&per_page=${params?.page_size || "20"}&${getParams("search")}page=${
            params?.page || ""
          }`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}${getParams("start")}${getParams("end")}${getParams(
            "sort_by"
          )}${getParams("search")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const master = createApi({
  reducerPath: "masterApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "Branches",
    "Sources",
    "Department",
    "Employee",
    "Assign",
    "ItemType",
    "Item",
    "Packing",
    "Engraving",
    "ItemGrading",
    "Certificate",
    "CertificatePrefix",
    "Report",
    "Billing",
    "Shop",
    "Role",
  ],
  endpoints: (builder) => ({
    // branches starts
    getBranchesListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/branch/list`,
      }),
      providesTags: ["Branches"],
    }),
    deleteBranch: builder.mutation({
      query: (id) => ({
        method: "delete",
        endpoint: `/branch/delete?branch_id=${id}`,
      }),
      invalidatesTags: ["Branches"],
    }),
    getFormData: builder.query({
      query: () => ({
        method: "get",
        endpoint: `/branch/basic-data`,
      }),
    }),
    updateBranchData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/branch/save`,
      }),
      invalidatesTags: ["Branches"],
    }),
    updateBranchTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),

      invalidatesTags: ["Branches"],
    }),

    // branches ends
    // source starts
    getSourceListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/source/list`,
      }),
      providesTags: ["Sources"],
    }),
    updateSourceTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),

      invalidatesTags: ["Sources"],
    }),
    // source ends
    // department starts
    getDepartmentListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/departments/list`,
      }),
      providesTags: ["Department"],
    }),
    updateDepartmentTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),

      invalidatesTags: ["Department"],
    }),
    // department ends
    // Role starts
    getRoleListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/roles/list`,
      }),
      providesTags: ["Role"],
    }),
    updateRoleTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),

      invalidatesTags: ["Role"],
    }),

    // role ends
    // employee starts
    getEmployeeListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/employee/list`,
      }),
      providesTags: ["Employee"],
    }),
    updateEmployeeTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),

      invalidatesTags: ["Employee"],
    }),

    // employee ends
    // assign starts
    getAssignListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/employee/list`,
      }),
      providesTags: ["Assign"],
    }),

    // assign ends
    // process starts
    getProcessListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/process/list`,
      }),
      providesTags: ["Process"],
    }),
    updateProcessTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),

      invalidatesTags: ["Process"],
    }),
    // process ends
    // service starts
    getServiceListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/service/list`,
      }),
      providesTags: ["Service"],
    }),
    getServiceBasicData: builder.query({
      query: () => ({
        method: "get",
        endpoint: `/service/list-process`,
      }),
    }),
    updateServiceTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),

      invalidatesTags: ["Service"],
    }),
    // service ends
    // item type starts
    getItemTypeListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/item-type/list`,
      }),
      providesTags: ["ItemType"],
    }),
    getItemTypeBasicData: builder.query({
      query: () => ({
        method: "get",
        endpoint: `/item-type/basic-data`,
      }),
    }),
    updateItemTypeTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),

      invalidatesTags: ["ItemType"],
    }),
    // item type ends
    //item starts
    getItemListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/item/list`,
      }),
      providesTags: ["Item"],
    }),
    updateItemTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),

      invalidatesTags: ["Item"],
    }),
    // item ends
    // packing starts
    getPackingListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/packing-type/list`,
      }),
      providesTags: ["Packing"],
    }),
    updatePackingTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),

      invalidatesTags: ["Packing"],
    }),
    // packing ends
    // engraving starts
    getEngravingListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/engraving/list`,
      }),
      providesTags: ["Engraving"],
    }),
    getEngravingBasicData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/engraving/basic-data`,
      }),
      providesTags: ["Engraving"],
    }),
    updateEngravingTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),

      invalidatesTags: ["Engraving"],
    }),

    // engraving ends
    // item grading starts
    getItemGradingListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/item-grading/list`,
      }),
      providesTags: ["ItemGrading"],
    }),
    getItemGradingBasicData: builder.query({
      query: () => ({
        method: "get",
        endpoint: `/item-grading/basic-data`,
      }),
    }),
    updateItemGradingTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),

      invalidatesTags: ["ItemGrading"],
    }),
    // item grading ends
    // certificate starts
    getCertificateListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/certificate-prefix/list`,
      }),
      providesTags: ["Certificate"],
    }),

    //edited by sreejitha
    getCertificatePrefixListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/certificate-prefix/list`,
      }),
      providesTags: ["CertificatePrefix"],
    }),
    updateCertificatePrefixData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/certificate-prefix/save`,
      }),
      invalidatesTags: ["CertificatePrefix"],
    }),
    getBasicCertificatePrefixData: builder.query({
      query: () => ({
        method: "get",
        endpoint: `/certificate-prefix/basic-data`,
      }),
      invalidatesTags: ["CertificatePrefix"],
    }),
    deleteCertificatePrefix: builder.mutation({
      query: (id) => ({
        method: "delete",
        endpoint: `/certificate-prefix/delete?certificate_prefix_id=${id}`,
      }),
      invalidatesTags: ["CertificatePrefix"],
    }),
    updateCertificatePrefixTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),

      invalidatesTags: ["CertificatePrefix"],
    }),

    // certificate ends
    // report starts
    getReportListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/report/list`,
      }),
      providesTags: ["Report"],
    }),
    updateReportTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),

      invalidatesTags: ["Report"],
    }),
    deleteReport: builder.mutation({
      query: (id) => ({
        method: "delete",
        endpoint: `/report/delete?report_id=${id}`,
      }),
      invalidatesTags: ["Report"],
    }),
    // report ends
    // bill starts
    getBillingListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/billing/list`,
      }),
      providesTags: ["Billing"],
    }),
    getBillingBasicData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/billing/basic-data`,
      }),
    }),
    updateBillingTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),

      invalidatesTags: ["Billing"],
    }),
    // bill ends
    // shop starts
    getShopListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/supplier-shop/list`,
      }),
      providesTags: ["Shop"],
    }),
    getShopBasicData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/supplier-shop/basic-data`,
      }),
      providesTags: ["Shop"],
    }),
    updateShopTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),

      invalidatesTags: ["Shop"],
    }),
    // shop ends
  }),
});

export const {
  useUpdateRoleTableHeadDataMutation,
  useUpdateBillingTableHeadDataMutation,
  useGetBillingBasicDataQuery,
  useUpdateEngravingTableHeadDataMutation,
  useGetEngravingBasicDataQuery,
  useUpdateShopTableHeadDataMutation,
  useGetShopBasicDataQuery,
  useUpdateDepartmentTableHeadDataMutation,
  useUpdateItemTypeTableHeadDataMutation,
  useGetItemTypeBasicDataQuery,
  useUpdateServiceTableHeadDataMutation,
  useGetServiceBasicDataQuery,
  useUpdateProcessTableHeadDataMutation,
  useUpdatePackingTableHeadDataMutation,
  useUpdateItemTableHeadDataMutation,
  useUpdateItemGradingTableHeadDataMutation,
  useGetItemGradingBasicDataQuery,
  useGetShopListDataQuery,
  useGetBillingListDataQuery,
  useGetReportListDataQuery,
  useGetCertificateListDataQuery,
  useGetCertificatePrefixListDataQuery,
  useUpdateCertificatePrefixDataMutation,
  useDeleteCertificatePrefixMutation,
  useGetBasicCertificatePrefixDataQuery,
  useUpdateCertificatePrefixTableHeadDataMutation,
  useGetItemGradingListDataQuery,
  useGetEngravingListDataQuery,
  useGetPackingListDataQuery,
  useGetItemListDataQuery,
  useGetItemTypeListDataQuery,
  useGetServiceListDataQuery,
  useGetProcessListDataQuery,
  useGetAssignListDataQuery,
  useGetEmployeeListDataQuery,
  useGetRoleListDataQuery,
  useGetDepartmentListDataQuery,
  useGetSourceListDataQuery,
  useGetBranchesListDataQuery,
  useDeleteBranchMutation,
  useDeleteReportMutation,
  useGetFormDataQuery,
  useUpdateBranchDataMutation,
  useUpdateBranchTableHeadDataMutation,
  useUpdateReportTableHeadDataMutation,
  useUpdateEmployeeTableHeadDataMutation,
  useUpdateSourceTableHeadDataMutation,
} = master;
