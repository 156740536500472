import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  verificationStatus: "idle",
  photoUploadStatus: "idle",
  uploadedRoughImageStatus: "idle",
  uploadedRoughImage: "",
  verificationData: {
    pending_list: [],
    complete_list: [],
  },
  myQueueList: {
    Pending: [],
  },
  photoUploadData: {
    pending_list: [],
    complete_list: [],
  },
  myQueueRecheckList: {
    Pending: [],
  },
  photoUploadRecheckData: {
    pending_list: [],
    complete_list: [],
  },
  searchData: {},
  searchStatus: "idle",
  pendingStatus: "idle",
  uploadedImageData: [],
  uploadedRecheckImageData: [],
  uploadedImageStatus: "idle",
  selectedProfiles: [],
  currentPage: 1,
  search: "",
  from_date: "",
  to_date: "",
  item_type: "",
  shop: "",
  status: "",
  total: {
    Queue: "",
    Pending: "",
    Completed: "",
  },
  roughImageModal: false,
};
export const getMyQueueList = createAsyncThunk(
  "employee-dashboard/order/assigned-to-me",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/order/assigned-to-me`,
        params
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getCompleteList = createAsyncThunk(
  "employee-dashboard/order/work-completed",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/order/work-completed`,
        params
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getPendingList = createAsyncThunk(
  "employee-dashboard/order/work-pending",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/order/work-pending`,
        params
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getSearchList = createAsyncThunk(
  "back-office-image/search-design",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/back-office-image/search-design`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const startTimer = createAsyncThunk(
  "/employee-dashboard/order/start-timer",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/order/start-timer`,
        params
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getUploadedPhoto = createAsyncThunk(
  "back-office-image/edit",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/back-office-image/edit?item_details_id=${params}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteUploadedPhoto = createAsyncThunk(
  "/back-office-image/delete",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const url = `/back-office-image/delete?design_id=${params.design_id}&name=${params.name}`;
      const response = await api.get(url);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//photo upload recheck
export const getMyRecheckQueueList = createAsyncThunk(
  "employee-dashboard/recheck/assigned-to-me",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/recheck/assigned-to-me`,
        params
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getCompleteRecheckList = createAsyncThunk(
  "employee-dashboard/recheck/work-completed",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/recheck/work-completed`,
        params
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getPendingRecheckList = createAsyncThunk(
  "employee-dashboard/recheck/work-pending",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/recheck/work-pending`,
        params
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getSearchRecheckList = createAsyncThunk(
  "back-office-image/search-design",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/back-office-image/search-design`,
        params
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const startRecheckTimer = createAsyncThunk(
  "/employee-dashboard/recheck/start-timer",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/recheck/start-timer`,
        params
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getUploadedRecheckPhoto = createAsyncThunk(
  "/back-office-image/recheck/edit",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/back-office-image/recheck/edit?recheck_item_details_id=${params}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUploadedRoughPhoto = createAsyncThunk(
  "getRoughImage",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/back-office-image/rough-image?item_details_id=${id}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//------------------------------
const PhotoUploadSlice = createSlice({
  name: "photoUpload",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setStoreFormValues: (state, action) => {
      state.franchiseId = action.payload.franchise;
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    resetExtraFilters: (state, action) => {
      state.franchiseId = "";
      state.startDate = "";
      state.endDate = "";
    },
  },
  extraReducers: {
    [getMyQueueList.pending]: (state, action) => {
      state.photoUploadStatus = "pending";
    },
    [getMyQueueList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedQueue = [];
      // Add existing items with unique _id values to uniqueItems
      state.myQueueList.Pending.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedQueue.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedQueue.push(item);
        }
      });
      state.myQueueList.Pending = combinedQueue;
      state.total.Queue = action.payload.data.data.total_count;

      state.photoUploadStatus = "fulfilled";
    },
    [getMyQueueList.rejected]: (state) => {
      state.photoUploadStatus = "failed";
    },

    [getCompleteList.pending]: (state, action) => {
      state.photoUploadStatus = "pending";
    },
    [getCompleteList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedComplete = [];
      // Add existing items with unique _id values to uniqueItems
      state.photoUploadData.complete_list.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedComplete.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedComplete.push(item);
        }
      });
      state.photoUploadData.complete_list = combinedComplete;
      state.total.Completed = action.payload?.data?.data?.total_count;
      state.photoUploadStatus = "fulfilled";
    },
    [getCompleteList.rejected]: (state) => {
      state.photoUploadStatus = "failed";
    },
    [getPendingList.pending]: (state, action) => {
      state.photoUploadStatus = "pending";
    },
    [getPendingList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedPending = [];
      // Add existing items with unique _id values to uniqueItems
      state.photoUploadData.pending_list.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedPending.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedPending.push(item);
        }
      });
      state.photoUploadData.pending_list = combinedPending;
      state.total.Pending = action.payload?.data?.data?.total_count;
      state.photoUploadStatus = "fulfilled";
    },
    [getPendingList.rejected]: (state) => {
      state.photoUploadStatus = "failed";
    },
    [getSearchList.pending]: (state, action) => {
      state.searchStatus = "pending";
    },
    [getSearchList.fulfilled]: (state, action) => {
      state.searchData = action?.payload?.data?.data;
      state.searchStatus = "fulfilled";
    },
    [getSearchList.rejected]: (state) => {
      state.searchStatus = "failed";
    },

    [getUploadedPhoto.pending]: (state, action) => {
      state.uploadedImageStatus = "pending";
    },
    [getUploadedPhoto.fulfilled]: (state, action) => {
      state.uploadedImageData = action?.payload?.data?.data;
      state.uploadedImageStatus = "fulfilled";
    },
    [getUploadedPhoto.rejected]: (state) => {
      state.uploadedImageStatus = "failed";
    },
    //photo upload recheck reducers
    [getMyRecheckQueueList.pending]: (state, action) => {
      state.photoUploadStatus = "pending";
    },
    [getMyRecheckQueueList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedQueue = [];
      // Add existing items with unique _id values to uniqueItems
      state.myQueueRecheckList.Pending.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedQueue.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedQueue.push(item);
        }
      });
      state.myQueueRecheckList.Pending = combinedQueue;
      state.total.Queue = action.payload.data.data.total_count;

      state.photoUploadStatus = "fulfilled";
    },
    [getMyRecheckQueueList.rejected]: (state) => {
      state.photoUploadStatus = "failed";
    },

    [getCompleteRecheckList.pending]: (state, action) => {
      state.photoUploadStatus = "pending";
    },
    [getCompleteRecheckList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedComplete = [];
      // Add existing items with unique _id values to uniqueItems
      state.photoUploadRecheckData.complete_list.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedComplete.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedComplete
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedComplete.push(item);
        }
      });
      state.photoUploadRecheckData.complete_list = combinedComplete;
      state.total.Completed = action.payload.data.data.total_count;
      state.photoUploadStatus = "fulfilled";
    },
    [getCompleteRecheckList.rejected]: (state) => {
      state.photoUploadStatus = "failed";
    },
    [getPendingRecheckList.pending]: (state, action) => {
      state.photoUploadStatus = "pending";
    },
    [getPendingRecheckList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedPending = [];
      // Add existing items with unique _id values to uniqueItems
      state.photoUploadRecheckData.pending_list.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedPending.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedPending.push(item);
        }
      });
      state.photoUploadRecheckData.pending_list = combinedPending;
      state.total.Pending = action.payload.data.data.total_count;
      state.photoUploadStatus = "fulfilled";
    },
    [getPendingRecheckList.rejected]: (state) => {
      state.photoUploadStatus = "failed";
    },

    [getSearchRecheckList.pending]: (state, action) => {
      state.searchStatus = "pending";
    },
    [getSearchRecheckList.fulfilled]: (state, action) => {
      state.searchData = action?.payload?.data?.data;
      state.searchStatus = "fulfilled";
    },
    [getSearchRecheckList.rejected]: (state) => {
      state.searchStatus = "failed";
    },

    [getUploadedRecheckPhoto.pending]: (state, action) => {
      state.uploadedImageStatus = "pending";
    },
    [getUploadedRecheckPhoto.fulfilled]: (state, action) => {
      state.uploadedRecheckImageData = action?.payload?.data?.data;
      state.uploadedImageStatus = "fulfilled";
    },
    [getUploadedRecheckPhoto.rejected]: (state) => {
      state.uploadedImageStatus = "failed";
    },
    [getUploadedRoughPhoto.pending]: (state) => {
      state.uploadedRoughImageStatus = "pending";
    },
    [getUploadedRoughPhoto.fulfilled]: (state, action) => {
      state.uploadedRoughImage = action?.payload?.data?.data;
      state.uploadedImageStatus = "fulfilled";
    },
    [getUploadedRoughPhoto.rejected]: (state) => {
      state.uploadedRoughImageStatus = "failed";
    },
  },
});

export const { updateConfig } = PhotoUploadSlice.actions;

export default PhotoUploadSlice.reducer;
