import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";

const useSettings = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const drawerMenu = [
    {
      title: "Role management",
      label: "Role management",
      link: "/settings",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">manage_accounts</span>,
      active: checkIfActiveRoute("/settings", true),
    },
  ];
  return { drawerMenu };
};

export default useSettings;
