import React from "react";
import { useSelector } from "react-redux";

const PapperBack = React.forwardRef(({ papperBackPrintRef }) => {
  const { printingData } = useSelector((state) => state.printing);

  return (
    <div ref={papperBackPrintRef} style={{ width: "100%", height: "100%" }}>
      <title>DHC PDF</title>
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap"
        rel="stylesheet"
      />
      {printingData?.map((value, index) => {
        return (
          <div
            key={index}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            id="element-to-print"
          >
            <table
              key={index}
              border={0}
              cellSpacing={0}
              role="presentation"
              cellPadding={0}
              style={{
                backgroundColor: "#ffffff",
                maxWidth: 840,
                borderCollapse: "collapse",
                minWidth: 840,
                width: 840,
                margin: "0 auto",
              }}
            >
              <tbody>
                <tr>
                  <td style={{ maxWidth: "100%" }}>
                    <img
                      src={value?.back_image ?? ""}
                      alt="back_image"
                      style={{ width: 840, height: 595 }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ pageBreakBefore: "always" }}></div>
          </div>
        );
      })}
    </div>
  );
});

export default PapperBack;
