import Style from "./orderCardListing.module.scss";
import OrderCard from "../OrderCard/index";
import useGliCardListing from "../GliCardLIsting/useGliCardListing";
import CardShimmer from "../CardShimmer";

const PhotoCardListing = ({
  data,
  handleChange,
  extaClassName,
  hasButton,
  activeCard,
  refBottom = false,
  handleLoadMore,
  setIsLoading,
  isLoading,
  totalLength,
}) => {
  const { scrollRefs, mouseHover, handleMouseOver } = useGliCardListing(
    data,
    handleLoadMore,
    setIsLoading,
    totalLength
  );
  return (
    <>
      {Object.keys(data ? data : {}).map((key, mainIndex) => (
        <div
          key={mainIndex}
          className={`${Style.root_list} ${extaClassName} ${
            hasButton ? "pro-pb-0" : ""
          }`}
          ref={(ref) => !refBottom && (scrollRefs.current[key] = ref)}
          onMouseEnter={() => handleMouseOver(key)}
        >
          <h6 className="pro-ttl pro-mb-2 pro-font-regular">
            {key === "pending_list"
              ? "Pending Queue"
              : key === "complete_list"
              ? "Passed"
              : ""}
          </h6>
          <div className={`${Style.root_list_item}`}>
            {data[key] &&
              data[key]?.map((item, index) => (
                <OrderCard
                  activeCard={activeCard}
                  id={item?._id}
                  index={index}
                  key={index}
                  onClick={() =>
                    handleChange(item._id, key, item.certificate_number)
                  }
                  title={item.certificate_number}
                  subTitle={`${item.shop}`}
                />
              ))}
            {mouseHover === key &&
              isLoading &&
              data?.[key]?.length !== totalLength?.[key] &&
              [...Array(2)]?.map((_, i) => <CardShimmer key={i} />)}
          </div>
        </div>
      ))}
    </>
  );
};

export default PhotoCardListing;
