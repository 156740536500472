import { Pagination, Table } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import usePrintingTable from "./usePrintingTable";

const PrintingTable = () => {
  const {
    tableData,
    tableFields,
    paginationOptions,
    isFetching,
    currentPageSize,
    getRow,
    handlePagination,
    handlePageSize,
    handlePrintClick,
  } = usePrintingTable();
  return (
    <div>
      <Table
        multiSelect={true}
        data={tableData?.data?.data ?? []}
        uniqueID={"id"}
        fields={tableFields}
        SortIcon={<FaSort />}
        assignIcon={<span className="material-symbols-outlined">print</span>}
        getRow={getRow}
        loading={isFetching}
        perpage={currentPageSize}
        assignable={true}
        deletable={false}
        showCheckBox={true}
        editable={false}
        handleAssign={handlePrintClick}
        assignText={`Print`}
      />
      {tableData?.data?.data && (
        <Pagination
          currentPage={tableData?.data?.current_page}
          totalPageCount={Math.ceil(
            tableData?.data?.total_count / tableData?.data?.per_page
          )}
          onPageChange={handlePagination}
          options={paginationOptions}
          onActionChange={handlePageSize}
          center
        />
      )}
    </div>
  );
};

export default PrintingTable;
