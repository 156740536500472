import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/QC/QCSlice";
import { getExcelData } from "../api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const useCompareTab = () => {
  const dispatch = useDispatch();
  const { certificateID, data, activeId } = useSelector((state) => state.qc);

  const handleAdd = () => {
    dispatch(
      updateConfig((state) => {
        state.data = [{ name: "Version 1" }, { name: "Version 2" }];
        state.activeId = 2;
      })
    );
  };

  const handleClick = (e) => {
    if (e.name === "Version 1") {
      dispatch(updateConfig((state) => (state.activeId = 1)));
    } else {
      dispatch(updateConfig((state) => (state.activeId = 2)));
    }
  };

  const handleCompare = () => {
    dispatch(updateConfig((state) => (state.showCompare = true)));
    getExcelData(certificateID).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => (state.excelData = response?.data?.data))
        );
      } else {
        toast.error("Can't genrate excel data ");
      }
    });
  };
  return { data, activeId, handleAdd, handleClick, handleCompare };
};

export default useCompareTab;
