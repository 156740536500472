import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 20,
  currentFilter:"all",
  sortBy: "",
  sortOrder: "asc",
  allRoles: [],
  allRolesStatus: "idle",
  allPermissions: [],
  allPermissionsStatus: "idle",
  rolePermission: {},
  rolePermissionStatus: "idle",
  name: "",
  PermissionsModules: {},
  userDetails: "",
  search:""
};

export const getAllPermissions = createAsyncThunk(
  "roles/getAllPermissions",
  async (_, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/roles/basic-data`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPermission = createAsyncThunk(
  "roles/getPermission",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/roles/view?role_id=${params.id}`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    [getAllPermissions.pending]: (state) => {
      state.allPermissionsStatus = "pending";
    },
    [getAllPermissions.fulfilled]: (state, action) => {
      state.allPermissionsStatus = "success";
      state.allPermissions = action.payload?.data?.data;
    },
    [getAllPermissions.rejected]: (state) => {
      state.allPermissionsStatus = "failed";
    },
    [getPermission.pending]: (state) => {
      state.rolePermissionStatus = "pending";
    },
    [getPermission.fulfilled]: (state, action) => {
      state.rolePermission[action.meta.arg.id] = action.payload?.data?.data;
      state.userDetails = action.payload?.data?.data;
      state.rolePermissionStatus = "fulfilled";
    },
    [getPermission.rejected]: (state) => {
      state.rolePermissionStatus = "rejected";
    },
  },
});

export const { updateConfig } = roleSlice.actions;

export default roleSlice.reducer;
