import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getQcAssignedList,
  getQcCompletedList,
  getQcMarkedList,
  getQcRejectedList,
  getQcPendingList,
  updateConfig,
} from "../../../store/slices/QC/QCSlice";
import { useSelector } from "react-redux";
import { geFormData, submitForm, getExcelFormData } from "./api";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { getStatusData } from "../../Global/AddStatus/api";

const useQC = () => {
  const {
    qcData,
    showForm,
    showQcModal,
    formData,
    certificateID,
    assignedID,
    showValues,
    itemID,
    total,
    qcPendingStatus,
    qcAssignedStatus,
    qcCompletedStatus,
  } = useSelector((state) => state.qc);
  const [assignedPage, setAssignedPage] = useState(1);
  const [pendingPage, setPendingPage] = useState(1);
  const [completedPage, setCompletedPage] = useState(1);
  const [markedPage, setMarkedPage] = useState(1);
  const [rejectedPage, setRejectedPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [statusData, setStatusData] = useState([]);
  const [timer, setTimer] = useState(true);
  const [activeCard, setActiveCard] = useState({ id: "", key: "" });
  const [imagePreview, setImagePreview] = useState("");
  const reportRef = useRef(null);
  const dispatch = useDispatch();
  const slug = localStorage?.getItem("slug");
  useEffect(() => {
    dispatch(
      getQcAssignedList({
        page: 1,
        per_page: 10,
        date: [],
        item_type: [],
        shop: [],
        status: [],
        process_slug: slug,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pendingPage !== 1) {
      dispatch(
        getQcPendingList({
          page: pendingPage,
          per_page: 10,
          receipt_id: assignedID,
          process_slug: slug,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingPage]);
  useEffect(() => {
    if (assignedPage !== 1) {
      dispatch(
        getQcAssignedList({
          page: assignedPage,
          per_page: 10,
          receipt_id: assignedID,
          process_slug: slug,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedPage]);

  useEffect(() => {
    if (completedPage !== 1) {
      dispatch(
        getQcCompletedList({
          page: completedPage,
          per_page: 10,
          receipt_id: assignedID,
          process_slug: slug,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedPage]);

  useEffect(() => {
    if (markedPage !== 1) {
      dispatch(getQcMarkedList({
        page: markedPage,
        per_page: 10,
        receipt_id: assignedID,
        process_slug: slug,
        status: 5,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markedPage])


  useEffect(() => {
    if (rejectedPage !== 1) {
      dispatch(getQcRejectedList({
        page: rejectedPage,
        per_page: 10,
        receipt_id: assignedID,
        process_slug: slug,
        status: 4,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectedPage])


  let dynamicStructure = {};

  useEffect(() => {
    // Iterate over the qc_fields array unconditionally
    if (formData?.length !== 0) {
      for (const field of formData?.qc_fields) {
        const slug = field?.slug;
        dynamicStructure[slug] = "";
        dynamicStructure["status"] = "";
      }
      // Check if cstone_sstone is 1 before adding center_stone_fields and side_stone_fields
      if (formData.cstone_sstone === 1) {
        for (const field of formData?.center_stone_fields) {
          const slug = field?.slug;
          dynamicStructure[`center_stone_${slug}`] = "";
        }

        for (const field of formData?.side_stone_fields) {
          const slug = field?.slug;
          dynamicStructure[`side_stone_${slug}`] = "";
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const formik = useFormik({
    initialValues: dynamicStructure,
    validate: (values) => {
      let errors = {};
      Object.keys(values || {}).forEach((key) => {
        if (
          values[key] === null ||
          values[key] === undefined ||
          values[key] === ""
        ) {
          errors[`${key}`] = `This field is required`;
        }
        if (values?.status === undefined) {
          errors.status = "Select Status";
        }
        if (key === "image") {
          const image = values[key];
          if (image) {
            // Check image type
            const allowedTypes = ["image/jpeg", "image/png"];
            if (!allowedTypes.includes(image.type)) {
              errors.image = "Image must be in JPEG or PNG format";
            }
            // Check image size (less than 2MB)
            const maxSizeInBytes = 2 * 1024 * 1024; // 2MB
            if (image.size > maxSizeInBytes) {
              errors.image = "Image size must be less than 2MB";
            }
          }
        }
      });
      return errors;
    },
    onSubmit: (values) => {
      handleConfirm(values);
    },
  });
  useEffect(() => {
    if (formData) {
      formik?.setErrors({});
      formik.setValues(dynamicStructure);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const handleCardClick = (item, key) => {
    formik?.setErrors({});
    setActiveCard({ id: item?._id, key: key });
    dispatch(updateConfig((state) => (state.item = item)));
    if (key === "Assigned") {
      dispatch(
        updateConfig((state) => {
          state.assignedID = item?._id;
          state.qcData.Passed = [];
          state.qcData.Pending = [];
          state.qcData.Marked = [];
          state.qcData.Rejected = [];
          state.showValues = false;
          state.showForm = false
        })
      );
      dispatch(
        getQcPendingList({
          page: 1,
          per_page: 10,
          receipt_id: item?._id,
          process_slug: slug,
        })
      );
      dispatch(
        getQcCompletedList({
          page: 1,
          per_page: 10,
          receipt_id: item?._id,
          process_slug: slug,
        })
      );
      dispatch(
        getQcMarkedList({
          page: 1,
          per_page: 10,
          receipt_id: item?._id,
          process_slug: slug,
          status: 5,
        })
      );
      dispatch(
        getQcRejectedList({
          page: 1,
          per_page: 10,
          receipt_id: item?._id,
          process_slug: slug,
          status: 4,
        })
      );
    } else if (key === "Pending") {
    
      getStatusData().then((response) => {
        if (response?.data?.status_code === 200) {
          setStatusData(response?.data?.data);
        }
    
      });
      dispatch(
        updateConfig((state) => {
          state.certificateID = item?._id;
          state.itemID = item?.gli_number;
        })
      );
      let data = {
        item_details_id: item?._id,
        item_type_id: item?.item_type_id,
        process_slug: slug,
      };
      geFormData(data)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            if (timer) {
              toast.success("timer started");
              setTimer(false);
            }
            dispatch(
              updateConfig((state) => {
                state.formData = response?.data?.data;
                state.showValues = false;
                state.showForm = true;
              })
            );
          }
        })
        .catch((error) => toast.error(error));
    } else if (key === "Passed") {
      dispatch(
        updateConfig((state) => {
          state.certificateID = item?._id;
          state.itemID = item?.gli_number;
          state.showCompare = false;
          state.data = [{ name: "Version 1" }];
          state.activeId = 1;
        })
      );
      let data2 = {
        item_details_id: item?._id,
        item_type_id: item?.item_type_id,
        process_slug: slug,
      };
      getExcelFormData(data2)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            if (response?.data?.data?.updated_values.length !== 0) {
              dispatch(
                updateConfig((state) => {
                  state.data = [{ name: "Version 1" }, { name: "Version 2" }];
                })
              );
            }
            dispatch(
              updateConfig((state) => {
                state.excelFormData = response?.data?.data?.graded_values;
                state.excelEditedFormData =
                  response?.data?.data?.updated_values.length === 0
                    ? (state.excelFormData =
                      response?.data?.data?.graded_values)
                    : response?.data?.data?.updated_values;
                state.showValues = true;
                state.showForm = false;
                state.dropdownValues =
                  response?.data?.data?.dropdown_values ?? {};
              })
            );
          }
        })
        .catch((error) => toast.error(error));
    }
    else if (key === "Marked") {
      dispatch(
        updateConfig((state) => {
          state.certificateID = item?._id;
          state.itemID = item?.gli_number;
          state.showCompare = false;
          // state.data = [{ name: "Version 1" }];
          // state.activeId = 1;
        })
      );
      let data2 = {
        item_details_id: item?._id,
        item_type_id: item?.item_type_id,
        process_slug: slug,
      };
      getExcelFormData(data2)
      .then((response) => {
        if (response?.data?.status_code === 200) {
          if (response?.data?.data?.updated_values.length !== 0) {
            dispatch(
              updateConfig((state) => {
                state.data = [{ name: "Version 1" }, { name: "Version 2" }];
              })
            );
          }
          dispatch(
            updateConfig((state) => {
              state.excelFormData = response?.data?.data?.graded_values;
              state.excelEditedFormData =
                response?.data?.data?.updated_values.length === 0
                  ? (state.excelFormData =
                    response?.data?.data?.graded_values)
                  : response?.data?.data?.updated_values;
              state.showValues = true;
              state.showForm = false;
              state.dropdownValues =
                response?.data?.data?.dropdown_values ?? {};
            })
          );
        }
      })
      .catch((error) => toast.error(error));

    }
    else if (key === "Rejected") {
     
      dispatch(
        updateConfig((state) => {
          state.certificateID = item?._id;
          state.itemID = item?.gli_number;
          state.showCompare = false;
          // state.data = [{ name: "Version 1" }];
          // state.activeId = 1;
        })
      );
      let data2 = {
        item_details_id: item?._id,
        item_type_id: item?.item_type_id,
        process_slug: slug,
      };
      getExcelFormData(data2)
      .then((response) => {
        if (response?.data?.status_code === 200) {
          if (response?.data?.data?.updated_values.length !== 0) {
            dispatch(
              updateConfig((state) => {
                state.data = [{ name: "Version 1" }, { name: "Version 2" }];
              })
            );
          }
          dispatch(
            updateConfig((state) => {
              state.excelFormData = response?.data?.data?.graded_values;
              state.excelEditedFormData =
                response?.data?.data?.updated_values.length === 0
                  ? (state.excelFormData =
                    response?.data?.data?.graded_values)
                  : response?.data?.data?.updated_values;
              state.showValues = true;
              state.showForm = false;
              state.dropdownValues =
                response?.data?.data?.dropdown_values ?? {};
            })
          );
        }
      })
      .catch((error) => toast.error(error));

    }
  };

  const getFieldError = (fieldName) => {
    if (formik?.touched?.[fieldName] && formik?.errors?.[fieldName]) {
      return formik?.errors?.[fieldName];
    }
    return "";
  };

  const handleSearchInput = debounce((searchValue) => {
    dispatch(
      updateConfig((state) => {
        state.qcData.Pending = [];
        state.qcData.Passed = [];
        state.qcData.Assigned = [];
        state.qcData.Marked = [];
        state.qcData.Rejected = [];
      })
    );
    dispatch(
      getQcAssignedList({
        search: searchValue,
        page: 1,
        process_slug: slug,
      })
    );
    if (assignedID) {
      dispatch(
        getQcCompletedList({
          search: searchValue,
          page: 1,
          receipt_id: assignedID,
          process_slug: slug,
        })
      );
      dispatch(
        getQcPendingList({
          search: searchValue,
          page: 1,
          receipt_id: assignedID,
          process_slug: slug,
        })
      );
      dispatch(
        getQcMarkedList({
          search: searchValue,
          page: 1,
          receipt_id: assignedID,
          process_slug: slug,
          status: 5,
        })
      );
      dispatch(
        getQcRejectedList({
          search: searchValue,
          page: 1,
          receipt_id: assignedID,
          process_slug: slug,
          status: 4,
        })
      );
    }
  }, 500);

  // Inside your component
  const handleSearch = (e) => {
    dispatch(updateConfig((state) => (state.search = e.target.value)));
    const inputValue = e.target.value;
    handleSearchInput(inputValue);
  };

  const handleConfirm = (values) => {
    let data = {
      process_slug: slug,
      item_details_id: certificateID,
      ...values,
    };
    let formData = new FormData();
    for (let key in data) {
      if (key.startsWith("center_stone")) {
        const formattedKey = key.replace("center_stone_", ""); // Remove the prefix
        formData.append(`center_stone[${formattedKey}]`, data[key]);
      } else if (key.startsWith("side_stone")) {
        const formattedKey = key.replace("side_stone_", ""); // Remove the prefix
        formData.append(`side_stone[${formattedKey}]`, data[key]);
      } else {
        // For other keys, append them as they are
        formData.append(key, data[key]);
      }
    }
    submitForm(formData)
      .then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Success");
          setImagePreview("");
          dispatch(updateConfig((state) => (state.showForm = false)));
          dispatch(
            updateConfig((state) => {
              state.qcData.Pending = [];
              state.qcData.Passed = [];
              state.qcData.Marked = [];
              state.qcData.Rejected = [];
            })
          );
          formik?.resetForm();
          formik?.setErrors({});
          dispatch(
            getQcPendingList({
              page: 1,
              per_page: 10,
              receipt_id: assignedID,
              process_slug: slug,
            })
          );
          dispatch(
            getQcCompletedList({
              page: 1,
              per_page: 10,
              receipt_id: assignedID,
              process_slug: slug,
            })
          );
          dispatch(
            getQcMarkedList({
              page: 1,
              per_page: 10,
              receipt_id: assignedID,
              process_slug: slug,
              status: 5,
            })
          );
          dispatch(
            getQcRejectedList({
              page: 1,
              per_page: 10,
              receipt_id: assignedID,
              process_slug: slug,
              status: 4,
            })
          );
        }
      })
      .catch((error) => {
        toast.error(error);
        setImagePreview("");
      });
  };

  const handleLoadMore = (mouseHover) => {
    if (
      mouseHover === "Pending" &&
      qcData?.Pending?.length !== total?.Pending
    ) {
      setPendingPage(pendingPage + 1);
    }
    if (
      mouseHover === "Assigned" &&
      qcData?.Assigned?.length !== total?.Assigned
    ) {
      setAssignedPage(assignedPage + 1);
    }
    if (mouseHover === "Passed" && qcData?.Passed?.length !== total?.Passed) {
      setCompletedPage(completedPage + 1);
    }
    if (
      mouseHover === "Marked" &&
      qcData?.Marked?.length !== total?.Marked
    ) {
      setMarkedPage(markedPage + 1);
    }
    if (
      mouseHover === "Rejected" &&
      qcData?.Rejected?.length !== total?.Rejected
    ) {
      setRejectedPage(rejectedPage + 1);
    }
  };

  const handleImageChange = (event) => {
    const image = event?.target?.files[0];
    formik.setFieldValue("image", image);
    const file = image;
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qcPendingStatus, qcAssignedStatus, qcCompletedStatus]);

  return {
    isLoading,
    qcData,
    showForm,
    showQcModal,
    formik,
    showValues,
    itemID,
    formData,
    totalLength: {
      Pending: total?.Pending,
      Assigned: total?.Assigned,
      Passed: total?.Passed,
      Rejected: total?.Rejected,
      Marked: total?.Marked
    },
    statusData,
    activeCard,
    imagePreview,
    reportRef,
    handleImageChange,
    handleLoadMore,
    setIsLoading,
    getFieldError,
    handleCardClick,
    handleSearch,
    handleConfirm,
  };
};

export default useQC;
