import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useOrder from "./useOrder";
import { FaSort } from "react-icons/fa";
import AddOrder from "./AddOrder";
// import OrderCard from "../../Global/OrderCard";
// import OrderCardListing from "../../Global/OrderCardListing";
import OrderColumn from "../../Global/OrderColumn";
import StockReceipt from "./StockInvoice/StockReceipt";

const Order = () => {
  const {
    isLoading = false,
    ordersList,
    tableFields,
    isFetching,
    orderState,
    paginationOptions,
    showEditModal,
    stockPrintRef,
    handleSearch,
    handleClearClick,
    handleEditClick,
    handlePagination,
    handlePageSize,
    refetch,
    handleSort,
    handleCloseAddOrderModal,
    handleEditColumnsClick,
    handleAddOrderClick,
    handleDelete,
    handleDeleteModal,
    updateOrderFields,
    getRow,
    handlePrintClick,
  } = useOrder();

  return (
    <>
      <HeadingGroup
        title={"Orders"}
        className={`pro-mb-4`}
        buttonTitle={"Add new"}
        handleClick={handleAddOrderClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          showActions={true}
          handleActionClick={handleEditColumnsClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: orderState?.search }}
        />
        <Table
          multiSelect={false}
          data={ordersList?.data ?? []}
          uniqueID={"_id"}
          fields={tableFields}
          SortIcon={<FaSort />}
          clear={orderState.clearSelection}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={orderState?.currentPageSize}
          assignable={true}
          handleEdit={handleEditClick}
          handleDelete={handleDeleteModal}
          deletable={true}
          showCheckBox={true}
          assignText={"Print"}
          assignIcon={<span class="material-symbols-outlined">print</span>}
          handleAssign={handlePrintClick}
        />

        {/* <OrderCardListing /> */}

        {ordersList?.data?.length > 0 && (
          <Pagination
            currentPage={orderState?.currentPage}
            totalPageCount={Math.ceil(
              ordersList?.total_count / orderState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout
          show={orderState?.showDeleteModal}
          handleClose={handleDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure delete the selected item ?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteModal}
              submitText={"Yes"}
              submitAction={handleDelete}
              isRight={true}
            />
          </div>
        </ModalLayout>
        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={ordersList?.fields}
              moduleId={ordersList?.module_id}
              updateData={updateOrderFields}
            />
          </div>
        </ModalLayout>
        <div
          style={{
            position: "fixed",
            opacity: "0",
            visibility: "hidden",
            height: "0",
            width: "0",
          }}
        >
          <StockReceipt stockPrintRef={stockPrintRef} />
        </div>
        <OffCanvasLayout
          show={orderState?.showAddOrderModal}
          handleClose={handleCloseAddOrderModal}
          title={"Add Order"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
        >
          <AddOrder selectedUser={""} refetch={refetch} />
        </OffCanvasLayout>
      </div>
    </>
  );
};
export default Order;
