import { AvatarGroup } from "@wac-ui-dashboard/wac_component_library";
import Style from "./orderCard.module.scss";

const Ordercard = ({
  extraClassName,
  ref,
  title,
  subTitle,
  avatars,
  curretNumber,
  tottalNumber,
  badgeText,
  onClick,
  completed,
  activeCard,
  title2,
  id,
}) => {
  // add when active card issue fixes      ${extraClassName ? extraClassName : ""}
  // ${activeCard === id ? Style.active : ""}
  return (
    <div
      // ref={lastElement || null}
      ref={ref}
      onClick={onClick}
      className={`${Style.root} 
       pro-p-4 pro-ps-3 ${extraClassName ? extraClassName : ""} ${activeCard?.id === id ? Style.active : ""}`}
    >
      {title && (
        <h6 className={`pro-ttl pro-font-regular pro-mb-3 ${Style.title}`}>
          {title}
        </h6>
      )}
      {title2 && (
        <p className={` pro-font-regular pro-mb-3 ${Style.title}`}>{title2}</p>
      )}

      {subTitle && <p className="pro-mb-0">{subTitle}</p>}
      {(avatars ||
        badgeText ||
        (curretNumber && tottalNumber) ||
        completed) && (
        <div className={`pro-d-flex pro-justify-between ${Style.card_footer}`}>
          {avatars ||
            (badgeText && (
              <div className="pro-d-flex pro-flex-column">
                {avatars && <AvatarGroup avatars={avatars} />}
                {badgeText && (
                  <span
                    className={`${
                      badgeText === "Urgent"
                        ? Style.order_badge_urgent
                        : badgeText === "Normal"
                        ? Style.order_badge_normal
                        : Style.order_badge_hold
                    } pro-px-2 pro-d-flex`}
                  >
                    {/* <span>icon</span> */}
                    <span>{badgeText}</span>
                  </span>
                )}
              </div>
            ))}

          {((curretNumber && tottalNumber) || completed) && (
            <div className="pro-d-flex pro-ms-auto">
              {curretNumber && tottalNumber && (
                <span className={`${Style.number} pro-font-xs`}>
                  {curretNumber}/{tottalNumber}
                </span>
              )}

              {completed && (
                <span
                  className={`${Style.tick} pro-d-flex pro-justify-center pro-rounded-circle`}
                >
                  <span className="material-symbols-outlined pro-align-self-center">
                    check
                  </span>
                </span>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Ordercard;
