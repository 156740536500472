import { getAxiosInstance } from "../../../api";

export const addToRecheckList = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`/recheck/item`, params);
    return res;
  } catch (error) {
    return error.response.data;
  }
};
