import { getAxiosInstance } from "../../../api";

export const getBillingData = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/billing/edit?billing_id=${id}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteBilling = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.delete(`/billing/delete`, {
      data: body,
    });
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const addBillingData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/billing/create`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const editBillingData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/billing/update`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
