import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  qcPendingStatus: "idle",
  qcAssignedStatus: "idle",
  qcCompletedStatus: "idle",
  qcMarkedStatus:'idle',
  qcRejectedStatus:'idle',
  qcData: {
    Assigned: [],
    Pending: [],
    Passed: [],
    Marked:[],
    Rejected:[]
  },
  qcDataRecheck: {
    Assigned: [],
    Pending: [],
    Passed: [],
  },
  total: {
    Passed: "",
    Assigned: "",
    Pending: "",
    Marked:"",
    Rejected:"",
  },
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 20,
  sortBy: "",
  sortOrder: "",
  search: "",
  showQcModal: false,
  showForm: false,
  formData: [],
  excelFormData: [],
  excelEditedFormData: [],
  excelData: [],
  certificateID: "",
  assignedID: "",
  showValues: false,
  itemID: "",
  showCompare: false,
  data: [{ name: "Version 1" }],
  activeId: 1,
  item: "",
  printData: [],
  dropdownValues: {},
};
export const getQcAssignedList = createAsyncThunk(
  "qc/assigned-list",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/order/assigned-to-me`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getQcCompletedList = createAsyncThunk(
  "qc/completed-list",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/order/work-completed`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getQcPendingList = createAsyncThunk(
  "qc/pending-list",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/order/work-pending`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getQcMarkedList = createAsyncThunk(
  "qc/marked-list",
  async(params,{rejectWithValue})=>{
    const api = await getAxiosInstance();
    try{
      const response = await api.post(
        `/employee-dashboard/order/rejected_or_marked`,
        params
      );
      return response;
    }catch(error){
      return rejectWithValue(error.response.data);
    }
  }
)

export const getQcRejectedList = createAsyncThunk(
  "qc/rejected-list",
  async(params,{rejectWithValue})=>{
    const api = await getAxiosInstance();
    try{
      const response = await api.post(
        `/employee-dashboard/order/rejected_or_marked`,
        params
      );
      return response;
    }catch(error){
      return rejectWithValue(error.response.data);
    }
  }
)

//recheck section
export const getQcAssignedRecheckList = createAsyncThunk(
  "qc/recheck/assigned-list",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/recheck/assigned-to-me`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getQcCompletedRecheckList = createAsyncThunk(
  "qc/recheck/completed-list",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/recheck/work-completed`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getQcPendingRecheckList = createAsyncThunk(
  "/employee-dashboard/recheck/work-pending",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/recheck/work-pending`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const QCSlice = createSlice({
  name: "qc",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    // completed list
    [getQcCompletedList.pending]: (state) => {
      state.qcCompletedStatus = "pending";
    },
    [getQcCompletedList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedCompleted = [];
      // Add existing items with unique _id values to uniqueItems
      state.qcData.Passed.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedCompleted.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedCompleted.push(item);
        }
      });
      state.qcData.Passed = combinedCompleted;
      state.total.Passed = action.payload.data.data.total_count;
      state.qcCompletedStatus = "fulfilled";
      // state.qcData.Completed = [
      //   ...state.qcData.Completed,
      //   ...action.payload.data.data.data,
      // ];
    },
    [getQcCompletedList.rejected]: (state) => {
      state.qcCompletedStatus = "failed";
    },
    // assigned list
    [getQcAssignedList.pending]: (state) => {
      state.qcAssignedStatus = "pending";
    },
    [getQcAssignedList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedAssigned = [];
      // Add existing items with unique _id values to uniqueItems
      state.qcData.Assigned.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedAssigned.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedAssigned.push(item);
        }
      });
      state.qcData.Assigned = combinedAssigned;
      state.total.Assigned = action.payload.data.data.total_count;
      state.qcAssignedStatus = "fulfilled";
      // state.qcData.Assigned = [
      //   ...state.qcData.Assigned,
      //   ...action.payload.data.data.data,
      // ];
    },
    [getQcAssignedList.rejected]: (state) => {
      state.qcAssignedStatus = "failed";
    },
    // pending list
    [getQcPendingList.pending]: (state) => {
      state.qcPendingStatus = "pending";
    },
    [getQcPendingList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedPending = [];
      // Add existing items with unique _id values to uniqueItems
      state.qcData.Pending.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedPending.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedPending.push(item);
        }
      });
      state.qcData.Pending = combinedPending;
      state.total.Pending = action.payload.data.data.total_count;
      state.qcPendingStatus = "fulfilled";
      // state.qcData.Pending = [
      //   ...state.qcData.Pending,
      //   ...action.payload.data.data.data,
      // ];
    },
    [getQcPendingList.rejected]: (state) => {
      state.qcPendingStatus = "failed";
    },

    //marked list
    [getQcMarkedList.pending]: (state) => {
      state.qcMarkedStatus = "pending";
    },
    [getQcMarkedList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedMarked = [];

      // Add existing items with unique _id values to uniqueItems
      state.qcData.Marked.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedMarked.push(item);
      });

      // Add new items with unique _id values to uniqueItems and combinedMarked
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedMarked.push(item);
        }
      });
      state.qcData.Marked = combinedMarked;
      state.total.Marked = action.payload.data.data.total_count;
      state.qcMarkedStatus = "fulfilled";
      
    },
    [getQcMarkedList.rejected]: (state) => {
      state.qcMarkedStatus = "failed";
    },

    //rejected section 
    [getQcRejectedList.pending]: (state) => {
      state.qcRejectedStatus = "pending";
    },
    [getQcRejectedList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedRejected = [];

      // Add existing items with unique _id values to uniqueItems
      state.qcData.Rejected.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedRejected.push(item);
      });

      // Add new items with unique _id values to uniqueItems and combinedRejected
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedRejected.push(item);
        }
      });
      state.qcData.Rejected = combinedRejected;
      state.total.Rejected = action.payload.data.data.total_count;
      state.qcRejectedStatus = "fulfilled";
      
    },
    [getQcRejectedList.rejected]: (state) => {
      state.qcRejectedStatus = "failed";
    },

    //recheck section
    [getQcCompletedRecheckList.pending]: (state) => {
      state.qcCompletedStatus = "pending";
    },
    [getQcCompletedRecheckList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedCompleted = [];
      // Add existing items with unique _id values to uniqueItems
      state.qcDataRecheck.Passed.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedCompleted.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedCompleted.push(item);
        }
      });
      state.qcDataRecheck.Passed = combinedCompleted;
      state.total.Passed = action.payload.data.data.total_count;
      state.qcCompletedStatus = "fulfilled";
    },
    [getQcCompletedRecheckList.rejected]: (state) => {
      state.qcCompletedStatus = "failed";
    },
    // assigned list
    [getQcAssignedRecheckList.pending]: (state) => {
      state.qcAssignedStatus = "pending";
    },
    [getQcAssignedRecheckList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedAssigned = [];
      // Add existing items with unique _id values to uniqueItems
      state.qcDataRecheck.Assigned.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedAssigned.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedAssigned.push(item);
        }
      });
      state.qcDataRecheck.Assigned = combinedAssigned;
      state.total.Assigned = action.payload.data.data.total_count;
      state.qcAssignedStatus = "fulfilled";
    },
    [getQcAssignedRecheckList.rejected]: (state) => {
      state.qcAssignedStatus = "failed";
    },
    // pending list
    [getQcPendingRecheckList.pending]: (state) => {
      state.qcPendingStatus = "pending";
    },
    [getQcPendingRecheckList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedPending = [];
      // Add existing items with unique _id values to uniqueItems
      state.qcDataRecheck.Pending.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedPending.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedPending.push(item);
        }
      });
      state.qcDataRecheck.Pending = combinedPending;
      state.total.Pending = action.payload.data.data.total_count;
      state.qcPendingStatus = "fulfilled";
    },
    [getQcPendingRecheckList.rejected]: (state) => {
      state.qcPendingStatus = "failed";
    },
  },
});

export const { updateConfig } = QCSlice.actions;

export default QCSlice.reducer;
