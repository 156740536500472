import Style from "./orderCardListing.module.scss";
import OrderCard from "../OrderCard";
import CardShimmer from "../CardShimmer";
import useGliCardListing from "./useGliCardListing";
import { Spinner } from "react-bootstrap";

const GliCardListing = ({
  data,
  title,
  scrollRef,
  isOverView = false,
  handleChange,
  activeCard,
  hasButton,
  extraClassName,
  refBottom = false,
  extaClassName,
  handleLoadMore,
  isLoading,
  setIsLoading,
  totalLength,
}) => {
  const { scrollRefs, mouseHover, handleMouseOver } = useGliCardListing({
    data: {
      overView: data,
    },
    handleLoadMore,
    setIsLoading,
    totalLength,
    scrollRef,
    isOverView,
  });
  return (
    <>
      {isOverView ? (
        <>
          {" "}
          <div
            className={`${Style.root_list} ${Style.dashboard_list} ${extraClassName}`}
          >
            {" "}
            <h6 className="pro-ttl pro-pb-3 pro-pt-4 pro-font-regular">
              {title}
            </h6>{" "}
            {/* <div className={`${Style.root_list_item}`}></div> */}{" "}
            <div className={`${Style.root_list_item}`}>
              {data?.map((item, index) => (
                <OrderCard
                  onClick={() => handleChange(item._id, item.gli_number)}
                  title={item.gli_number}
                  subTitle={`${item.shop}`}
                  curretNumber={item.total_pending}
                  tottalNumber={item.total}
                  badgeText={item.priority}
                  id={item._id}
                  key={index}
                  extraClassName={Style.dashboard_card}
                  activeCard={{ id: "", key: "" }}
                />
              ))}
              {mouseHover === "overView" &&
                isLoading &&
                data?.["overView"]?.length !== totalLength?.["overView"] && (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                )}
            </div>
            {/* <div className={`${Style.root_list_item}`}></div> */}{" "}
          </div>
        </>
      ) : (
        <>
          {Object.keys(data ? data : {})?.map((key, mainIndex) => (
            <>
              <div
                key={mainIndex}
                className={`${Style.root_list} ${extaClassName} ${
                  hasButton ? "pro-pb-0" : ""
                }`}
                ref={(ref) => !refBottom && (scrollRefs.current[key] = ref)}
                onMouseEnter={() => handleMouseOver(key)}
              >
                <h6 className="pro-ttl pro-mb-2 pro-font-regular">
                  {key ?? "Title"}
                </h6>
                {/* <div className={`${Style.root_list_item}`}></div> */}
                <div
                  className={`${Style.root_list_item} ${Style.scroll_list}`}
                  ref={(ref) => refBottom && (scrollRefs.current[key] = ref)}
                >
                  {data[key] &&
                    data[key]?.map((item, index) => (
                      <OrderCard
                        onClick={() => handleChange(item._id, item.gli_number)}
                        title={item.gli_number}
                        subTitle={`${item.shop}`}
                        curretNumber={item.total_pending}
                        tottalNumber={item.total}
                        badgeText={item.priority}
                        activeCard={activeCard?.key === key && activeCard}
                        id={item._id}
                        key={index}
                      />
                    ))}
                  {mouseHover === key &&
                    isLoading &&
                    data?.[key]?.length !== totalLength?.[key] &&
                    [...Array(2)]?.map((_, i) => <CardShimmer key={i} />)}
                </div>
                {/* <div className={`${Style.root_list_item}`}></div> */}
              </div>
            </>
          ))}
        </>
      )}
    </>
  );
};

export default GliCardListing;
