import { ModalLayout } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import usePhotoUpload from "./usePhotoUpload";
import Style from "./photoUpload.module.scss";
import PhotoCardListing from "../../Global/PhotoCardListing/index";
import { Button, Image } from "@wac-ui-dashboard/wac_component_library";
import PendingQueueDetails from "./PendingQueueDetail";
import GliCardListing from "../../Global/GliCardLIsting";
import { PhotoCard } from "./photoCard";
import RoughImage from "./RoughImage";
const PhotoUpload = () => {
  const {
    file,
    state,
    states,
    isChecked,
    showImageModal,
    imageData,
    checkedItems,
    isSelected,
    photoList,
    showDetail,
    searchKey,
    activeCard,
    photoUpload,
    showCompleted,
    photoUploadData,
    uploadedImageData,
    uploadStatus,
    totalLength,
    isLoading,
    selectedItems,
    roughPhotoUpload,
    handleRoughView,
    handleRoughImage,
    closeModal,
    SearchFile,
    handleCheck,
    handleSubmit,
    handleDownload,
    handleChange,
    handleSearch,
    setIsLoading,
    handleLoadMore,
    photoHandleChange,
    handleUploadPhoto,
    handlePhotoClick,
    handlePhotoDelete,
  } = usePhotoUpload();
  return (
    <>
      <div className="row pro-mb-4">
        <div className="col-auto"></div>
      </div>
      <div className={Style.varifaication_main_wrap}>
        <div className="row">
          <div className={`col-xxl-5 col-5 ${Style.card_main_wrapper}`}>
            <div className={`pro-d-flex ${Style.card_flow_wrap} pro-gap-3`}>
              {states?.length === 0 ? null : (
                <GliCardListing
                  title={"My Queue"}
                  data={states}
                  handleChange={handleChange}
                  isLoading={isLoading}
                  totalLength={totalLength}
                  setIsLoading={setIsLoading}
                  handleLoadMore={handleLoadMore}
                  activeCard={activeCard}
                />
              )}
              <PhotoCardListing
                data={photoUploadData}
                extaClassName={`pro-ms-0`}
                activeCard={activeCard}
                totalLength={totalLength}
                isLoading={isLoading}
                handleLoadMore={handleLoadMore}
                handleChange={photoHandleChange}
                setIsLoading={setIsLoading}
              />
            </div>
          </div>
          <ModalLayout show={showImageModal} handleClose={closeModal}>
            <PhotoCard
              data={imageData}
              handleDownload={handleDownload}
              handleClose={closeModal}
            />
          </ModalLayout>
          <ModalLayout
            show={state?.roughImageModal}
            handleClose={handleRoughView}
          >
            <PhotoCard
              data={roughPhotoUpload?.photo}
              handleDownload={handleDownload}
              handleClose={handleRoughView}
            />
          </ModalLayout>

          <div className={`col pro-pe-0 ${Style.table_main_wrapper}`}>
            <div className={`${Style.table_flow_wrapper} pro-pe-3`}>
              {showDetail === true ? (
                <>
                  <div className={`row gx-3 ${Style.multicolumn_wrapper}`}>
                    <div className="col">
                      <PendingQueueDetails
                        file={file}
                        photoList={photoList}
                        isChecked={isChecked}
                        searchKey={searchKey}
                        isSelected={isSelected}
                        image={photoList.image}
                        photoUpload={photoUpload}
                        selectedItems={selectedItems}
                        handleUploadPhoto={handleUploadPhoto}
                        handlePhotoClick={handlePhotoClick}
                        handleSearch={handleSearch}
                        handleCheck={handleCheck}
                        SearchFile={SearchFile}
                      />
                      <div>
                        <RoughImage
                          roughPhotoUpload={roughPhotoUpload}
                          handleRoughImage={handleRoughImage}
                          handleRoughView={handleRoughView}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={`col-12 pro-d-flex pro-justify-end pro-py-3 pro-pe-5 ${Style.footer_btn_wrap}`}
                  >
                    {isChecked && isSelected && (
                      <>
                        <Button
                          className={"pro-btn- lg pro-ms-3"}
                          type="button"
                          onClick={() =>
                            handlePhotoDelete(
                              checkedItems?.design_id,
                              checkedItems?.name
                            )
                          }
                        >
                          {uploadStatus === "Deleting" ? "..." : "Delete"}
                        </Button>
                      </>
                    )}

                    <Button
                      className={"pro-btn-primary lg pro-ms-3"}
                      type="button"
                      onClick={handleSubmit}
                    >
                      {uploadStatus === "pending" ? "..." : "Submit"}
                    </Button>
                  </div>
                </>
              ) : showCompleted === true ? (
                <div className={`${Style.photoTable_image} pro-mt-6 pro-mx-auto`}>
                  <Image
                    width={300}
                    height={300}
                    src={uploadedImageData.url}
                    alt={`image - 01`}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PhotoUpload;
