import React from "react";
import { useSelector } from "react-redux";
import useFontCases from "../../../../utils/hooks/useFontCases";

const ReportPrint = React.forwardRef(({ reportRef }) => {
  const { getFontCase } = useFontCases();
  const { printData } = useSelector((state) => state.qc);



  const { data } = printData; // Extract the "data" object from printData

  // Extract graded_values, center_stone, and side_stone from the "data" object
  const gradedValues = data?.graded_values;
  const centerStone = data?.center_stone;
  const sideStone = data?.side_stone;

  // Combine all values into a single array
  const fontCaseAllValues = [gradedValues, centerStone, sideStone]
    .filter(Boolean)
    .flat();

  const fontCases = fontCaseAllValues?.map((item) => item?.font_case);
  const fontCase = getFontCase(parseInt(fontCases));
  const renderText = (value) => {
    switch (fontCase) {
      case "Uppercase":
        return <span style={{ textTransform: "uppercase" }}>{value}</span>;
      case "Lowercase":
        return <span style={{ textTransform: "lowercase" }}>{value}</span>;
      case "Title_Case":
        return <span style={{ textTransform: "capitalize" }}>{value}</span>;
      case "Sentence_Case":
        const sentanceWords = value?.toLowerCase().split(" ");
        const sentenceCaseWords = sentanceWords.map(
          (word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)
        );
        return sentenceCaseWords.join(" ");
      case "Camel_Case":
        const camelWords = value?.toLowerCase().split(" ");
        const camelCaseWords = camelWords.map((word, index) =>
          index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
        );
        return camelCaseWords.join("");
      default:
        return <span>{value}</span>;
    }
  };
  return (
    <div ref={reportRef} style={{ width: "100%", height: "100%" }}>
      <title>DHC PDF</title>
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap"
        rel="stylesheet"
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
        id="element-to-print"
      >
        <table
          border={0}
          cellSpacing={0}
          role="presentation"
          cellPadding={0}
          style={{
            backgroundColor: "#ffffff",
            maxWidth: 595,
            borderCollapse: "collapse",
            minWidth: 595,
            width: 595,
            margin: "0 auto",
          }}
        >
          <tbody>
            <tr>
              <td
                style={{
                  maxWidth: "100%",
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 20,
                  height: "112px",
                }}
              >
                <img
                  src={printData?.front_top_image ?? ""}
                  alt="header"
                  style={{ width: 555, height: "112px" }}
                />
              </td>
            </tr>
            <tr>
              <td
                style={{
                  paddingTop: 8,
                  paddingBottom: 10,
                  fontWeight: 500,
                  fontSize: 13,
                  textAlign: "center",
                  color: "#000000",
                  maxHeight: "40px",
                  minHeight: "40px",
                  height: "40px",
                }}
              >
                DIAMOND GRADING REPORT
              </td>
            </tr>
            <tr>
              <td style={{ paddingLeft: 20, paddingRight: 20, height: 35 }}>
                <table
                  border={0}
                  cellSpacing={0}
                  role="presentation"
                  cellPadding={0}
                  style={{ backgroundColor: "#1e58b1", width: "100%" }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          fontSize: 10,
                          fontWeight: 400,
                          textAlign: "left",
                          paddingTop: 5,
                          paddingBottom: 5,
                          color: "#ffffff",
                          paddingLeft: 20,
                        }}
                      >
                        CERTIFICATE NO. : {printData?.certificate_number}
                      </td>
                      <td
                        style={{
                          fontSize: 9,
                          fontWeight: 500,
                          textAlign: "center",
                          paddingRight: 30,
                          marginLeft: "auto",
                          paddingTop: 5,
                          paddingBottom: 5,
                          maxWidth: 190,
                          width: 190,
                          color: "#ffffff",
                        }}
                      >
                        This Document Contains Valuable Data So Keep In A Secure
                        Area
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ paddingLeft: 30, verticalAlign: "top" }}>
                <table
                  border={0}
                  cellSpacing={0}
                  role="presentation"
                  cellPadding={0}
                  style={{}}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          width: 260,
                          minWidth: 260,
                          verticalAlign: "top",
                          paddingTop: "6px",
                        }}
                      >
                        <table
                          border={0}
                          cellSpacing={0}
                          role="presentation"
                          cellPadding={0}
                          style={{
                            width: 260,
                            minWidth: 260,
                          }}
                        >
                          <tbody>
                            <tr>
                              <td>
                                <table
                                  border={0}
                                  cellSpacing={0}
                                  role="presentation"
                                  cellPadding={0}
                                  style={{
                                    paddingTop: 15,
                                    width: 260,
                                    minWidth: 260,
                                  }}
                                >
                                  <tbody>
                                    {printData?.data?.graded_values &&
                                      printData?.data?.graded_values?.map(
                                        (grdvalue, index) => {
                                          const color =
                                            index % 2 === 0
                                              ? "#eeeeee"
                                              : "#ffffff";
                                          return (
                                            <tr key={index}>
                                              <td
                                                style={{
                                                  width: 131,
                                                  paddingTop: 4,
                                                  paddingBottom: 4,
                                                  paddingLeft: 6,
                                                  fontSize: 8,
                                                  fontWeight: 500,
                                                  textAlign: "left",
                                                  color: "#000000",
                                                  backgroundColor: color,
                                                }}
                                              >
                                                {grdvalue?.bold === 1 ? (
                                                  <b>
                                                    {renderText(grdvalue?.text)}
                                                  </b>
                                                ) : (
                                                  renderText(grdvalue?.text)
                                                )}
                                              </td>
                                              <td
                                                style={{
                                                  width: 131,
                                                  paddingTop: 4,
                                                  paddingBottom: 4,
                                                  fontSize: 8,
                                                  fontWeight: 400,
                                                  textAlign: "left",
                                                  color: "#000000",
                                                  backgroundColor: color,
                                                }}
                                              >
                                                {grdvalue?.bold === 1 ? (
                                                  <b>
                                                    {renderText(
                                                      grdvalue?.value
                                                    )}
                                                  </b>
                                                ) : (
                                                  renderText(grdvalue?.value)
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                  </tbody>
                                </table>
                              </td>
                            </tr>

                            {/* center stone table start  */}
                            {printData?.data?.center_stone && (
                              <tr>
                                <td
                                  style={{
                                    paddingTop: 20,
                                  }}
                                >
                                  <td>
                                    <table
                                      border={0}
                                      cellSpacing={0}
                                      role="presentation"
                                      cellPadding={0}
                                      style={{
                                        paddingTop: 15,
                                        width: 260,
                                        minWidth: 260,
                                      }}
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{
                                              paddingRight: 5,
                                            }}
                                          >
                                            <table
                                              border={0}
                                              cellSpacing={0}
                                              role="presentation"
                                              cellPadding={0}
                                              style={{
                                                width: "100%",
                                              }}
                                            >
                                              <tbody>
                                                <small
                                                  style={{
                                                    textAlign: "center",
                                                    fontSize: 8,
                                                    fontWeight: 500,
                                                  }}
                                                >
                                                  CENTER STONE
                                                </small>

                                                {printData?.data
                                                  ?.center_stone &&
                                                  printData?.data?.center_stone?.map(
                                                    (val, index) => {
                                                      const color =
                                                        index % 2 === 0
                                                          ? "#eeeeee"
                                                          : "#ffffff";
                                                      return (
                                                        <tr key={index}>
                                                          <td
                                                            style={{
                                                              paddingTop: 4,
                                                              paddingBottom: 4,
                                                              paddingLeft: 6,
                                                              fontSize: 8,
                                                              fontWeight: 500,
                                                              textAlign: "left",
                                                              color: "#000000",
                                                              backgroundColor:
                                                                color,
                                                            }}
                                                          >
                                                            {val?.bold === 1 ? (
                                                              <b>
                                                                {renderText(
                                                                  val?.text
                                                                )}
                                                              </b>
                                                            ) : (
                                                              renderText(
                                                                val?.text
                                                              )
                                                            )}
                                                          </td>
                                                          <td
                                                            style={{
                                                              paddingTop: 4,
                                                              paddingBottom: 4,
                                                              fontSize: 8,
                                                              fontWeight: 400,
                                                              textAlign: "left",
                                                              color: "#000000",
                                                              backgroundColor:
                                                                color,
                                                            }}
                                                          >
                                                            {val?.bold === 1 ? (
                                                              <b>
                                                                {renderText(
                                                                  val?.value
                                                                )}
                                                              </b>
                                                            ) : (
                                                              renderText(
                                                                val?.value
                                                              )
                                                            )}
                                                          </td>
                                                        </tr>
                                                      );
                                                    }
                                                  )}
                                              </tbody>
                                            </table>
                                          </td>

                                          <td
                                            style={{
                                              paddingLeft: 5,
                                            }}
                                          >
                                            <table
                                              border={0}
                                              cellSpacing={0}
                                              role="presentation"
                                              cellPadding={0}
                                              style={{
                                                width: "100%",
                                              }}
                                            >
                                              <tbody>
                                                <small
                                                  style={{
                                                    textAlign: "center",
                                                    fontSize: 8,
                                                    fontWeight: 500,
                                                  }}
                                                >
                                                  SIDE STONE
                                                </small>
                                                {printData?.data?.side_stone &&
                                                  printData?.data?.side_stone?.map(
                                                    (grdvalue, index) => {
                                                      const color =
                                                        index % 2 === 0
                                                          ? "#eeeeee"
                                                          : "#ffffff";
                                                      return (
                                                        <tr key={index}>
                                                          <td
                                                            style={{
                                                              paddingTop: 4,
                                                              paddingBottom: 4,
                                                              paddingLeft: 6,
                                                              fontSize: 8,
                                                              fontWeight: 500,
                                                              textAlign: "left",
                                                              color: "#000000",
                                                              backgroundColor:
                                                                color,
                                                            }}
                                                          >
                                                            {grdvalue?.bold ===
                                                            1 ? (
                                                              <b>
                                                                {renderText(
                                                                  grdvalue?.text
                                                                )}
                                                              </b>
                                                            ) : (
                                                              renderText(
                                                                grdvalue?.text
                                                              )
                                                            )}
                                                          </td>
                                                          <td
                                                            style={{
                                                              paddingTop: 4,
                                                              paddingBottom: 4,
                                                              fontSize: 8,
                                                              fontWeight: 400,
                                                              textAlign: "left",
                                                              color: "#000000",
                                                              backgroundColor:
                                                                color,
                                                            }}
                                                          >
                                                            {grdvalue?.bold ===
                                                            1 ? (
                                                              <b>
                                                                {renderText(
                                                                  grdvalue?.value
                                                                )}
                                                              </b>
                                                            ) : (
                                                              renderText(
                                                                grdvalue?.value
                                                              )
                                                            )}
                                                          </td>
                                                        </tr>
                                                      );
                                                    }
                                                  )}
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </td>
                              </tr>
                            )}
                            {/* center stone table end  */}
                          </tbody>
                        </table>
                      </td>
                      <td style={{ paddingLeft: 60, paddingTop: 6 }}>
                        <img
                          src="https://newsletter.webc.in/DHC/PDF/Images/bhima_logo.png"
                          alt="bhima_logo"
                          style={{
                            width: 220,
                            height: "auto",
                            paddingBottom: 80,
                          }}
                        />
                        <img
                          src={printData?.image ?? ""}
                          alt="diamond"
                          style={{
                            width: 220,
                            height: 220,
                            paddingBottom: 71,
                            paddingLeft: 52,
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  paddingTop: 13,
                  paddingBottom: 20,
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                <img
                  src={printData?.front_foot_image ?? ""}
                  alt="footer"
                  style={{ width: 555, height: 280 }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{ pageBreakBefore: "always" }}></div>
      </div>
    </div>
  );
});

export default ReportPrint;
