import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
// import { submitForm } from "../api";
// import { toast } from "react-toastify";
// import { useDispatch } from "react-redux";
// import {
//   getCVDCompletedRecheckList,
//   getCVDPendingRecheckList,
//   updateConfig,
// } from "../../../../store/slices/CVD/CVDSlice";
// import { useSelector } from "react-redux";

const useForm1 = ({ handleConfirmModal, setFormValues }) => {
  // const dispatch = useDispatch();
  const [imagePreview, setImagePreview] = useState("");

  // const { certificateID, assignedID } = useSelector((state) => state.cvd);

  const validation = Yup.object({
    rft_no: Yup.string()
      .required("This field is required")
      .matches(/^[0-9]*$/, "Enter valid number"),
    percentage: Yup.string().required("This field is required"),
    // removed image validation after internal review
    // image: Yup.mixed()
    //   .required("Add one image")
    //   .test("fileType", "Only JPEG and PNG formats are allowed", (value) => {
    //     if (!value) return true;
    //     const acceptedFormats = ["image/jpeg", "image/png"];
    //     return value && acceptedFormats.includes(value.type);
    //   })
    //   .test("fileSize", "File size must be less than 2 MB", (value) => {
    //     if (!value) return true;
    //     const maxSizeInBytes = 2 * 1024 * 1024;
    //     return value && value.size <= maxSizeInBytes;
    //   }),
  });

  const initialValues = {
    rft_no: "",
    percentage: "",
    image: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: (values) => {
      setFormValues(values);
      handleConfirmModal();
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleImageChange = (event) => {
    const image = event?.target?.files[0];
    formik.setFieldValue("image", image);
    const file = image;
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleReset = () => {
    formik?.resetForm();
    setImagePreview("");
  };

  return {
    formik,
    imagePreview,
    getFieldError,
    handleImageChange,
    handleReset,
  };
};

export default useForm1;
