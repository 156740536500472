import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import VerificationFilterForm from "./VerificationFilterForm";
import { useGetFilterListDataQuery } from "../../../../store/queries/Verification";
import {
  getApprovedList,
  getPendingList,
  resetExtraFilters,
  setStoreFormValues,
  updateConfig,
} from "../../../../store/slices/Verification/verificationSlice";

const useFilterForm = (setShowform) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.verification);
  const { data: FilterData = {} } = useGetFilterListDataQuery({});

  const validationSchema = Yup.object({
    from_date: Yup.date().nullable(),
    to_date: Yup.date().nullable(),
    item_type: Yup.array().nullable(),
    shop: Yup.array().nullable(),
    status: Yup.array().nullable(),
  });

  // Set the initial values based on whether edit is true or false
  const initialValues = {
    from_date: state.from_date,
    to_date: state.to_date,
    item_type: state.item_type,
    shop: state.shop,
    status: state.status,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const { from_date, to_date, item_type, shop, status } = values;
      if (
        from_date === "" &&
        to_date === "" &&
        item_type === "" &&
        shop === "" &&
        status === ""
      ) {
        toast.error("All fields are empty!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        setShowform(false);
      }
      let date = [];
      if (values?.from_date && values?.to_date) {
        date.push(...[values?.from_date, values?.to_date]);
      }
      let data = {
        date,
        item_type,
        shop,
        status,
      };
      if (values) {
        dispatch(
          updateConfig((state) => {
            state.verificationData.Pending = [];
            state.verificationData.Approved = [];
          })
        );
        dispatch(setStoreFormValues(values));
        dispatch(getPendingList(data));
        dispatch(getApprovedList(data));
      }
    },
  });

  const renderTabContent = () => {
    return (
      <VerificationFilterForm
        FilterData={FilterData}
        formik={formik}
        handleReset={handleReset}
      />
    );
  };

  const handleReset = () => {
    dispatch(resetExtraFilters());
    dispatch(getPendingList());
    dispatch(getApprovedList());
    formik.resetForm();
    setShowform(false);
  };

  return {
    renderTabContent,
  };
};

export default useFilterForm;
