import React from "react";
import useCompareTab from "./useCompareTab";
import { TabHeading } from "../../../Global/TabHeading";
import VersionOne from "./VersionOne";
import VersionTwo from "./VersionTwo";
import { Button } from "@wac-ui-dashboard/wac_component_library";
import Style from './compareTab.module.scss';
const CompareTab = () => {
  const { data, activeId, handleClick, handleAdd, handleCompare } =
    useCompareTab();
  return (
    <div className={`pro-w-100 ${Style.compare_wrap}`}>
        <TabHeading
          data={data}
          uniqueId={"id"}
          activeId={activeId}
          handleClick={handleClick}
        />
      <div className={`pro-ms-4 pro-d-flex pro-flex-column ${Style.tabs_wrap}`}>
        {activeId === 1 ? <VersionOne /> : <VersionTwo />}
      </div>
        {data?.length === 1 && (
        <div
        className={`col-12 pro-d-flex pro-justify-end pro-py-3 pro-gap-3 ${Style.footer_btn_wrap} `}
        >
            <>
              <Button
                className={"pro-btn pro-btn-outline lg pro-px-5"}
                onClick={handleCompare}
              >
                Compare
              </Button>
              <Button
                className={"pro-btn pro-btn-outline lg"}
                type="button"
                onClick={handleAdd}
              >
                Create a copy
              </Button>
            </>
        </div>
          )}
    </div>
  );
};

export default CompareTab;
