import React, { useState } from "react";
import { useGetApprovedListDataQuery } from "../../../../store/queries/Verification";
import { updateConfig as updateConfigGlobal } from "../../../../store/slices/Global";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Verification/approvedSlice";
import { getPrintBarCode, getVerificationEditBasicData } from "./api";
import { toast } from "react-toastify";
import html2pdf from "html2pdf.js";
import moment from "moment";

const useApprovedDetails = ({ barCodeRef }) => {
  const [basicData, setBasicData] = useState();

  const approvedState = useSelector((state) => state.approved);
  const { tableFields } = useSelector((state) => state.global);
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];
  const dispatch = useDispatch();
  const {
    data: ApprovedDetailsList = {},
    isFetching,
    refetch,
  } = useGetApprovedListDataQuery({
    receipt_id: approvedState.receipt_id,
    search: approvedState.search,
    page_size: approvedState.currentPageSize,
    page: approvedState.currentPage,
  });
  useEffect(() => {
    dispatch(
      updateConfigGlobal((state) => {
        state.tableFields = ApprovedDetailsList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [ApprovedDetailsList]);

  useEffect(() => {
    if (approvedState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [approvedState.clearSelection]);

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleClearClick = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      status: (field, data) =>
        data?.status === "Rejected" ? (
          <span className={"outline-danger pro-badge"}>{"Rejected"}</span>
        ) : data?.status === "Marked" ? (
          <span className={"outline-primary pro-badge"}>{"Marked"}</span>
        ) : data?.status === "Inprogress" ? (
          <span className={"outline-warning pro-badge"}>{"Inprogress"}</span>
        ) : (
          <span className={"outline pro-badge"}>{data.status}</span>
        ),
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };
  const handleApprovedData = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showAddOrderModal = !state.showAddOrderModal;
        state.selectedOrder = e?.[0];
        state.isEdit = !state.isEdit;
      })
    );
    if (e?.[0]) {
      getVerificationEditBasicData(e?.[0]).then((res) => {
        setBasicData(res?.data?.data);
      });
    }
  };

  const handlePrintBarCode = () => {
    const stockId = approvedState?.receipt_id;
    getPrintBarCode(stockId).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => (state.printData = response?.data?.data))
        );
      } else {
        toast.error("Something went wrong");
      }
      const printSlip = () => {
        const element = barCodeRef?.current;
        html2pdf(element, {
          margin: [11, 0],
          filename: `${
            "Barcode" + "-" + moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
          }.pdf`,
          image: { type: "jpeg", quality: 1 },
          html2canvas: {
            dpi: 100,
            letterRendering: true,
            useCORS: true,
            logging: true,
            scale: 4,
            scrollY: 0,
          },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
          DisablePdfCompression: true,
        });
      };
      setTimeout(() => {
        printSlip();
      }, 500);
    });
  };

  return {
    basicData,
    isFetching,
    tableFields,
    approvedState,
    paginationOptions,
    ApprovedDetailsList,
    handlePrintBarCode,
    refetch,
    getRow,
    handleSearch,
    handleClearClick,
    handlePageSize,
    handlePagination,
    handleApprovedData,
  };
};

export default useApprovedDetails;
