import { getAxiosInstance } from "../../../api";

export const uploadPhoto = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/back-office-image/recheck/upload`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
