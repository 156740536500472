import React from "react";
import Style from "./addReport.module.scss";
import { useAddReport } from "./useAddReport";
import Select from "react-select";
import ItemField from "./ItemField";
import ItemFieldCS from "./ItemFieldCS";
import ItemFieldSS from "./ItemFieldSS";

import {
  Button,
  IconText,
  Image,
} from "@wac-ui-dashboard/wac_component_library";

export const AddReport = (refetch, reportState) => {
  let temp = [];
  const {
    tempFilteredData,
    formik,
    isEdit,
    isQc,
    basicData,
    backImage,
    reportData,
    itemTypeData,
    supplierData,
    shopLogo,
    frontTopImage,
    frontFootImage,
    certificateData,
    handleAddFieldCenter,
    handleAddFieldSide,
    handleItemTypeChange,
    getFieldError,
    handleAddField,
    handleCloseModal,
    handleBackImageChange,
    handleFrontTopImageChange,
    handleFrontFootImageChange,
    handleShopLogoChange
  } = useAddReport(refetch, reportState);


  return (
    <div className="row">
      <div className="input-wrap pro-mb-4">
        <div className="pro-check-box pro-py-2">
          <input
            type="checkbox"
            className="pro-check"
            id="defult"
            name="pro-checkbox"
            checked={Boolean(formik.values?.format_type === 1) ?? false}
            onChange={(e) => {
              formik.setFieldValue(`format_type`, e?.target?.checked ? 1 : 2);
            }}
          />
          <label htmlFor="defult" className="pro-check-label">
            Default
          </label>
        </div>
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Shop Name
        </label>
        <Select
          id="supplier_shop_previd"
          name="supplier_shop_id"
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("supplier_shop_id") && " error"
          }`}
          classNamePrefix="pro-input"
          options={basicData?.data?.shop}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?._id}
          value={supplierData}
          onBlur={formik.handleBlur("supplier_shop_id")}
          onChange={(value) => {
            formik?.setFieldValue("supplier_shop_id", value?._id || null);
          }}
          menuPlacement="auto"
        />

        {getFieldError("supplier_shop_id") && (
          <span className="error-text">
            {getFieldError("supplier_shop_id")}
          </span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Certificate Type
        </label>
        <Select
          id="certificate_type"
          name="certificate_type"
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("certificate_type") && " error"
          }`}
          classNamePrefix="pro-input"
          options={basicData?.data?.certificate_type}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          value={certificateData}
          onBlur={formik.handleBlur("certificate_type")}
          onChange={(value) => {
            formik?.setFieldValue("certificate_type", value?.id || null);
          }}
          menuPlacement="auto"
        />
        {getFieldError("certificate_type") && (
          <span className="error-text">
            {getFieldError("certificate_type")}
          </span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Item Type
        </label>
        <Select
          id="item_type_id"
          name="item_type_id"
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("item_type_id") && " error"
          }`}
          classNamePrefix="pro-input"
          options={basicData?.data?.item_type}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?._id}
          value={itemTypeData}
          onBlur={formik.handleBlur("item_type_id")}
          onChange={(value) => {
            formik?.setFieldValue("item_type_id", value?._id);
            handleItemTypeChange(value);
          }}
          menuPlacement="auto"
        />
        {getFieldError("item_type_id") && (
          <span className="error-text">{getFieldError("item_type_id")}</span>
        )}
      </div>

      <div className={Style.address}>
        {isQc && (
          <>
            {formik?.values?.item_fields_CS?.map((fields, index) => {
              temp = [...temp, formik?.values?.item_fields_CS[index]?.field_id];
              return (
                <ItemFieldCS
                  key={index}
                  title={"Center Stone"}
                  fieldName={"Center Stone field"}
                  itmIndex={index}
                  fields={fields}
                  basicData={basicData}
                  formik={formik}
                  getFieldError={getFieldError}
                  tempIds={temp}
                  itemTypeData={itemTypeData}
                  isEdit={isEdit}
                />
              );
            })}
            {formik?.values?.item_fields_CS?.length !==
              tempFilteredData?.[0]?.qc_fields?.length && (
              <Button
                className={`${Style.add_btn} pro-btn-primary lg pro-w-100 pro-mt-4`}
                onClick={handleAddFieldCenter}
                disabled={!tempFilteredData?.[0]?.qc_fields?.length}
              >
                {` Add Another Center Stone`}
              </Button>
            )}
            {formik?.values?.item_fields_SS?.map((fields, index) => {
              temp = [...temp, formik?.values?.item_fields_SS[index]?.field_id];
              return (
                <ItemFieldSS
                  key={index}
                  title={"Side Stone"}
                  fieldName={"Side Stone field"}
                  itmIndex={index}
                  fields={fields}
                  basicData={basicData}
                  formik={formik}
                  getFieldError={getFieldError}
                  tempIds={temp}
                  itemTypeData={itemTypeData}
                  isEdit={isEdit}
                />
              );
            })}
            {formik?.values?.item_fields_SS?.length !==
              tempFilteredData?.[0]?.qc_field?.length && (
              <Button
                className={`${Style.add_btn} pro-btn-primary lg pro-w-100 pro-mt-4`}
                onClick={handleAddFieldSide}
                disabled={!tempFilteredData?.[0]?.qc_fields?.length}
              >
                {` Add Another Side Stone`}
              </Button>
            )}
          </>
        )}

        {formik?.values?.item_fields?.map((fields, index) => {
          temp = [...temp, formik?.values?.item_fields[index]?.field_id];
          return (
            <ItemField
              key={index}
              title={"QC"}
              fieldName={"QC field"}
              itmIndex={index}
              fields={fields}
              basicData={basicData}
              formik={formik}
              getFieldError={getFieldError}
              tempIds={temp}
              itemTypeData={itemTypeData}
              isEdit={isEdit}
            />
          );
        })}
        {formik?.values?.item_fields?.length !==
          tempFilteredData?.[0]?.qc_fields?.length && (
          <Button
            className={`${Style.add_btn} pro-btn-primary lg pro-w-100 pro-mt-4`}
            onClick={handleAddField}
            disabled={!tempFilteredData?.[0]?.qc_fields?.length}
          >
            {` Add Another Qc`}
          </Button>
        )}
      </div>
      { (formik?.values?.certificate_type ===3) && (
        <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Shop Logo
        </label>
        <div className="input-drag">
          <input
            type="file"
            multiple
            accept="image/png, image/jpeg"
            placeholder="placeholder"
            // ref={documentRef}
            className={`pro-input lg ${
              getFieldError("shop_logo") && " error"
            }`}
            id="shop_logo"
            onBlur={formik.handleBlur("shop_logo")}
            onChange={(e) => handleShopLogoChange(e)}
          />

          <span className="input-drag-box" style={{ padding: "50px" }}>
            {!isEdit && formik?.values?.shop_logo === "" ? (
              <IconText title={`Drop files to attach or browse`} />
            ) : (
              formik?.values?.shop_logo?.name
            )}
            {isEdit &&
              (reportData?.shop_logo?.original_name ?? (
                <IconText title={`${"Drop files to attach or browses"}`} />
              ))}
          </span>

          {getFieldError("shop_logo") && (
            <span className="error-text">{getFieldError("shop_logo")}</span>
          )}
          <div className={`col-2 pro-my-2`}>
            <div className={`${""}`}>
              <Image
                width={100}
                height={100}
                src={shopLogo}
                alt={`image - 01`}
              />
            </div>
          </div>
        </div>
      </div>
      )}
      
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Front Top Image
        </label>
        <div className="input-drag">
          <input
            type="file"
            multiple
            accept="image/png, image/jpeg"
            placeholder="placeholder"
            // ref={documentRef}
            className={`pro-input lg ${
              getFieldError("front_top_image") && " error"
            }`}
            id="front_top_image"
            onBlur={formik.handleBlur("front_top_image")}
            onChange={(e) => handleFrontTopImageChange(e)}
          />

          <span className="input-drag-box" style={{ padding: "50px" }}>
            {!isEdit && formik?.values?.front_top_image === "" ? (
              <IconText title={`Drop files to attach or browse`} />
            ) : (
              formik?.values?.front_top_image?.name
            )}
            {isEdit &&
              (reportData?.front_top_image?.original_name ?? (
                <IconText title={`${"Drop files to attach or browses"}`} />
              ))}
          </span>

          {getFieldError("front_top_image") && (
            <span className="error-text">
              {getFieldError("front_top_image")}
            </span>
          )}
          <div className={`col-2 pro-my-2`}>
            <div className={`${""}`}>
              <Image
                width={100}
                height={100}
                src={frontTopImage}
                alt={`image - 01`}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Front Foot Image
        </label>
        <div className="input-drag">
          <input
            type="file"
            multiple
            accept="image/png, image/jpeg"
            placeholder="placeholder"
            // ref={documentRef}
            className={`pro-input lg ${
              getFieldError("front_foot_image") && " error"
            }`}
            id="front_foot_image"
            onBlur={formik.handleBlur("front_foot_image")}
            onChange={(e) => handleFrontFootImageChange(e)}
          />

          <span className="input-drag-box" style={{ padding: "50px" }}>
            {!isEdit && formik?.values?.front_foot_image === "" ? (
              <IconText title={`Drop files to attach or browse`} />
            ) : (
              formik?.values?.front_foot_image?.name
            )}
            {isEdit &&
              (reportData?.front_foot_image?.original_name ?? (
                <IconText title={`${"Drop files to attach or browses"}`} />
              ))}
          </span>

          {getFieldError("front_foot_image") && (
            <span className="error-text">
              {getFieldError("front_foot_image")}
            </span>
          )}
          <div className={`col-2 pro-my-2`}>
            <div className={`${""}`}>
              <Image
                width={100}
                height={100}
                src={frontFootImage}
                alt={`image - 01`}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Back Image
        </label>
        <div className="input-drag">
          <input
            type="file"
            multiple
            accept="image/png, image/jpeg"
            placeholder="placeholder"
            // ref={documentRef}
            className={`pro-input lg ${
              getFieldError("back_image") && " error"
            }`}
            id="back_image"
            onBlur={formik.handleBlur("back_image")}
            onChange={(e) => handleBackImageChange(e)}
          />

          <span className="input-drag-box" style={{ padding: "50px" }}>
            {!isEdit && formik?.values?.back_image === "" ? (
              <IconText title={`Drop files to attach or browse`} />
            ) : (
              formik?.values?.back_image?.name
            )}
            {isEdit &&
              (reportData?.back_image?.original_name ?? (
                <IconText title={`${"Drop files to attach or browses"}`} />
              ))}
          </span>

          {getFieldError("back_image") && (
            <span className="error-text">{getFieldError("back_image")}</span>
          )}
          <div className={`col-2 pro-my-2`}>
            <div className={`${""}`}>
              <Image
                width={100}
                height={100}
                src={backImage}
                alt={`image - 01`}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn pro-mt-5`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {isEdit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default AddReport;
