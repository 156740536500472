import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../store/slices/Master/Billing/billingSlice";
import { addBillingData, editBillingData } from "../api";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useGetBillingBasicDataQuery } from "../../../../store/queries/Master";

const useAddBilling = ({ refetch }) => {
  const dispatch = useDispatch();
  const queryState = useSelector((state) => state.billing);

  const { data: basicData = {}, isLoading } = useGetBillingBasicDataQuery();

  useEffect(() => {
    if (queryState?.itemData && queryState?.isEdit && !isLoading) {
      Object.keys(queryState?.itemData || {}).forEach((key) => {
        if (key === "type") {
          const type = basicData?.data?.type?.filter(
            // eslint-disable-next-line eqeqeq
            (value) => value?.id == queryState?.itemData?.[key]
          );
          formik?.setFieldValue("type", type?.[0]);
          if (type[0]?.id === 1) {
            dispatch(
              updateConfig(
                (state) => (state.serviceItems = basicData?.data?.service_type)
              )
            );
          } else if (type[0]?.id === 2) {
            dispatch(
              updateConfig(
                (state) =>
                  (state.serviceItems = state.serviceItems =
                    basicData?.data?.certificate_type)
              )
            );
          } else if (type[0]?.id === 3) {
            dispatch(
              updateConfig(
                (state) => (state.serviceItems = basicData?.data?.packing_type)
              )
            );
          } else {
            dispatch(updateConfig((state) => (state.serviceItems = [])));
          }
        } else if (key === "service_type") {
          const serviceType = basicData?.data?.service_type?.filter(
            // eslint-disable-next-line eqeqeq
            (value) => value?._id == queryState?.itemData?.[key]
          );
          formik?.setFieldValue("service_type", serviceType?.[0]);
        } else if (key === "certificate_type") {
          const certificateType = basicData?.data?.certificate_type?.filter(
            // eslint-disable-next-line eqeqeq
            (value) => value?._id == queryState?.itemData?.[key]
          );
          formik?.setFieldValue("service_type", certificateType?.[0]);
        } else if (key === "packing_type") {
          const packingType = basicData?.data?.packing_type?.filter(
            // eslint-disable-next-line eqeqeq
            (value) => value?._id == queryState?.itemData?.[key]
          );
          formik?.setFieldValue("service_type", packingType?.[0]);
        } else {
          formik.setFieldValue(key, queryState?.itemData?.[key]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryState?.itemData, isLoading]);

  const validation = Yup.object({
    item_type: Yup.object().required("Select one"),
    type: Yup.object().required("Select one"),
    service_type: Yup.object().required("Select one"),
    carat_slab: Yup.string().matches(/^[0-9]\d*(\.\d+)?$/, "Invalid number"),
    rate_per_piece: Yup.string().matches(
      /^[0-9]\d*(\.\d+)?$/,
      "Invalid number"
    ),
    rate_per_carat: Yup.string().matches(
      /^[0-9]\d*(\.\d+)?$/,
      "Invalid number"
    ),
    number_of_stones_slab: Yup.string().matches(
      /^[0-9]\d*(\.\d+)?$/,
      "Invalid number"
    ),
    rate_per_stones: Yup.string().matches(
      /^[0-9]\d*(\.\d+)?$/,
      "Invalid number"
    ),
  });

  const formik = useFormik({
    initialValues: {
      supplier: "",
      shop: "",
      item_type: "",
      type: "",
      service_type: "",
      rate_per_piece: "",
      carat_slab: "",
      rate_per_carat: "",
      number_of_stones_slab: "",
      rate_per_stones: "",
      format_type: "",
    },
    validationSchema: validation,

    onSubmit: (values) => {
      if (values?.shop === "" && values?.supplier === "") {
        toast.warning("Select Shop or Suppler");
      } else {
        let data = {
          supplier_id: values?.supplier?._id,
          shop_id: values?.shop?._id,
          item_type_id: values?.item_type?._id,
          type: values?.type?.id,
          type_id: values?.service_type?._id,
          carat_slab: values?.type?.id === 1 ? values?.carat_slab : "",
          rate_per_piece: values?.rate_per_piece,
          rate_per_carat: values?.type.id === 1 ? values?.rate_per_carat : "",
          number_of_stones_slab:
            values?.type?.id === 1 ? values?.number_of_stones_slab : "",
          rate_per_stones:
            values?.type?.id === 1 ? values?.rate_per_stones : "",
          format_type: values?.format_type ? 2 : 1,
        };
        if (queryState?.isEdit) {
          let editData = {
            billing_id: queryState?.selectedItem,
            supplier_id: values?.supplier?._id,
            shop_id: values?.shop?._id,
            item_type_id: values?.item_type?._id,
            type: values?.type?.id,
            type_id: values?.service_type?._id,
            carat_slab: values?.type?.id === 1 ? values?.carat_slab : "",
            rate_per_piece: values?.rate_per_piece,
            rate_per_carat: values?.type.id === 1 ? values?.rate_per_carat : "",
            number_of_stones_slab:
              values?.type?.id === 1 ? values?.number_of_stones_slab : "",
            rate_per_stones:
              values?.type?.id === 1 ? values?.rate_per_stones : "",
            format_type: values?.format_type ? 2 : 1,
          };
          editBillingData(editData).then((response) => {
            if (response?.data?.status_code === 200) {
              handleCloseModal();
              toast.success("Added successfully");
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );
              refetch();
            } else if (response?.status_code === 422) {
              if (response?.errors?.shop_id) {
                formik?.setFieldError(
                  "shop",
                  "Bill already exist for this shop"
                );
              }
            } else {
              toast.error("Something went wrong");
              handleBackendError(response);
            }
          });
        } else {
          addBillingData(data).then((response) => {
            if (response?.data?.status_code === 200) {
              handleCloseModal();
              toast.success("Added successfully");
              refetch();
            } else if (response?.status_code === 422) {
              if (response?.errors?.shop_id) {
                formik?.setFieldError(
                  "shop",
                  "Bill already exist for this shop"
                );
              }
            } else {
              toast.error("Something went wrong");
              handleBackendError(response);
            }
          });
        }
      }
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddModal = false;
      })
    );
  };

  const handleTypeChange = (value) => {
    formik?.setFieldValue("type", value || null);
    formik?.setFieldValue("service_type", "");
    if (value?.id === 1) {
      dispatch(
        updateConfig(
          (state) => (state.serviceItems = basicData?.data?.service_type)
        )
      );
    } else if (value.id === 2) {
      dispatch(
        updateConfig(
          (state) =>
            (state.serviceItems = state.serviceItems =
              basicData?.data?.certificate_type)
        )
      );
    } else if (value.id === 3) {
      dispatch(
        updateConfig(
          (state) => (state.serviceItems = basicData?.data?.packing_type)
        )
      );
    } else {
      dispatch(updateConfig((state) => (state.serviceItems = [])));
    }
  };
  const handleBackendError = (response) => {
    if (response?.status_code === 422) {
      let errors = response?.errors;
      let errorField = Object.keys(errors);
      errorField.forEach((field) => {
        formik?.setFieldError(field, errors[field]);
      });
    }
  };
  return {
    formik,
    queryState,
    basicData: basicData?.data,
    serviceItems: queryState?.serviceItems,
    getFieldError,
    handleCloseModal,
    handleTypeChange,
  };
};

export default useAddBilling;
