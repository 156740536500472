import React from "react";
import Style from "./CardShimmer.module.scss";
const CardShimmer = ({ key }) => {
  return (
    <div className={`${Style.root} pro-p-4 pro-rounded-3 pro-d-flex pro-flex-column pro-items-start`} key={key}>
      {" "}
      <div class={`shimmer pro-pb-3 pro-pt-1 pro-px-6 pro-mb-2`}></div>{" "}
      <div className={`shimmer pro-py-1 pro-mb-3 ${Style.content}`}></div>{" "}
      <div className="pro-mt-4 pro-py-2 pro-px-5 shimmer">
        
      </div>{" "}
    </div>
  );
};
export default CardShimmer;
