import React from "react";
import { useSelector } from "react-redux";
import useFontCases from "../../../../../utils/hooks/useFontCases";

const BigFront = ({ bigFrontPrintRef }) => {
  const { getFontCase } = useFontCases();
  const { printingData } = useSelector((state) => state.printing);

  const fontCaseAllValues = printingData
    ?.map((value) => {
      const gradedValues = value?.data?.graded_values;
      const centerStone = value?.data?.center_stone;
      const sideStone = value?.data?.side_stone;

      return [gradedValues, centerStone, sideStone].filter(Boolean); // Filter out undefined values
    })
    .flat();
  const fontCaseValue = fontCaseAllValues?.flatMap((sublist) =>
    sublist?.map((item) => item?.font_case)
  );
  const fontCase = getFontCase(parseInt(fontCaseValue));
  const renderText = (value) => {
    switch (fontCase) {
      case "Uppercase":
        return <span style={{ textTransform: "uppercase" }}>{value}</span>;
      case "Lowercase":
        return <span style={{ textTransform: "lowercase" }}>{value}</span>;
      case "Title_Case":
        return <span style={{ textTransform: "capitalize" }}>{value}</span>;
      case "Sentence_Case":
        const sentanceWords = value?.toLowerCase().split(" ");
        const sentenceCaseWords = sentanceWords.map(
          (word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)
        );
        return sentenceCaseWords.join(" ");
      case "Camel_Case":
        const camelWords = value?.toLowerCase().split(" ");
        const camelCaseWords = camelWords.map((word, index) =>
          index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
        );
        return camelCaseWords.join("");
      default:
        return <span>{value}</span>;
    }
  };

  return (
    <div ref={bigFrontPrintRef}>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>DHC Card</title>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap"
        rel="stylesheet"
      />
      <style
        dangerouslySetInnerHTML={{
          __html: "vertical-align: top",
        }}
      />
      {printingData?.map((value, index) => {
        const conclusionValues = value?.data?.graded_values?.filter(
          (obj) => obj.slug === "conclusion"
        );
        return (
          <div style={{ display: "flex" }} key={index} id="element-to-print">
            <table
              align="center"
              border={0}
              cellSpacing={0}
              role="presentation"
              cellPadding={0}
              width="100%"
              style={{
                maxWidth: 454,
                minWidth: 454,
                minHeight: 300,
                height: 300,
                maxHeight: 300,
                borderCollapse: "collapse",
                backgroundColor: "#ffffff",
                boxSizing: "border-box",
                margin: "0 auto",
                fontFamily: '"Poppins", sans-serif',
                width: 454,
              }}
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      padding: "16px 28px 8px 28px",
                      borderBottom: "1px solid #818181",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={value?.front_top_image ?? ""}
                      alt="header"
                      style={{
                        maxWidth: 396,
                        width: 396,
                        height: 58,
                        objectFit: "cover",
                      }}
                      width={396}
                      height={58}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      paddingLeft: 28,
                      paddingRight: 28,
                      paddingTop: 10,
                    }}
                  >
                    <table
                      border={0}
                      cellSpacing={0}
                      role="presentation"
                      cellPadding={0}
                      width="100%"
                      style={{
                        backgroundColor: "#ffffff",
                        maxWidth: 280,
                        borderCollapse: "collapse",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              fontSize: 10,
                              fontWeight: 500,
                              paddingBottom: 8,
                              verticalAlign: "top",
                              color: "#000000",
                              width: 100,
                            }}
                          >
                            Summary No
                          </td>
                          <td
                            style={{
                              paddingBottom: 8,
                              paddingRight: 8,
                              verticalAlign: "top",
                              fontSize: 10,
                              fontWeight: 500,
                            }}
                          >
                            :
                          </td>
                          <td
                            style={{
                              fontSize: 10,
                              fontWeight: 500,
                              paddingBottom: 8,
                              verticalAlign: "top",
                              color: "#000000",
                            }}
                          >
                            {value?.certificate_number}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: 10,
                              fontWeight: 500,
                              verticalAlign: "top",
                              color: "#000000",
                              width: 100,
                            }}
                          >
                            Description
                          </td>
                          <td
                            style={{
                              paddingRight: 8,
                              verticalAlign: "top",
                              fontSize: 10,
                              fontWeight: 500,
                            }}
                          >
                            :
                          </td>
                          <td
                            style={{
                              fontSize: 10,
                              fontWeight: 400,
                              verticalAlign: "top",
                              color: "#000000",
                            }}
                          >
                            One Necklace Set Studded <br /> With Colored &amp;
                            Colorless Stones
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      paddingLeft: 28,
                      paddingRight: 28,
                      fontSize: 10,
                    }}
                  >
                    <table
                      border={0}
                      cellSpacing={0}
                      role="presentation"
                      cellPadding={0}
                      style={{
                        backgroundColor: "#ffffff",
                        maxWidth: "100%",
                        borderCollapse: "collapse",
                        minWidth: "100%",
                        margin: "0 auto",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td style={{ fontSize: "10px" }}>
                            <table
                              border={0}
                              cellSpacing={0}
                              role="presentation"
                              cellPadding={0}
                              style={{
                                backgroundColor: "#ffffff",
                                maxWidth: "100%",
                                borderCollapse: "collapse",
                                minWidth: "100%",
                                margin: "0 auto",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    <table
                                      border={0}
                                      cellSpacing={0}
                                      role="presentation"
                                      cellPadding={0}
                                      style={{
                                        backgroundColor: "#ffffff",
                                        maxWidth: "100%",
                                        width: "100%",
                                        borderCollapse: "collapse",
                                        minWidth: "100%",
                                        margin: "0 auto",
                                      }}
                                    >
                                      <tbody>
                                        <tr>
                                          <td>
                                            <table
                                              border={0}
                                              cellSpacing={0}
                                              role="presentation"
                                              cellPadding={0}
                                              style={{
                                                backgroundColor: "#ffffff",
                                                maxWidth: "100%",
                                                borderCollapse: "collapse",
                                                minWidth: "100%",
                                                margin: "0 auto",
                                              }}
                                            >
                                              <tbody>
                                                {value?.data?.graded_values &&
                                                  value?.data?.graded_values?.map(
                                                    // eslint-disable-next-line array-callback-return
                                                    (grdvalue, index) => {
                                                      if (
                                                        grdvalue?.slug !==
                                                        "conclusion"
                                                      )
                                                        return (
                                                          <tr key={index}>
                                                            <td
                                                              style={{
                                                                width: 100,
                                                                verticalAlign:
                                                                  "top",
                                                                color:
                                                                  "#000000",
                                                                fontWeight: 500,
                                                                paddingBottom: 8,
                                                              }}
                                                            >
                                                              {grdvalue?.bold ===
                                                              1 ? (
                                                                <b>
                                                                  {renderText(
                                                                    grdvalue?.text
                                                                  )}
                                                                </b>
                                                              ) : (
                                                                renderText(
                                                                  grdvalue?.text
                                                                )
                                                              )}
                                                            </td>
                                                            <td
                                                              style={{
                                                                width: 100,
                                                                verticalAlign:
                                                                  "top",
                                                                color:
                                                                  "#000000",
                                                                fontWeight: 500,
                                                                paddingBottom: 8,
                                                              }}
                                                            >
                                                              :
                                                            </td>
                                                            <td
                                                              style={{
                                                                verticalAlign:
                                                                  "top",
                                                                color:
                                                                  "#000000",
                                                                paddingBottom: 8,
                                                              }}
                                                            >
                                                              {grdvalue?.bold ===
                                                              1 ? (
                                                                <b>
                                                                  {renderText(
                                                                    grdvalue?.value
                                                                  )}{" "}
                                                                </b>
                                                              ) : (
                                                                renderText(
                                                                  grdvalue?.value
                                                                )
                                                              )}
                                                            </td>
                                                          </tr>
                                                        );
                                                    }
                                                  )}
                                              </tbody>
                                            </table>
                                          </td>
                                          <td
                                            style={{
                                              maxWidth: 98,
                                              paddingBottom: 8,
                                              textAlign: "right",
                                              verticalAlign: "bottom",
                                            }}
                                          >
                                            <img
                                              src={value?.image ?? ""}
                                              alt="ornament"
                                              width={98}
                                              height={65}
                                              style={{
                                                width: 98,
                                                height: 65,
                                                objectFit: "cover",
                                              }}
                                            />
                                          </td>
                                        </tr>
                                        {/* center stone table start  */}
                                        {value?.data?.center_stone && (
                                          <tr>
                                            <td
                                            >
                                              <td>
                                                <table
                                                  border={0}
                                                  cellSpacing={0}
                                                  role="presentation"
                                                  cellPadding={0}
                                                  style={{
                                                    maxWidth: "100%",
                                                    borderCollapse: "collapse",
                                                    minWidth: "100%",
                                                    margin: "0 auto",
                                                  }}
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          paddingRight: 5,
                                                        }}
                                                      >
                                                        <table
                                                          border={0}
                                                          cellSpacing={0}
                                                          role="presentation"
                                                          cellPadding={0}
                                                          style={{
                                                            width: "100%",
                                                          }}
                                                        >
                                                          <thead>
                                                            <th
                                                              colSpan={2}
                                                              style={{
                                                                textAlign:
                                                                  "center",
                                                                fontSize: 10,
                                                                fontWeight: 500,
                                                              }}
                                                            >
                                                              CENTER STONE
                                                            </th>
                                                          </thead>
                                                          <tbody>
                                                            {value?.data
                                                              ?.center_stone &&
                                                              value?.data?.center_stone?.map(
                                                                (
                                                                  val,
                                                                  index
                                                                ) => {
                                                                  return (
                                                                    <tr
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      <td
                                                                        style={{
                                                                          width: 100,
                                                                          verticalAlign:
                                                                            "top",
                                                                          color:
                                                                            "#000000",
                                                                          fontWeight: 500,
                                                                          paddingBottom: 8,
                                                                        }}
                                                                      >
                                                                        {val?.bold ===
                                                                        1 ? (
                                                                          <b>
                                                                            {renderText(
                                                                              val?.text
                                                                            )}
                                                                          </b>
                                                                        ) : (
                                                                          renderText(
                                                                            val?.text
                                                                          )
                                                                        )}
                                                                      </td>
                                                                      <td
                                                                        style={{
                                                                          width: 100,
                                                                          verticalAlign:
                                                                            "top",
                                                                          color:
                                                                            "#000000",
                                                                          fontWeight: 500,
                                                                          paddingBottom: 8,
                                                                        }}
                                                                      >
                                                                        :
                                                                      </td>
                                                                      <td
                                                                        style={{
                                                                          width: 100,
                                                                          verticalAlign:
                                                                            "top",
                                                                          color:
                                                                            "#000000",
                                                                          fontWeight: 500,
                                                                          paddingBottom: 8,
                                                                        }}
                                                                      >
                                                                        {val?.bold ===
                                                                        1 ? (
                                                                          <b>
                                                                            {renderText(
                                                                              val?.value
                                                                            )}
                                                                          </b>
                                                                        ) : (
                                                                          renderText(
                                                                            val?.value
                                                                          )
                                                                        )}
                                                                      </td>
                                                                    </tr>
                                                                  );
                                                                }
                                                              )}
                                                          </tbody>
                                                        </table>
                                                      </td>

                                                      <td
                                                        style={{
                                                          paddingLeft: 5,
                                                        }}
                                                      >
                                                        <table
                                                          border={0}
                                                          cellSpacing={0}
                                                          role="presentation"
                                                          cellPadding={0}
                                                          style={{
                                                            width: "100%",
                                                          }}
                                                        >
                                                          <thead>
                                                            <th
                                                              colSpan={2}
                                                              style={{
                                                                textAlign:
                                                                  "center",
                                                                fontSize: 10,
                                                                fontWeight: 500,
                                                              }}
                                                            >
                                                              SIDE STONE
                                                            </th>
                                                          </thead>
                                                          <tbody>
                                                            {value?.data
                                                              ?.side_stone &&
                                                              value?.data?.side_stone?.map(
                                                                (
                                                                  grdvalue,
                                                                  index
                                                                ) => {
                                                                  return (
                                                                    <tr
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      <td
                                                                        style={{
                                                                          width: 100,
                                                                          verticalAlign:
                                                                            "top",
                                                                          color:
                                                                            "#000000",
                                                                          fontWeight: 500,
                                                                          paddingBottom: 8,
                                                                        }}
                                                                      >
                                                                        {grdvalue?.bold ===
                                                                        1 ? (
                                                                          <b>
                                                                            {renderText(
                                                                              grdvalue?.text
                                                                            )}
                                                                          </b>
                                                                        ) : (
                                                                          renderText(
                                                                            grdvalue?.text
                                                                          )
                                                                        )}
                                                                      </td>
                                                                      <td
                                                                        style={{
                                                                          width: 100,
                                                                          verticalAlign:
                                                                            "top",
                                                                          color:
                                                                            "#000000",
                                                                          fontWeight: 500,
                                                                          paddingBottom: 8,
                                                                        }}
                                                                      >
                                                                        :
                                                                      </td>
                                                                      <td
                                                                        style={{
                                                                          width: 100,
                                                                          verticalAlign:
                                                                            "top",
                                                                          color:
                                                                            "#000000",
                                                                          fontWeight: 500,
                                                                          paddingBottom: 8,
                                                                        }}
                                                                      >
                                                                        {grdvalue?.bold ===
                                                                        1 ? (
                                                                          <b>
                                                                            {renderText(
                                                                              grdvalue?.value
                                                                            )}
                                                                          </b>
                                                                        ) : (
                                                                          renderText(
                                                                            grdvalue?.value
                                                                          )
                                                                        )}
                                                                      </td>
                                                                    </tr>
                                                                  );
                                                                }
                                                              )}
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </td>
                                          </tr>
                                        )}
                                        {/* center stone table end  */}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                {conclusionValues?.length !== 0 && (
                                  <tr>
                                    <td>
                                      <table
                                        border={0}
                                        cellSpacing={0}
                                        role="presentation"
                                        cellPadding={0}
                                        style={{
                                          backgroundColor: "#ffffff",
                                          maxWidth: "100%",
                                          width: "100%",
                                          borderCollapse: "collapse",
                                          minWidth: "100%",
                                          margin: "0 auto",
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              style={{
                                                fontSize: 10,
                                                fontWeight: 500,
                                                verticalAlign: "top",
                                                color: "#000000",
                                                width: 100,
                                              }}
                                            >
                                              {conclusionValues?.[0]?.bold ===
                                              1 ? (
                                                <b>
                                                  {renderText(
                                                    conclusionValues?.[0]?.text
                                                  )}
                                                </b>
                                              ) : (
                                                renderText(
                                                  conclusionValues?.[0]?.text
                                                )
                                              )}
                                            </td>
                                            <td
                                              style={{
                                                paddingRight: 8,
                                                verticalAlign: "top",
                                                fontSize: 10,
                                                fontWeight: 500,
                                                width: 4,
                                              }}
                                            >
                                              :
                                            </td>
                                            <td
                                              style={{
                                                verticalAlign: "top",
                                                color: "#000000",
                                                paddingBottom: 8,
                                              }}
                                            >
                                              {conclusionValues?.[0]?.bold ===
                                              1 ? (
                                                <b>
                                                  {renderText(
                                                    conclusionValues?.[0]?.value
                                                  )}
                                                </b>
                                              ) : (
                                                renderText(
                                                  conclusionValues?.[0]?.value
                                                )
                                              )}
                                            </td>
                                            {/* <td
                                              style={{
                                                paddingRight: 260,
                                                verticalAlign: "top",
                                                fontSize: 10,
                                                fontWeight: 500,
                                              }}
                                            ></td> */}
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* <table
                      border={0}
                      cellSpacing={0}
                      role="presentation"
                      cellPadding={0}
                      style={{
                        backgroundColor: "#ffffff",
                        maxWidth: "100%",
                        borderCollapse: "collapse",
                        minWidth: "100%",
                        margin: "0 auto",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td>
                            <table
                              border={0}
                              cellSpacing={0}
                              role="presentation"
                              cellPadding={0}
                              width="100%"
                              style={{
                                maxWidth: 280,
                                borderCollapse: "collapse",
                                width: 280,
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td
                                    style={{
                                      width: 100,
                                      verticalAlign: "top",
                                      color: "#000000",
                                      fontWeight: 500,
                                      paddingBottom: 8,
                                    }}
                                  >
                                    Gross Weight
                                  </td>
                                  <td
                                    style={{
                                      paddingBottom: 8,
                                      paddingRight: 8,
                                      verticalAlign: "top",
                                      width: 4,
                                      fontSize: 6,
                                      fontWeight: 500,
                                    }}
                                  >
                                    :
                                  </td>
                                  <td
                                    style={{
                                      verticalAlign: "top",
                                      color: "#000000",
                                      paddingBottom: 8,
                                    }}
                                  >
                                    1.00 gms
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      width: 100,
                                      verticalAlign: "top",
                                      color: "#000000",
                                      fontWeight: 500,
                                      paddingBottom: 8,
                                    }}
                                  >
                                    Diamond Weight
                                  </td>
                                  <td
                                    style={{
                                      paddingBottom: 8,
                                      paddingRight: 8,
                                      verticalAlign: "top",
                                      width: 4,
                                      fontWeight: 500,
                                    }}
                                  >
                                    :
                                  </td>
                                  <td
                                    style={{
                                      verticalAlign: "top",
                                      color: "#000000",
                                      paddingBottom: 8,
                                    }}
                                  >
                                    0.03 cts
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      width: 100,
                                      verticalAlign: "top",
                                      color: "#000000",
                                      fontWeight: 500,
                                      paddingBottom: 8,
                                    }}
                                  >
                                    Stone Weight
                                  </td>
                                  <td
                                    style={{
                                      paddingBottom: 8,
                                      paddingRight: 8,
                                      verticalAlign: "top",
                                      width: 4,
                                      fontWeight: 500,
                                    }}
                                  >
                                    :
                                  </td>
                                  <td
                                    style={{
                                      verticalAlign: "top",
                                      color: "#000000",
                                      paddingBottom: 8,
                                    }}
                                  >
                                    223.11 cts
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td
                            style={{
                              maxWidth: 98,
                              paddingBottom: 8,
                              textAlign: "right",
                            }}
                          >
                            <img
                              src="https://newsletter.webc.in/DHC-CARD-PDF/images/card-img.jpg"
                              alt="ornament"
                              width={98}
                              height={65}
                              style={{
                                width: 98,
                                height: 65,
                                objectFit: "cover",
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table> */}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      paddingLeft: 28,
                      paddingRight: 28,
                      paddingBottom: 20,
                    }}
                  >
                    {/* <table
                      border={0}
                      cellSpacing={0}
                      role="presentation"
                      cellPadding={0}
                      width="100%"
                      style={{
                        backgroundColor: "#ffffff",
                        maxWidth: "100%",
                        borderCollapse: "collapse",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              fontSize: 10,
                              fontWeight: 500,
                              verticalAlign: "top",
                              color: "#000000",
                              width: 100,
                            }}
                          >
                            Conclusion{" "}
                          </td>
                          <td
                            style={{
                              paddingRight: 8,
                              verticalAlign: "top",
                              fontSize: 10,
                              fontWeight: 500,
                            }}
                          >
                            :
                          </td>
                          <td
                            style={{
                              fontSize: 10,
                              fontWeight: 400,
                              verticalAlign: "top",
                              color: "#000000",
                            }}
                          >
                            Natural Ruby, Natural Emerald,
                            <br />
                            Natural Yellow Sapphire, Natural Blue Sapphire,
                            <br /> Natural Chrysoberyl Cat's Eye, Natural
                            Hessonite, Pearl,
                            <br /> Coral &amp; Natural Diamonds
                          </td>
                        </tr>
                      </tbody>
                    </table> */}
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <img
                      src={value?.front_foot_image ?? ""}
                      alt="footer"
                      style={{
                        maxWidth: "100%",
                        width: 396,
                        height: 30,
                        textAlign: "center",
                      }}
                      width={396}
                      height={30}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              align="center"
              border={0}
              cellSpacing={0}
              role="presentation"
              cellPadding={0}
              width="100%"
              style={{
                maxWidth: 454,
                minWidth: 454,
                borderCollapse: "collapse",
                backgroundColor: "#ffffff",
                boxSizing: "border-box",
                margin: "0 auto",
                fontFamily: '"Poppins", sans-serif',
              }}
            >
              <tbody>
                <tr>
                  <td style={{ padding: "34px 20px 20px 52px" }}>
                    <img
                      src={value?.back_image ?? ""}
                      alt="item-pic"
                      style={{
                        maxWidth: 381,
                        width: 381,
                        height: 285,
                        objectFit: "cover",
                      }}
                      width={381}
                      height={285}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            {/* <div style={{ pageBreakBefore: "always" }}></div> */}
            
          </div>
        );
      })}
    </div>
  );
};

export default BigFront;
