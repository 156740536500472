import React from "react";
import useVersionOne from "./useVersionOne";
import { Input, Image } from "@wac-ui-dashboard/wac_component_library";

const VersionOne = () => {
  const { excelFormData, showCompare, itemID, excelData } = useVersionOne();

  return (
    <div className="row">
      <div className="col-6">
        <h6 className="pro-ttl h6">{itemID}</h6>
        <small>{"QC"}</small>
        {excelFormData?.data?.map((value, index) => {
          return (
            <div key={index}>
              {value?.slug === "image" ?
                (<>
                  <label>{value?.slug === "image" ? "Image" : value?.name}</label>
                  <div className={`col-2 pro-my-2`}>
                    <div className={``}>
                      <Image
                        width={100}
                        height={100}
                        src={value?.value}
                        alt={`image - 01`}
                      />
                    </div>
                  </div>
                </>)
                :
             (   <Input
                  label={value?.slug === "image" ? "Image" : value?.name}
                  type="text"
                  id={`${value?.slug}`}
                  name={`${value?.slug}`}
                  value={value?.value}
                  disabled={true}
                />)
              }

            </div>
          );
        })}
        {/* Mapping the 'center_stone' object */}
        {excelFormData?.center_stone && <small>{"Center stone"}</small>}
        {excelFormData?.center_stone?.map((value, index) => (
          <div key={index}>
            <Input
              label={value?.name}
              type="text"
              id={`${value?.slug}`}
              name={`${value?.slug}`}
              value={value?.value}
              disabled={true}
            />
          </div>
        ))}

        {/* Mapping the 'side_stone' object */}
        {excelFormData?.side_stone && <small>{"Side stone"}</small>}
        {excelFormData?.side_stone?.map((value, index) => (
          <div key={index}>
            <Input
              label={value?.name}
              type="text"
              id={`${value?.slug}`}
              name={`${value?.slug}`}
              value={value?.value}
              disabled={true}
            />
          </div>
        ))}
      </div>
      <div className="col-6">
        {showCompare && (
          <>
            <h6>{"Excel Data"}</h6>
            <small>QC</small>
            {excelData?.data?.map((value, index) => {
              return (
                <div key={index}>
                  <Input
                    label={value?.name}
                    type="text"
                    id={`${value?.slug}`}
                    name={`${value?.slug}`}
                    value={value?.value}
                    disabled={true}
                  />
                </div>
              );
            })}

            {/* Mapping the 'center_stone' object */}
            {excelData?.center_stone && <small>{"Center stone"}</small>}
            {excelData?.center_stone?.map((value, index) => (
              <div key={index}>
                <Input
                  label={value?.name}
                  type="text"
                  id={`${value?.slug}`}
                  name={`${value?.slug}`}
                  value={value?.value}
                  disabled={true}
                />
              </div>
            ))}
            {/* Mapping the 'side_stone' object */}
            {excelData?.side_stone && <small>{"Side stone"}</small>}
            {excelData?.side_stone?.map((value, index) => (
              <div key={index}>
                <Input
                  label={value?.name}
                  type="text"
                  id={`${value?.slug}`}
                  name={`${value?.slug}`}
                  value={value?.value}
                  disabled={true}
                />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default VersionOne;
