import { useFormik } from "formik";

const useForm2 = ({ handleConfirm }) => {
  // const validation = Yup.object({
  //   rft_weight: Yup.string()
  //     .required("This field is required")
  //     .matches(/^[0-9]*$/, "Enter valid number"),
  //   rft_d_no: Yup.string()
  //     .required("This field is required")
  //     .matches(/^[0-9]*$/, "Enter valid number"),
  //   rft_percentage: Yup.string()
  //     .required("This field is required")
  //     .matches(/^\d+(\.\d{1,2})?$/, "Enter a valid percentage")
  //     .test("max", "Enter a valid percentage", function (value) {
  //       if (!value) return true; // Let Yup.string().required handle the empty value case
  //       const numericValue = parseFloat(value);
  //       return numericValue <= 100;
  //     }),
  //   cvd_weight: Yup.string()
  //     .required("This field is required")
  //     .matches(/^[0-9]*$/, "Enter valid number"),
  //   cvd_d_no: Yup.string()
  //     .required("This field is required")
  //     .matches(/^[0-9]*$/, "Enter valid number"),
  //   cvd_percentage: Yup.string()
  //     .required("This field is required")
  //     .matches(/^\d+(\.\d{1,2})?$/, "Enter a valid percentage")
  //     .test("max", "Enter a valid percentage", function (value) {
  //       if (!value) return true; // Let Yup.string().required handle the empty value case
  //       const numericValue = parseFloat(value);
  //       return numericValue <= 100;
  //     }),
  //   htpt_weight: Yup.string()
  //     .required("This field is required")
  //     .matches(/^[0-9]*$/, "Enter valid number"),
  //   htpt_d_no: Yup.string()
  //     .required("This field is required")
  //     .matches(/^[0-9]*$/, "Enter valid number"),
  //   htpt_percentage: Yup.string()
  //     .required("This field is required")
  //     .matches(/^\d+(\.\d{1,2})?$/, "Enter a valid percentage")
  //     .test("max", "Enter a valid percentage", function (value) {
  //       if (!value) return true; // Let Yup.string().required handle the empty value case
  //       const numericValue = parseFloat(value);
  //       return numericValue <= 100;
  //     }),
  //   cvd_pass_weight: Yup.string()
  //     .required("This field is required")
  //     .matches(/^[0-9]*$/, "Enter valid number"),
  //   cvd_pass_d_no: Yup.string()
  //     .required("This field is required")
  //     .matches(/^[0-9]*$/, "Enter valid number"),
  //   cvd_pass_percentage: Yup.string()
  //     .required("This field is required")
  //     .matches(/^\d+(\.\d{1,2})?$/, "Enter a valid percentage")
  //     .test("max", "Enter a valid percentage", function (value) {
  //       if (!value) return true; // Let Yup.string().required handle the empty value case
  //       const numericValue = parseFloat(value);
  //       return numericValue <= 100;
  //     }),
  //   status: Yup.string().required("Select Status"),
  // });
  const initialValues = {
    status2: "",
    rft_weight: "",
    rft_d_no: "",
    rft_percentage: "",
    cvd_weight: "",
    cvd_d_no: "",
    cvd_percentage: "",
    htpt_weight: "",
    htpt_d_no: "",
    htpt_percentage: "",
    cvd_pass_weight: "",
    cvd_pass_d_no: "",
    cvd_pass_percentage: "",
  };

  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const errors = {};
      // Validate the status field if it's not empty
      if (!values.status2) {
        errors.status2 = "Select Status";
      }
      // Validation for rft_weight
      if (!values.rft_weight || !/^[0-9]*$/.test(values.rft_weight)) {
        errors.rft_weight = "Enter valid number";
      }

      // Validation for rft_d_no
      if (!values.rft_d_no || !/^[0-9]*$/.test(values.rft_d_no)) {
        errors.rft_d_no = "Enter valid number";
      }

      // Validation for rft_percentage
      if (!values.rft_percentage) {
        errors.rft_percentage = "This field is required";
      } else if (!/^\d+(\.\d{1,2})?$/.test(values.rft_percentage)) {
        errors.rft_percentage = "Enter a valid percentage";
      } else {
        const numericValue = parseFloat(values.rft_percentage);
        if (numericValue > 100) {
          errors.rft_percentage = "Enter a valid percentage";
        }
      }

      // Validation for cvd_weight
      if (!values.cvd_weight || !/^[0-9]*$/.test(values.cvd_weight)) {
        errors.cvd_weight = "Enter valid number";
      }

      // Validation for cvd_d_no
      if (!values.cvd_d_no || !/^[0-9]*$/.test(values.cvd_d_no)) {
        errors.cvd_d_no = "Enter valid number";
      }

      // Validation for cvd_percentage
      if (!values.cvd_percentage) {
        errors.cvd_percentage = "This field is required";
      } else if (!/^\d+(\.\d{1,2})?$/.test(values.cvd_percentage)) {
        errors.cvd_percentage = "Enter a valid percentage";
      } else {
        const numericValue = parseFloat(values.cvd_percentage);
        if (numericValue > 100) {
          errors.cvd_percentage = "Enter a valid percentage";
        }
      }

      // Validation for htpt_weight
      if (!values.htpt_weight || !/^[0-9]*$/.test(values.htpt_weight)) {
        errors.htpt_weight = "Enter valid number";
      }

      // Validation for htpt_d_no
      if (!values.htpt_d_no || !/^[0-9]*$/.test(values.htpt_d_no)) {
        errors.htpt_d_no = "Enter valid number";
      }

      // Validation for htpt_percentage
      if (!values.htpt_percentage) {
        errors.htpt_percentage = "This field is required";
      } else if (!/^\d+(\.\d{1,2})?$/.test(values.htpt_percentage)) {
        errors.htpt_percentage = "Enter a valid percentage";
      } else {
        const numericValue = parseFloat(values.htpt_percentage);
        if (numericValue > 100) {
          errors.htpt_percentage = "Enter a valid percentage";
        }
      }

      // Validation for cvd_pass_weight
      if (!values.cvd_pass_weight || !/^[0-9]*$/.test(values.cvd_pass_weight)) {
        errors.cvd_pass_weight = "Enter valid number";
      }

      // Validation for cvd_pass_d_no
      if (!values.cvd_pass_d_no || !/^[0-9]*$/.test(values.cvd_pass_d_no)) {
        errors.cvd_pass_d_no = "Enter valid number";
      }

      // Validation for cvd_pass_percentage
      if (!values.cvd_pass_percentage) {
        errors.cvd_pass_percentage = "This field is required";
      } else if (!/^\d+(\.\d{1,2})?$/.test(values.cvd_pass_percentage)) {
        errors.cvd_pass_percentage = "Enter a valid percentage";
      } else {
        const numericValue = parseFloat(values.cvd_pass_percentage);
        if (numericValue > 100) {
          errors.cvd_pass_percentage = "Enter a valid percentage";
        }
      }

      return errors;
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      handleConfirm(values);
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  return { formik, getFieldError };
};

export default useForm2;
