import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../store/slices/Orders/orderSlice";
import { updateOrderData } from "../../../../../store/slices/Orders/addOrderSlice";

const useCollection = () => {
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddOrderModal = false;
      })
    );
  };

  const handleNext = () => {
    dispatch(
      updateOrderData((state) => {
        state.activeTab = "Item";
      })
    );
  };
  return { handleCloseModal, handleNext };
};

export default useCollection;
