import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  updateConfig,
  getDeliveryRecheckListData,
} from "../../../store/slices/Delivery/deliverySlice";
import { getDeliveryCertificate } from "./api";

const useDeliveryRecheck = () => {
  const deliveryState = useSelector((state) => state.delivery);
  const deliveryRecheckData = deliveryState.deliverySectionRecheckData;

  const [showDetail, setShowDetail] = useState(false);
  const [activeCard, setActiveCard] = useState({ id: "", key: "" });
  const [pendingPage, setPendingPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [gliNumber, setGliNumber] = useState("");
  const [certificateListData, setCertificateList] = useState();
  const dispatch = useDispatch();

  const slug = localStorage?.getItem("slug");

  useEffect(() => {
    let params = {
      page: pendingPage,
      per_page: deliveryState.currentPageSize,
      search: deliveryState.search,
      process_slug: slug,
    };
    dispatch(getDeliveryRecheckListData(params));

    // eslint-disable-next-line
  }, [pendingPage, deliveryState.currentPageSize, deliveryState.search]);
  // const observer = useRef();
  // const lastCard = useCallback((ref) => {
  //   if (observer.current) observer.current.disconnect();
  //   observer.current = new IntersectionObserver(
  //     (entries) => {
  //       if (entries[0].isIntersecting) {
  //         setPage((prevPageNo) => prevPageNo + 1);
  //       }
  //     },
  //     { threshold: 0.9 }
  //   );
  //   if (ref) observer.current.observe(ref);
  // }, []);

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleChange = (item, key, index) => {
    setActiveCard({ id: item?._id, key: key });
    dispatch(
      updateConfig((state) => {
        state.selectedRecheckCardId = item?._id;
      })
    );
    setGliNumber(item?.gli_number);
    setActiveCard(item?._id);
    if (key === "Pending") {
      setShowDetail(true);
      getDeliveryCertificate(item?._id, slug).then((response) => {
        const certificateList = response?.data;
        setCertificateList(certificateList);
      });
    } else {
      setShowDetail(false);
      dispatch(
        updateConfig((state) => {
          state.receipt_id = item?._id;
        })
      );
    }
  };
  const handleLoadMore = (mouseHover) => {
    if (
      mouseHover === "Pending" &&
      deliveryRecheckData?.Pending?.length !== deliveryState?.total?.Pending
    ) {
      setPendingPage(pendingPage + 1);
    }
  };

  return {
    gliNumber,
    showDetail,
    isLoading,
    activeCard,
    totalLength: {
      Pending: deliveryState.total.Pending,
    },
    deliveryRecheckData,
    certificateListData,
    handleSearch,
    setIsLoading,
    handleLoadMore,
    handleChange,
    setCertificateList,
  };
};

export default useDeliveryRecheck;
