import React from "react";
import useVersionTwo from "./useVersionTwo";
import { Button, Input } from "@wac-ui-dashboard/wac_component_library";

const VersionTwo = () => {
  const { itemID, excelEditedFormData, formik, getFieldError, handleReset } =
    useVersionTwo();
  return (
    <div className="row">
      <div className="col-6">
        <h6>{itemID}</h6>
        {excelEditedFormData?.map((value, index) => {
          return (
            <div key={index}>
              <Input
                label={value?.name}
                type="text"
                id={`${value?.slug}`}
                name={`${value?.slug}`}
                className={`pro-input lg ${
                  getFieldError(`${value?.slug}`) && " error"
                }`}
                onChange={(e) =>
                  formik?.setFieldValue(`${value?.slug}`, e?.target?.value)
                }
                onBlur={formik?.handleBlur(`${value?.slug}`)}
                error={getFieldError(value?.slug)}
                errorMessage={getFieldError(value?.slug)}
                value={formik?.values[value?.slug]}
              />
            </div>
          );
        })}
      </div>
      <div>
        <div
          className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
        >
          <Button className={"pro-btn-link lg pro-px-5"} onClick={handleReset}>
            Reset
          </Button>
          <Button
            className={"pro-btn-primary lg pro-ms-3"}
            type="button"
            onClick={formik?.handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VersionTwo;
