import {
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAssignedTable from "./useAssignedTable";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../OrderColumn";

const AssignedTable = () => {
  const {
    isLoading,
    isFetching,
    staffState,
    tableFields,
    showEditModal,
    assignedTableData,
    paginationOptions,
    getRow,
    refetch,
    handleSearch,
    handlePageSize,
    handlePagination,
    updateAssignedFields,
    handleEditColumnsClick,
  } = useAssignedTable();
  return (
    <div>
      <SearchFilters
        onSearchInput={handleSearch}
        SearchIcon={<span className="material-symbols-outlined"> search </span>}
        showClearFilters={false}
        searchInputProps={{ value: staffState?.assignedSearch }}
        isLoading={isLoading}
        showActions={true}
        handleActionClick={handleEditColumnsClick}
      />
      <Table
        multiSelect={false}
        data={assignedTableData?.data?.data ?? []}
        uniqueID={"id"}
        fields={tableFields}
        SortIcon={<FaSort />}
        assignIcon={<span className="material-symbols-outlined">badge</span>}
        getRow={getRow}
        loading={isFetching}
        perpage={staffState?.currentPageSize}
        assignable={false}
        deletable={false}
        editable={false}
        showCheckBox={false}
      />
      <Pagination
        currentPage={assignedTableData?.data?.current_page}
        totalPageCount={Math.ceil(
          assignedTableData?.data?.total_count /
            assignedTableData?.data?.per_page
        )}
        onPageChange={handlePagination}
        options={paginationOptions}
        onActionChange={handlePageSize}
        center
      />
      <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
        <div className="pro-m-5">
          <OrderColumn
            title={"Choose which columns you see"}
            refetchData={refetch}
            tableFields={assignedTableData?.data?.fields}
            moduleId={assignedTableData?.data?.module_id}
            updateData={updateAssignedFields}
          />
        </div>
      </ModalLayout>
    </div>
  );
};

export default AssignedTable;
