import { getAxiosInstance } from "../../../api";

export const getAllPermissions = async () => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/permissions/list`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const AddRole = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/roles/create`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const updateRole = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.put(`/roles/update`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
