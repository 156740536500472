import {
  Button,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import usePendingTable from "./usePendingTable";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../OrderColumn";
import Style from "../staffWindow.module.scss";

const PendingTable = () => {
  const {
    getRow,
    staffState,
    pendingTableData,
    isFetching,
    tableFields,
    isLoading,
    paginationOptions,
    showEditModal,
    refetch,
    handleAssignClick,
    handlePagination,
    handlePageSize,
    handleSearch,
    handleEditColumnsClick,
    updatePendingFields,
  } = usePendingTable();
  return (
    <div>
      <SearchFilters
        onSearchInput={handleSearch}
        SearchIcon={<span className="material-symbols-outlined"> search </span>}
        showClearFilters={false}
        searchInputProps={{ value: staffState?.pendingSearch }}
        showActions={true}
        handleActionClick={handleEditColumnsClick}
        loading={isLoading}
      />
      <Table
        multiSelect={pendingTableData?.data?.data?.length !== 0 ? true : false}
        data={pendingTableData?.data?.data ?? []}
        uniqueID={"id"}
        fields={tableFields}
        SortIcon={<FaSort />}
        assignIcon={<span className="material-symbols-outlined">badge</span>}
        getRow={getRow}
        loading={isFetching}
        perpage={staffState?.currentPageSize}
        assignable={true}
        assignText={"Assign to me"}
        handleAssign={handleAssignClick}
        deletable={false}
        editable={false}
        showCheckBox={true}
        extraClasssName={`pro-pb-4`}
      />
      <Pagination
        currentPage={pendingTableData?.data?.current_page}
        totalPageCount={Math.ceil(
          pendingTableData?.data?.total_count / pendingTableData?.data?.per_page
        )}
        onPageChange={handlePagination}
        options={paginationOptions}
        onActionChange={handlePageSize}
        center
      />
      <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
        <div className="pro-m-5">
          <OrderColumn
            title={"Choose which columns you see"}
            refetchData={refetch}
            tableFields={pendingTableData?.data?.fields}
            moduleId={pendingTableData?.data?.module_id}
            updateData={updatePendingFields}
          />
        </div>
      </ModalLayout>
      {/* <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn`}
      >
        {pendingTableData?.data?.data?.length > 0 && (
          <Button
            className={"pro-btn-primary lg pro-ms-3"}
            type="submit"
            onClick={handleAssignAll}
          >
            Assign All
          </Button>
        )}
      </div> */}
    </div>
  );
};

export default PendingTable;
