import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useAddBilling from "./useAddBilling";

const AddBilling = (refetch) => {
  const {
    formik,
    queryState,
    basicData,
    serviceItems,
    getFieldError,
    handleCloseModal,
    handleTypeChange,
  } = useAddBilling(refetch);
  return (
    <div className={`row`}>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Supplier
        </label>
        <Select
          id="supplier"
          name="supplier"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("supplier") && " error"}`}
          classNamePrefix="pro-input"
          options={basicData?.supplier ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?._id}
          value={formik?.values?.supplier}
          onBlur={formik.handleBlur("supplier")}
          onChange={(value) => formik?.setFieldValue("supplier", value || null)}
          menuPlacement="auto"
        />
        {getFieldError("supplier") && (
          <span className="error-text">{getFieldError("supplier")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Shop
        </label>
        <Select
          id="shop"
          name="shop"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("shop") && " error"}`}
          classNamePrefix="pro-input"
          options={basicData?.shop ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?._id}
          value={formik?.values?.shop}
          onBlur={formik.handleBlur("shop")}
          onChange={(value) => formik?.setFieldValue("shop", value || null)}
          menuPlacement="auto"
        />
        {getFieldError("shop") && (
          <span className="error-text">{getFieldError("shop")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Item Type
        </label>
        <Select
          id="item_type"
          name="item_type"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("item_type") && " error"}`}
          classNamePrefix="pro-input"
          options={basicData?.item_type ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?._id}
          value={formik?.values?.item_type}
          onBlur={formik.handleBlur("item_type")}
          onChange={(value) =>
            formik?.setFieldValue("item_type", value || null)
          }
          menuPlacement="auto"
        />
        {getFieldError("item_type") && (
          <span className="error-text">{getFieldError("item_type")}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Type
        </label>
        <Select
          id="type"
          name="type"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("type") && " error"}`}
          classNamePrefix="pro-input"
          options={basicData?.type ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          value={formik?.values?.type}
          onBlur={formik.handleBlur("type")}
          onChange={(value) => {
            handleTypeChange(value);
          }}
          menuPlacement="auto"
        />
        {getFieldError("type") && (
          <span className="error-text">{getFieldError("type")}</span>
        )}
      </div>
      {formik?.values?.type !== "" && (
        <div>
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              {formik?.values?.type?.id === 1
                ? "Service type"
                : formik?.values?.type?.id === 2
                ? "Certificate type"
                : formik?.values?.type?.id === 3
                ? "Packing type"
                : "category Type"}
            </label>
            <Select
              id="service_type"
              name="service_type"
              placeholder={"Select"}
              className={`pro-input lg  ${
                getFieldError("service_type") && " error"
              }`}
              classNamePrefix="pro-input"
              options={serviceItems ?? []}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?._id}
              value={formik?.values?.service_type}
              onBlur={formik.handleBlur("service_type")}
              onChange={(value) =>
                formik?.setFieldValue("service_type", value || null)
              }
              menuPlacement="auto"
            />
            {getFieldError("service_type") && (
              <span className="error-text">
                {getFieldError("service_type")}
              </span>
            )}
          </div>

          <Input
            label={"Rate Per Piece"}
            type="text"
            id="rate_per_piece"
            name="rate_per_piece"
            className={`pro-input lg ${
              getFieldError("rate_per_piece") && " error"
            }`}
            {...formik.getFieldProps("rate_per_piece")}
            error={getFieldError("rate_per_piece")}
            errorMessage={getFieldError("rate_per_piece")}
          />
          {formik?.values?.type.id === 1 && (
            <div>
              <Input
                label={"Carat Slab"}
                type="text"
                id="carat_slab"
                name="carat_slab"
                className={`pro-input lg ${
                  getFieldError("carat_slab") && " error"
                }`}
                {...formik.getFieldProps("carat_slab")}
                error={getFieldError("carat_slab")}
                errorMessage={getFieldError("carat_slab")}
              />
              <Input
                label={"Rate Per Carat"}
                type="text"
                id="rate_per_carat"
                name="rate_per_caret"
                className={`pro-input lg ${
                  getFieldError("rate_per_carat") && " error"
                }`}
                {...formik.getFieldProps("rate_per_carat")}
                error={getFieldError("rate_per_carat")}
                errorMessage={getFieldError("rate_per_carat")}
              />
              <Input
                label={"Number of stone slab"}
                type="text"
                id="number_of_stones_slab"
                name="number_of_stones_slab"
                className={`pro-input lg ${
                  getFieldError("number_of_stones_slab") && " error"
                }`}
                {...formik.getFieldProps("number_of_stones_slab")}
                error={getFieldError("number_of_stones_slab")}
                errorMessage={getFieldError("number_of_stones_slab")}
              />
              <Input
                label={"Rate per stones"}
                type="rate_per_stones"
                id="rate_per_stones"
                name="rate_per_stones"
                className={`pro-input lg ${
                  getFieldError("rate_per_stones") && " error"
                }`}
                {...formik.getFieldProps("rate_per_stones")}
                error={getFieldError("rate_per_stones")}
                errorMessage={getFieldError("rate_per_stones")}
              />
            </div>
          )}
        </div>
      )}
      <div className="input-wrap pro-mb-4">
        <div className="pro-check-box pro-py-2">
          <input
            type="checkbox"
            className="pro-check"
            id="bold"
            name="pro-checkbox"
            checked={Boolean(formik.values?.format_type) ?? false}
            onChange={(e) => {
              formik.setFieldValue(
                `format_type`,
                !Boolean(formik.values?.format_type) ?? false
              );
            }}
            disabled={false}
          />
          <label htmlFor="day_check" className="pro-check-label">
            Default
          </label>
        </div>
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {queryState?.isEdit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default AddBilling;
