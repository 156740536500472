import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { logout } from "./HeaderActions/api";
import { toast } from "react-toastify";
const useCommonLayout = () => {
  const navigate = useNavigate();
  const { menuDrawer, showLoginModal, formData } = useSelector(
    (state) => state.global
  );
  const dispatch = useDispatch();
  const handleModalClose = () => {
    dispatch(globalUpdateConfig((state) => (state.showLoginModal = false)));
    logout().then((response) => {
      if (response?.data?.status_code === 200) {
        localStorage.clear("USER_ACCESS_TOKEN");
        localStorage.clear("dashboard_permissions");
        navigate("/");
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const handleGoBack = () => {
    navigate(menuDrawer?.backTo);
  };

  return {
    menuDrawer,
    showLoginModal,
    formData,
    handleGoBack,
    handleModalClose,
  };
};

export default useCommonLayout;
