import React from "react";
import { Button } from "@wac-ui-dashboard/wac_component_library";
import useAddCertificatePrefix from "./useAddCertificatePrefix";
import Style from "./addCertficatePrefix.module.scss";
import Select from "react-select";

const AddCertificatePrefix = (refetch) => {
  const {
    filteredOptions,
    formik,
    basicData,
    isEdit,
    exampleText,
    dayChecked,
    yearChecked,
    goldBasedChecked,
    platinumBasedChecked,
    checkboxFields,
    getFieldError,
    handleInputChange,
    handleCloseModal,
    handleCheckboxChange,
    setDayChecked,
    setYearChecked,
    setGoldBasedChecked,
    setPlatinumBasedChecked,
  } = useAddCertificatePrefix(refetch);
  const renderCheckboxField = (
    id,
    label,
    checked,
    onChange,
    orderFieldName,
    disable
  ) => (
    <div className="pro-d-flex pro-items-center pro-py-2">
      <div className="pro-check-box" key={id}>
        <input
          type="checkbox"
          className="pro-check"
          id={id}
          name="pro-checkbox"
          onChange={(e) => handleCheckboxChange(e, onChange, id, checked)}
          checked={checked}
          disabled={disable}
        />
        <label htmlFor={id} className="pro-check-label">
          {label}
        </label>
      </div>
      {/* eslint-disable-next-line eqeqeq */}
      {checked && !(id == "gold_based" || id == "platinum_based") && (
        <Select
          id={orderFieldName}
          name={orderFieldName}
          placeholder={"Order"}
          className={`select-class-1 pro-input lg ${
            getFieldError(orderFieldName) && " error"
          }`}
          classNamePrefix="pro-input"
          options={filteredOptions}
          getOptionLabel={(option) => option?.label}
          getOptionValue={(option) => option?.value}
          value={formik?.values?.[orderFieldName]}
          onBlur={formik.handleBlur(orderFieldName)}
          onChange={handleInputChange(orderFieldName, checked)}
          menuPlacement="auto"
        />
      )}
    </div>
  );

  const renderSelectField = (
    id,
    name,
    placeholder,
    options,
    getOptionLabel,
    getOptionValue,
    value,
    onBlur,
    onChange,
    disable
  ) => {
    return (
      <Select
        id={id}
        name={name}
        placeholder={placeholder}
        className={`pro-input lg ${getFieldError(name) && " error"}`}
        classNamePrefix="pro-input"
        options={options}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        isDisabled={disable}
        menuPlacement="auto"
      />
    );
  };

  const renderSerialSelect = (id, name) => (
    <div className="pro-w-100">
      <Select
        id={`${id}_order`}
        name={`${name}_order`}
        placeholder="Order"
        className={`select-class-1 pro-input lg ${
          getFieldError(`${name}_order`) && "error"
        }`}
        classNamePrefix="pro-input"
        options={filteredOptions}
        getOptionLabel={(option) => option?.label}
        getOptionValue={(option) => option?.value}
        value={formik?.values[`${name}_order`]}
        onBlur={formik.handleBlur(`${name}_order`)}
        onChange={handleInputChange(`${name}_order`, "")}
        menuPlacement="auto"
      />
    </div>
  );

  const renderInputField = (
    label,
    type,
    id,
    name,
    error,
    errorMessage,
    ...props
  ) => (
    <div className="pro-d-flex pro-w-100 pro-gap-3">
      <div className="pro-d-flex pro-flex-column pro-w-100">
        <input
          type={type}
          id={id}
          name={name}
          className={`select-serial-class-1 pro-input lg ${
            getFieldError(name) && "error"
          }`}
          {...formik.getFieldProps(name)}
          {...props}
          disabled={isEdit}
        />
        {getFieldError(name) && (
          <span className="error-text">{errorMessage}</span>
        )}
      </div>
      {renderSerialSelect(id, name)}
    </div>
  );

  return (
    <>
      <div className="row">
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Shop
          </label>
          {renderSelectField(
            "shop_id",
            "shop_id",
            "Select",
            basicData?.shop,
            (option) => option?.name,
            (option) => option?._id,
            formik?.values?.shop_id,
            formik.handleBlur("shop_id"),
            (value) => formik?.setFieldValue("shop_id", value || null),
            isEdit
          )}
          {getFieldError("shop_id") && (
            <span className="error-text">{getFieldError("shop_id")}</span>
          )}
        </div>

        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Serial number reset
          </label>
          {renderSelectField(
            "serial_number_reset",
            "serial_number_reset",
            "Select",
            basicData?.serial_number_reset,
            (option) => option?.name,
            (option) => option?.id,
            formik?.values?.serial_number_reset,
            formik.handleBlur("serial_number_reset"),
            (value) =>
              formik?.setFieldValue("serial_number_reset", value || null),
            false
          )}
          {getFieldError("serial_number_reset") && (
            <span className="error-text">
              {getFieldError("serial_number_reset")}
            </span>
          )}
        </div>
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Serial number basis
          </label>
          {renderSelectField(
            "serial_number_basis",
            "serial_number_basis",
            "Select",
            basicData?.serial_number_basis,
            (option) => option?.name,
            (option) => option?.id,
            formik?.values?.serial_number_basis,
            formik.handleBlur("serial_number_basis"),
            (value) =>
              formik?.setFieldValue("serial_number_basis", value || null),
            false
          )}
          {getFieldError("serial_number_basis") && (
            <span className="error-text">
              {getFieldError("serial_number_basis")}
            </span>
          )}
        </div>
        <div className="input-wrap pro-mb-4">
          <label className="pro-font-sm pro-mb-1 pro-fw-medium">Serial</label>
          {renderInputField(
            "Serial",
            "text",
            "serial_number",
            "serial_number",
            getFieldError("serial_number"),
            getFieldError("serial_number")
          )}
        </div>

        {checkboxFields.map((field) =>
          renderCheckboxField(
            field.id,
            field.label,
            field.state,
            field.setState,
            field.name,
            false
          )
        )}
        <div className="pro-check-box row pro-py-2">
          <div className="col-md-3">
            <input
              type="checkbox"
              className="pro-check"
              id="day_check"
              name="pro-checkbox"
              onChange={(e) =>
                handleCheckboxChange(e, setDayChecked, "day_check", dayChecked)
              }
              checked={dayChecked}
              disabled={false}
            />
            <label htmlFor="day_check" className="pro-check-label">
              Day
            </label>
          </div>
          <div className="col-md-6">
            {dayChecked && (
              <div className="input-wrap pro-mb-4 col-md-6">
                {renderSelectField(
                  "day",
                  "day",
                  "Select",
                  basicData?.day,
                  (option) => option?.name,
                  (option) => option?.id,
                  formik?.values?.day,
                  formik.handleBlur("day"),
                  (value) => formik?.setFieldValue("day", value || null),
                  false
                )}
                {getFieldError("day") && (
                  <span className="error-text">{getFieldError("day")}</span>
                )}
              </div>
            )}
          </div>
          <div className="col-md-3">
            {dayChecked && (
              <Select
                id="day_check_order"
                name="day_check_order"
                placeholder={"Order"}
                className={`select-class-1 pro-input lg ${
                  getFieldError("day_check_order") && " error"
                }`}
                classNamePrefix="pro-input"
                options={filteredOptions}
                getOptionLabel={(option) => option?.label}
                getOptionValue={(option) => option?.value}
                value={formik?.values?.["day_check_order"]}
                onBlur={formik.handleBlur("day_check_order")}
                onChange={handleInputChange("day_check_order", dayChecked)}
                menuPlacement="auto"
              />
            )}
          </div>
        </div>

        <div className="pro-check-box row" style={{ marginBottom: "25px" }}>
          <div className="col-md-3">
            <input
              type="checkbox"
              className="pro-check"
              id="year_check"
              name="pro-checkbox"
              onChange={(e) =>
                handleCheckboxChange(
                  e,
                  setYearChecked,
                  "year_check",
                  yearChecked
                )
              }
              checked={yearChecked}
              disabled={false}
            />
            <label htmlFor="year_check" className="pro-check-label">
              Year
            </label>
          </div>
          <div className="col-md-6">
            {yearChecked && (
              <div className="input-wrap pro-mb-4">
                {renderSelectField(
                  "year",
                  "year",
                  "Select",
                  basicData?.year,
                  (option) => option?.name,
                  (option) => option?.id,
                  formik?.values?.year,
                  formik.handleBlur("year"),
                  (value) => formik?.setFieldValue("year", value || null),
                  false
                )}
                {getFieldError("year") && (
                  <span className="error-text">{getFieldError("year")}</span>
                )}
              </div>
            )}
          </div>
          <div className="col-md-3">
            {yearChecked && (
              <Select
                id="year_check_order"
                name="year_check_order"
                placeholder={"Order"}
                className={`select-class-1 pro-input lg ${
                  getFieldError("year_check_order") && " error"
                }`}
                classNamePrefix="pro-input"
                options={filteredOptions}
                getOptionLabel={(option) => option?.label}
                getOptionValue={(option) => option?.value}
                value={formik?.values?.["year_check_order"]}
                onBlur={formik.handleBlur("year_check_order")}
                onChange={handleInputChange("year_check_order", yearChecked)}
                menuPlacement="auto"
              />
            )}
          </div>
        </div>
        <div>
          {renderCheckboxField(
            "gold_based",
            "Gold Based",
            goldBasedChecked,
            setGoldBasedChecked,
            "",
            platinumBasedChecked
          )}
          {renderCheckboxField(
            "platinum_based",
            "Platinum Based",
            platinumBasedChecked,
            setPlatinumBasedChecked,
            "",
            goldBasedChecked
          )}
          {!goldBasedChecked &&
            !platinumBasedChecked &&
            getFieldError("gold_based") && (
              <span className="goldBasedErr">
                {getFieldError("gold_based")}
              </span>
            )}
        </div>
      </div>
      <div>{exampleText && <p>eg:{exampleText}</p>}</div>
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn ${Style.button}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {isEdit ? "Update" : "Create"}
        </Button>
      </div>
    </>
  );
};

export default AddCertificatePrefix;
