import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import usePrintType from "./usePrintType";
import Select from "react-select";

const PrintType = ({
  visitingOneFrontPrintRef,
  visitingOneBackPrintRef,
  papperFrontPrintRef,
  papperBackPrintRef,
  bigFrontPrintRef,
}) => {
  const { formik, basicData, handleCloseModal, getFieldError } = usePrintType({
    visitingOneFrontPrintRef,
    visitingOneBackPrintRef,
    papperFrontPrintRef,
    papperBackPrintRef,
    bigFrontPrintRef,
  });
  return (
    <div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Certificate Type
        </label>
        <Select
          id="certificate_type"
          name="certificate_type"
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("certificate_type") && " error"
          }`}
          classNamePrefix="pro-input"
          options={basicData?.data}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?._id}
          value={formik?.values?.certificate_type}
          onBlur={formik.handleBlur("certificate_type")}
          onChange={(value) => {
            formik?.setFieldValue("certificate_type", value || null);
          }}
          menuPlacement="auto"
        />
        {getFieldError("certificate_type") && (
          <span className="error-text">
            {getFieldError("certificate_type")}
          </span>
        )}
      </div>
      {(formik?.values?.certificate_type?._id === 1 ||
        formik?.values?.certificate_type?._id === 3) && (
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Side
          </label>
          <Select
            id="side"
            name="side"
            placeholder={"Select"}
            className={`pro-input lg  ${getFieldError("side") && " error"}`}
            classNamePrefix="pro-input"
            options={[
              {
                name: "Front",
                id: 1,
              },
              {
                name: "Back",
                id: 2,
              },
            ]}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
            value={formik?.values?.side}
            onBlur={formik.handleBlur("side")}
            onChange={(value) => {
              formik?.setFieldValue("side", value || null);
            }}
            menuPlacement="auto"
          />
          {getFieldError("side") && (
            <span className="error-text">{getFieldError("side")}</span>
          )}
        </div>
      )}
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {"Print"}
        </Button>
      </div>
    </div>
  );
};

export default PrintType;
