import React from "react";
import { useSelector } from "react-redux";
import useFontCases from "../../../../../utils/hooks/useFontCases";

const Front = React.forwardRef(({ visitingOneFrontPrintRef }) => {
  const { getFontCase } = useFontCases();
  const { printingData } = useSelector((state) => state.printing);

  const fontCaseAllValues = printingData
    ?.map((value) => {
      const gradedValues = value?.data?.graded_values;
      const centerStone = value?.data?.center_stone;
      const sideStone = value?.data?.side_stone;

      return [gradedValues, centerStone, sideStone].filter(Boolean); // Filter out undefined values
    })
    .flat();
  const fontCaseValue = fontCaseAllValues?.flatMap((sublist) =>
    sublist?.map((item) => item?.font_case)
  );
  const fontCase = getFontCase(parseInt(fontCaseValue));
  const renderText = (value) => {
    switch (fontCase) {
      case "Uppercase":
        return <span style={{ textTransform: "uppercase" }}>{value}</span>;
      case "Lowercase":
        return <span style={{ textTransform: "lowercase" }}>{value}</span>;
      case "Title_Case":
        return <span style={{ textTransform: "capitalize" }}>{value}</span>;
      case "Sentence_Case":
        const sentanceWords = value?.toLowerCase().split(" ");
        const sentenceCaseWords = sentanceWords.map(
          (word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)
        );
        return sentenceCaseWords.join(" ");
      case "Camel_Case":
        const camelWords = value?.toLowerCase().split(" ");
        const camelCaseWords = camelWords.map((word, index) =>
          index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
        );
        return camelCaseWords.join("");
      default:
        return <span>{value}</span>;
    }
  };
  return (
    <div
      ref={visitingOneFrontPrintRef}
      style={{ display: "flex", flexWrap: "wrap", gap: 30 }}
    >
      <title>DHC Card</title>
      <style
        dangerouslySetInnerHTML={{
          __html:
            "@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900 &display=swap');\n        ",
        }}
      />
      {printingData?.map((value, mainIndex) => {
        return (
          <div key={mainIndex} id="element-to-print">
            <table
              border={0}
              cellSpacing={0}
              role="presentation"
              cellPadding={0}
              style={{
                backgroundColor: "#ffffff",
                maxWidth: "100%",
                borderCollapse: "collapse",
                minWidth: "100%",
                height: "100%",
                minHeight: "100%",
                border: "0",
              }}
            >
              <tbody>
                <tr>
                  <td>
                    <table
                      border={0}
                      cellSpacing={0}
                      role="presentation"
                      cellPadding={0}
                      style={{
                        border: "1px solid #E6E6E6",
                        backgroundColor: "#ffffff",
                        maxWidth: "9cm",
                        minWidth: "9cm",
                        minHeight: "4.8cm",
                        maxHeight: "4.8cm",
                        borderCollapse: "collapse",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td style={{ height: "38px" }}>
                            <img
                              src={
                                value?.front_top_image ??
                                "https://assets.babycenter.com/ims/2020/11/img_noimageavailable.svg"
                              }
                              alt="header"
                              style={{
                                maxWidth: "100%",
                                width: "100%",
                                paddingBottom: "5px",
                                height: "38px",
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              paddingBottom: "10px",
                              fontSize: "8px",
                              fontWeight: 400,
                              textAlign: "center",
                              paddingTop: "3px",
                              color: "#000000",
                              height: "18px",
                            }}
                          >
                            CERTIFICATE OF AUTHENTICITY
                          </td>
                        </tr>
                        <tr>
                          <td style={{ verticalAlign: "top" }}>
                            <table
                              border={0}
                              cellSpacing={0}
                              role="presentation"
                              cellPadding={0}
                              style={{
                                backgroundColor: "#ffffff",
                                maxWidth: "100%",
                                borderCollapse: "collapse",
                                minWidth: "100%",
                                height: "100%",
                                minHeight: "100%",
                                border: "0",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td
                                    style={{
                                      paddingLeft: 18,
                                      paddingRight: 18,
                                    }}
                                  >
                                    <table
                                      border={0}
                                      cellSpacing={0}
                                      role="presentation"
                                      cellPadding={0}
                                      style={{
                                        backgroundColor: "#ffffff",
                                        maxWidth: "100%",
                                        borderCollapse: "collapse",
                                        minWidth: "100%",
                                        margin: "0 auto",
                                      }}
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{
                                              fontSize: "6px",
                                              fontWeight: 400,
                                              width: "140px",
                                              paddingBottom: "5px",
                                              verticalAlign: "top",
                                              color: "#000000",
                                            }}
                                          >
                                            Summary No
                                          </td>
                                          <td
                                            style={{
                                              paddingBottom: "5px",
                                              paddingRight: "5px",
                                              verticalAlign: "top",
                                              fontSize: "6px",
                                              fontWeight: 400,
                                            }}
                                          >
                                            :
                                          </td>
                                          <td
                                            style={{
                                              fontSize: "6px",
                                              fontWeight: 400,
                                              paddingBottom: "5px",
                                              verticalAlign: "top",
                                              color: "#000000",
                                            }}
                                          >
                                            {value?.certificate_number}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              fontSize: "6px",
                                              fontWeight: 400,
                                              width: "140px",
                                              verticalAlign: "top",
                                              color: "#000000",
                                            }}
                                          >
                                            Description
                                          </td>
                                          <td
                                            style={{
                                              paddingBottom: "5px",
                                              paddingRight: "5px",
                                              verticalAlign: "top",
                                              fontSize: "6px",
                                              fontWeight: 400,
                                            }}
                                          >
                                            :
                                          </td>
                                          <td
                                            style={{
                                              fontSize: "6px",
                                              fontWeight: 400,
                                              verticalAlign: "top",
                                              paddingBottom: "5px",
                                              color: "#000000",
                                            }}
                                          >
                                            One second stud studded with7
                                            natural diamonds
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      paddingLeft: "18px",
                                      paddingRight: "18px",
                                    }}
                                  >
                                    <table
                                      border={0}
                                      cellSpacing={0}
                                      role="presentation"
                                      cellPadding={0}
                                      style={{
                                        backgroundColor: "#ffffff",
                                        maxWidth: "100%",
                                        borderCollapse: "collapse",
                                        minWidth: "100%",
                                        margin: "0 auto",
                                      }}
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{
                                              paddingRight: "12px",
                                            }}
                                          >
                                            <table
                                              border={0}
                                              cellSpacing={0}
                                              role="presentation"
                                              cellPadding={0}
                                              style={{
                                                backgroundColor: "#ffffff",
                                                maxWidth: "100%",
                                                borderCollapse: "collapse",
                                                minWidth: "100%",
                                                margin: "0 auto",
                                              }}
                                            >
                                              <tbody>
                                                {value?.data?.graded_values &&
                                                  value?.data?.graded_values?.map(
                                                    (graded_value, index) => {
                                                      return (
                                                        <tr key={index}>
                                                          <td>
                                                            <table
                                                              border={0}
                                                              cellSpacing={0}
                                                              role="presentation"
                                                              cellPadding={0}
                                                              style={{
                                                                backgroundColor:
                                                                  "#ffffff",
                                                                maxWidth:
                                                                  "100%",
                                                                borderCollapse:
                                                                  "collapse",
                                                                minWidth:
                                                                  "100%",
                                                                margin:
                                                                  "0 auto",
                                                              }}
                                                            >
                                                              <tbody>
                                                                <tr>
                                                                  <td
                                                                    style={{
                                                                      maxWidth:
                                                                        "50%",
                                                                      // paddingBottom: "10px",
                                                                    }}
                                                                  >
                                                                    <table
                                                                      border={0}
                                                                      cellSpacing={
                                                                        0
                                                                      }
                                                                      role="presentation"
                                                                      cellPadding={
                                                                        0
                                                                      }
                                                                      style={{
                                                                        maxWidth:
                                                                          "100%",
                                                                        borderCollapse:
                                                                          "collapse",
                                                                        minWidth:
                                                                          "100%",
                                                                        margin:
                                                                          "0 auto",
                                                                      }}
                                                                    >
                                                                      <tbody>
                                                                        <tr>
                                                                          <td
                                                                            style={{
                                                                              fontSize:
                                                                                "6px",
                                                                              fontWeight: 400,
                                                                              width:
                                                                                "140px",
                                                                              verticalAlign:
                                                                                "top",
                                                                              color:
                                                                                "#000000",
                                                                            }}
                                                                          >
                                                                            {graded_value?.bold ===
                                                                            1 ? (
                                                                              <b>
                                                                                {renderText(
                                                                                  graded_value?.text
                                                                                )}
                                                                              </b>
                                                                            ) : (
                                                                              renderText(
                                                                                graded_value?.text
                                                                              )
                                                                            )}
                                                                          </td>
                                                                          <td
                                                                            style={{
                                                                              paddingBottom:
                                                                                "5px",
                                                                              paddingRight:
                                                                                "5px",
                                                                              verticalAlign:
                                                                                "top",
                                                                              width:
                                                                                "4px",
                                                                              fontSize:
                                                                                "6px",
                                                                              fontWeight: 400,
                                                                            }}
                                                                          >
                                                                            :
                                                                          </td>
                                                                          <td
                                                                            style={{
                                                                              fontSize:
                                                                                "6px",
                                                                              fontWeight: 400,
                                                                              verticalAlign:
                                                                                "top",
                                                                              color:
                                                                                "#000000",
                                                                            }}
                                                                          >
                                                                            {graded_value?.bold ===
                                                                            1 ? (
                                                                              <b>
                                                                                {renderText(
                                                                                  graded_value?.value
                                                                                )}{" "}
                                                                              </b>
                                                                            ) : (
                                                                              renderText(
                                                                                graded_value?.value
                                                                              )
                                                                            )}
                                                                          </td>
                                                                        </tr>
                                                                      </tbody>
                                                                    </table>
                                                                  </td>
                                                                </tr>
                                                              </tbody>
                                                            </table>
                                                          </td>
                                                        </tr>
                                                      );
                                                    }
                                                  )}
                                              </tbody>
                                            </table>
                                          </td>
                                          <td>
                                            <table
                                              border={0}
                                              cellSpacing={0}
                                              role="presentation"
                                              cellPadding={0}
                                              style={{
                                                backgroundColor: "#ffffff",
                                                maxWidth: "100%",
                                                borderCollapse: "collapse",
                                                minWidth: "100%",
                                                margin: "0 auto",
                                              }}
                                            >
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <img
                                                      src={value?.image}
                                                      alt="ornament"
                                                      width={80}
                                                      height={80}
                                                      style={{
                                                        width: "50px",
                                                        height: "30px",
                                                      }}
                                                    />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>

                                        {/* center stone table start  */}
                                        {value?.data?.center_stone && (
                                          <tr>
                                            <td
                                            // style={{
                                            //   paddingTop: 20,
                                            // }}
                                            >
                                              <td>
                                                <table
                                                  border={0}
                                                  cellSpacing={0}
                                                  role="presentation"
                                                  cellPadding={0}
                                                  style={{
                                                    maxWidth: "100%",
                                                    borderCollapse: "collapse",
                                                    minWidth: "100%",
                                                    margin: "0 auto",
                                                  }}
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          paddingRight: 5,
                                                        }}
                                                      >
                                                        <table
                                                          border={0}
                                                          cellSpacing={0}
                                                          role="presentation"
                                                          cellPadding={0}
                                                          style={{
                                                            width: "100%",
                                                          }}
                                                        >
                                                          <thead>
                                                            <th
                                                              colSpan={2}
                                                              style={{
                                                                textAlign:
                                                                  "center",
                                                                fontSize: 6,
                                                                fontWeight: 500,
                                                              }}
                                                            >
                                                              CENTER STONE
                                                            </th>
                                                          </thead>
                                                          <tbody>
                                                            {value?.data
                                                              ?.center_stone &&
                                                              value?.data?.center_stone?.map(
                                                                (
                                                                  val,
                                                                  index
                                                                ) => {
                                                                  return (
                                                                    <tr
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      <td
                                                                        style={{
                                                                          fontSize:
                                                                            "6px",
                                                                          fontWeight: 400,
                                                                          width:
                                                                            "140px",
                                                                          verticalAlign:
                                                                            "top",
                                                                          color:
                                                                            "#000000",
                                                                        }}
                                                                      >
                                                                        {val?.bold ===
                                                                        1 ? (
                                                                          <b>
                                                                            {renderText(
                                                                              val?.text
                                                                            )}
                                                                          </b>
                                                                        ) : (
                                                                          renderText(
                                                                            val?.text
                                                                          )
                                                                        )}
                                                                      </td>
                                                                      <td
                                                                        style={{
                                                                          paddingBottom:
                                                                            "5px",
                                                                          paddingRight:
                                                                            "5px",
                                                                          verticalAlign:
                                                                            "top",
                                                                          width:
                                                                            "4px",
                                                                          fontSize:
                                                                            "6px",
                                                                          fontWeight: 400,
                                                                        }}
                                                                      >
                                                                        :
                                                                      </td>
                                                                      <td
                                                                        style={{
                                                                          fontSize:
                                                                            "6px",
                                                                          fontWeight: 400,
                                                                          width:
                                                                            "140px",
                                                                          verticalAlign:
                                                                            "top",
                                                                          color:
                                                                            "#000000",
                                                                        }}
                                                                      >
                                                                        {val?.bold ===
                                                                        1 ? (
                                                                          <b>
                                                                            {renderText(
                                                                              val?.value
                                                                            )}
                                                                          </b>
                                                                        ) : (
                                                                          renderText(
                                                                            val?.value
                                                                          )
                                                                        )}
                                                                      </td>
                                                                    </tr>
                                                                  );
                                                                }
                                                              )}
                                                          </tbody>
                                                        </table>
                                                      </td>

                                                      <td
                                                        style={{
                                                          paddingLeft: 5,
                                                        }}
                                                      >
                                                        <table
                                                          border={0}
                                                          cellSpacing={0}
                                                          role="presentation"
                                                          cellPadding={0}
                                                          style={{
                                                            width: "100%",
                                                          }}
                                                        >
                                                          <thead>
                                                            <th
                                                              colSpan={2}
                                                              style={{
                                                                textAlign:
                                                                  "center",
                                                                fontSize: 6,
                                                                fontWeight: 500,
                                                              }}
                                                            >
                                                              SIDE STONE
                                                            </th>
                                                          </thead>
                                                          <tbody>
                                                            {value?.data
                                                              ?.side_stone &&
                                                              value?.data?.side_stone?.map(
                                                                (
                                                                  grdvalue,
                                                                  index
                                                                ) => {
                                                                  return (
                                                                    <tr
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      <td
                                                                        style={{
                                                                          fontSize:
                                                                            "6px",
                                                                          fontWeight: 400,
                                                                          width:
                                                                            "140px",
                                                                          verticalAlign:
                                                                            "top",
                                                                          color:
                                                                            "#000000",
                                                                        }}
                                                                      >
                                                                        {grdvalue?.bold ===
                                                                        1 ? (
                                                                          <b>
                                                                            {renderText(
                                                                              grdvalue?.text
                                                                            )}
                                                                          </b>
                                                                        ) : (
                                                                          renderText(
                                                                            grdvalue?.text
                                                                          )
                                                                        )}
                                                                      </td>
                                                                      <td
                                                                        style={{
                                                                          paddingBottom:
                                                                            "5px",
                                                                          paddingRight:
                                                                            "5px",
                                                                          verticalAlign:
                                                                            "top",
                                                                          width:
                                                                            "4px",
                                                                          fontSize:
                                                                            "6px",
                                                                          fontWeight: 400,
                                                                        }}
                                                                      >
                                                                        :
                                                                      </td>
                                                                      <td
                                                                        style={{
                                                                          fontSize:
                                                                            "6px",
                                                                          fontWeight: 400,
                                                                          width:
                                                                            "140px",
                                                                          verticalAlign:
                                                                            "top",
                                                                          color:
                                                                            "#000000",
                                                                        }}
                                                                      >
                                                                        {grdvalue?.bold ===
                                                                        1 ? (
                                                                          <b>
                                                                            {renderText(
                                                                              grdvalue?.value
                                                                            )}
                                                                          </b>
                                                                        ) : (
                                                                          renderText(
                                                                            grdvalue?.value
                                                                          )
                                                                        )}
                                                                      </td>
                                                                    </tr>
                                                                  );
                                                                }
                                                              )}
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </td>
                                          </tr>
                                        )}
                                        {/* center stone table end  */}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              backgroundColor: "#E6E6E6",
                              height: "20px",
                            }}
                          >
                            <img
                              src={
                                value?.front_foot_image ??
                                "https://assets.babycenter.com/ims/2020/11/img_noimageavailable.svg"
                              }
                              alt="footer"
                              style={{
                                maxWidth: "100%",
                                width: "100%",
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
});

export default Front;
