import { Image } from "@wac-ui-dashboard/wac_component_library";
import Style from '../../verifications.module.scss';

const Engraving = ({ state }) => {
    return (
      <div className={`${Style.multicolumn_table}`}>
          <h6 className="pro-ttl h6 pro-mb-2">Engraving</h6>
        <table>
          <tbody>
                <tr>
                  <td>Engraving Format</td>
                    <td>{state?.pendingData?.engraving?.engraving_type_format ?? "--"}</td>
                </tr>
                <tr>
                  <td>Engraving Type</td>
                    <td>{state?.pendingData?.engraving?.engraving_type ?? "--"}</td>
                </tr>
                <tr>
                    <td>Engraving Font</td>
                    <td>{state?.pendingData?.engraving?.engraving_text_font ?? "--"}</td>
                </tr>
                <tr>
                    <td>Engraving Remarks</td>
                    <td>{state?.pendingData?.engraving?.engraving_remarks ?? "--"}</td>
                </tr>
                <tr>
                  <td>Engraving Image</td>
                  <td>
                  <Image
            width={20}
            height={20}
            src={state?.pendingData?.engraving?.engraving_logo ?? state?.pendingData?.engraving?.engraving_logo_name}
            alt={`magazine - 01`}
          />
                    </td>
                   
                  </tr>
          </tbody>
        </table>
      </div>
    );
  };
  
  export default Engraving;
  