import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateConfig } from "../../../../store/slices/Global";
import { departmentsList, logout } from "./api";
import { toast } from "react-toastify";

const useHeaderActions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchInputRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const globalState = useSelector((state) => state.global);

  const localItem = localStorage
    ?.getItem("slug")
    ?.toUpperCase()
    ?.replace(/[^a-zA-Z]/g, " ", /\s/g, " ", /[0-9]/g, " ");

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "k") {
        event.preventDefault();
        setIsModalOpen(true);
        focusSearchInput();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const focusSearchInput = () => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    dispatch(
      updateConfig((state) => {
        state.searchKey = "";
        state.searchData = {};
      })
    );
  };

  const handleLogout = () => {
    handleLogoutClick();
    logout().then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Logout Success");
        localStorage?.clear("USER_ACCESS_TOKEN");
        localStorage?.clear("dashboard_permissions");
        localStorage?.clear("slug");
        navigate("/");
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const handleLogoutClick = () => {
    dispatch(updateConfig((state) => (state.logoutModal = !state.logoutModal)));
  };

  const handleSettings = () => {
    // navigate("/settings/appearance");
    // toast.warning("coming soon");

    departmentsList().then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => (state.formData = response?.data?.data))
        );
      }
    });
    dispatch(
      updateConfig((state) => {
        state.showSettingsModal = !state.showSettingsModal;
      })
    );
  };

  return {
    isModalOpen,
    searchInputRef,
    globalState,
    localItem,
    setIsModalOpen,
    closeModal,
    handleLogout,
    handleSettings,
    handleLogoutClick,
  };
};

export default useHeaderActions;
