import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";
import { updateConfig } from "../../../../store/slices/Printing/printingSlice";
import { useGetPrintingTableDataQuery } from "../../../../store/queries/Printing";

const usePrintingTable = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "15 Per page", value: 15 },
    { label: "20 Per page", value: 20 },
  ];
  const dispatch = useDispatch();
  const state = useSelector((state) => state.printing);
  const { tableFields } = useSelector((state) => state.global);
  const slug = localStorage?.getItem("slug");
  const {
    data: tableData,
    isFetching,
    isLoading,
  } = useGetPrintingTableDataQuery({
    receipt_id: state?.selectedId,
    search: state.search,
    page_size: state.currentPageSize,
    page: state.currentPage,
    process_slug: slug,
  });

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = tableData?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [tableData]);

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePrintClick = (items) => {
    dispatch(
      updateConfig((state) => {
        state.printModal = true;
        state.selectedItems = items;
      })
    );
  };

  return {
    isFetching,
    isLoading,
    tableData,
    tableFields,
    paginationOptions,
    getRow,
    handlePageSize,
    handlePagination,
    handlePrintClick,
  };
};

export default usePrintingTable;
