import { getAxiosInstance } from "../../../../api";

export const logout = async () => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/user/logout`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const addDepartments = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/user/change-department`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const departmentsList = async () => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/user/change-department-list`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
