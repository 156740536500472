import { useFormik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { submitForm } from "../api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  getCVDCompletedRecheckList,
  getCVDPendingRecheckList,
  updateConfig,
} from "../../../../store/slices/CVD/CVDSlice";

const useForm2 = () => {
  const dispatch = useDispatch();
  const { certificateID, assignedID } = useSelector((state) => state.cvd);
  const validation = Yup.object({
    rft_weight: Yup.string()
      .required("This field is required")
      .matches(/^[0-9]*$/, "Enter valid number"),
    rft_d_no: Yup.string()
      .required("This field is required")
      .matches(/^[0-9]*$/, "Enter valid number"),
    rft_percentage: Yup.string()
      .required("This field is required")
      .matches(/^\d+(\.\d{1,2})?$/, "Enter a valid percentage")
      .test("max", "Enter a valid percentage", function (value) {
        if (!value) return true; // Let Yup.string().required handle the empty value case
        const numericValue = parseFloat(value);
        return numericValue <= 100;
      }),
    cvd_weight: Yup.string()
      .required("This field is required")
      .matches(/^[0-9]*$/, "Enter valid number"),
    cvd_d_no: Yup.string()
      .required("This field is required")
      .matches(/^[0-9]*$/, "Enter valid number"),
    cvd_percentage: Yup.string()
      .required("This field is required")
      .matches(/^\d+(\.\d{1,2})?$/, "Enter a valid percentage")
      .test("max", "Enter a valid percentage", function (value) {
        if (!value) return true; // Let Yup.string().required handle the empty value case
        const numericValue = parseFloat(value);
        return numericValue <= 100;
      }),
    htpt_weight: Yup.string()
      .required("This field is required")
      .matches(/^[0-9]*$/, "Enter valid number"),
    htpt_d_no: Yup.string()
      .required("This field is required")
      .matches(/^[0-9]*$/, "Enter valid number"),
    htpt_percentage: Yup.string()
      .required("This field is required")
      .matches(/^\d+(\.\d{1,2})?$/, "Enter a valid percentage")
      .test("max", "Enter a valid percentage", function (value) {
        if (!value) return true; // Let Yup.string().required handle the empty value case
        const numericValue = parseFloat(value);
        return numericValue <= 100;
      }),
    cvd_pass_weight: Yup.string()
      .required("This field is required")
      .matches(/^[0-9]*$/, "Enter valid number"),
    cvd_pass_d_no: Yup.string()
      .required("This field is required")
      .matches(/^[0-9]*$/, "Enter valid number"),
    cvd_pass_percentage: Yup.string()
      .required("This field is required")
      .matches(/^\d+(\.\d{1,2})?$/, "Enter a valid percentage")
      .test("max", "Enter a valid percentage", function (value) {
        if (!value) return true; // Let Yup.string().required handle the empty value case
        const numericValue = parseFloat(value);
        return numericValue <= 100;
      }),
  });
  const initialValues = {
    rft_weight: "",
    rft_d_no: "",
    rft_percentage: "",
    cvd_weight: "",
    cvd_d_no: "",
    cvd_percentage: "",
    htpt_weight: "",
    htpt_d_no: "",
    htpt_percentage: "",
    cvd_pass_weight: "",
    cvd_pass_d_no: "",
    cvd_pass_percentage: "",
  };

  const formik = useFormik({
    validationSchema: validation,
    initialValues,
    onSubmit: (values) => {
      let formData = new FormData();
      formData.append("recheck_item_details_id", certificateID);
      formData.append("cvd[weight]", values?.cvd_weight);
      formData.append("cvd[diamond_number]", values?.cvd_d_no);
      formData.append("cvd[percentage]", values?.cvd_percentage);
      formData.append("cvd_pass[weight]", values?.cvd_pass_weight);
      formData.append("cvd_pass[diamond_number]", values?.cvd_pass_d_no);
      formData.append("cvd_pass[percentage]", values?.cvd_pass_percentage);
      formData.append("hpht[weight]", values?.htpt_weight);
      formData.append("hpht[diamond_number]", values?.htpt_d_no);
      formData.append("hpht[percentage]", values?.htpt_percentage);
      formData.append("rft[weight]", values?.rft_weight);
      formData.append("rft[diamond_number]", values?.rft_d_no);
      formData.append("rft[percentage]", values?.rft_percentage);
      submitForm(formData).then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Sucessfully submitted");
          dispatch(updateConfig((state) => (state.showForm = false)));
          dispatch(
            getCVDPendingRecheckList({
              page: 1,
              per_page: 10,
              receipt_id: assignedID,
            })
          );
          dispatch(
            getCVDCompletedRecheckList({
              page: 1,
              per_page: 10,
              receipt_id: assignedID,
            })
          );
        } else {
          toast.error("Something went wrong");
        }
      });
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  return { formik, getFieldError };
};

export default useForm2;
