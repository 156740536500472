import React, { useMemo } from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useSelector } from "react-redux";

const useMenus = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const handleSetSlug = (slug) => {
    localStorage?.setItem("slug", slug);
  };
  const drawerMenu = [
    {
      title: "Employee Dashboard",
      label: "Employee Dashboard",
      link: "/master/dashboard",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">bar_chart_4_bars</span>,
      active:
        checkIfActiveRoute("/dashboard", true) ||
        checkIfActiveRoute("/master/dashboard", true),
    },
    {
      title: "Work Dashboard",
      label: "Work Dashboard",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">bar_chart_4_bars</span>,
      active: checkIfActiveRoute("/overview"),
      subMenu: [
        {
          title: "Orders",
          link: "/overview",
          active: checkIfActiveRoute("/overview", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Recheck",
          link: "/overview/recheck",
          active: checkIfActiveRoute("/overview/recheck", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
      ],
    },
    {
      title: "Stock Receipt",
      label: "Stock Receipt",
      link: "/orders",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">inventory</span>,
      active: checkIfActiveRoute("/orders" || "/orders/recheck", true),
      subMenu: [
        {
          title: "Orders",
          link: "/orders",
          active: checkIfActiveRoute("/orders", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Recheck",
          link: "/orders/recheck",
          active: checkIfActiveRoute("/orders/recheck", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
      ],
    },
    {
      title: "Verification",
      label: "Verification",
      link: "/verification",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">new_releases</span>,
      active: checkIfActiveRoute("/verification"),
    },
    {
      title: "QC",
      label: "QC",
      link: "/qc",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">inventory</span>,
      active: checkIfActiveRoute("/orders/qc"),
      handleClick: () => handleSetSlug("qc"),
      subMenu: [
        {
          title: "Orders",
          link: "/orders/qc",
          active: checkIfActiveRoute("/orders/qc", true),
          handleClick: () => handleSetSlug("qc"),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Recheck",
          link: "/orders/qc-recheck",
          active: checkIfActiveRoute("/orders/qc-recheck"),
          handleClick: () => handleSetSlug("qc"),
          icon: <span className={`sub-icon`}>SR</span>,
        },
      ],
    },
    {
      title: "Metal QC",
      label: "Metal QC",
      link: "/orders",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">done_all</span>,
      active: checkIfActiveRoute("/orders/metal-qc"),
      handleClick: () => handleSetSlug("metal_qc"),
      subMenu: [
        {
          title: "Orders",
          link: "/orders/metal-qc",
          active: checkIfActiveRoute("/orders/metal-qc", true),
          handleClick: () => handleSetSlug("metal_qc"),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Recheck",
          link: "/orders/metal-qc-recheck",
          active: checkIfActiveRoute("/orders/metal-qc-recheck"),
          handleClick: () => handleSetSlug("metal_qc"),
          icon: <span className={`sub-icon`}>SR</span>,
        },
      ],
    },
    {
      title: "Old Gold QC",
      label: "Old Gold QC",
      link: "/qc",
      iconAsset: "DrawerIcon1",

      icon: <span className="material-symbols-outlined">check_circle</span>,
      active: checkIfActiveRoute("/orders/old_gold_qc"),
      handleClick: () => handleSetSlug("og_qc"),
      subMenu: [
        {
          title: "Orders",
          link: "/orders/old-gold-qc",
          active: checkIfActiveRoute("/orders/old-gold-qc", true),
          handleClick: () => handleSetSlug("og_qc"),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Recheck",
          link: "/orders/old-gold-qc-recheck",
          active: checkIfActiveRoute("/orders/old-gold-qc-recheck"),
          handleClick: () => handleSetSlug("og_qc"),
          icon: <span className={`sub-icon`}>SR</span>,
        },
      ],
    },
    {
      title: "Final QC",
      label: "Final QC",
      link: "/qc",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">fact_check</span>,
      active: checkIfActiveRoute("/orders/final-qc"),
      handleClick: () => handleSetSlug("final_qc"),
      subMenu: [
        {
          title: "Orders",
          link: "/orders/final-qc",
          active: checkIfActiveRoute("/orders/final-qc", true),
          handleClick: () => handleSetSlug("final_qc"),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Recheck",
          link: "/orders/final-qc-recheck",
          active: checkIfActiveRoute("/orders/final-qc-recheck"),
          handleClick: () => handleSetSlug("final_qc"),
          icon: <span className={`sub-icon`}>SR</span>,
        },
      ],
    },
    {
      title: "CVD/RFT",
      label: "CVD/RFT",
      link: "/cvd",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">verified_user</span>,
      active: checkIfActiveRoute("/cvd"),
      handleClick: () => handleSetSlug("cvd_rft"),
      subMenu: [
        {
          title: "Orders",
          link: "/orders/cvd",
          active: checkIfActiveRoute("/orders/cvd", true),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => handleSetSlug("cvd_rft"),
        },
        {
          title: "Recheck",
          link: "/orders/cvd-recheck",
          active: checkIfActiveRoute("/orders/cvd-recheck", true),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => handleSetSlug("cvd_rft"),
        },
      ],
    },
    {
      title: "Engraving",
      label: "Engraving",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">stylus_note</span>,
      active: checkIfActiveRoute("/engraving"),
      handleClick: () => handleSetSlug("engraving"),
      subMenu: [
        {
          title: "Orders",
          link: "/orders/engraving",
          active: checkIfActiveRoute("orders/engraving", true),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => handleSetSlug("engraving"),
        },
        {
          title: "Recheck",
          link: "/orders/engraving-recheck",
          active: checkIfActiveRoute("/orders/engraving-recheck", true),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => handleSetSlug("engraving"),
        },
      ],
    },
    {
      title: "Photo Upload",
      label: "Photo Upload",
      iconAsset: "DrawerIcon1",
      icon: (
        <span className="material-symbols-outlined">photo_camera_back</span>
      ),
      active: checkIfActiveRoute("/photo-upload"),
      handleClick: () => handleSetSlug("photo_section"),
      subMenu: [
        {
          title: "Orders",
          link: "/orders/photo-upload",
          active: checkIfActiveRoute("/orders/photo-upload", true),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => handleSetSlug("photo_section"),
        },
        {
          title: "Recheck",
          link: "/orders/photo-upload-recheck",
          active: checkIfActiveRoute("/orders/photo-upload-recheck", true),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => handleSetSlug("photo_section"),
        },
      ],
    },
    {
      title: "Printing",
      label: "Printing",
      link: "/printing",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">print</span>,
      active: checkIfActiveRoute("/printing"),
      handleClick: () => handleSetSlug("printing"),
      subMenu: [
        {
          title: "Orders",
          link: "/orders/printing/printing-work",
          active: checkIfActiveRoute("/orders/printing", true),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => handleSetSlug("printing"),
        },
        {
          title: "Recheck",
          link: "/orders/printing-recheck/printing-work-recheck",
          active: checkIfActiveRoute("/orders/printing-recheck"),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => handleSetSlug("printing"),
        },
      ],
    },
    {
      title: "Packing",
      label: "Packing",
      link: "/packing",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">archive</span>,
      active: checkIfActiveRoute("/packing"),
      handleClick: () => handleSetSlug("packing"),
      subMenu: [
        {
          title: "Orders",
          link: "/orders/packing",
          active: checkIfActiveRoute("/orders/packing", true),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => handleSetSlug("packing"),
        },
        {
          title: "Recheck",
          link: "/orders/packing-recheck",
          active: checkIfActiveRoute("/orders/packing-recheck"),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => handleSetSlug("packing"),
        },
      ],
    },
    {
      title: "Billing",
      label: "Billing",
      link: "/billing",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">receipt_long</span>,
      active: checkIfActiveRoute("/billing"),
      subMenu: [
        {
          title: "Orders",
          link: "/billing",
          active: checkIfActiveRoute("/billing", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Recheck",
          link: "/billing",
          active: checkIfActiveRoute("/billing", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Invoice History",
          link: "/billing",
          active: checkIfActiveRoute("/billing", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
      ],
    },
    {
      title: "Delivery",
      label: "Delivery",
      link: "/delivery",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">local_shipping</span>,
      active: checkIfActiveRoute("/delivery"),
      handleClick: () => handleSetSlug("delivery"),
      subMenu: [
        {
          title: "Orders",
          link: "/orders/delivery/delivery-work",
          active: checkIfActiveRoute("/orders/delivery", true),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => handleSetSlug("delivery"),
        },
        {
          title: "Recheck",
          link: "/orders/delivery-recheck/delivery-work-recheck",
          active: checkIfActiveRoute(
            "/orders/delivery-recheck/delivery-work-recheck"
          ),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => handleSetSlug("delivery"),
        },
      ],
    },

    // {
    //   title: "Payment History",
    //   label: "Payment History",
    //   link: "/payment-history",
    //   iconAsset: "DrawerIcon1",
    //   icon: <span className="material-symbols-outlined">currency_rupee</span>,
    //   active: checkIfActiveRoute("/payment-history"),
    // },
    {
      title: "Masters",
      label: "Masters",
      iconAsset: "DrawerIcon1",
      icon: (
        <span className="material-symbols-outlined">admin_panel_settings</span>
      ),
      active: checkIfActiveRoute("/master"),
      subMenu: [
        {
          title: "Branches",
          link: "/master/branches",
          active: checkIfActiveRoute("/master/branches"),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Source",
          link: "/master/source",
          active: checkIfActiveRoute("/master/source"),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Grading Type",
          link: "/master/item-grading",
          active: checkIfActiveRoute("/master/item-grading", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Item",
          link: "/master/item",
          active: checkIfActiveRoute("/master/item", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Packing Type",
          link: "/master/packing",
          active: checkIfActiveRoute("/master/packing", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Process",
          link: "/master/process",
          active: checkIfActiveRoute("/master/process", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Services",
          link: "/master/service",
          active: checkIfActiveRoute("/master/service", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Item Types",
          link: "/master/item-type",
          active: checkIfActiveRoute("/master/item-type", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Departments",
          link: "/master/department",
          active: checkIfActiveRoute("/master/department", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Role",
          link: "/master/role",
          active: checkIfActiveRoute("/master/role", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Employee",
          link: "/master/employee",
          active: checkIfActiveRoute("/master/employee", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Supplier/Shop",
          link: "/master/shop",
          active: checkIfActiveRoute("/master/shop", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Certificate Prefix",
          link: "/master/certificate-prefix",
          active: checkIfActiveRoute("/master/certificate-prefix", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Engraving Types",
          link: "/master/engraving",
          active: checkIfActiveRoute("/master/engraving", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Report/Certificate",
          link: "/master/report",
          active: checkIfActiveRoute("/master/report", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Billing",
          link: "/master/billing",
          active: checkIfActiveRoute("/master/billing", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
      ],
    },
  ];
  const drawerMenuPermission = useMemo(() => {
    let menus = globalState.dashboard_permissions?.menu_permissions?.flatMap?.(
      (menu) => {
        return menu?.sub_menu_permissions
          ? [
              menu.menu_name,
              ...menu?.sub_menu_permissions?.map(
                (sub_menu) => sub_menu?.sub_menu_name
              ),
            ]
          : [menu.menu_name];
      }
    );
    return drawerMenu
      .filter((menu) => menus?.includes?.(menu.title))
      .map((menu) => {
        return {
          ...menu,
          subMenu: menu.subMenu?.filter?.((subMenu) =>
            menus?.includes?.(subMenu?.title)
          ),
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  return { drawerMenu: drawerMenuPermission };
};

export default useMenus;
