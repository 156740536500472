import React from "react";
import Style from "../../Employees/Packing/Packing.module.scss";
import {
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import GliCardListing from "../../Global/GliCardLIsting";
import usePacking from "./usePacking";
import AddStatus from "../../Global/AddStatus";

const EmployeePackingRecheck = () => {
  const {
    state,
    states,
    isLoading,
    showDetail,
    activeCard,
    totalLength,
    paginationOptions,
    disableMultiSelect,
    getRow,
    handleChange,
    handleSearch,
    setIsLoading,
    handleConfirm,
    handlePageSize,
    handleLoadMore,
    handlePagination,
    handleConfirmModal,
    setSubmissionStatus,
  } = usePacking();

  return (
    <>
      <div className="row pro-mb-4">
        <div className="col-auto">
          <SearchFilters
            showDateRange={false}
            onSearchInput={handleSearch}
            showFilters={true}
            showActions={false}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
          />
        </div>
      </div>
      <div className={Style.varifaication_main_wrap}>
        <div className="row">
          <div className={`col-xxl-2 col-3 ${Style.card_main_wrapper} `}>
            <div className={`pro-d-flex ${Style.card_flow_wrap}`}>
              {states === [] ? null : (
                <GliCardListing
                  data={states}
                  activeCard={activeCard}
                  handleChange={handleChange}
                  isLoading={isLoading}
                  totalLength={totalLength}
                  handleLoadMore={handleLoadMore}
                  setIsLoading={setIsLoading}
                />
              )}
            </div>
          </div>

          <div
            className={`col-xxl-10 col-9 pro-pe-0 ${Style.table_main_wrapper}`}
          >
            <div className={`${Style.table_flow_wrapper} pro-pe-3`}>
              {showDetail === true ? (
                <>
                  <div className={`row gx-3 ${Style.multicolumn_wrapper}`}>
                    <div className="col">
                      <Table
                        multiSelect={disableMultiSelect ? true : false}
                        data={state?.packingRecheckData.data || []}
                        uniqueID={"id"}
                        fields={state?.packingRecheckData?.fields}
                        showCheckBox={true}
                        getRow={getRow}
                        perpage={10}
                        assignable={true}
                        editable={false}
                        deletable={false}
                        assignText={"Submit"}
                        assignIcon={
                          <span className="material-symbols-outlined">
                            check_circle
                          </span>
                        }
                        handleAssign={handleConfirmModal}
                      />
                    </div>
                  </div>
                  <Pagination
                    currentPage={state?.packingRecheckData.current_page}
                    totalPageCount={Math.ceil(
                      state?.packingRecheckData.total_count /
                        state?.currentPageSize
                    )}
                    onPageChange={handlePagination}
                    options={paginationOptions}
                    onActionChange={handlePageSize}
                    center
                  />
                  <ModalLayout
                    show={state?.showAddPackingModal}
                    handleClose={handleConfirmModal}
                    title={"Choose status of submission?"}
                  >
                    <div className="pro-m-5">
                      <AddStatus
                        handleConfirm={handleConfirm}
                        handleConfirmModal={handleConfirmModal}
                        setSubmissionStatus={setSubmissionStatus}
                      />
                    </div>
                  </ModalLayout>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeePackingRecheck;
