import Style from "./orderCardListing.module.scss";
import OrderCard from "../OrderCard";
import { Button } from "@wac-ui-dashboard/wac_component_library";
import UseOrderCardListing from "./UseOrderCardListing";
import CardShimmer from "../CardShimmer";

const OrderCardListing = ({
  data,
  handleChange,
  isLoading,
  setIsLoading,
  hasButton,
  refBottom = false,
  handleStartClick,
  activeCard,
  extaClassName,
  handleLoadMore,
  totalLength,
}) => {
  const { scrollRefs, mouseHover, handleMouseOver } = UseOrderCardListing({
    data,
    handleLoadMore,
    isLoading,
    setIsLoading,
    totalLength,
  });
  
  return (
    <>
      {Object.keys(data ? data : {})?.map((key, mainIndex) => (
        <div
          key={mainIndex}
          className={`${Style.root_list} ${extaClassName} ${
            hasButton ? "pro-pb-0" : ""
          }`}
          ref={(ref) => !refBottom && (scrollRefs.current[key] = ref)}
          onMouseEnter={() => handleMouseOver(key)}
        >
          <h6 className="pro-ttl pro-mb-2 pro-font-regular">
            {key ?? "Title"}
          </h6>
          {/* <div className={`${Style.root_list_item}`}></div> */}
          <div
            className={`${Style.root_list_item}`}
            ref={(ref) => refBottom && (scrollRefs.current[key] = ref)}
          >
            {data[key] &&
              data[key]?.map((item, index) => (
                <OrderCard
                  onClick={() => handleChange(item, key, index)}
                  badgeText={item.priority ?? ""}
                  title={item?.certificate_number ?? item.gli_number ?? ""}
                  title2={item?.certificate_number ? item?.gli_number : ""}
                  subTitle={`${item.shop ?? ""}-${item.item_type ?? ""}`}
                  key={index}
                  activeCard={activeCard?.key === key && activeCard}
                  id={item._id}
                />
              ))}
            {mouseHover === key &&
              isLoading &&
              data?.[key]?.length !== totalLength?.[key] &&
              [...Array(2)]?.map((_, i) => <CardShimmer key={i} />)}
          </div>
          {data?.Assigned?.length > 0 && key === "Assigned" && hasButton && (
            <Button
              className={`pro-btn-primary lg pro-mt-3 ${Style.footer_btn}`}
              type="submit"
              onClick={handleStartClick}
            >
              Start Work
            </Button>
          )}
          {/* <div className={`${Style.root_list_item}`}></div> */}
        </div>
      ))}
    </>
  );
};

export default OrderCardListing;
