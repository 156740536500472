import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../store/slices/Master/Shop/shopSlice";
import { useSelector } from "react-redux";

const useContact = (formik) => {
  const dispatch = useDispatch();

  const { showPassword, isEdit } = useSelector((state) => state.shop);

  const handleAddItemClick = () => {
    const { items } = formik?.values;
    items.push({ item_type: "", image: "" });
    formik.setFieldValue("items", items);
  };

  const handleImageChange = (event, index) => {
    formik.setFieldValue(`items.${index}.image`, event?.target?.files[0]);
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      dispatch(
        updateConfig((state) => {
          state.logoPreview = reader.result;
        })
      );
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddShopModal = false;
      })
    );
  };
  const handleShowPassword = (e) => {
    e.preventDefault();
    dispatch(
      updateConfig((state) => {
        state.showPassword = !state.showPassword;
      })
    );
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  return {
    isEdit,
    showPassword,
    handleAddItemClick,
    handleImageChange,
    handleCloseModal,
    handleShowPassword,
  };
};

export default useContact;
