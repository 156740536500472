import { useSelector } from "react-redux";
import {
  useGetRoleListDataQuery,
  useUpdateRoleTableHeadDataMutation,
} from "../../../store/queries/Master";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Master/Role/roleSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const useRole = () => {
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const roleState = useSelector((state) => state.role);
  const {
    data: roleList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetRoleListDataQuery({
    page_size: roleState.currentPageSize,
    page: roleState.currentPage,
    sort_by: roleState.sortBy,
    sort_order: roleState.sortOrder,
    start: roleState.startDate,
    end: roleState.endDate,
    search: roleState.search,
  });

  const [updateRoleFields] = useUpdateRoleTableHeadDataMutation();

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = roleList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [roleList]);
  const handleCreateClick = (item) => {
    navigate("update");
    if (item) {
      navigate({
        pathname: "update",
        search: `role=${item._id}`,
      });
    } else {
      navigate("update");
    }
    dispatch(
      updateConfig((state) => {
        state.isEdit = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      branch: (feild, data) => (
        <img
          className=""
          alt="logo"
          src={data[feild]}
          style={{ height: "10px" }}
        />
      ),
      settings: (feild, data) => (
        <img
          className=""
          alt="logo"
          src={data[feild]}
          style={{ height: "10px" }}
        />
      ),
      source: (feild, data) => (
        <img
          className=""
          alt="logo"
          src={data[feild]}
          style={{ height: "10px" }}
        />
      ),
      supplier_and_shop: (feild, data) => (
        <img
          className=""
          alt="logo"
          src={data[feild]}
          style={{ height: "10px" }}
        />
      ),
      status: (field, data) =>
        data?.status === "Deleted" ? (
          <span className={"pro-badge badge-danger-outline"}>{"Deleted"}</span>
        ) : data?.status === "Active" ? (
          <span className={"pro-badge badge-success-outline"}>{"Active"}</span>
        ) : (
          data?.status
        ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleEditAction = (e) => {
    navigate({
      pathname: "update",
      search: `role=${e?.[0]}&update=true`,
    });
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (roleState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = roleState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  return {
    roleState,
    showEditModal,
    tableFields,
    roleList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleCreateClick,
    handleEditAction,
    handleSearch,
    updateRoleFields,
    handleClearClick,
    handlePageSize,
    refetch,
  };
};

export default useRole;
