import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 20,
  sortBy: "",
  sortOrder: "",
  search: "",
  selectedBilling: "",
  billingData: [],
  item_type: [],
  shop: [],
  status: [],
  date: [],
  from_date: "",
  to_date: "",
  estimateResponse: "",
  showAddInvoiceModal: false,
  selectedInvoice: "",
  invoiceData: [],
  invoicePrintData: [],
  estimateModal: false,
  editModal: false,
  selectedItems: [],
};
const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    resetConfig: () => initialState,
  },
});

export const { updateConfig, resetConfig } = billingSlice.actions;

export default billingSlice.reducer;
