import { useSelector } from "react-redux";
import {
  useGetAssignedTableDataQuery,
  useUpdateAssignedTableHeadDataMutation,
} from "../../../../store/queries/Staff";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";
import { updateConfig } from "../../../../store/slices/Staff/staffSlice";

const useAssignedTable = () => {
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];
  const dispatch = useDispatch();
  const staffState = useSelector((state) => state.staff);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const slug = localStorage?.getItem("slug");
  const {
    data: assignedTableData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetAssignedTableDataQuery({
    receipt_id: staffState.gliData?.id,
    search: staffState.assignedSearch,
    page_size: staffState.currentPageSize,
    page: staffState.currentPage,
    process_slug: slug,
  });
  const [updateAssignedFields] = useUpdateAssignedTableHeadDataMutation();

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = assignedTableData?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [assignedTableData]);

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => (state.assignedSearch = e?.target?.value))
    );
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  return {
    staffState,
    assignedTableData,
    isFetching,
    tableFields,
    paginationOptions,
    isLoading,
    showEditModal,
    getRow,
    refetch,
    handlePageSize,
    handlePagination,
    updateAssignedFields,
    handleEditColumnsClick,
    handleSearch,
  };
};

export default useAssignedTable;
