import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getStatusData } from "./api";

export const useAddStatus = ({ error }) => {
  const [statusData, setStatusData] = useState();
  useEffect(() => {
    getStatusData().then((response) => {
      setStatusData(response?.data?.data);
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      status: "",
    },
    validationSchema: Yup.object({
      status: Yup.string().required("Select status of submission"),
    }),
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  useEffect(() => {
    if (error) {
      formik?.setFieldTouched("status", true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  let statusObj = statusData?.filter(
    (item) => item?.id === formik?.values?.status
  );
  return { formik, statusData, statusObj, getFieldError };
};

export default useAddStatus;
