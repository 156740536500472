import {
  ModalLayout,
  SearchFilters,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import usePrinting from "./usePrinting";
import OrderCardListing from "../../Global/OrderCardListing";
import Style from "./printing.module.scss";
import PrintingTable from "./PrintingTable";
import PrintType from "./PrintType";
import Front from "./CertificateTypes/VisitingCardOne/Front";
import Back from "./CertificateTypes/VisitingCardOne/Back";
import PapperFront from "./CertificateTypes/PapperCard/PapperFront";
import PapperBack from "./CertificateTypes/PapperCard/PapperBack";
import BigFront from "./CertificateTypes/BigCard/BigFront";
import Assets from "../../../assets/Assets";
import PaymentReciept from "./PaymentReciept";
import DeliveryChallanPrint from "./DeliveryChallanPrint";
import RoughEstimate from "./RoughEstimate";
import DiamondJewellery from "./DiamondJewellery";

const Printing = () => {
  const {
    lastCard,
    cardData,
    isLoading,
    activeCard,
    totalLength,
    printingState,
    bigFrontPrintRef,
    papperBackPrintRef,
    papperFrontPrintRef,
    visitingOneBackPrintRef,
    visitingOneFrontPrintRef,
    setIsLoading,
    handleSearch,
    handleLoadMore,
    handleCardClick,
    handleModalClick,
  } = usePrinting();

  return (
    <>
      <div className={Style.varifaication_main_wrap}>
        <div className="row">
          <div className={`col-4 ${Style.card_main_wrapper}`}>
            <div className="row pro-mb-4 gx-2">
              <div className="col-auto">
                <SearchFilters
                  showDateRange={false}
                  onSearchInput={handleSearch}
                  showFilters={true}
                  showActions={false}
                  SearchIcon={
                    <span className="material-symbols-outlined"> search </span>
                  }
                />
              </div>
            </div>
            <div className={`pro-d-flex ${Style.card_flow_wrap}`}>
              <OrderCardListing
                isLoading={isLoading}
                data={cardData}
                lastCard={lastCard}
                handleChange={handleCardClick}
                handleLoadMore={handleLoadMore}
                setIsLoading={setIsLoading}
                activeCard={activeCard}
                totalLength={totalLength}
                extaClassName={Style.print_list}
              />
            </div>
          </div>
          {printingState?.showTable ? (
            <div className={`col-8 pro-pe-0 ${Style.table_main_wrapper}`}>
              <div className={`${Style.table_flow_wrapper}`}>
                <h6 className="pro-ttl h6">{printingState?.gli_id}</h6>
                <PrintingTable />
              </div>
            </div>
          ) : (
            <div className={`col-8 pro-pe-0 ${Style.table_main_wrapper}`}>
              <div className={`${Style.table_flow_wrapper}`}>
                {/* <h6 className="pro-ttl h6">Select one</h6> */}
              </div>
            </div>
          )}
          <ModalLayout
            show={printingState?.printModal}
            handleClose={handleModalClick}
            title={"Select Type"}
            closeIcon={<span className="material-symbols-outlined">close</span>}
          >
            <div className="pro-m-5">
              <PrintType
                visitingOneFrontPrintRef={visitingOneFrontPrintRef}
                visitingOneBackPrintRef={visitingOneBackPrintRef}
                papperFrontPrintRef={papperFrontPrintRef}
                papperBackPrintRef={papperBackPrintRef}
                bigFrontPrintRef={bigFrontPrintRef}
              />
            </div>
          </ModalLayout>

          {/* <ModalLayout show={false}>
            <div className="pro-p-4">
              <PaymentReciept />
            </div>
          </ModalLayout>

          <ModalLayout show={false}>
            <div className="pro-p-4">
              <DeliveryChallanPrint />
            </div>
          </ModalLayout>

          <ModalLayout show={false}>
            <div className="pro-p-4">
              <RoughEstimate />
            </div>
          </ModalLayout> */}

          {/* <ModalLayout show={false}>
            <div className="pro-p-4">
              <DiamondJewellery />
            </div>
          </ModalLayout> */}

          <div className={Style.pdf_container}>
            <Front visitingOneFrontPrintRef={visitingOneFrontPrintRef} />
            <Back visitingOneBackPrintRef={visitingOneBackPrintRef} />
            <PapperFront papperFrontPrintRef={papperFrontPrintRef} />
            <PapperBack papperBackPrintRef={papperBackPrintRef} />
            <BigFront bigFrontPrintRef={bigFrontPrintRef} />
          </div>
          {/* <ModalLayout show={false}>
            <div className="pro-p-5 pro-px-4">
              <table
                border={0}
                cellSpacing={0}
                role="presentation"
                cellPadding={0}
                style={{
                  backgroundColor: "#ffffff",
                  maxWidth: "321px",
                  width: "100%",
                  borderCollapse: "collapse",
                  margin: "0 auto",
                  // border: "1px solid #000000",
                  fontSize: 6,
                }}
              >
                <tbody>
                  <tr>
                    <td>
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td style={{ verticalAlign: "top" }}>
                              <table
                                border={0}
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                style={{
                                  width: "100%",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        width: 24,
                                        height: 24,
                                      }}
                                    >
                                      <img
                                        src={Assets.PRINT_LOGO}
                                        width={24}
                                        height={24}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td
                              style={{
                                width: 99,
                                verticalAlign: "top",
                              }}
                            >
                              <table
                                border={0}
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                style={{
                                  width: "100%",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: 7,
                                        fontWeight: 500,
                                        backgroundColor: "#000000",
                                        padding: "2px 4px",
                                        color: "#ffffff",
                                        width: 99,
                                        textAlign: "center",
                                      }}
                                    >
                                      Reception Office Use Only
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td
                              style={{
                                width: 117,
                                paddingLeft: 21,
                                paddingBottom: 6,
                              }}
                            >
                              <table
                                border={0}
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                style={{
                                  width: "100%",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td>
                                      <table
                                        border={0}
                                        cellSpacing={0}
                                        role="presentation"
                                        cellPadding={0}
                                        style={{
                                          width: "100%",
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              style={{
                                                width: 40,
                                                height: 21,
                                                marginRight: 6,
                                              }}
                                            >
                                              <img
                                                src={Assets.URGENT_IMG}
                                                width={40}
                                                height={21}
                                              />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td>
                                      <table
                                        border={0}
                                        cellSpacing={0}
                                        role="presentation"
                                        cellPadding={0}
                                        style={{
                                          width: "100%",
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td>
                                              <table
                                                border={0}
                                                cellSpacing={0}
                                                role="presentation"
                                                cellPadding={0}
                                                style={{
                                                  width: "100%",
                                                }}
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style={{
                                                        fontSize: 6,
                                                        fontWeight: 500,
                                                        paddingBottom: 2,
                                                      }}
                                                    >
                                                      R.No
                                                    </td>
                                                    <td
                                                      style={{
                                                        fontSize: 6,
                                                        fontWeight: 500,
                                                        width: 6,
                                                        padding:
                                                          "0px 2px 2px 0px",
                                                      }}
                                                    >
                                                      :
                                                    </td>
                                                    <td
                                                      style={{
                                                        fontSize: 6,
                                                        fontWeight: 600,
                                                        paddingBottom: 2,
                                                      }}
                                                    >
                                                      {" "}
                                                      1/TR/23/1
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td
                                                      style={{
                                                        fontSize: 5,
                                                        fontWeight: 400,
                                                      }}
                                                    >
                                                      Date
                                                    </td>
                                                    <td
                                                      style={{
                                                        fontSize: 5,
                                                        fontWeight: 500,
                                                        width: 6,
                                                        padding: "0px 2px",
                                                      }}
                                                    >
                                                      :
                                                    </td>
                                                    <td
                                                      style={{
                                                        fontSize: 5,
                                                        fontWeight: 500,
                                                      }}
                                                    >
                                                      {" "}
                                                      02.06.2023
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td
                                                      style={{
                                                        fontSize: 5,
                                                        fontWeight: 400,
                                                      }}
                                                    >
                                                      Time
                                                    </td>
                                                    <td
                                                      style={{
                                                        fontSize: 5,
                                                        fontWeight: 500,
                                                        width: 6,
                                                        padding: "0px 2px",
                                                      }}
                                                    >
                                                      :
                                                    </td>
                                                    <td
                                                      style={{
                                                        fontSize: 5,
                                                        fontWeight: 500,
                                                      }}
                                                    >
                                                      {" "}
                                                      11:32 AM
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        padding: " 2px 0",
                      }}
                    >
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                paddingRight: 12,
                                verticalAlign: "bottom",
                              }}
                            >
                              <table
                                border={0}
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                style={{
                                  width: "100%",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        width: 32,
                                      }}
                                    >
                                      Supplier
                                    </td>
                                    <td
                                      style={{ width: 6, padding: "0px 2px" }}
                                    >
                                      :
                                    </td>
                                    <td
                                      style={{
                                        fontWeight: 600,
                                        borderBottom: "0.5px dashed #000000",
                                      }}
                                    >
                                      Tyohar LLP
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td
                              style={{
                                width: 117,
                              }}
                            >
                              <table
                                border={0}
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                style={{
                                  width: "100%",

                                  border: "1px solid #000000",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        fontWeight: 500,
                                        padding: 2,
                                      }}
                                    >
                                      Cert.No
                                    </td>
                                    <td
                                      style={{
                                        fontWeight: 500,
                                        width: 6,
                                        padding: 2,
                                      }}
                                    >
                                      :
                                    </td>
                                    <td
                                      style={{
                                        fontWeight: 600,
                                        paddingRight: 2,
                                      }}
                                    >
                                      KJTIGW00100 - KJTIGW00300
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        padding: "2px 0",
                      }}
                    >
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                width: 32,
                              }}
                            >
                              Shop
                            </td>
                            <td
                              style={{
                                width: 6,
                                padding: "0px 2px",
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                borderBottom: "0.5px dashed #000000",
                                fontWeight: 600,
                              }}
                            >
                              Kalyan Jewellers, Ernakulam
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                width: "50%",
                                padding: "2px 0",
                                paddingRight: 5,
                              }}
                            >
                              <table
                                border={0}
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                style={{
                                  width: "100%",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        width: 38,
                                      }}
                                    >
                                      Item Category
                                    </td>
                                    <td
                                      style={{
                                        width: 6,
                                        padding: "0px 2px",
                                      }}
                                    >
                                      :
                                    </td>
                                    <td
                                      style={{
                                        fontWeight: 600,
                                      }}
                                    >
                                      Diamond Jewellery
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td
                              style={{
                                width: "50%",
                                padding: "2px 0",
                                paddingLeft: 5,
                              }}
                            >
                              <table
                                border={0}
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                style={{
                                  width: "100%",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        width: 20,
                                      }}
                                    >
                                      Service
                                    </td>
                                    <td
                                      style={{
                                        width: 6,
                                        padding: "0px 2px",
                                      }}
                                    >
                                      :
                                    </td>
                                    <td
                                      style={{
                                        fontWeight: 600,
                                      }}
                                    >
                                      Certification / Engraving
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                padding: "2px 0",
                                paddingRight: 5,
                              }}
                            >
                              <table
                                border={0}
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                style={{
                                  width: "100%",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        paddingRight: 5,
                                      }}
                                    >
                                      <table
                                        border={0}
                                        cellSpacing={0}
                                        role="presentation"
                                        cellPadding={0}
                                        style={{
                                          width: "100%",
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              style={{
                                                width: 26,
                                              }}
                                            >
                                              Total PCs
                                            </td>
                                            <td
                                              style={{
                                                width: 6,
                                                padding: "0px 2px",
                                              }}
                                            >
                                              :
                                            </td>
                                            <td
                                              style={{
                                                fontWeight: 600,
                                              }}
                                            >
                                              200
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td
                                      style={{
                                        paddingLeft: 5,
                                      }}
                                    >
                                      <table
                                        border={0}
                                        cellSpacing={0}
                                        role="presentation"
                                        cellPadding={0}
                                        style={{
                                          width: "100%",
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              style={{
                                                width: 42,
                                              }}
                                            >
                                              DMD/Stone Wt
                                            </td>
                                            <td
                                              style={{
                                                width: 6,
                                                padding: "0px 2px",
                                              }}
                                            >
                                              :
                                            </td>
                                            <td
                                              style={{
                                                fontWeight: 600,
                                                borderBottom:
                                                  "0.5px dashed #000000",
                                              }}
                                            ></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td
                              style={{
                                padding: "2px 0",
                                paddingLeft: 5,
                              }}
                            >
                              <table
                                border={0}
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                style={{
                                  width: "100%",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        paddingRight: 5,
                                      }}
                                    >
                                      <table
                                        border={0}
                                        cellSpacing={0}
                                        role="presentation"
                                        cellPadding={0}
                                        style={{
                                          width: "100%",
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              style={{
                                                width: 20,
                                              }}
                                            >
                                              Box Wt
                                            </td>
                                            <td
                                              style={{
                                                width: 6,
                                                padding: "0px 2px",
                                              }}
                                            >
                                              :
                                            </td>
                                            <td
                                              style={{
                                                fontWeight: 600,
                                                borderBottom:
                                                  "0.5px dashed #000000",
                                              }}
                                            ></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td
                                      style={{
                                        paddingLeft: 5,
                                      }}
                                    >
                                      <table
                                        border={0}
                                        cellSpacing={0}
                                        role="presentation"
                                        cellPadding={0}
                                        style={{
                                          width: "100%",
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              style={{
                                                width: 52,
                                              }}
                                            >
                                              Changeable Stones
                                            </td>
                                            <td
                                              style={{
                                                width: 6,
                                                padding: "0px 2px",
                                              }}
                                            >
                                              :
                                            </td>
                                            <td
                                              style={{
                                                fontWeight: 600,
                                              }}
                                            >
                                              2
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        paddingTop: 8,
                        paddingBottom: 3,
                      }}
                    >
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                verticalAlign: "middle",
                                width: 135,
                              }}
                            >
                              <table
                                border={0}
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                style={{
                                  width: "100%",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        height: 0.5,
                                        backgroundColor: "#000000",
                                      }}
                                    ></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td
                              style={{
                                backgroundColor: "black",
                                color: "white",
                                padding: "2px 4px",
                                fontSize: 5,
                                fontWeight: 500,
                                textAlign: "center",
                              }}
                            >
                              Lab/Back Office
                            </td>
                            <td
                              style={{
                                verticalAlign: "middle",
                                width: 135,
                              }}
                            >
                              <table
                                border={0}
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                style={{
                                  width: "100%",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        height: 0.5,
                                        backgroundColor: "#000000",
                                      }}
                                    ></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                padding: " 2px 0",
                                width: "33.3%",
                              }}
                            >
                              <table
                                border={0}
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                style={{
                                  width: "100%",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        width: 20,
                                      }}
                                    >
                                      Passed
                                    </td>
                                    <td
                                      style={{
                                        width: 6,
                                        padding: "0px 2px",
                                      }}
                                    >
                                      :
                                    </td>
                                    <td
                                      style={{
                                        fontWeight: 600,
                                        borderBottom: ".05px dashed #000000",
                                      }}
                                    ></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td
                              style={{
                                padding: "2px 10px",
                                width: "33.3%",
                              }}
                            >
                              <table
                                border={0}
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                style={{
                                  width: "100%",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        width: 20,
                                      }}
                                    >
                                      Marked
                                    </td>
                                    <td
                                      style={{
                                        width: 6,
                                        padding: "0px 2px",
                                      }}
                                    >
                                      :
                                    </td>
                                    <td
                                      style={{
                                        fontWeight: 600,
                                        borderBottom: "0.5px dashed #000000",
                                      }}
                                    ></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td
                              style={{
                                padding: "2px 0",
                                width: "33.3%",
                              }}
                            >
                              <table
                                border={0}
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                style={{
                                  width: "100%",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        width: 20,
                                      }}
                                    >
                                      Rejected
                                    </td>
                                    <td
                                      style={{
                                        width: 6,
                                        padding: "0px 2px",
                                      }}
                                    >
                                      :
                                    </td>
                                    <td
                                      style={{
                                        fontWeight: 600,
                                        borderBottom: ".05px dashed #000000",
                                      }}
                                    ></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                padding: "2px 0",
                                width: "33.3%",
                              }}
                            >
                              <table
                                border={0}
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                style={{
                                  width: "100%",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td>
                                      <table
                                        border={0}
                                        cellSpacing={0}
                                        role="presentation"
                                        cellPadding={0}
                                        style={{
                                          width: "100%",
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              style={{
                                                width: 35,
                                              }}
                                            >
                                              Grade (DMD)
                                            </td>
                                            <td
                                              style={{
                                                width: 6,
                                                padding: "0px 2px",
                                              }}
                                            >
                                              :
                                            </td>
                                            <td
                                              style={{
                                                fontWeight: 600,
                                              }}
                                            >
                                              EF/VVS
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td
                              style={{
                                padding: "2px 10px",
                                width: "33.3%",
                              }}
                            >
                              <table
                                border={0}
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                style={{
                                  width: "100%",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td>
                                      <table
                                        border={0}
                                        cellSpacing={0}
                                        role="presentation"
                                        cellPadding={0}
                                        style={{
                                          width: "100%",
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              style={{
                                                width: 33,
                                              }}
                                            >
                                              Other (Gem)
                                            </td>
                                            <td
                                              style={{
                                                width: 6,
                                                padding: "0px 2px",
                                              }}
                                            >
                                              :
                                            </td>
                                            <td
                                              style={{
                                                fontWeight: 600,
                                              }}
                                            >
                                              Natural Ruby
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td
                              style={{
                                width: "33.3%",
                                padding: "2px 0",
                              }}
                            ></td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                width: "20%",
                                padding: "2px 0",
                              }}
                            >
                              <table
                                border={0}
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                style={{
                                  width: "100%",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        width: 15,
                                      }}
                                    >
                                      Color
                                    </td>
                                    <td
                                      style={{
                                        width: 6,
                                        padding: "0px 2px",
                                      }}
                                    >
                                      :
                                    </td>
                                    <td
                                      style={{
                                        fontWeight: 600,
                                        borderBottom: "0.5px dashed #000000",
                                      }}
                                    ></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td
                              style={{
                                width: "20%",
                                padding: "2px 8px",
                              }}
                            >
                              <table
                                border={0}
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                style={{
                                  width: "100%",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        width: 17,
                                      }}
                                    >
                                      Clarity
                                    </td>
                                    <td
                                      style={{
                                        width: 6,
                                        padding: "0px 2px",
                                      }}
                                    >
                                      :
                                    </td>
                                    <td
                                      style={{
                                        fontWeight: 600,
                                        borderBottom: "0.5px dashed #000000",
                                      }}
                                    ></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td
                              style={{
                                width: "20%",
                                padding: "2px 0",
                              }}
                            >
                              <table
                                border={0}
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                style={{
                                  width: "100%",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        width: 10,
                                      }}
                                    >
                                      Cut
                                    </td>
                                    <td
                                      style={{
                                        width: 6,
                                        padding: "0px 2px",
                                      }}
                                    >
                                      :
                                    </td>
                                    <td
                                      style={{
                                        fontWeight: 600,
                                        borderBottom: "0.5px dashed #000000",
                                      }}
                                    ></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td
                              style={{
                                width: "20%",
                                padding: "2px 8px",
                              }}
                            >
                              <table
                                border={0}
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                style={{
                                  width: "100%",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        width: 16,
                                      }}
                                    >
                                      Finish
                                    </td>
                                    <td
                                      style={{
                                        width: 6,
                                        padding: "0px 2px",
                                      }}
                                    >
                                      :
                                    </td>
                                    <td
                                      style={{
                                        fontWeight: 600,
                                        borderBottom: "0.5px dashed #000000",
                                      }}
                                    ></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td
                              style={{
                                width: "20%",
                                padding: "2px 0",
                              }}
                            >
                              <table
                                border={0}
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                style={{
                                  width: "100%",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        width: 11,
                                      }}
                                    >
                                      RFT
                                    </td>
                                    <td
                                      style={{
                                        width: 6,
                                        padding: "0px 2px",
                                      }}
                                    >
                                      :
                                    </td>
                                    <td
                                      style={{
                                        fontWeight: 600,
                                        borderBottom: "0.5px dashed #000000",
                                      }}
                                    ></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        padding: "2px 0",
                      }}
                    >
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                width: 27,
                              }}
                            >
                              Engraving
                            </td>
                            <td
                              style={{
                                width: 6,
                                padding: "0px 2px",
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                              }}
                            >
                              CERT No./STYLE No./G.Wt
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                paddingRight: 12,
                                verticalAlign: "bottom",
                              }}
                            >
                              <table
                                border={0}
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                style={{
                                  width: "100%",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        width: 24,
                                      }}
                                    >
                                      Remarks
                                    </td>
                                    <td
                                      style={{
                                        width: 6,
                                        padding: "0px 2px",
                                      }}
                                    >
                                      :
                                    </td>
                                    <td
                                      style={{
                                        fontWeight: 600,
                                        borderBottom: "0.5px dashed #000000",
                                      }}
                                    ></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td
                              style={{
                                width: 27,
                                height: 16,
                                padding: "2px 4px",
                                border: "0.2px dashed #D0DAE4",
                                borderRadius: "1px",
                              }}
                            >
                              <img
                                src={Assets.PRINT_DIAMOND}
                                width={19}
                                height={12}
                                style={{
                                  width: 19,
                                  height: 12,
                                }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ModalLayout> */}
        </div>
      </div>
    </>
  );
};

export default Printing;
