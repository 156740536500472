import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getMyQueueList,
  getPackingsList,
  updateConfig,
} from "../../../store/slices/Packings/packingsSlice";
import { approvePacking } from "../../Orders/Order/api";
import { toast } from "react-toastify";

const usePacking = () => {
  const state = useSelector((state) => state.packings);
  const states = state?.myQueueList;
  const [status, setSubmissionStatus] = useState();
  const [showDetail, setShowDetail] = useState(false);
  const [detailId, setDetailId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pendingPage, setPendingPage] = useState(1);
  const [activeCard, setActiveCard] = useState({ id: "", key: "" });
  const dispatch = useDispatch();
  const slug = localStorage?.getItem("slug");
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];
  const disableMultiSelect = useMemo(
    () => state.packingData?.data?.every((item) => item.disable === true),
    //eslint-disable-next-line
    []
  );
  useEffect(() => {
    dispatch(
      getMyQueueList({
        page: pendingPage,
        process_slug: slug,
      })
    );
    //eslint-disable-next-line
  }, [pendingPage, slug]);
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handleChange = (id) => {
    setActiveCard({ id: id, key: "Pending" });
    setDetailId(id);
    setShowDetail(true);
    if (id) {
      dispatch(getPackingsList({ id, slug }));
    }
  };

  const handleSearch = (e) => {
    dispatch(getMyQueueList({ search: e.target.value, process_slug: slug }));
  };
  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleConfirmModal = (e) => {
    let certificateCheck = [];
    dispatch(
      updateConfig((state) => {
        state.showAddPackingModal = !state.showAddPackingModal;
        certificateCheck.push(...[e]);
        state.selectedPacking = certificateCheck;
      })
    );
  };
  const handleConfirm = () => {
    let params = {
      item_details_id: state.selectedPacking[0],
      status: status,
    };
    approvePacking(params).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Packing status changed");
        dispatch(getPackingsList({ id: detailId, slug: slug }));
        handleConfirmModal();
      } else {
        toast.error("Failed changing packing status");
        handleConfirmModal();
      }
    });
  };

  const handleLoadMore = (mouseHover) => {
    if (
      mouseHover === "Pending" &&
      states?.Pending?.length !== state?.total?.Pending
    ) {
      setPendingPage(pendingPage + 1);
    }
  };

  return {
    state,
    states,
    showDetail,
    paginationOptions,
    disableMultiSelect,
    isLoading,
    totalLength: {
      Pending: state.total.Pending,
    },
    activeCard,
    getRow,
    handleSearch,
    setIsLoading,
    handleChange,
    handlePageSize,
    handleLoadMore,
    handlePagination,
    handleConfirmModal,
    handleConfirm,
    setSubmissionStatus,
  };
};

export default usePacking;
