import { getAxiosInstance } from "../../api";

export const getEstimateList = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`/back-office-billing/create-bill`, params);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getSubmitList = async (body) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`/back-office-billing/certificate-list`, body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};
