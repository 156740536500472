import React from "react";
import useEngravingCertificate from "./useEngravingCertificate";
import {
  ModalLayout,
  Pagination,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import AddStatus from "../../../Global/AddStatus";
import { ProfileCard } from "./ProfileCard";

const EngravingCertificate = ({
  gliNumber,
  certificateList,
  setCertificateList,
}) => {
  const {
    paginationOptions,
    disableMultiSelect,
    engravingSectionState,
    showImageModal,
    imageData,
    handleDownload,
    closeModal,
    getRow,
    handleConfirm,
    handlePageSize,
    handlePagination,
    handleConfirmModal,
    setSubmissionStatus,
  } = useEngravingCertificate(setCertificateList, certificateList);

  return (
    <>
      <h5 className="pro-ttl h5 pro-mb-2">{gliNumber}</h5>
      <Table
        multiSelect={disableMultiSelect ? true : false}
        data={certificateList?.data?.data || []}
        uniqueID={"id"}
        fields={certificateList?.data?.fields || []}
        showCheckBox={true}
        SortIcon={<FaSort />}
        getRow={getRow}
        perpage={10}
        assignable={true}
        editable={false}
        deletable={false}
        assignText={"Submit"}
        assignIcon={
          <span className="material-symbols-outlined">check_circle</span>
        }
        handleAssign={handleConfirmModal}
      />

      <ModalLayout show={showImageModal} handleClose={closeModal}>
        <ProfileCard
          data={imageData}
          handleClose={closeModal}
          handleDownload={handleDownload}
        />
      </ModalLayout>
      <ModalLayout
        show={engravingSectionState?.showAddEngravingSecModal}
        handleClose={handleConfirmModal}
        title={"Choose status of submission?"}
      >
        <div className="pro-m-5">
          <AddStatus
            handleConfirm={handleConfirm}
            handleConfirmModal={handleConfirmModal}
            setSubmissionStatus={setSubmissionStatus}
          />
        </div>
      </ModalLayout>
      <Pagination
        currentPage={certificateList?.data?.current_page}
        totalPageCount={Math.ceil(
          certificateList?.data?.total_count / certificateList?.data?.per_page
        )}
        onPageChange={handlePagination}
        options={paginationOptions}
        onActionChange={handlePageSize}
        center
      />
    </>
  );
};

export default EngravingCertificate;
