import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  pendingCardStatus: "idle",
  cardData: {
    Pending: [],
  },
  cardRecheckData: {
    Pending: [],
  },
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  search: "",
  showTable: false,
  selectedId: "",
  total: {
    Pending: "",
  },
  gli_id: "",
  tableData: [],
  printModal: false,
  selectedItems: [],
  printingData: [],
  printUI: false,
};

export const getPrintingList = createAsyncThunk(
  "card/assigned-list",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/order/pending-queue`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getPrintingRecheckList = createAsyncThunk(
  `/employee-dashboard/recheck/assigned-to-me`,
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/recheck/pending-queue`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const PrintingSlice = createSlice({
  name: "printing",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    // pending list
    //
    [getPrintingList.pending]: (state, action) => {
      state.pendingCardStatus = "pending";
    },
    [getPrintingList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedPending = [];
      // Add existing items with unique _id values to uniqueItems
      state.cardData.Pending.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedPending.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedPending.push(item);
        }
      });
      state.cardData.Pending = combinedPending;
      state.total.Pending = action.payload.data.data.total_count;
      state.pendingCardStatus = "fulfilled";
    },
    [getPrintingList.rejected]: (state, action) => {
      state.pendingCardStatus = "failed";
    },

    //recheck list
    [getPrintingRecheckList.pending]: (state, action) => {
      state.pendingCardStatus = "pending";
    },
    [getPrintingRecheckList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedPending = [];
      // Add existing items with unique _id values to uniqueItems
      state.cardRecheckData.Pending.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedPending.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedPending.push(item);
        }
      });
      state.cardRecheckData.Pending = combinedPending;
      state.total.Pending = action.payload.data.data.total_count;
      state.pendingCardStatus = "fulfilled";
    },
    [getPrintingRecheckList.rejected]: (state, action) => {
      state.pendingCardStatus = "failed";
    },
  },
});

export const { updateConfig } = PrintingSlice.actions;

export default PrintingSlice.reducer;
