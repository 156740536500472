import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  updateConfig,
  getDeliveryListData,
} from "../../../store/slices/Delivery/deliverySlice";
import { getDeliveryCertificate } from "./api";

const useDelivery = () => {
  const [pendingPage, setPendingPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const deliveryState = useSelector((state) => state.delivery);
  const deliveryData = deliveryState.deliverySectionData;
  const [showDetail, setShowDetail] = useState(false);
  const [gliNumber, setGliNumber] = useState("");
  const [certificateListData, setCertificateList] = useState();
  const [activeCard, setActiveCard] = useState({ id: "", key: "" });
  const slipRef = useRef(null);
  const dispatch = useDispatch();
  const slug = localStorage?.getItem("slug");
  useEffect(() => {
    let params = {
      page: pendingPage,
      per_page: deliveryState.currentPageSize,
      search: deliveryState.search,
      process_slug: slug,
    };
    dispatch(getDeliveryListData(params));
    // eslint-disable-next-line
  }, [pendingPage, deliveryState.currentPageSize, deliveryState.search, slug]);

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleChange = (item, key, index) => {
    dispatch(
      updateConfig((state) => {
        state.selectedCardId = item?._id;
      })
    );
    setGliNumber(item?.gli_number);
    setActiveCard({ id: item?._id, key: "Pending" });
    if (key === "Pending") {
      setShowDetail(true);
      getDeliveryCertificate(item?._id, slug).then((response) => {
        const certificateList = response?.data;
        setCertificateList(certificateList);
      });
    } else {
      setShowDetail(false);
      dispatch(
        updateConfig((state) => {
          state.receipt_id = item?._id;
        })
      );
    }
  };
  const handleLoadMore = (mouseHover) => {
    if (
      mouseHover === "Pending" &&
      deliveryData?.Pending?.length !== deliveryState?.total?.Pending
    ) {
      setPendingPage(pendingPage + 1);
    }
  };

  return {
    gliNumber,
    slipRef,
    showDetail,
    activeCard,
    deliveryData,
    isLoading,
    totalLength: {
      Pending: deliveryState.total.Pending,
    },
    certificateListData,
    handleSearch,
    handleChange,
    handleLoadMore,
    setIsLoading,
    setCertificateList,
  };
};

export default useDelivery;
