import {
  Button,
  Input,
  ModalLayout,
  SearchFilters,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import OrderCardListing from "../../Global/OrderCardListing";
import Style from "./qc.module.scss";
import useQC from "./useQC";
import CompareTab from "./CompareTab";
import AddStatus from "../../Global/AddStatus";

const QCRecheck = () => {
  const {
    qcDataRecheck,
    formik,
    showForm,
    showQcModal,
    activeCard,
    formData,
    showValues,
    itemID,
    isLoading,
    totalLength,
    handleCardClick,
    getFieldError,
    handleSearch,
    handleConfirm,
    setSubmissionStatus,
    handleConfirmModal,
    handleLoadMore,
    setIsLoading,
  } = useQC();
  return (
    <>
      <div className={Style.varifaication_main_wrap}>
        <div className="row">
          <div className={`col-xxl-5 col-5 pro-pt-4 ${Style.card_main_wrapper}`}>
          <div className="pro-mb-4">
          <SearchFilters
            showDateRange={false}
            onSearchInput={handleSearch}
            showFilters={true}
            showActions={false}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
          />
        </div>
            <div className={`pro-d-flex ${Style.card_flow_wrap}`}>
              <OrderCardListing
                data={qcDataRecheck}
                activeCard={activeCard}
                handleChange={handleCardClick}
                hasButton={false}
                isLoading={isLoading}
                totalLength={totalLength}
                setIsLoading={setIsLoading}
                handleLoadMore={handleLoadMore}
              />
            </div>
          </div>
          {showForm ? (
            <div className={`col pro-pt-4 ${Style.table_main_wrapper}`}>
              <h3>{itemID}</h3>
              {formData?.map((value, index) => {
                return (
                  <div key={index}>
                    <Input
                      label={value?.name}
                      type="text"
                      id={`${value?.slug}`}
                      name={`${value?.slug}`}
                      className={`pro-input lg ${
                        getFieldError(`${value?.slug}`) && " error"
                      }`}
                      onChange={(e) =>
                        formik?.setFieldValue(
                          `${value?.slug}`,
                          e?.target?.value
                        )
                      }
                      onBlur={formik?.handleBlur(`${value?.slug}`)}
                      error={getFieldError(value?.slug)}
                      errorMessage={getFieldError(value?.slug)}
                      value={formik?.values[value?.slug]}
                    />
                  </div>
                );
              })}
              <div
                className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn pro-py-4 ${Style.ftr_btn_wrap}`}
              >
                <Button
                  className={"pro-btn-primary lg pro-ms-3"}
                  type="submit"
                  onClick={formik.handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
          ) : showValues ? (
            // <div className="col">
            //   <h3>{itemID}</h3>
            //   {Object?.keys(allValues || {})?.map((key) => {
            //     return (
            //       <div Style={{ display: "flex" }}>
            //         <p>
            //           {key} : {allValues[key]}
            //         </p>
            //       </div>
            //     );
            //   })}
            // </div>
            // changes to compare tag to migrate qc and exel entry
            <div className={`col pro-ps-0 ${Style.compare_wrap}`}>
              <CompareTab />
            </div>
          ) : (
            <div className={`col ${Style.table_main_wrapper}`}>
            {/* <h3>Select One</h3> */}</div>
          )}
        </div>
      </div>
      <ModalLayout
        show={showQcModal}
        handleClose={handleConfirmModal}
        title={"Choose status of submission?"}
      >
        <div className="pro-m-5">
          <AddStatus
            handleConfirm={handleConfirm}
            handleConfirmModal={handleConfirmModal}
            setSubmissionStatus={setSubmissionStatus}
          />
        </div>
      </ModalLayout>
    </>
  );
};

export default QCRecheck;
