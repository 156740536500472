import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import appReducer from "./store";
import { master } from "./store/queries/Master";
import { orders } from "./store/queries/Orders";
import { verification } from "./store/queries/Verification";
import { staff } from "./store/queries/Staff";
import { recheckStaff } from "./store/queries/Recheck";
import { billing } from "./store/queries/Billing";
import { printing } from "./store/queries/Printing";

const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      master.middleware,
      orders.middleware,
      verification.middleware,
      staff.middleware,
      recheckStaff.middleware,
      billing.middleware,
      printing.middleware
    ),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
