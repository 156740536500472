import React from "react";
import Select from "react-select";
import useContact from "./useContact";
import { Button, Input } from "@wac-ui-dashboard/wac_component_library";

const Contact = ({ formik, getFieldError, checkError }) => {
  const {
    isEdit,
    countryCodeOptions,
    showPassword,
    handleCloseModal,
    handleShowPassword,
  } = useContact(formik);
  return (
    <div className="row">
      <div>
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Select Country
          </label>
          <Select
            id="country_code"
            options={countryCodeOptions}
            getOptionLabel={(option) => `${option.label} ${option.name}`}
            getOptionValue={(option) => `${option.value}`}
            className={`pro-input lg ${
              getFieldError("country_code") && " error"
            }`}
            value={countryCodeOptions?.filter(
              (p) =>
                p.value?.toString() === formik.values.country_code?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("country_code")}
            onChange={(value) =>
              formik.setFieldValue("country_code", value?.value)
            }
            menuPlacement="auto"
          ></Select>
          {getFieldError("country_code") && (
            <span className="error-text">{getFieldError("country_code")}</span>
          )}
        </div>
        <Input
          label={"Branch Contact Number 1"}
          type="text"
          id="contact_number_1"
          name="contact_number_1"
          className={`pro-input lg ${
            getFieldError("contact_number_1") && " error"
          }`}
          {...formik.getFieldProps("contact_number_1")}
          error={getFieldError("contact_number_1")}
          errorMessage={getFieldError("contact_number_1")}
        />
        <Input
          label={"Branch Contact Number 2"}
          type="text"
          id="contact_number_2"
          name="contact_number_2"
          className={`pro-input lg ${
            getFieldError("contact_number_2") && " error"
          }`}
          {...formik.getFieldProps("contact_number_2")}
          error={getFieldError("contact_number_2")}
          errorMessage={getFieldError("contact_number_2")}
        />
        <Input
          label={"Contact Person 1 Name "}
          type="text"
          id="person_1_name"
          name="person_1_name"
          className={`pro-input lg ${
            getFieldError("person_1_name") && " error"
          }`}
          {...formik.getFieldProps("person_1_name")}
          error={getFieldError("person_1_name")}
          errorMessage={getFieldError("person_1_name")}
        />
        <Input
          label={"Contact Person 1 Email "}
          type="text"
          id="person_1_email"
          name="person_1_email"
          className={`pro-input lg ${
            getFieldError("person_1_email") && " error"
          }`}
          {...formik.getFieldProps("person_1_email")}
          error={getFieldError("person_1_email")}
          errorMessage={getFieldError("person_1_email")}
        />
        <div className="input-wrap pro-mb-4 ">
          <div className="input-country-wrap ">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Contact Person 1 Phone
            </label>
            <div className="input-main-wrap verify-btn-wrap">
              <div className="code-wrap pro-mb-4 ">
                <Select
                  id="person_1_phone_code"
                  options={countryCodeOptions}
                  getOptionLabel={(option) => `${option.label}`}
                  getOptionValue={(option) => `${option.value}`}
                  value={formik?.values?.person_1_phone_code}
                  classNamePrefix="pro-input"
                  onBlur={formik.handleBlur("person_1_phone_code")}
                  menuPlacement="auto"
                  onChange={(value) =>
                    formik.setFieldValue("person_1_phone_code", value)
                  }
                ></Select>
              </div>
              <input
                type="text"
                id="person_1_phone"
                name="person_1_phone"
                className={`pro-input lg ${
                  formik.errors.person_1_phone &&
                  formik.touched.person_1_phone &&
                  " error"
                }`}
                {...formik.getFieldProps("person_1_phone")}
              />
            </div>
            {formik.errors.person_1_phone && formik.touched.person_1_phone && (
              <>
                <span className="error-text">
                  {formik.errors.person_1_phone}
                </span>
              </>
            )}
          </div>
        </div>
        <Input
          label={"Contact Person 2 Name "}
          type="text"
          id="person_2_name"
          name="person_2_name"
          className={`pro-input lg ${
            getFieldError("person_2_name") && " error"
          }`}
          {...formik.getFieldProps("person_2_name")}
          error={getFieldError("person_2_name")}
          errorMessage={getFieldError("person_2_name")}
        />
        <Input
          label={"Contact Person 2 Email "}
          type="text"
          id="person_2_email"
          name="person_2_email"
          className={`pro-input lg ${
            getFieldError("person_2_email") && " error"
          }`}
          {...formik.getFieldProps("person_2_email")}
          error={getFieldError("person_2_email")}
          errorMessage={getFieldError("person_2_email")}
        />
        <div className="input-wrap pro-mb-4 ">
          <div className="input-country-wrap ">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Contact Person 2 Phone
            </label>
            <div className="input-main-wrap verify-btn-wrap">
              <div className="code-wrap pro-mb-4 ">
                <Select
                  id="person_2_phone_code"
                  options={countryCodeOptions}
                  getOptionLabel={(option) => `${option.label}`}
                  getOptionValue={(option) => `${option.value}`}
                  value={formik?.values?.person_2_phone_code}
                  classNamePrefix="pro-input"
                  onBlur={formik.handleBlur("person_2_phone_code")}
                  menuPlacement="auto"
                  onChange={(value) =>
                    formik.setFieldValue("person_2_phone_code", value)
                  }
                ></Select>
              </div>
              <input
                type="text"
                id="person_2_phone"
                name="person_2_phone"
                className={`pro-input lg ${
                  formik.errors.person_2_phone &&
                  formik.touched.person_2_phone &&
                  " error"
                }`}
                {...formik.getFieldProps("person_2_phone")}
              />
            </div>

            {formik.errors.person_2_phone && formik.touched.person_2_phone && (
              <span className="error-text">{formik.errors.person_2_phone}</span>
            )}
          </div>
        </div>
      </div>
      <div>
        <Input
          label={"User Name"}
          type="text"
          id="user_name"
          name="user_name"
          className={`pro-input lg ${getFieldError("user_name") && " error"}`}
          {...formik.getFieldProps("user_name")}
          error={getFieldError("user_name")}
          errorMessage={getFieldError("user_name")}
          disabled={isEdit}
        />

        {!isEdit && (
          <div className="col-12 p-re">
            <Input
              label={"Password"}
              type="password"
              id="password"
              autoComplete="branch-password"
              name="password"
              className={`pro-input lg ${
                getFieldError("password") && " error"
              } hide-icon-input`}
              {...formik.getFieldProps("password")}
              error={getFieldError("password")}
              errorMessage={getFieldError("password")}
              disabled={isEdit}
              placeholder={isEdit ? "Password can't be changed" : ""}
            />
            {!isEdit && (
              <>
                {showPassword ? (
                  <button
                    className={`hide-icon`}
                    onClick={(e) => handleShowPassword(e)}
                  >
                    <span className="material-symbols-outlined">
                      {" "}
                      visibility{" "}
                    </span>
                  </button>
                ) : (
                  <button
                    className={`hide-icon`}
                    onClick={(e) => handleShowPassword(e)}
                  >
                    <span className="material-symbols-outlined">
                      {" "}
                      visibility_off{" "}
                    </span>
                  </button>
                )}
              </>
            )}
          </div>
        )}
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={checkError}
        >
          {isEdit ? "update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default Contact;
