import { useFormik } from "formik";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getExcelFormData, updateForm } from "../../api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../store/slices/QC/QCSlice";

const useVersionTwo = () => {
  const { item, excelEditedFormData, dropdownValues, itemID, certificateID } =
    useSelector((state) => state.qc);
  const slug = localStorage?.getItem("slug");
  const dispatch = useDispatch();
  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReset = () => {
    excelEditedFormData?.data?.map((value) =>
      formik?.setFieldValue(`${value?.slug}`, value?.value)
    );
    excelEditedFormData?.side_stone?.map((value) =>
      formik?.setFieldValue(`side_stone_${value?.slug}`, value?.value)
    );
    excelEditedFormData?.center_stone?.map((value) =>
      formik?.setFieldValue(`center_stone_${value?.slug}`, value?.value)
    );
  };

  const formik = useFormik({
    initialValues: {},
    validate: (values) => {
      let errors = {};
      Object.keys(values || {}).forEach((key) => {
        if (!values[key]) {
          errors[`${key}`] = `This field is required`;
        }
      });
      return errors;
    },
    onSubmit: (values) => {
      let data = {
        process_slug: slug,
        item_details_id: certificateID,
        ...values,
      };
      let formData = new FormData();
      for (let key in data) {
        if (key.startsWith("center_stone")) {
          const formattedKey = key.replace("center_stone_", ""); // Remove the prefix
          formData.append(`center_stone[${formattedKey}]`, data[key]);
        } else if (key.startsWith("side_stone")) {
          const formattedKey = key.replace("side_stone_", ""); // Remove the prefix
          formData.append(`side_stone[${formattedKey}]`, data[key]);
        } else {
          // For other keys, append them as they are
          formData.append(key, data[key]);
        }
      }

      updateForm(formData).then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Data updated ");
          dispatch(
            updateConfig((state) => {
              state.certificateID = item?._id;
              state.itemID = item?.gli_number;
              state.showCompare = false;
            })
          );
          let data = {
            item_details_id: item?._id,
            item_type_id: item?.item_type_id,
            process_slug: slug,
          };
          getExcelFormData(data)
            .then((response) => {
              if (response?.data?.status_code === 200) {
                if (response?.data?.data?.updated_values.length !== 0) {
                  dispatch(
                    updateConfig((state) => {
                      state.data = [
                        { name: "Version 1" },
                        { name: "Version 2" },
                      ];
                    })
                  );
                }
                dispatch(
                  updateConfig((state) => {
                    state.excelFormData = response?.data?.data?.graded_values;
                    state.excelEditedFormData =
                      response?.data?.data?.updated_values.length === 0
                        ? (state.excelFormData =
                            response?.data?.data?.graded_values)
                        : response?.data?.data?.updated_values;
                    state.showValues = true;
                    state.showForm = false;
                  })
                );
              }
            })
            .catch((error) => toast.error(error));
        } else {
          toast.error("Something went wrong");
        }
      });
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  return {
    formik,
    dropdownValues,
    excelEditedFormData,
    itemID,
    getFieldError,
    handleReset,
  };
};

export default useVersionTwo;
