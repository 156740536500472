import { useSelector } from "react-redux";

const useVersionOne = () => {
  
  const { excelFormData, showCompare, excelData, itemID } = useSelector(
    (state) => state.qc
  );

  return { excelFormData, showCompare, excelData, itemID  };
};

export default useVersionOne;
