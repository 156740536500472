import Dropdown from "react-bootstrap/Dropdown";
// import Assets from "../../../../assets/Assets";
import Style from "./HeaderActions.module.scss";
import useHeaderActions from "./useHeaderActions";
import {
  Button,
  ConfirmationBox,
  // Image,
  ModalLayout,
  // OffCanvasLayout,
} from "@wac-ui-dashboard/wac_component_library";
// import DepartmentForm from "./DepartmentForm";

const HeaderActions = () => {
  const { globalState, localItem, handleLogout, handleLogoutClick } =
    useHeaderActions();

  return (
    <div className={`pro-ms-auto pro-d-flex pro-items-center ${Style.root}`}>
      {/* <Dropdown className="pro-me-3"></Dropdown> */}
      <span className="pro-mx-6">
        {localItem === "QC" ? "Grading" : localItem}
      </span>

      <div className="pro-d-flex">
        <button
          className={`pro-btn pro-btn-outline-primary pro-items-center ${Style.logout_btn}`}
          onClick={handleLogoutClick}
        >
          <span>Logout </span>
          <span className="material-symbols-outlined">
            power_settings_new
          </span>{" "}
        </button>
      </div>

      <ModalLayout
        show={globalState?.logoutModal}
        handleClose={handleLogoutClick}
      >
        <div className="pro-m-5">
          <ConfirmationBox
            title={"Are you sure you want to Logout"}
            cancelText={"No"}
            cancelAction={handleLogoutClick}
            submitText={"Yes"}
            submitAction={handleLogout}
            isRight={true}
          />
        </div>
      </ModalLayout>
      {/* 
      <ModalLayout
        show={globalState?.showSettingsModal}
        handleClose={handleSettings}
        title={"Settings"}
        closeIcon={<span className="material-symbols-outlined">close</span>}
      >
        <DepartmentForm basicData={globalState?.formData} isOutside={true} />
      </ModalLayout>

      <OffCanvasLayout
        show={isProfileViewVisible}
        handleClose={handleCloseProfileView}
        title={"Profile"}
        closeIcon={<span className="material-symbols-outlined"> close </span>}
      ></OffCanvasLayout> */}
    </div>
  );
};

export default HeaderActions;
