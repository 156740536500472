/* eslint-disable eqeqeq */
import {
  Button,
  IconText,
  Image,
  Input,
  SearchFilters,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import OrderCardListing from "../../Global/OrderCardListing";
import Style from "./qc.module.scss";
import useQC from "./useQC";
import CompareTab from "./CompareTab";
import Select from "react-select";
import ReportPrint from "./ReportPrint/ReportPrint";

const QC = () => {
  const {
    qcData,
    formik,
    showForm,
    formData,
    showValues,
    itemID,
    isLoading,
    totalLength,
    statusData,
    activeCard,
    imagePreview,
    reportRef,
    handleImageChange,
    handleLoadMore,
    setIsLoading,
    handleSearch,
    getFieldError,
    handleCardClick,
  } = useQC();
  



  return (
    <>
      <div className={Style.varifaication_main_wrap}>
        <div className="row">
          <div
            className={`col-xxl-5 pro-pt-4 col-5 ${Style.card_main_wrapper}`}
          >
            <div className="pro-mb-4">
              <SearchFilters
                showDateRange={false}
                onSearchInput={handleSearch}
                showFilters={true}
                showActions={false}
                SearchIcon={
                  <span className="material-symbols-outlined"> search </span>
                }
              />
            </div>
            <div className={`pro-d-flex ${Style.card_flow_wrap}`}>
              <OrderCardListing
                hasButton={false}
                data={qcData}
                isLoading={isLoading}
                totalLength={totalLength}
                activeCard={activeCard}
                setIsLoading={setIsLoading}
                handleChange={handleCardClick}
                refBottom
                handleLoadMore={handleLoadMore}
              />
            </div>
          </div>
          {showForm ? (
            <div className={`col pro-pt-4 ${Style.compare_wrap}`}>
              <div
                className={`pro-w-100 pro-d-flex pro-flex-column ${Style.compare_inner_wrap}`}
              >
                <h5 className="pro-ttl  h5">{itemID}</h5>
                <div className="input-wrap pro-mb-4">
                  <label
                    htmlFor="status"
                    className="pro-font-sm pro-mb-1 pro-fw-medium"
                  >
                    Status
                  </label>
                  <Select
                    id="status"
                    name="status"
                    placeholder={"Select"}
                    className={`pro-input lg  ${getFieldError("status") && " error"
                      }`}
                    classNamePrefix="pro-input"
                    options={statusData ?? []}
                    getOptionLabel={(option) => option?.name}
                    getOptionValue={(option) => option?.id}
                    onBlur={formik.handleBlur("status")}
                    isMulti={false}
                    onChange={(value) => {
                      formik?.setFieldValue("status", value?.id || null);
                    }}
                    menuPlacement="auto"
                  />
                  {getFieldError("status") && (
                    <span className="error-text">
                      {getFieldError("status")}
                    </span>
                  )}
                </div>
                <>
                  <small>QC Fields</small>
                  {formData?.qc_fields?.map((value, index) => {
                    return (
                      <>
                        {value?.slug === "colour" ||
                          value?.slug === "clarity" ||
                          value?.slug === "finish" ||
                          value?.slug === "shape" ? (
                          <div className="input-wrap pro-mb-4" key={index}>
                            <label
                              htmlFor=""
                              className="pro-font-sm pro-mb-1 pro-fw-medium"
                            >
                              {value?.name}
                            </label>
                            <Select
                              id={value?.slug}
                              placeholder={"Select"}
                              className={`pro-input lg  ${getFieldError(`${value?.slug}`) && "error"
                                }`}
                              classNamePrefix="pro-input"
                              name={value?.slug}
                              options={formData?.[value?.slug]}
                              getOptionValue={(option) => option?._id}
                              getOptionLabel={(option) => option?.name}
                              onBlur={formik.handleBlur(`${value?.slug}`)}
                              value={
                                formData?.[value?.slug]?.filter(
                                  (val) =>
                                    val?.name === formik?.values?.[value?.slug]
                                )?.[0]
                              }
                              onChange={(e) =>
                                formik?.setFieldValue(`${value?.slug}`, e?.name)
                              }
                              menuPlacement="auto"
                            />
                            {getFieldError(`${value?.slug}`) && (
                              <span className="error-text">
                                {getFieldError(`${value?.slug}`)}
                              </span>
                            )}
                          </div>
                        ) : (
                          <>
                            <Input
                              key={index}
                              label={value?.name}
                              type="text"
                              id={value?.slug}
                              name={value?.slug}
                              className={`pro-input lg ${getFieldError(`${value?.slug}`) && " error"
                                }`}
                              onChange={(e) =>
                                formik?.setFieldValue(
                                  `${value?.slug}`,
                                  e?.target?.value
                                )
                              }
                              onBlur={formik?.handleBlur(value?.slug)}
                              error={getFieldError(value?.slug)}
                              errorMessage={getFieldError(value?.slug)}
                              value={formik?.values?.[value?.slug]}
                            />
                          </>
                        )}
                      </>
                    );
                  })}
                </>
                {formData?.cstone_sstone === 1 && (
                  <>
                    <small>Center Stone Fields</small>
                    {formData?.center_stone_fields?.map((value, index) => {
                      return (
                        <>
                          {value?.slug === "colour" ||
                            value?.slug === "clarity" ||
                            value?.slug === "finish" ||
                            value?.slug === "shape" ? (
                            <div className="input-wrap pro-mb-4" key={index}>
                              <label
                                htmlFor=""
                                className="pro-font-sm pro-mb-1 pro-fw-medium"
                              >
                                {value?.name}
                              </label>
                              <Select
                                id={`center_stone_${value?.slug}`}
                                placeholder={"Select"}
                                className={`pro-input lg  ${getFieldError(
                                  `center_stone_${value?.slug}`
                                ) && "error"
                                  }`}
                                classNamePrefix="pro-input"
                                name={`center_stone_${value?.slug}`}
                                options={formData?.[value?.slug]}
                                getOptionValue={(option) => option?._id}
                                getOptionLabel={(option) => option?.name}
                                onBlur={formik.handleBlur(
                                  `center_stone_${value?.slug}`
                                )}
                                value={
                                  formData?.[value?.slug]?.filter(
                                    (val) =>
                                      val?.name ===
                                      formik?.values?.[
                                      `center_stone_${value?.slug}`
                                      ]
                                  )?.[0]
                                }
                                onChange={(e) =>
                                  formik?.setFieldValue(
                                    `center_stone_${value?.slug}`,
                                    e?.name
                                  )
                                }
                                menuPlacement="auto"
                              />
                              {getFieldError(`center_stone_${value?.slug}`) && (
                                <span className="error-text">
                                  {getFieldError(`center_stone_${value?.slug}`)}
                                </span>
                              )}
                            </div>
                          ) : (
                            <Input
                              key={index}
                              label={value?.name}
                              type="text"
                              id={`center_stone_${value?.slug}`}
                              name={`center_stone_${value?.slug}`}
                              className={`pro-input lg ${getFieldError(
                                `${`center_stone_${value?.slug}`}`
                              ) && "error"
                                }`}
                              onChange={(e) =>
                                formik?.setFieldValue(
                                  `${`center_stone_${value?.slug}`}`,
                                  e?.target?.value
                                )
                              }
                              onBlur={formik?.handleBlur(
                                `center_stone_${value?.slug}`
                              )}
                              error={getFieldError(
                                `center_stone_${value?.slug}`
                              )}
                              errorMessage={getFieldError(
                                `center_stone_${value?.slug}`
                              )}
                              value={
                                formik?.values?.[`center_stone_${value?.slug}`]
                              }
                            />
                          )}
                        </>
                      );
                    })}
                  </>
                )}
                {formData?.cstone_sstone === 1 && (
                  <>
                    <small>Side Stone Fields</small>
                    {formData?.side_stone_fields?.map((value, index) => {
                      return (
                        <>
                          {value?.slug === "colour" ||
                            value?.slug === "clarity" ||
                            value?.slug === "finish" ||
                            value?.slug === "shape" ? (
                            <div className="input-wrap pro-mb-4" key={index}>
                              <label
                                htmlFor=""
                                className="pro-font-sm pro-mb-1 pro-fw-medium"
                              >
                                {value?.name}
                              </label>
                              <Select
                                id={`side_stone_${value?.slug}`}
                                placeholder={"Select"}
                                className={`pro-input lg  ${getFieldError(`side_stone_${value?.slug}`) &&
                                  "error"
                                  }`}
                                classNamePrefix="pro-input"
                                name={`side_stone_${value?.slug}`}
                                options={formData?.[value?.slug]}
                                getOptionValue={(option) => option?._id}
                                getOptionLabel={(option) => option?.name}
                                onBlur={formik.handleBlur(
                                  `side_stone_${value?.slug}`
                                )}
                                value={
                                  formData?.[value?.slug]?.filter(
                                    (val) =>
                                      val?.name ===
                                      formik?.values?.[
                                      `side_stone_${value?.slug}`
                                      ]
                                  )?.[0]
                                }
                                onChange={(e) =>
                                  formik?.setFieldValue(
                                    `side_stone_${value?.slug}`,
                                    e?.name
                                  )
                                }
                                menuPlacement="auto"
                              />
                              {getFieldError(`side_stone_${value?.slug}`) && (
                                <span className="error-text">
                                  {getFieldError(`side_stone_${value?.slug}`)}
                                </span>
                              )}
                            </div>
                          ) : (
                            <Input
                              key={index}
                              label={value?.name}
                              type="text"
                              id={`side_stone_${value?.slug}`}
                              name={`side_stone_${value?.slug}`}
                              className={`pro-input lg ${getFieldError(
                                `${`side_stone_${value?.slug}`}`
                              ) && "error"
                                }`}
                              onChange={(e) =>
                                formik?.setFieldValue(
                                  `${`side_stone_${value?.slug}`}`,
                                  e?.target?.value
                                )
                              }
                              onBlur={formik?.handleBlur(
                                `side_stone_${value?.slug}`
                              )}
                              error={getFieldError(`side_stone_${value?.slug}`)}
                              errorMessage={getFieldError(
                                `side_stone_${value?.slug}`
                              )}
                              value={
                                formik?.values?.[`side_stone_${value?.slug}`]
                              }
                            />
                          )}
                        </>
                      );
                    })}
                  </>
                )}
                {formik?.values?.status != 2 &&
                  formik?.values?.status != "" && (
                    <>
                      <Input
                        label={"Reason"}
                        type="text"
                        id={"reason"}
                        name={"reason"}
                        className={`pro-input lg ${getFieldError("reason") && " error"
                          }`}
                        onChange={(e) =>
                          formik?.setFieldValue(`reason`, e?.target?.value)
                        }
                        onBlur={formik?.handleBlur("reason")}
                        error={getFieldError("reason")}
                        errorMessage={getFieldError("reason")}
                        value={formik?.values?.reason}
                      />
                      <div className="input-wrap pro-mb-4">
                        <label
                          htmlFor=""
                          className={`pro-font-sm pro-mb-1 pro-fw-medium`}
                        >
                          Image
                        </label>
                        <div className="input-drag">
                          <input
                            type="file"
                            accept=".jpeg,.jpg , .png "
                            className={`pro-input ${formik.errors.image &&
                              formik.touched.image &&
                              " error"
                              }`}
                            id="image"
                            onBlur={formik.handleBlur("logo")}
                            onChange={(e) => handleImageChange(e)}
                          />
                          <span className="input-drag-box">
                            <IconText
                              title={
                                formik?.values?.image?.name ??
                                `Drop files to attach or browse`
                              }
                            />
                          </span>
                          {formik.touched.image && formik.errors.image && (
                            <span className="error-text">
                              {formik.errors.image}
                            </span>
                          )}
                        </div>
                        <div className={`col-2 pro-my-2`}>
                          <div className={``}>
                            <Image
                              width={100}
                              height={100}
                              src={imagePreview}
                              alt={`image - 01`}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                <></>
                <div
                  className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn pro-py-4 ${Style.ftr_btn_wrap}`}
                >
                  <Button
                    className={"pro-btn-primary lg pro-ms-3"}
                    type="submit"
                    onClick={formik.handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          ) : showValues ? (
            // <div className="col">
            //   <h3>{itemID}</h3>
            //   {Object?.keys(allValues || {})?.map((key) => {
            //     return (
            //       <div Style={{ display: "flex" }}>
            //         <p>
            //           {key} : {allValues[key]}
            //         </p>
            //       </div>
            //     );
            //   })}
            // </div>
            // changes to compare tag to migrate qc and exel entry
            <div className={`col pro-ps-0 ${Style.compare_wrap}`}>
                <CompareTab reportRef={reportRef} activeCard={activeCard} />
            </div>
          ) : (
            <div className={`col pro-pt-4  ${Style.compare_wrap}`}>
              <h5 className="pro-ttl h5 pro-mb-0">Select One</h5>
            </div>
          )}
        </div>
      </div>

      <div className={Style.print}>
        <ReportPrint reportRef={reportRef} />
      </div>


    </>
  );
};

export default QC;
