import Style from "./orderCardListing.module.scss";
import OrderCard from "../../../Global/OrderCard";
import React from "react";

const QCCardListing = ({ data, handleChange, lastElement, title, dataKey }) => {
  const activeCard = { id: "", key: "" };
  return (
    <>
      <div className={`${Style.root_list}`}>
        <h6 className="pro-ttl pro-pb-3 pro-pt-4 pro-font-regular">{title}</h6>
        {/* <div className={`${Style.root_list_item}`}></div> */}
        <div className={`${Style.item_wrap}`}>
          {data?.map((item, index) =>
            item !== null && item[dataKey] !== 0 && item[dataKey] !== true ? (
              <React.Fragment key={index}>
                <OrderCard
                  lastElement={
                    index === data?.data?.length - 10 ? lastElement : false
                  }
                  // onClick={() => handleChange(item._id, item.gli_number)}
                  title={item.gli_number}
                  subTitle={`${item.shop}`}
                  curretNumber={item[dataKey]}
                  tottalNumber={item.total}
                  badgeText={item.priority}
                  activeCard={activeCard}
                  extraClassName={Style.board}
                />
              </React.Fragment>
            ) : item !== null && item[dataKey] === true ? (
              <React.Fragment key={index}>
                <OrderCard
                  lastElement={
                    index === data?.data?.length - 10 ? lastElement : false
                  }
                  // onClick={() => handleChange(item._id, item.gli_number)}
                  title={item.gli_number}
                  subTitle={`${item.shop}`}
                  completed={item[dataKey]}
                  badgeText={item.priority}
                  activeCard={activeCard}
                  extraClassName={Style.board}
                  key={index}
                />
              </React.Fragment>
            ) : (
              <div className={`${Style.root_list_item}`} key={index}></div>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default QCCardListing;
