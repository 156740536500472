import React from "react";
import Select from "react-select";
import { Input } from "@wac-ui-dashboard/wac_component_library";
import Style from "../addReport.module.scss";
import useItemFieldCS from "./useItemFieldCS";

export const ItemFieldCS = ({
  title,
  basicData,
  formik,
  itmIndex,
  fields,
  tempIds,
  isEdit,
  fieldName,
  itemTypeData,
}) => {
  const {
    fieldObj,
    caseText,
    priority,
    qcFieldData,
    fontCaseObj,
    priorityObj,
    handleRemoveFieldCS,
  } = useItemFieldCS(fields, itmIndex, formik, basicData, tempIds, isEdit);


  return (
    <>
      <div className={Style.formFields}>
        {formik?.values?.item_fields_CS?.length !== 1 && (
          <span
            className={`${Style.corner_button} btn-close`}
            onClick={() => handleRemoveFieldCS(itmIndex)}
          ></span>
        )}
        <h5>{title}</h5>
        <div className="row gx-2">
          <div className="col-md-6">
            <div className="input-wrap pro-mb-4">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
               {fieldName}
              </label>
              <Select
                id={`item_fields_CS.${itmIndex}.field_id`}
                name={`item_fields_CS.${itmIndex}.field_id`}
                placeholder={"Select"}
                className={`pro-input lg  ${formik.touched?.item_fields_CS?.[itmIndex]?.field_id &&
                  formik.errors?.item_fields_CS?.[itmIndex]?.field_id &&
                  "error"
                  }`}
                classNamePrefix="pro-input"
                options={qcFieldData}
                getOptionLabel={(option) => option?.name}
                getOptionValue={(option) => option?._id}
                value={fieldObj}
                onBlur={formik.handleBlur(`item_fields_CS.${itmIndex}.field_id`)}
                onChange={(value) => {
                  formik?.setFieldValue(
                    `item_fields_CS.${itmIndex}.field_id`,
                    value?._id || null
                  );
                }}
                menuPlacement="auto"
              />
              {formik.touched?.item_fields_CS?.[itmIndex]?.field_id &&
                formik.errors?.item_fields_CS?.[itmIndex]?.field_id && (
                  <span className="error-text">
                    {formik.errors?.item_fields_CS?.[itmIndex]?.field_id}
                  </span>
                )}
            </div>
          </div>
          <div className="col-md-6">
            <Input
              type="text"
              id={`text`}
              name={`text`}
              label={`Short Name`}
              onBlur={formik.handleBlur(`item_fields_SS.${itmIndex}.text`)}
              className={`pro-input lg ${formik.touched?.item_fields_CS?.[itmIndex]?.text &&
                formik.errors?.item_fields_CS?.[itmIndex]?.text &&
                " error"
                }`}
              {...formik.getFieldProps(`item_fields_CS.${itmIndex}.text`)}
              error={
                formik.touched?.item_fields_CS?.[itmIndex]?.text &&
                formik.errors?.item_fields_CS?.[itmIndex]?.text && (
                  <span className="error-text">
                    {formik.errors?.item_fields_CS?.[itmIndex]?.text}
                  </span>
                )
              }
              errorMessage={
                formik.touched?.item_fields_CS?.[itmIndex]?.text &&
                formik.errors?.item_fields_CS?.[itmIndex]?.text && (
                  <span className="error-text">
                    {formik.errors?.item_fields_CS?.[itmIndex]?.text}
                  </span>
                )
              }
            />
          </div>
        </div>
        <div className="row gx-2">
          <div className="col-md-6">
            <div className="input-wrap pro-mb-4">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Font Case
              </label>
              <Select
                id={`font_case${itmIndex}`}
                name={`font_case${itmIndex}`}
                placeholder={"Select"}
                className={`pro-input lg  ${formik.touched?.item_fields_CS?.[itmIndex]?.font_case &&
                  formik.errors?.item_fields_CS?.[itmIndex]?.font_case &&
                  " error"
                  }`}
                classNamePrefix="pro-input"
                options={basicData?.data?.font_case}
                getOptionLabel={(option) => option?.name}
                getOptionValue={(option) => option?.id}
                value={fontCaseObj}
                onBlur={formik.handleBlur(`font_case${itmIndex}`)}
                onChange={(value) => {
                  formik?.setFieldValue(
                    `item_fields_CS.${itmIndex}.font_case`,
                    value?.id || null
                  );
                }}
                menuPlacement="auto"
              />
              {formik.touched?.item_fields_CS?.[itmIndex]?.font_case &&
                formik.errors?.item_fields_CS?.[itmIndex]?.font_case && (
                  <span className="error-text">
                    {formik.errors?.item_fields_CS?.[itmIndex]?.font_case}
                  </span>
                )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="input-wrap pro-mb-4">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Priority
              </label>
              <Select
                id={`priority${itmIndex}`}
                name={`priority${itmIndex}`}
                placeholder={"Select"}
                className={`pro-input lg  ${formik.touched?.item_fields_CS?.[itmIndex]?.priority &&
                  formik.errors?.item_fields_CS?.[itmIndex]?.priority &&
                  " error"
                  }`}
                classNamePrefix="pro-input"
                options={priority}
                getOptionLabel={(option) => option?.name}
                getOptionValue={(option) => option?.id}
                value={priorityObj}
                onBlur={formik.handleBlur(`priority${itmIndex}`)}
                onChange={(value) => {
                  formik?.setFieldValue(
                    `item_fields_CS.${itmIndex}.priority`,
                    value?.id || null
                  );
                }}
                menuPlacement="auto"
              />
              {formik.touched?.item_fields_CS?.[itmIndex]?.priority &&
                formik.errors?.item_fields_CS?.[itmIndex]?.priority && (
                  <span className="error-text">
                    {formik.errors?.item_fields_CS?.[itmIndex]?.priority}
                  </span>
                )}
            </div>
          </div>
        </div>
        <div className="row gx-2">
          <div className="pro-check-box row pro-py-2">
            <div className="col-md-6">
              <input
                type="checkbox"
                className="pro-check"
                id="bold"
                name="pro-checkbox"
                checked={
                  Boolean(formik.values?.item_fields_CS[itmIndex].bold) ?? false
                }
                onChange={(e) => {
                  formik.setFieldValue(
                    `item_fields_CS.${itmIndex}.bold`,
                    !Boolean(formik.values?.item_fields_CS[itmIndex].bold) ?? false
                  );
                }}
                disabled={false}
              />
              <label htmlFor="day_check" className="pro-check-label">
                Bold
              </label>
            </div>
            <div className={`col-md-6 ${Style.display_text}`}>
              {"Ex:"}
              {formik.values?.item_fields_CS[itmIndex].bold ? (
                <b>{caseText}</b>
              ) : (
                caseText
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemFieldCS;
