import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useRecheck from "./useRecheck";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import Filter from "./Filter";
import Style from "./recheck.module.scss";

const Recheck = () => {
  const {
    tableFields,
    recheckList,
    isFetching,
    recheckState,
    filterShow,
    showEditModal,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    setFilterShow,
    handleRecheck,
    handlePageSize,
    handlePagination,
    handleClearClick,
    handleRecheckModal,
    handleEditColumnsClick,
    updateRecheckTableData,
  } = useRecheck();

  return (
    <>
      <HeadingGroup title={"Recheck"} className={`pro-mb-4`} />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <div className="row gx-2">
          <div className="col-auto">
            <SearchFilters
              onSearchInput={handleSearch}
              showActions={true}
              showFilters={true}
              handleActionClick={handleEditColumnsClick}
              // loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              handleClear={handleClearClick}
              searchInputProps={{ value: recheckState?.search }}
              showDateRange={false}
            />
          </div>
          <div className="col-auto">
            <button
              className={`pro-btn pro-btn-outline pro-h-100  pro-items-center pro-px-5 ${Style.filter_btn}`}
              onClick={() => setFilterShow(true)}
            >
              <span className="material-symbols-outlined">tune</span>
              <span>Filter</span>
            </button>
          </div>
        </div>
        <Table
          multiSelect={true}
          data={recheckList?.data?.data || []}
          uniqueID={"_id"}
          fields={tableFields}
          SortIcon={<FaSort />}
          clear={recheckState.clearSelection}
          assignIcon={
            <span className="material-symbols-outlined">check_circle</span>
          }
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={recheckList?.currentPageSize}
          assignable={true}
          editable={false}
          assignText={"Recheck"}
          handleAssign={handleRecheckModal}
          deletable={false}
          showCheckBox={true}
        />
        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={recheckList?.data?.fields}
              moduleId={recheckList?.data?.module_id}
              updateData={updateRecheckTableData}
            />
          </div>
        </ModalLayout>
        {recheckList?.data?.data?.length > 0 && (
          <Pagination
            currentPage={recheckState?.currentPage}
            totalPageCount={Math.ceil(
              recheckList?.data?.total_count / recheckState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          show={recheckState?.showAddRecheckModal}
          handleClose={handleRecheckModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={
                "Are you sure you want to recheck the selected certificate?"
              }
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleRecheckModal}
              submitText={"Yes"}
              submitAction={handleRecheck}
              isRight={true}
            />
          </div>
        </ModalLayout>
        <OffCanvasLayout
          show={filterShow}
          title={"Filter"}
          handleClose={() => {
            setFilterShow(false);
          }}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <Filter setShowform={setFilterShow} />
        </OffCanvasLayout>
      </div>
    </>
  );
};

export default Recheck;
