import React from "react";
import useApprovedDetails from "./useApprovedDetails";
import {
  Button,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import EditApprovedCertificate from "./EditApprovedCertificate";
import Style from "../../verifications.module.scss";

const ApprovedDetails = ({ gliNumber, barCodeRef }) => {
  const {
    basicData,
    isFetching,
    tableFields,
    approvedState,
    paginationOptions,
    ApprovedDetailsList,
    handlePrintBarCode,
    refetch,
    getRow,
    handleSearch,
    handlePageSize,
    handleClearClick,
    handlePagination,
    handleApprovedData,
  } = useApprovedDetails({ barCodeRef });

  return (
    <div className="pro-w-100">
      <div className={Style.head}>
        <h5 className="pro-ttl h5 pro-mb-2">{gliNumber}</h5>
        <button className={`${Style.Print_btn} pro-btn pro-items-center pro-btn-outline`} onClick={handlePrintBarCode}>
          <span className="material-symbols-outlined">barcode_scanner</span>
          <span>Print Barcode</span>
        </button>
      </div>
      <SearchFilters
        onSearchInput={handleSearch}
        showActions={false}
        SearchIcon={<span className="material-symbols-outlined"> search </span>}
        showClearFilters
        handleClear={handleClearClick}
        searchInputProps={""}
      />
      <Table
        multiSelect={false}
        data={ApprovedDetailsList?.data?.data}
        uniqueID={"id"}
        fields={tableFields}
        showCheckBox={true}
        clear={approvedState.clearSelection}
        SortIcon={<FaSort />}
        editIcon={<span className="material-symbols-outlined">edit</span>}
        deleteIcon={<span className="material-symbols-outlined">delete</span>}
        getRow={getRow}
        loading={isFetching}
        handleEdit={handleApprovedData}
        perpage={10}
        assignable={false}
        deletable={false}
      />
      <Pagination
        currentPage={ApprovedDetailsList?.data?.current_page}
        totalPageCount={Math.ceil(
          ApprovedDetailsList?.data?.total_count /
            ApprovedDetailsList?.data?.per_page
        )}
        onPageChange={handlePagination}
        options={paginationOptions}
        onActionChange={handlePageSize}
        center
      />
      <ModalLayout
        show={approvedState?.showAddOrderModal}
        handleClose={handleApprovedData}
        title={"Edit Verification Data"}
        closeIcon={<span className="material-symbols-outlined"> close </span>}
      >
        <div className="pro-m-5">
          <EditApprovedCertificate
            handleApprovedData={handleApprovedData}
            basicData={basicData}
            refetch={refetch}
          />
        </div>
      </ModalLayout>
    </div>
  );
};

export default ApprovedDetails;
