import { IconText, Image } from "@wac-ui-dashboard/wac_component_library";
import React from "react";

const RoughImage = ({
  handleRoughImage,
  roughPhotoUpload,
  handleRoughView,
}) => {
  return (
    <div>
      <div className="input-wrap">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Rough Image
        </label>
        <div className="input-drag">
          <input
            type="file"
            multiple
            accept="image/png, image/jpeg"
            placeholder="placeholder"
            onChange={(e) => handleRoughImage(e)}
          />

          <span className="input-drag-box" style={{ padding: "50px" }}>
            {roughPhotoUpload?.imageData?.name === "" ||
            roughPhotoUpload?.imageData?.name === undefined ? (
              <IconText title={`${"Drop files to attach or browses"}`} />
            ) : (
              <IconText title={`${roughPhotoUpload?.imageData?.name}`} />
            )}
          </span>
        </div>
        {roughPhotoUpload?.photo?.length > 2 && (
          <div className={`col-3 pro-mt-2`} onClick={handleRoughView}>
            <div className={`pro-rounded-3 pro-overflow-hidden`}>
              <Image
                width={800}
                height={800}
                src={roughPhotoUpload?.photo}
                alt={`image - 01`}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RoughImage;
