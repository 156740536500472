import React from "react";
import { useSelector } from "react-redux";

const Back = React.forwardRef(({ visitingOneBackPrintRef }) => {
  const { printingData } = useSelector((state) => state.printing);
  return (
    <div
      ref={visitingOneBackPrintRef}
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "30px ",
        flexDirection: "row-reverse",
      }}
    >
      <title>DHC Card Back</title>
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <style
        dangerouslySetInnerHTML={{
          __html:
            "@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900 &display=swap');\n        ",
        }}
      />
      {printingData?.map((value, index) => {
        return (
          <table
            key={index}
            border={0}
            cellSpacing={0}
            role="presentation"
            cellPadding={0}
            style={{
              backgroundColor: "#ffffff",
              maxWidth: "9cm",
              borderCollapse: "collapse",
              minWidth: "9cm",
              border: "1px solid black",
            }}
          >
            <tbody>
              <tr>
                <td style={{ paddingBottom: "0.5cm", paddingRight: "0.5cm" }}>
                  <table
                    border={0}
                    cellSpacing={0}
                    role="presentation"
                    cellPadding={0}
                    style={{
                      backgroundColor: "#ffffff",
                      maxWidth: "9cm",
                      borderCollapse: "collapse",
                      minWidth: "9cm",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td style={{ maxWidth: "9cm", minWidth: "9cm" }}>
                          <img
                            src={value?.back_image ?? ""}
                            alt="back_image"
                            style={{
                              maxWidth: "9cm",
                              minWidth: "9cm",
                              width: "9cm",
                              height: "4.95cm",
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        );
      })}
    </div>
  );
});

export default Back;
