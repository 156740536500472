import React from "react";
import useCompareTab from "./useCompareTab";
import { TabHeading } from "../../../Global/TabHeading";
import VersionOne from "./VersionOne";
import VersionTwo from "./VersionTwo";
import { Button } from "@wac-ui-dashboard/wac_component_library";
import Style from "./compareTab.module.scss";

const CompareTab = ({ reportRef, activeCard }) => {
  const {
    data,
    activeId,
    handleClick,
    handleAdd,
    handleCompare,
    handleDownload,
  } = useCompareTab({ reportRef });

  return (
    <div className={`pro-w-100 ${Style.compare_wrap}`}>
      {activeCard.key !== "Marked" && activeCard.key !== "Rejected" &&
        <TabHeading
          data={data}
          uniqueId={"id"}
          activeId={activeId}
          handleClick={handleClick}
        />}
      <div className={`pro-ms-4 pro-d-flex pro-flex-column ${Style.tabs_wrap}`}>
        {activeId === 1 ? <VersionOne /> : <VersionTwo />}
      </div>
      {
        activeCard.key !== "Marked" && activeCard.key !== "Rejected" &&
        <div
          className={`col-12 pro-d-flex pro-justify-end pro-py-3 pro-gap-3 ${Style.footer_btn_wrap} `}
        >
          {activeId === 1 && (
            <>
              <Button
                type="button"
                className={"pro-btn-outline lg pro-px-5"}
                onClick={handleDownload}
              >
                <span className="material-symbols-outlined">
                  download_for_offline
                </span>
                Download
              </Button>
              <Button
                className={"pro-btn-outline lg pro-px-5"}
                onClick={handleCompare}
              >
                Compare
              </Button>
              {data?.length === 1 && (
                <>
                  <Button
                    className={"pro-btn-outline lg "}
                    type="button"
                    onClick={handleAdd}
                  >
                    Create a copy
                  </Button>
                </>
              )}
            </>
          )}
        </div>
      }

      <div
        className={`col-12 pro-d-flex pro-justify-end pro-py-3 pro-gap-3 ${Style.footer_btn_wrap} `}
      >
        {activeId === 1 && (
          <>
            <button
              type="button"
              className={"pro-btn-outline pro-btn lg pro-px-5"}
              onClick={handleDownload}
            >
              <span className="material-symbols-outlined">
                download
              </span>
              <span>Download</span>
            </button>
            <Button
              className={"pro-btn-outline lg pro-px-5"}
              onClick={handleCompare}
            >
              Compare
            </Button>
            {data?.length === 1 && (
              <>
                <Button
                  className={"pro-btn-outline lg "}
                  type="button"
                  onClick={handleAdd}
                >
                  Create a copy
                </Button>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CompareTab;
