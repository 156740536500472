import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  tableFields: {},
  dashboard_permissions: {},
  dashboard_permissions_status: "idle",
  showEditModal: false,
  showLoginModal: false,
  formData: [],
  showSettingsModal: false,
  logoutModal: false,
};

export const getMenuPermissions = createAsyncThunk(
  "global/getMenuPermissions",
  async (body, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/user/menu-list`, body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMenuPermissions.pending, (state) => {
      state.dashboard_permissions_status = "pending";
    });
    builder.addCase(getMenuPermissions.fulfilled, (state, action) => {
      state.dashboard_permissions = action.payload.data?.data;
      state.dashboard_permissions_status = "fulfilled";
      localStorage?.setItem(
        "dashboard_permissions",
        JSON.stringify(action.payload.data?.data)
      );
    });
    builder.addCase(getMenuPermissions.rejected, (state) => {
      state.dashboard_permissions_status = "rejected";
    });
  },
});

export const { updateConfig } = globalSlice.actions;

export default globalSlice.reducer;
