import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../store/slices/Billing/staffBillingSlice";
import { useEffect } from "react";
import { getEstimateList, getSubmitList } from "../api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const useEstimate = () => {
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];
  const data = localStorage?.getItem("billing");
  const ParsedData = JSON?.parse(data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { estimateResponse, estimateModal, selectedItems } = useSelector(
    (state) => state.billing
  );
  useEffect(() => {
    if (!estimateResponse) {
      let data = {
        ...ParsedData,
        per_page:20
      }
      getSubmitList(data)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            dispatch(
              updateConfig((state) => {
                state.estimateResponse = response?.data?.data;
              })
            );
          }
        })
        .catch((error) => toast.error(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimateResponse]);

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.estimateModal = !state.estimateModal;
        state.selectedItems = e;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    let updatedData = {
      ...ParsedData,
      per_page: page_size,
    };
    getSubmitList(updatedData)
      .then((response) => {
        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig((state) => {
              state.estimateResponse = response?.data?.data;
            })
          );
        }
      })
      .catch((error) => toast.error(error));
  };

  const handlePagination = (page) => {
    let updatedData = {
      ...ParsedData,
      page: page,
    };
    getSubmitList(updatedData)
      .then((response) => {
        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig((state) => {
              state.estimateResponse = response?.data?.data;
            })
          );
        }
      })
      .catch((error) => toast.error(error));
  };
  const handleYes = () => {
    dispatch(
      updateConfig((state) => (state.estimateModal = !state.estimateModal))
    );
    const params = {
      item_details_id: selectedItems ?? [],
    };
    getEstimateList(params).then((response) => {
      if (response?.data?.status_code === 200) {
        localStorage?.setItem("invoice_id", response?.data?.data?.invoice_id);
        dispatch(
          updateConfig((state) => {
            state.invoiceData = response?.data?.data;
            state.estimateModal = false;
          })
        );
        navigate("invoice");
      } else {
        toast.error("Something went wrong");
      }
    });
  };
  return {
    estimateResponse,
    estimateModal,
    paginationOptions,
    handlePagination,
    handlePageSize,
    handleYes,
    getRow,
    handleModal,
  };
};

export default useEstimate;
