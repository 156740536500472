import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddService from "./useAddService";
import Select from "react-select";

const AddService = (refetch) => {
  const { formik, basicData, serviceState, getFieldError, handleCloseModal } =
    useAddService(refetch);
  return (
    <div className="row pro-p-4">
      <Input
        label={"Name"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${getFieldError("name") && " error"}`}
        {...formik.getFieldProps("name")}
        error={getFieldError("name")}
        errorMessage={getFieldError("name")}
      />
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Process
        </label>
        <Select
          id="process"
          name="process"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("process") && " error"}`}
          classNamePrefix="pro-input"
          options={basicData ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?._id}
          value={formik?.values?.process}
          onBlur={formik.handleBlur("process")}
          onChange={(value) => formik?.setFieldValue("process", value || null)}
          menuPlacement="auto"
        />
        {getFieldError("process") && (
          <span className="error-text">{getFieldError("process")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Sub Process
        </label>
        <Select
          id="sub_process"
          name="sub_process"
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("sub_process") && " error"
          }`}
          classNamePrefix="pro-input"
          options={basicData ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?._id}
          value={formik?.values?.sub_process}
          onBlur={formik.handleBlur("sub_process")}
          onChange={(value) =>
            formik?.setFieldValue("sub_process", value || null)
          }
          menuPlacement="auto"
        />
        {getFieldError("sub_process") && (
          <span className="error-text">{getFieldError("sub_process")}</span>
        )}
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {serviceState?.isEdit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default AddService;
