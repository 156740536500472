import React from "react";
import { useBillAddress } from "./useBillAddress";
import {
  Button,
  OffCanvasLayout,
} from "@wac-ui-dashboard/wac_component_library";
import Form from "./Form";

export const BillAddress = () => {
  const {
    formik,
    editModal,
    isChecked,
    handleCheckChange,
    handleEdit,
    getFieldError,
  } = useBillAddress();
  return (
    <>
      <div className="row">
        <div className="col-md-3">
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Supplier Name
            </label>
            <input
              className={`pro-input lg`}
              type="text"
              name="suppplier_name"
              value={formik.values.supplier_name}
              disabled
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Shop name
            </label>
            <input
              className={`pro-input lg`}
              type="text"
              name="shop_name"
              value={formik.values.shop_name}
              disabled
            />
          </div>
        </div>
        <div className="col-md-3">
          <Button className="" onClick={handleEdit}>
            Edit
            <span class="material-symbols-outlined">edit_square</span>
          </Button>
        </div>
        <OffCanvasLayout
          show={editModal}
          handleClose={handleEdit}
          getFieldError={getFieldError}
          title="Edit Details"
        >
          <div className="">
            <Form
              formik={formik}
              handleClose={handleEdit}
              getFieldError={getFieldError}
              isChecked={isChecked}
              handleCheckChange={handleCheckChange}
            />
          </div>
        </OffCanvasLayout>
      </div>
    </>
  );
};
export default BillAddress;
