import React from "react";
import useEditApprovedCertificate from "./useEditApprovedCertificate";
import Select from "react-select";
import { Button } from "@wac-ui-dashboard/wac_component_library";

export const EditApprovedCertificate = ({
  handleApprovedData,
  basicData,
  refetch,
}) => {
  const { formik, certificateObj, packingObj, tagObj, getFieldError } =
    useEditApprovedCertificate({ handleApprovedData, basicData, refetch });
  return (
    <>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Certificate Type
        </label>
        <Select
          id="certificate_type"
          name="certificate_type"
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("certificate_type") && " error"
          }`}
          classNamePrefix="pro-input"
          options={basicData?.certificate_type ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          value={certificateObj}
          onBlur={formik.handleBlur("certificate_type")}
          onChange={(value) =>
            formik?.setFieldValue("certificate_type", value?.id || null)
          }
          menuPlacement="auto"
        />
        {getFieldError("certificate_type") && (
          <span className="error-text">
            {getFieldError("certificate_type")}
          </span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Packaging Type
        </label>
        <Select
          id="packing_type"
          name="packing_type"
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("packing_type") && " error"
          }`}
          classNamePrefix="pro-input"
          options={basicData?.packing_type ?? []}
          getOptionLabel={(option) => option?.packing_type}
          getOptionValue={(option) => option?._id}
          value={packingObj}
          onBlur={formik.handleBlur("packing_type")}
          onChange={(value) =>
            formik?.setFieldValue("packing_type", value?._id || null)
          }
          menuPlacement="auto"
        />
        {getFieldError("packing_type") && (
          <span className="error-text">{getFieldError("packing_type")}</span>
        )}
      </div>
      {!basicData?.tag_number_id && (
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Excel Tag Number
          </label>
          <Select
            id="tag_number"
            name="tag_number"
            placeholder={"Select"}
            className={`pro-input lg  ${
              getFieldError("tag_number") && " error"
            }`}
            classNamePrefix="pro-input"
            options={basicData?.tag_number ?? []}
            getOptionLabel={(option) => option?.tag_number}
            getOptionValue={(option) => option?._id}
            value={tagObj}
            onBlur={formik.handleBlur("tag_number")}
            onChange={(value) =>
              formik?.setFieldValue("tag_number", value?._id || null)
            }
            menuPlacement="auto"
          />
          {getFieldError("tag_number") && (
            <span className="error-text">{getFieldError("tag_number")}</span>
          )}
        </div>
      )}
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleApprovedData}
        >
          Cancel
        </Button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {"Update"}
        </Button>
      </div>
    </>
  );
};

export default EditApprovedCertificate;
