import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Style from "./pendingQueueDetail.module.scss";

const PendingQueueDetails = ({
  handleUploadPhoto,
  photoUpload,
  file,
  handleSearch,
  SearchFile,
  isChecked,
  isSelected,
  handlePhotoClick,
  handleCheck,
  photoList,
  searchKey,
}) => {
  return (
    <>
      <div className="row">
        <div className="col">
          <h6 className="pro-ttl h6">Design Id</h6>
          <div className="row">
            <div className="col">
              <Input
                className={`pro-input lg `}
                onChange={(e) => handleSearch(e)}
                value={searchKey}
              />
            </div>
            <div className="col">
              {" "}
              <Button
                className={"pro-btn pro-btn-outline lg pro-px-5"}
                onClick={() => searchKey && SearchFile()}
              >
                Search
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="input-wrap">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Image
        </label>
        <div className="input-drag">
          <input
            type="file"
            multiple
            accept="image/png, image/jpeg"
            placeholder="placeholder"
            onChange={(e) => handleUploadPhoto(e)}
          />

          <span className="input-drag-box" style={{ padding: "50px" }}>
            {file === "" ? (
              <IconText title={`${"Drop files to attach or browses"}`} />
            ) : (
              <IconText title={`${file?.name}`} />
            )}
          </span>
        </div>
        {photoUpload && (
          <div className={`col-3 pro-mt-2`}>
            <div className={`pro-rounded-3 pro-overflow-hidden`}>
              <Image
                width={800}
                height={800}
                src={photoUpload}
                alt={`image - 01`}
              />
            </div>
          </div>
        )}

        {photoList.image ? (
          <div className={`pro-mt-4 row pro-w-100`}>
            {photoList.image.map((item, index) => (
              <div className={`col-3 pro-mt-2`} key={index}>
                <div className={`${Style.imageCheck_wrap}`}>
                  <input
                    type="checkbox"
                    className={`pro-check ${Style.check_box}`}
                    onChange={(e) =>
                      handleCheck(e, photoList.design_id, item.name, photoList)
                    }
                    checked={
                      isChecked
                        ? isSelected?.name === item?.name
                          ? true
                          : false
                        : false
                    }
                  />
                  <div onClick={() => handlePhotoClick(item)}>
                    <Image
                      width={800}
                      height={800}
                      src={item.url}
                      alt={`image - 01`}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default PendingQueueDetails;
