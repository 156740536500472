/* eslint-disable eqeqeq */
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { getPercentage } from "../api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const useForm1 = ({ handleConfirm }) => {
  const [imagePreview, setImagePreview] = useState("");
  const { certificateID } = useSelector((state) => state.cvd);
  const slug = localStorage?.getItem("slug");

  const initialValues = {
    rft_no: "",
    percentage: "",
    image: "",
    status: "",
  };
  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const errors = {};
      // Validate the status field
      if (!values.status) {
        errors.status = "Select Status";
      }
      // Perform custom validation for other fields if status is not "2"
      if (values.status != 2 && values.status != "") {
        if (!values.rft_no || !/^[0-9]*$/.test(values.rft_no)) {
          errors.rft_no = "Enter valid number";
        }

        if (!values.percentage) {
          errors.percentage = "This field is required";
        }

        if (!values.image) {
          errors.image = "Add one image";
        } else {
          // Validate image file type
          const acceptedFormats = ["image/jpeg", "image/png"];
          if (!acceptedFormats.includes(values.image.type)) {
            errors.image = "Only JPEG and PNG formats are allowed";
          }

          // Validate image file size
          const maxSizeInBytes = 2 * 1024 * 1024;
          if (values.image.size > maxSizeInBytes) {
            errors.image = "File size must be less than 2 MB";
          } else {
            // Clear all errors when status is "2"
            Object.keys(errors).forEach((key) => {
              if (key === "status") {
                errors[key] = "Select Status";
              } else {
                errors[key] = undefined;
              }
            });
          }
        }
      }
      return errors;
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      handleConfirm(values);
    },
  });

  useEffect(() => {
    // Use a timeout to delay the API call
    const timer = setTimeout(() => {
      let params = {
        id: certificateID,
        d_no: formik?.values?.rft_no,
        slug: slug,
      };
      if (formik?.values?.rft_no) {
        getPercentage(params)
          .then((response) => {
            if (response?.data?.status_code === 200) {
              formik?.setFieldValue(
                "percentage",
                response?.data?.data?.percentage
              );
            } else {
              toast.error("Can't fetch percentage");
            }
          })
          .catch(() => {
            toast.error("Can't fetch percentage");
          });
      } else {
        formik?.setFieldValue("percentage", "");
      }
    }, 500); // Delay the API call by 500 milliseconds

    // Clear the previous timer when the rft_no changes to avoid unnecessary calls
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.rft_no, certificateID, slug]);

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleImageChange = (event) => {
    const image = event?.target?.files[0];
    formik.setFieldValue("image", image);
    const file = image;
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleReset = () => {
    formik?.resetForm();
    setImagePreview("");
  };

  return {
    formik,
    imagePreview,
    getFieldError,
    handleImageChange,
    handleReset,
  };
};

export default useForm1;
