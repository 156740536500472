import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getQcAssignedRecheckList,
  getQcCompletedRecheckList,
  getQcPendingRecheckList,
  updateConfig,
} from "../../../store/slices/QC/QCSlice";
import { useSelector } from "react-redux";
import { getFormData, submitForm, getExcelFormData } from "./api";
import { useFormik } from "formik";
import { toast } from "react-toastify";

const useQCRecheck = () => {
  const {
    qcDataRecheck,
    showForm,
    showQcModal,
    formData,
    total,
    certificateID,
    search,
    assignedID,
    showValues,
    itemID,
    qcPendingStatus,
    qcAssignedStatus,
    qcCompletedStatus,
  } = useSelector((state) => state.qc);
  const [assignedPage, setAssignedPage] = useState(1);
  const [pendingPage, setPendingPage] = useState(1);
  const [activeCard, setActiveCard] = useState({ id: "", key: "" });
  const [completedPage, setCompletedPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState();
  const [status, setSubmissionStatus] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getQcAssignedRecheckList({
        page: 1,
        per_page: 10,
        search: search,
        date: [],
        item_type: [],
        shop: [],
        status: [],
      })
    );
    if (search.length !== 0) {
      dispatch(
        getQcPendingRecheckList({
          page: 1,
          per_page: 10,
          search: search,
          receipt_id: assignedID,
        })
      );
      dispatch(
        getQcCompletedRecheckList({
          page: 1,
          per_page: 10,
          search: search,
          receipt_id: assignedID,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  useEffect(() => {
    if (pendingPage !== 1) {
      dispatch(
        getQcPendingRecheckList({
          page: pendingPage,
          per_page: 10,
          receipt_id: assignedID,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingPage]);

  useEffect(() => {
    if (assignedPage !== 1) {
      dispatch(
        getQcAssignedRecheckList({
          page: assignedPage,
          per_page: 10,
          receipt_id: assignedID,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedPage]);

  useEffect(() => {
    if (completedPage !== 1) {
      dispatch(
        getQcCompletedRecheckList({
          page: completedPage,
          per_page: 10,
          receipt_id: assignedID,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedPage]);

  const formik = useFormik({
    initialValues: {},
    validate: (values) => {
      let errors = {};
      Object.keys(values || {}).forEach((key) => {
        if (!values[key]) {
          errors[`${key}`] = `This field is required`;
        }
      });
      return errors;
    },
    onSubmit: (values) => {
      setFormValues(values);
      handleConfirmModal();
    },
  });

  const handleCardClick = (item, key) => {
    setActiveCard({ id: item?._id, key: key });
    if (key === "Assigned") {
      dispatch(updateConfig((state) => (state.assignedID = item?._id)));
      dispatch(
        getQcPendingRecheckList({
          page: 1,
          per_page: 10,
          receipt_id: item?._id,
        })
      );
      dispatch(
        getQcCompletedRecheckList({
          page: 1,
          per_page: 10,
          receipt_id: item?._id,
        })
      );
    } else if (key === "Pending") {
      dispatch(
        updateConfig((state) => {
          state.certificateID = item?._id;
          state.itemID = item?.gli_number;
        })
      );
      let data = {
        item_details_id: item?._id,
        item_type_id: item?.item_type_id,
      };
      getFormData(data)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            dispatch(
              updateConfig((state) => {
                state.formData = response?.data?.data;
                state.showValues = false;
                state.showForm = true;
              })
            );
            formData?.qc_fields.map((value) => {
              return formik?.setFieldValue(value?.slug, "");
            });
          }
        })
        .catch((error) => toast.error(error));
    } else if (key === "Passed") {
      dispatch(
        updateConfig((state) => {
          state.certificateID = item?._id;
          state.itemID = item?.gli_number;
          state.showCompare = false;
          state.data = [{ name: "Version 1" }];
          state.activeId = 1;
        })
      );
      let excelData = {
        item_details_id: item?._id,
        item_type_id: item?.item_type_id,
      };
      getExcelFormData(excelData)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            if (response?.data?.data?.updated_values.length !== 0) {
              dispatch(
                updateConfig((state) => {
                  state.data = [{ name: "Version 1" }, { name: "Version 2" }];
                })
              );
            }
            dispatch(
              updateConfig((state) => {
                state.excelFormData = response?.data?.data?.graded_values;
                state.excelEditedFormData =
                  response?.data?.data?.updated_values.length === 0
                    ? (state.excelFormData =
                        response?.data?.data?.graded_values)
                    : response?.data?.data?.updated_values;
                state.showValues = true;
                state.showForm = false;
              })
            );
          }
        })
        .catch((error) => toast.error(error));
    }
  };

  const getFieldError = (fieldName) => {
    if (formik?.touched?.[fieldName] && formik?.errors?.[fieldName]) {
      return formik?.errors?.[fieldName];
    }
    return "";
  };

  const handleSearch = (e) => {
    dispatch(updateConfig((state) => (state.search = e.target.value)));
  };
  const handleConfirm = () => {
    let data = {
      recheck_item_details_id: certificateID,
      status: status,
      ...formValues,
    };
    submitForm(data)
      .then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Success");
          dispatch(updateConfig((state) => (state.showForm = false)));
          dispatch(
            getQcPendingRecheckList({
              page: 1,
              per_page: 10,
              receipt_id: assignedID,
            })
          );
          dispatch(
            getQcCompletedRecheckList({
              page: 1,
              per_page: 10,
              receipt_id: assignedID,
            })
          );
          handleConfirmModal();
        }
      })
      .catch((error) => {
        toast.error(error);
        handleConfirmModal();
      });
  };
  const handleConfirmModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showQcModal = !state.showQcModal;
      })
    );
  };
  const handleLoadMore = (mouseHover) => {
    if (
      mouseHover === "Pending" &&
      qcDataRecheck?.Pending?.length !== total?.Pending
    ) {
      setPendingPage(pendingPage + 1);
    }
    if (
      mouseHover === "Assigned" &&
      qcDataRecheck?.Assigned?.length !== total?.Assigned
    ) {
      setAssignedPage(assignedPage + 1);
    }
    if (
      mouseHover === "Passed" &&
      qcDataRecheck?.Completed?.length !== total?.Completed
    ) {
      setCompletedPage(completedPage + 1);
    }
  };

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qcPendingStatus, qcAssignedStatus, qcCompletedStatus]);

  return {
    qcDataRecheck,
    showForm,
    formik,
    showQcModal,
    activeCard,
    showValues,
    isLoading,
    itemID,
    formData: formData?.qc_fields,
    totalLength: {
      Pending: total?.Pending,
      Assigned: total?.Assigned,
      Completed: total?.Completed,
    },
    getFieldError,
    handleCardClick,
    handleSearch,
    handleConfirm,
    setSubmissionStatus,
    handleConfirmModal,
    handleLoadMore,
    setIsLoading,
  };
};

export default useQCRecheck;
