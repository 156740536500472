import { useSelector } from "react-redux";
import {
  useGetPendingTableDataQuery,
  useUpdatePendingTableHeadDataMutation,
} from "../../../../store/queries/Recheck";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";
import { toast } from "react-toastify";
import { assignWork } from "../api";
import {
  getCardAssignedList,
  getCardPendingList,
  updateConfig,
} from "../../../../store/slices/Staff/recheckStaffSlice";

const usePendingTable = () => {
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];
  const dispatch = useDispatch();
  const staffState = useSelector((state) => state.recheckStaff);
  const { tableFields, showEditModal } = useSelector((state) => state.global);

  const slug = localStorage?.getItem("slug");
  const {
    data: pendingTableData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetPendingTableDataQuery({
    receipt_id: staffState.gliData?.id,
    search: staffState.pendingSearch,
    page_size: staffState.currentPageSize,
    page: staffState.currentPage,
    process_slug: slug,
  });

  const [updatePendingFields] = useUpdatePendingTableHeadDataMutation();

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = pendingTableData?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [pendingTableData]);

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleAssignClick = (ids) => {
    let data = {
      item_details_id: ids,
    };
    assignWork(data).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          getCardPendingList({
            page: 1,
            per_page: 10,
            search: "",
            date: [],
            item_type: [],
            shop: [],
            status: [],
            process_slug: slug,
          })
        );
        dispatch(
          getCardAssignedList({
            page: 1,
            per_page: 10,
            search: "",
            date: [],
            item_type: [],
            shop: [],
            status: [],
            process_slug: slug,
          })
        );
        refetch();
        if (pendingTableData?.data?.data.length <= 0) {
          dispatch(
            updateConfig((state) => {
              state.showTable = false;
              state.showAssignedTable = false;
            })
          );
        }
        toast.success("Assign success");
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  // const handleAssignAll = () => {
  //   let ids = [];
  //   pendingTableData?.data?.data?.map((item) => {
  //     return ids?.push(item?.id);
  //   });
  //   let data = {
  //     item_details_id: ids,
  //   };
  //   assignWork(data).then((response) => {
  //     if (response?.data?.status_code === 200) {
  //       dispatch(
  //         getCardPendingList({
  //           page: 1,
  //           per_page: 10,
  //           search: "",
  //           date: [],
  //           item_type: [],
  //           shop: [],
  //           status: [],
  //         })
  //       );
  //       dispatch(
  //         getCardAssignedList({
  //           page: 1,
  //           per_page: 10,
  //           search: "",
  //           date: [],
  //           item_type: [],
  //           shop: [],
  //           status: [],
  //         })
  //       );
  //       dispatch(
  //         updateConfig((state) => {
  //           state.showTable = false;
  //           state.showAssignedTable = false;
  //         })
  //       );
  //       toast.success("Assign success");
  //     } else {
  //       toast.error("Something went wrong");
  //     }
  //   });
  // };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(updateConfig((state) => (state.pendingSearch = e?.target?.value)));
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  return {
    isLoading,
    staffState,
    isFetching,
    tableFields,
    showEditModal,
    pendingTableData,
    paginationOptions,
    getRow,
    refetch,
    handleSearch,
    handlePageSize,
    handlePagination,
    handleAssignClick,
    updatePendingFields,
    handleEditColumnsClick,
  };
};

export default usePendingTable;
