import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Master/ItemType/itemTypeSlice";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useGetItemTypeBasicDataQuery } from "../../../../store/queries/Master";
import { addItemTypeData, editItemTypeData } from "../api";

const useAddItemType = ({ refetch, isChecked, setIsChecked }) => {
  const dispatch = useDispatch();
  const serviceState = useSelector((state) => state.itemType);

  const { data: basicData = {} } = useGetItemTypeBasicDataQuery();

  useEffect(() => {
    let services = [];
    let QC = [];
    let CenterStone = [];
    let SideStone = [];

    if (serviceState?.isEdit && serviceState?.itemData) {
      serviceState?.itemData?.services?.map((item) => {
        return services?.push(item);
      });
      if (serviceState.itemData.cstone_sstone === 1) {
        setIsChecked(true);
      }
      serviceState?.itemData?.qc_fields?.map((item) => {
        return QC?.push(item);
      });
      serviceState?.itemData?.center_stone_fields?.map((item) => {
        return CenterStone?.push(item);
      });
      serviceState?.itemData?.side_stone_fields?.map((item) => {
        return SideStone?.push(item);
      });

      formik?.setFieldValue("name", serviceState?.itemData?.name);
      formik?.setFieldValue("code", serviceState?.itemData?.code);
      formik?.setFieldValue("services", services);
      formik?.setFieldValue("qc_fields", QC);
      if (serviceState.itemData.cstone_sstone === 1) {
        formik?.setFieldValue("center_stone_fields", CenterStone);
        formik?.setFieldValue("side_stone_fields", SideStone);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceState?.isEdit, serviceState?.itemData]);

  const validation = Yup.object({
    name: Yup.string()
      .trim()
      .required("Enter Name")
      .max(50, "Enter valid Name"),
    code: Yup.string().trim().required("Enter Code").max(5, "Enter valid Code"),
    services: Yup.array().required("Select Atleast a Service"),
    qc_fields: Yup.array().required("Select Atleast a QC field"),
    center_stone_fields:
      isChecked && Yup.array().required("Select Atleast a Center Stone field"),
    side_stone_fields:
      isChecked && Yup.array().required("Select Atleast a Side Stone field"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      code: "",
      services: "",
      qc_fields: "",
      center_stone_fields: "",
      side_stone_fields: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      const Services = values?.services?.map((values) => values?._id);
      const QCFields = values?.qc_fields?.map((values) => values?._id);
      let CenterStoneId = [];
      let SideStoneId = [];
      if (isChecked) {
        CenterStoneId = values?.center_stone_fields?.map(
          (value) => (CenterStoneId = value?._id)
        );
        SideStoneId = values?.side_stone_fields?.map(
          (value) => (SideStoneId = value?._id)
        );
      }

      setIsChecked(false);
      let data = {
        name: values?.name,
        code: values?.code,
        services: Services,
        qc_fields: QCFields,
        cstone_sstone: isChecked ? 1 : 2,
        center_stone_fields: isChecked ? CenterStoneId : "",
        side_stone_fields: isChecked ? SideStoneId : "",

        // cvd_fields: CVDFields,
      };
      let editData = {
        item_type_id: serviceState?.selectedItem,
        name: values?.name,
        code: values?.code,
        services: Services,
        qc_fields: QCFields,
        cstone_sstone: isChecked ? 1 : 2,
        center_stone_fields: isChecked ? CenterStoneId : "",
        side_stone_fields: isChecked ? SideStoneId : "",

        // cvd_fields: CVDFields,
      };

      if (serviceState?.isEdit) {
        let newObj = Object.entries(editData);
        newObj = newObj
          .filter(
            (item) =>
              item[1] !== undefined && item[1] !== "" && item[1] !== null
          )
          .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
        editItemTypeData(newObj).then((response) => {
          if (response?.data?.status_code === 200) {
            handleCloseModal();
            toast.success("Successfully Updated");
            refetch();
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        let newObj = Object.entries(data);
        newObj = newObj
          .filter(
            (item) =>
              item[1] !== undefined && item[1] !== "" && item[1] !== null
          )
          .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
        addItemTypeData(newObj).then((response) => {
          if (response?.data?.status_code === 200) {
            handleCloseModal();
            toast.success("Successfully Added");
            refetch();
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.showAddItemTypeModal = false)));
  };
  return {
    formik,
    serviceState,
    basicData: basicData?.data,
    getFieldError,
    handleCloseModal,
  };
};

export default useAddItemType;
