import { HeadingGroup, Table } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useRoles from "./useRoles";
import { FaSort } from "react-icons/fa";

const Roles = () => {
  const { hasCreatePermission = true, handleCreateClick, getRow } = useRoles();

  return (
    <div>
      <HeadingGroup
        title={"Roles"}
        className={`pro-mb-4`}
        buttonTitle={hasCreatePermission ? "Create" : ""}
        handleClick={handleCreateClick}
      />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <Table
          data={[]}
          deletable={false}
          uniqueID={"_id"}
          // fields={roles?.data?.fields}
          showCheckBox
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          // handleSort={handleSort}
          getRow={getRow}
          // handleEdit={handleEdit}
          // loading={isFetching}
          // perpage={rolesState?.currentPageSize}
          assignable={false}
          multiSelect={false}
        />
      </div>
    </div>
  );
};

export default Roles;
