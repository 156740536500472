import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getCompleteRecheckList,
  getMyRecheckQueueList,
  getPendingRecheckList,
  getSearchRecheckList,
  getUploadedRecheckPhoto,
  startRecheckTimer,
  updateConfig,
} from "../../../store/slices/photoUpload/photoUploadSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { uploadPhoto } from "./api";

const usePhotoUpload = () => {
  const [photoUpload, setPhotoUpload] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pendingPage, setPendingPage] = useState(1);
  const [queuePage, setQueuePage] = useState(1);
  const [completedPage, setCompletedPage] = useState(1);
  const [activeCard, setActiveCard] = useState({ id: "", key: "" });
  const [file, setFile] = useState("");
  const [searchKey, setsearchKey] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [itemId, setItemId] = useState("");
  const [designId, setDesignId] = useState("");
  const [name, setName] = useState("");
  const [receiptId, setReceiptId] = useState("");
  const state = useSelector((state) => state.photoUpload);
  const states = state?.myQueueRecheckList;
  const photoUploadRecheckData = state?.photoUploadRecheckData;
  const photoList = state?.searchData;
  const uploadedRecheckImageData = state?.uploadedRecheckImageData;
  const [showDetail, setShowDetail] = useState(false);
  const [showCompleted, setShowCompleted] = useState();
  const [uploadStatus, setUploadStatus] = useState("idle");
  const dispatch = useDispatch();

  const slug = localStorage?.getItem("slug");

  useEffect(() => {
    dispatch(
      getMyRecheckQueueList({
        page: queuePage,
        process_slug: slug,
      })
    );
    //eslint-disable-next-line
  }, [queuePage, slug]);
  const handleChange = (id, gli_number) => {
    setActiveCard({ id: id, key: "Pending" });
    dispatch(
      updateConfig((state) => {
        state.photoUploadRecheckData.pending_list = [];
        state.photoUploadRecheckData.complete_list = [];
      })
    );
    setReceiptId(id);
    if (id) {
      dispatch(
        getCompleteRecheckList({
          page: completedPage,
          per_page: 10,
          receipt_id: id,
          process_slug: slug,
        })
      );
      dispatch(
        getPendingRecheckList({
          page: pendingPage,
          per_page: 10,
          receipt_id: id,
          process_slug: slug,
        })
      );
    }
  };
  useEffect(() => {
    if (receiptId) {
      dispatch(
        getCompleteRecheckList({
          page: completedPage,
          per_page: 10,
          receipt_id: receiptId,
          process_slug: slug,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedPage, slug]);
  useEffect(() => {
    if (receiptId) {
      dispatch(
        getPendingRecheckList({
          page: pendingPage,
          per_page: 10,
          receipt_id: receiptId,
          process_slug: slug,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingPage, slug]);

  const photoHandleChange = (id, key, certificate_number) => {
    setActiveCard({ id: id, key: key });
    setItemId(id);
    // eslint-disable-next-line eqeqeq
    if (key == "pending_list") {
      setShowDetail(true);
      dispatch(
        startRecheckTimer({
          recheck_item_details_id: id,
          process_slug: slug,
        })
      );
      setShowCompleted(false);
    } else {
      dispatch(getUploadedRecheckPhoto(id));
      setShowDetail(false);
      setShowCompleted(true);
    }
  };
  const handleUploadPhoto = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      setFile(e?.target?.files?.[0]);
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setPhotoUpload(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
    }
  };
  const handleCheck = (e, design_id, name) => {
    setIsChecked(!isChecked);
    if (e.target.checked === true) {
      setDesignId(design_id);
      setName(name);
    }
  };
  const handleSearch = (e) => {
    setsearchKey(e.target.value);
  };
  const SearchFile = () => {
    dispatch(
      getSearchRecheckList({
        search: searchKey,
      })
    );
  };
  const handleSubmit = () => {
    setUploadStatus("pending");
    let data;
    if (designId) {
      data = {
        recheck_item_details_id: itemId,
        design_id: designId,
        name: name,
      };
   
    } else {
      if (searchKey?.length === 0) {
        data = { image: file, recheck_item_details_id: itemId };
      } else {
        data = {
          design_id: searchKey,
          image: file,
          recheck_item_details_id: itemId,
        };
      }
    }
    let newObj = Object.entries(data);
    newObj = newObj
      .filter(
        (item) => item[1] !== undefined && item[1] !== "" && item[1] !== null
      )
      .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});

    const formData = new FormData();
    Object.keys(newObj).forEach((key) => {
      formData.append(key, data[key]);
    });
    if (file) {
      uploadPhoto(formData).then((response) => {
        if (response?.data?.status_code === 200) {
          setUploadStatus("success");
          setsearchKey("");
          toast.success("Image uploaded successfully");
          setShowDetail(false);
          setPhotoUpload("");
          setFile("");
          dispatch(
            getCompleteRecheckList({
              page: 1,
              per_page: 10,
              receipt_id: receiptId,
              process_slug: slug,
            })
          );
          dispatch(
            getPendingRecheckList({
              page: 1,
              per_page: 10,
              receipt_id: receiptId,
              process_slug: slug,
            })
          );
        } else {
          toast.error("Failed Uploading Image");
          setUploadStatus("failed");
        }
      });
    } else if (isChecked === true) {
      uploadPhoto(formData).then((response) => {
        if (response?.data?.status_code === 200) {
          setUploadStatus("success");
          toast.success("Image uploaded successfully");
          setShowDetail(false);
          setPhotoUpload("");
          setFile("");
          dispatch(
            getCompleteRecheckList({
              page: 1,
              per_page: 10,
              receipt_id: receiptId,
              process_slug: slug,
            })
          );
          dispatch(
            getPendingRecheckList({
              page: 1,
              per_page: 10,
              receipt_id: receiptId,
              process_slug: slug,
            })
          );
        } else {
          toast.error("Failed Uploading Image");
          setUploadStatus("failed");
        }
      });
    } else {
      toast.error("Please Select or upload an image");
      setUploadStatus("failed");
    }
  };
  const handleLoadMore = (mouseHover) => {
    if (
      mouseHover === "Pending" &&
      states?.myQueueRecheckList?.Pending?.length !== state?.total.Queue
    ) {
      setQueuePage(queuePage + 1);
    }
    if (
      mouseHover === "pending_list" &&
      states?.photoUploadRecheckData?.pending_list?.length !==
        state?.total.Pending
    ) {
      setPendingPage(pendingPage + 1);
    }
    if (
      mouseHover === "complete_list" &&
      states?.photoUploadRecheckData?.complete_list?.length !==
        state?.total.Completed
    ) {
      setCompletedPage(completedPage + 1);
    }
  };
  return {
    file,
    states,
    isChecked,
    photoList,
    showDetail,
    activeCard,
    isLoading,
    totalLength: {
      Pending: state.total.Pending,
      Completed: state.total.Completed,
      Queue: state.total.Queue,
    },
    photoUpload,
    showCompleted,
    photoUploadRecheckData,
    uploadedRecheckImageData,
    uploadStatus,
    SearchFile,
    handleCheck,
    handleSubmit,
    setIsLoading,
    handleSearch,
    handleChange,
    handleLoadMore,
    photoHandleChange,
    handleUploadPhoto,
  };
};

export default usePhotoUpload;
