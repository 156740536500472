import React from "react";
import usePhotoUpload from "./usePhotoUpload";
import Style from "../../Employees/PhotoUpload/photoUpload.module.scss";
import PhotoCardListing from "../../Global/PhotoCardListing/index";
import { Button, Image } from "@wac-ui-dashboard/wac_component_library";
import PendingQueueDetails from "./PendingQueueDetail";
import GliCardListing from "../../Global/GliCardLIsting";

const PhotoUploadRecheck = () => {
  const {
    file,
    states,
    photoList,
    isChecked,
    showDetail,
    activeCard,
    photoUpload,
    showCompleted,
    uploadedRecheckImageData,
    photoUploadRecheckData,
    isLoading,
    totalLength,
    uploadStatus,
    SearchFile,
    handleCheck,
    handleChange,
    handleSearch,
    handleSubmit,
    handleUploadPhoto,
    photoHandleChange,
    handleLoadMore,
    setIsLoading,
  } = usePhotoUpload();
  return (
    <>
      <div className="row pro-mb-4">
        <div className="col-auto">
          {/* <SearchFilters
        showDateRange={false}
        onSearchInput={handleSearch}
        showFilters={true}
        showActions={false}
        SearchIcon={
          <span className="material-symbols-outlined"> search </span>
        }
      
      /> */}
        </div>
      </div>
      <div className={Style.varifaication_main_wrap}>
        <div className="row">
          <div className={`col-xxl-5 col-5 ${Style.card_main_wrapper}`}>
            <div className={`pro-d-flex ${Style.card_flow_wrap} pro-gap-3`}>
              {states?.length === 0 ? null : (
                <GliCardListing
                  title={"My Queue"}
                  data={states}
                  activeCard={activeCard}
                  handleChange={handleChange}
                  isLoading={isLoading}
                  totalLength={totalLength}
                  setIsLoading={setIsLoading}
                  handleLoadMore={handleLoadMore}
                />
              )}

              <PhotoCardListing
                activeCard={activeCard}
                data={photoUploadRecheckData}
                handleChange={photoHandleChange}
                extaClassName={`pro-ms-0`}
                isLoading={isLoading}
                totalLength={totalLength}
                setIsLoading={setIsLoading}
                handleLoadMore={handleLoadMore}
              />
            </div>
          </div>

          <div className={`col pro-pe-0 ${Style.table_main_wrapper}`}>
            <div className={`${Style.table_flow_wrapper} pro-pe-3`}>
              {showDetail === true ? (
                <>
                  <div className={`row gx-3 ${Style.multicolumn_wrapper}`}>
                    <div className="col">
                      <PendingQueueDetails
                        handleUploadPhoto={handleUploadPhoto}
                        photoUpload={photoUpload}
                        file={file}
                        photoList={photoList}
                        isChecked={isChecked}
                        image={photoList.image}
                        handleSearch={handleSearch}
                        handleCheck={handleCheck}
                        SearchFile={SearchFile}
                      />
                    </div>
                  </div>
                  <div
                    className={`col-12 pro-d-flex pro-justify-end pro-py-3 pro-pe-5 ${Style.footer_btn_wrap}`}
                  >
                    <Button
                      className={"pro-btn-primary lg pro-ms-3"}
                      type="button"
                      onClick={handleSubmit}
                    >
                      {uploadStatus === "pending" ? "..." : "Submit"}
                    </Button>
                  </div>
                </>
              ) : showCompleted === true ? (
                <div className={`${Style.photoTable_image} pro-mx-auto pro-mt-6`}>
                  <Image
                    width={900}
                    height={900}
                    src={uploadedRecheckImageData.url}
                    alt={`image - 01`}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PhotoUploadRecheck;
