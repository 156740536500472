import { useEffect } from "react";

export const useItemField = (
  fields,
  itmIndex,
  formik,
  basicData,
  tempIds,
  isEdit
) => {

  let caseText;
  useEffect(() => {
    if (isEdit) {
      formik.setFieldValue("qc_text", fields?.text);
    }
    // eslint-disable-next-line
  }, [isEdit]);
  let tempFilteredData = basicData?.data?.item_type?.filter(
    (item) => item?._id === formik?.values?.item_type_id
  );

 
  let qcFieldData = tempFilteredData?.[0]?.qc_fields?.filter(
    (item) => !tempIds?.includes(item?._id)
  );

  let priority = tempFilteredData?.[0]?.qc_fields?.map((item, index) => {
    return {
      id: index + 1,
      name: index + 1,
    };
  });

  let fieldObj = tempFilteredData?.[0]?.qc_fields?.filter(
    (item) => item?._id === fields?.field_id
  );
  let fontCaseObj = basicData?.data?.font_case?.filter(
    (item) => item?.id === fields?.font_case
  );
  let priorityObj = priority?.filter(
    (item) => item?.id === formik?.values?.item_fields[itmIndex]?.priority
  );
  const handleRemoveField = (index) => {
    const data = formik?.values?.item_fields?.filter(
      (item, itemIndex) => itemIndex !== index
    );
    formik.setFieldValue("item_fields", data);
  };

  const titleCase = (string) => {
    return string
      .toLowerCase()
      .split(" ")
      .reduce(
        (s, c) => s + "" + (c.charAt(0).toUpperCase() + c.slice(1) + " "),
        ""
      );
  };
  const camelCase = (string) => {
    let ans = string.toLowerCase();
    return ans
      .split(" ")
      .reduce((s, c) => s + (c.charAt(0).toUpperCase() + c.slice(1)));
  };
  const sentenceCase = (string) => {
    return string.toLowerCase().charAt(0).toUpperCase() + string.slice(1);
  };
  switch (fields?.font_case) {
    case 1:
      caseText = formik?.values?.item_fields?.[itmIndex]?.text.toUpperCase();
      break;
    case 2:
      caseText = formik?.values?.item_fields?.[itmIndex]?.text.toLowerCase();
      break;
    case 3:
      caseText = titleCase(formik?.values?.item_fields?.[itmIndex]?.text);
      break;
    case 4:
      caseText = sentenceCase(formik?.values?.item_fields?.[itmIndex]?.text);
      break;
    case 5:
      caseText = camelCase(formik?.values?.item_fields?.[itmIndex]?.text);
      break;
    default:
      caseText = formik?.values?.item_fields?.[itmIndex]?.text;
      break;
  }

  return {
    caseText,
    priority,
    fieldObj,
    fontCaseObj,
    priorityObj,
    qcFieldData,
    handleRemoveField,
  };
};

export default useItemField;
