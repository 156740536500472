import { getAxiosInstance } from "../../../api";

export const updateOrder = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/stock-receipt/create`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getOrderData = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `/stock-receipt/edit?stock_receipt_id=${id}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteOrder = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.delete(`/stock-receipt/delete`, { data: body });
    return response;
  } catch (error) {
    return error.response.data;
  }
};
export const approveStockData = async (stockId) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `verification/stock-recept-verification?id=${stockId}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const rejectStockData = async (stockId) => {
  const api = await getAxiosInstance();
  let body = { id: stockId };
  try {
    const response = await api.post(`/verification/reject`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const approvePacking = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/packing/submit`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
export const approveRecheckPacking = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/packing/recheck/submit`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getAgentData = async (number) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `/stock-receipt/collection-agent?contact_number=${number}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getPrintData = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `/stock-receipt/slip?stock_receipt_id=${id}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};
