import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Printing/printingSlice";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { PrintCertficate } from "./api";
import { useGetCertificateListsQuery } from "../../../../store/queries/Printing";
import { toast } from "react-toastify";
import html2pdf from "html2pdf.js";
import moment from "moment/moment";
import { useEffect } from "react";

const usePrintType = ({
  visitingOneFrontPrintRef,
  visitingOneBackPrintRef,
  papperFrontPrintRef,
  papperBackPrintRef,
  bigFrontPrintRef,
}) => {
  const { data: basicData } = useGetCertificateListsQuery();
  const { selectedItems, printingData } = useSelector(
    (state) => state.printing
  );
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    certificate_type: Yup.object().required("Select Cetificate Type"),
    side: Yup.object().test(
      "conditional-validation",
      "Select Side of Certificate",
      function (value) {
        const certificateTypeId = this.parent.certificate_type?._id;
        if (certificateTypeId === 1 || certificateTypeId === 3) {
          return value !== undefined && value !== null;
        }
        return true; // No validation if certificate_type is not 1 or 3
      }
    ),
  });

  // const handlePrintClick = useReactToPrint({
  //   // content: () => printRef.current,
  // });

  const formik = useFormik({
    initialValues: {
      certificate_type: "",
      side: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let data = {
        item_details_id: selectedItems,
        certificate_type: values?.certificate_type?._id,
      };
      PrintCertficate(data).then((response) => {
        if (response?.data?.status_code === 200) {
          handleCloseModal();
          dispatch(
            updateConfig((state) => {
              state.printingData = response?.data?.data;
              state.printUI = true;
            })
          );
        } else {
          toast.error("Something went wrong");
        }
      });
    },
  });
  useEffect(() => {
    if (printingData?.length > 0) {
      if (formik?.values?.certificate_type?._id === 1) {
        if (formik.values?.side?.id === 1) {
          handleVistitingTypeOneFrontPrintClick();
        } else {
          handlevisitingTypeOneBackPrintClick();
        }
      }
      if (formik?.values?.certificate_type?._id === 2) {
        handleBigClick();
      }
      if (formik?.values?.certificate_type?._id === 3) {
        if (formik?.values?.side.id === 1) {
          handlePapperFrontClick();
        } else {
          handlePapperBackClick();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printingData]);
  const handleVistitingTypeOneFrontPrintClick = () => {
    const element = visitingOneFrontPrintRef?.current;
    html2pdf(element, {
      margin: [5, 5, 0, 5],
      filename: `${
        "visiting card certificate front" +
        "-" +
        moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
      }.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        width: 720,
        dpi: 100,
        letterRendering: true,
        useCORS: true,
        logging: true,
        scale: 4,
        scrollY: 0,
      },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
      DisablePdfCompression: true,
    });
    setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.printingData = [];
          state.printUI = false;
        })
      );
    }, 500);
  };

  const handlevisitingTypeOneBackPrintClick = () => {
    const element = visitingOneBackPrintRef?.current;
    html2pdf(element, {
      margin: [5, 5, 0, 5],
      filename: `${
        "visiting card certificate" +
        "-" +
        moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
      }.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        width: 720,
        dpi: 100,
        letterRendering: true,
        useCORS: true,
        logging: true,
        scale: 4,
        scrollY: 0,
      },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
      DisablePdfCompression: true,
    });
    setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.printingData = [];
          state.printUI = false;
        })
      );
    }, 500);
  };

  const handlePapperFrontClick = () => {
    const element = papperFrontPrintRef?.current;
    html2pdf(element, {
      margin: [5, 5, 5, 5],
      filename: `${
        "papper card certificate front" +
        "-" +
        moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
      }.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        dpi: 100,
        letterRendering: true,
        useCORS: true,
        logging: true,
        scale: 4,
        scrollY: 0,
      },
      pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      DisablePdfCompression: true,
    });
    setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.printingData = [];
          state.printUI = false;
        })
      );
    }, 500);
  };

  const handlePapperBackClick = () => {
    const element = papperBackPrintRef?.current;
    html2pdf(element, {
      margin: [5, 5, 5, 5],
      filename: `${
        "papper card certificate back" +
        "-" +
        moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
      }.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        dpi: 100,
        letterRendering: true,
        useCORS: true,
        logging: true,
        scale: 4,
        scrollY: 0,
      },
      pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
      jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
      DisablePdfCompression: true,
    });
    setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.printingData = [];
          state.printUI = false;
        })
      );
    }, 500);
  };

  const handleBigClick = () => {
    const element = bigFrontPrintRef?.current;
    html2pdf(element, {
      margin: [5, 5, 5, 5],
      filename: `${
        "big card certificate front" +
        "-" +
        moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
      }.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        dpi: 100,
        letterRendering: true,
        useCORS: true,
        logging: true,
        scale: 4,
        scrollY: 0,
      },
      pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
      jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
      DisablePdfCompression: true,
    });
    setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.printingData = [];
          state.printUI = false;
        })
      );
    }, 500);
  };
  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.printModal = false)));
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  return { formik, basicData, handleCloseModal, getFieldError };
};

export default usePrintType;
