import { getAxiosInstance } from "../../../api";

export const getDeliveryCertificate = async (id, slug) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(
      `delivery/recheck/list?receipt_id=${id}&process_slug=${slug}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const approveCertificate = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`delivery/recheck/submit`, params);
    return res;
  } catch (error) {
    return error.response.data;
  }
};
