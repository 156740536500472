import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getPrintingRecheckList,
  updateConfig,
} from "../../../store/slices/Printing/printingSlice";
import { debounce } from "lodash";

const usePrintingRecheck = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeCard, setActiveCard] = useState({ id: "", key: "" });
  const [pendingPage, setPendingPage] = useState(1);
  const visitingOneFrontPrintRef = useRef();
  const visitingOneBackPrintRef = useRef();
  const papperFrontPrintRef = useRef();
  const papperBackPrintRef = useRef();
  const bigFrontPrintRef = useRef();
  const dispatch = useDispatch();
  const printingState = useSelector((state) => state.printing);
  const slug = localStorage?.getItem("slug");

  useEffect(() => {
    let params = {
      page: pendingPage,
      per_page: 10,
      process_slug: slug,
    };
    dispatch(getPrintingRecheckList(params));
    // eslint-disable-next-line
  }, [printingState.search, pendingPage, printingState.currentPageSize]);

  const handleSearch = (e) => {
    // dispatch(updateConfig((state) => (state.search = e?.target?.value)));
    const inputValue = e.target.value;
    handleSearchInput(inputValue);
  };

  const handleSearchInput = debounce((searchValue) => {
    dispatch(
      updateConfig((state) => {
        state.cardRecheckData.Pending = [];
      })
    );
    dispatch(
      getPrintingRecheckList({
        search: searchValue,
        page: 1,
      })
    );
  }, 500);

  const handleCardClick = (e) => {
    setActiveCard({ id: e._id, key: "Pending" });
    dispatch(
      updateConfig((state) => {
        state.showTable = true;
        state.selectedId = e?._id;
        state.gli_id = e?.gli_number;
      })
    );
  };

  const handleModalClick = () => {
    dispatch(updateConfig((state) => (state.printModal = !state.printModal)));
  };
  const handleLoadMore = (mouseHover) => {
    if (
      mouseHover === "Pending" &&
      printingState?.cardRecheckData?.Pending?.length !==
        printingState?.total?.Pending
    ) {
      setPendingPage(pendingPage + 1);
    }
  };
  return {
    visitingOneFrontPrintRef,
    visitingOneBackPrintRef,
    papperFrontPrintRef,
    papperBackPrintRef,
    bigFrontPrintRef,
    cardRecheckData: printingState?.cardRecheckData,
    totalLength: { Pending: printingState.total.Pending },
    printingState,
    isLoading,
    activeCard,
    handleSearch,
    setIsLoading,
    handleCardClick,
    handleLoadMore,
    handleModalClick,
  };
};

export default usePrintingRecheck;
