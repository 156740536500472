import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useAddStatus from "./useAddStatus";

export const AddStatus = ({
  handleConfirmModal,
  handleConfirm,
  setSubmissionStatus,
  error,
}) => {
  const { formik, statusData, statusObj, getFieldError } = useAddStatus({
    error,
  });
  return (
    <>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Add Status
        </label>
        <Select
          id="status"
          name="status"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("status") && " error"}`}
          classNamePrefix="pro-input"
          options={statusData ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          value={statusObj}
          onBlur={formik.handleBlur("status")}
          isMulti={false}
          onChange={(value) => {
            formik?.setFieldValue("status", value?.id || null);
            setSubmissionStatus(value?.id);
          }}
          menuPlacement="auto"
        />
        {getFieldError("status") && (
          <span className="error-text">{getFieldError("status")}</span>
        )}
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleConfirmModal}
        >
          Cancel
        </Button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={handleConfirm}
        >
          {"Confirm"}
        </Button>
      </div>
    </>
  );
};

export default AddStatus;
