import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useEstimate from "./useEstimate";
import { FaSort } from "react-icons/fa";

const Estimate = () => {
  const {
    estimateResponse,
    estimateModal,
    paginationOptions,
    getRow,
    handleModal,
    handleYes,
    handlePagination,
    handlePageSize,
  } = useEstimate();
  return (
    <div>
      <div className="pro-d-flex pro-flex-column pro-h-100">
        <HeadingGroup title={`Invoice`} className={`pro-mb-4`} />
        <div className="col-auto pro-pt-3 pro-pb-0 flex-fill pro-d-flex pro-flex-column">
          <Table
            multiSelect={true}
            data={estimateResponse?.data || []}
            uniqueID={"id"}
            fields={estimateResponse?.fields || []}
            showCheckBox={true}
            SortIcon={<FaSort />}
            getRow={getRow}
            perpage={10}
            assignText={"Genarate Estimate"}
            assignIcon={
              <span className="material-symbols-outlined">print</span>
            }
            assignable={true}
            editable={false}
            deletable={false}
            handleAssign={handleModal}
          />
          {estimateResponse?.data?.length > 0 && (
            <Pagination
              currentPage={estimateResponse?.current_page}
              totalPageCount={Math.ceil(
                estimateResponse?.total_count / estimateResponse?.per_page
              )}
              onPageChange={handlePagination}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          )}
          <ModalLayout show={estimateModal} handleClose={handleModal}>
            <div className="pro-m-5">
              <ConfirmationBox
                title={"Are you sure genarate estimate ?"}
                subTitle={"This action can't be undo "}
                cancelText={"No"}
                cancelAction={handleModal}
                submitText={"Yes"}
                a
                submitAction={handleYes}
                isRight={true}
              />
            </div>
          </ModalLayout>
        </div>
      </div>
    </div>
  );
};

export default Estimate;
